import "../Product.scss";
import Dropzone from "react-dropzone";
import {
  faArrowToTop,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import env from "../../../../../environment.json";
import OneMedia from "../../../../../assets/PageModules/OneMedia";

export default function Media({ 
	productState, 
	setProductState,
	uploaded, 
	uploadFiles, 
	draggingOverUpload,
	setDraggingOverUpload,
	draggingIndex,
	setDraggingIndex,
	props,
	setForceUpdate,
}) {
	return (
		<div className="media">
			<h3>Common images</h3>
			<div className="wrap-image">
				<div className="drag-drop">
					{uploaded === 100 ? (
						<div className="dropzone">
							<Dropzone
								onDrop={(e) => {
									uploadFiles(e);
									setDraggingOverUpload(false);
								}}
								onDragEnter={() => {
									setDraggingOverUpload(true);
								}}
								onDragLeave={() => {
									setDraggingOverUpload(false);
								}}
							>
								{({ getRootProps, getInputProps }) => (
									<section>
										<div {...getRootProps()}>
											<input
												{...getInputProps()}
												accept=".jpg,.jpeg,.png,.gif,.mp4"
												maxLength={1}
											/>
											<div
												className={
													"upload-box" +
													(draggingOverUpload ? " drag-over" : "")
												}
											>
												<div className="text-content">
													<FontAwesomeIcon icon={faArrowToTop} />
													<div>UPLOAD IMAGES/VIDEOS</div>
												</div>
											</div>
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					) : (
						<div className="upload-bar">
							<div
								className="progress-bar"
								style={{
									width: uploaded + "%",
								}}
							/>
						</div>
					)}
					<div
						className="add"
						onClick={() => {
							if (uploaded === 100) {
								props.openOverlay("mediaChoose", {
									filter: { mediaType: ["images", "videos"] },
									singleMedia: false,
									afterFunc: (e) => {},
								});
							}
						}}
					>
						<FontAwesomeIcon icon={faPlus} />
					</div>
				</div>
				<div className="images">
					{cloneDeep(productState.media)
						.filter((x) => !x.deleted)
						.map((element, i) => {
							element.order_value = i;
							let url = "";
							if (element.external_url) {
								url = element.external_url;
							}
							if (element.file_name) {
								url =
									env.protocol +
									env.env +
									"/uploads/" +
									element.file_name +
									"_thumbnail." +
									element.extension;
							}
							return (
								<div key={element.id + i} className="wrap-onemedia">
									{element.media_id ? (
										<OneMedia
											key={"oneMedia" + i}
											media={{
												id: element.media_id,
												extension: element.extension,
												alt: element.alt,
												file_name: element.file_name,
											}}
											disableChange={true}
											imgFormat="thumbnail"
											videoThumbnail={true}
											draggable={true}
											onDragStart={(e) => {
												setDraggingIndex(i);
											}}
											onDragEnd={(e) => {
												setDraggingIndex(undefined);
											}}
											onDrop={(e) => {
												const swap = (arr, a, b) => {
													var temp = arr[a];
													arr[a] = arr[b];
													arr[b] = temp;
													return arr;
												};
												if (draggingIndex !== undefined) {
													let copyMedia = cloneDeep(productState.media);
													swap(copyMedia, i, draggingIndex);
													setProductState({
														...productState,
														media: copyMedia,
													});
													setDraggingIndex(undefined);
												}
											}}
											onDragOver={(e) => {
												e.preventDefault();
											}}
										/>
									) : (
										<div className="one-media ">
											<div className="upload-box">
												<div className="image-container" draggable="true">
													<img
														alt="Shop Product"
														onError={(e) => {
															e.target.onerror = null; // prevents looping
															e.target.src =
																"https://dæk.dk/import/product-images/.jpg?width=80&height=110&mode=pad&404=fotopaavej&bgcolor=ffffff";
														}}
														src={element.external_url ?? url}
													/>
												</div>
											</div>
										</div>
									)}
									<div
										onClick={() => {
											let findex = productState.media.findIndex(
												(x) => x.id === element.id
											);
											productState.media[findex].deleted = true;
											setForceUpdate((f) => !f);
										}}
										className="remove-image"
									>
										<FontAwesomeIcon icon={faTrashAlt} />
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
}
