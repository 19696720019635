import "../Product.scss";
import InventoryPrice from "./InventoryPrice";
import InventoryStock from './InventoryStock';
import InventoryCalculations from "./InventoryCalculations";

export default function EditInventory({
	editInventory,
	setEditInventory,
	productState,
	setForceUpdate,
	props,
	saveProduct,
	prices,
}) {

	return (
		<div className="editInventoryComponent">
			<div className="left-content">
				<InventoryPrice 
					editInventory={editInventory}
					productState={productState}
					setForceUpdate={setForceUpdate}
				/>
				<InventoryStock 
					editInventory={editInventory}
					setForceUpdate={setForceUpdate}
					props={props}
				/>
			</div>
			<InventoryCalculations 
				editInventory={editInventory}
				setEditInventory={setEditInventory}
				productState={productState}
				props={props}
				saveProduct={saveProduct}
				prices={prices}
			/>
		</div>
	);
}
