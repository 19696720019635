// Importing the raw modules configuration and the Config type definition.
import _modules from "src/modules.json";
import { TFeatureConfig } from "src/types";

/**
 * Validates the structure and content of the imported configuration against the expected format.
 * Specifically, it checks if the status field of each feature is one of the allowed values.
 *
 * @param config - The raw configuration object to validate.
 * @returns {boolean} - True if the configuration adheres to the expected format and content, otherwise false.
 */
export function validateConfig(config: any): config is TFeatureConfig {
	for (const feature in config) {
		const featureConfig = config[feature];
		// Check if the feature's status is one of the allowed values.
		if (featureConfig.status !== "alpha" && featureConfig.status !== "beta" && featureConfig.status !== "stable") {
			console.error(`Invalid status value for feature ${feature}: ${featureConfig.status}`);
			return false;
		}
	}
	return true;
}

// Immediately validate the imported modules configuration.
// If validation fails, an error is thrown, preventing further execution.
if (!validateConfig(_modules)) {
	throw new Error("Invalid modules.json configuration");
}

// Export the validated configuration, ensuring it adheres to the Config type.
export const config: TFeatureConfig = _modules;
