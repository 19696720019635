import "../Product.scss";
import {
	faArrowToTop,
	faChevronDown,
	faChevronUp,
	faPlus,
	faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropzone from "react-dropzone";
import env from "../../../../../environment.json";
import OneMedia from "../../../../../assets/PageModules/OneMedia";
import { cloneDeep } from "lodash";
import { useState } from "react";

export default function Attribute({
	productState,
	setProductState,
	setForceUpdate,
	uploaded,
	uploadFiles,
	draggingOverUpload,
	setDraggingOverUpload,
	draggingIndex,
	setDraggingIndex,
	props,
}) {

	const [openVarient] = useState([]);

	return (
		<div className="select-varient">
			<h3>Varients</h3>
			<div className="wrap-varients">
				{productState.varient.map((element) => {
					let findex = openVarient.findIndex((x) => x === element.varient_id);
					
					return (
						<div key={element.id}>
							<div>
								<div>
									<input
										onChange={(e) => {
											element.checked = e.target.checked;
											setForceUpdate((f) => (f ? false : true));
										}}
										defaultChecked={element.checked}
										type="checkbox"
									></input>
									{element.label}
								</div>
								<div
									onClick={() => {
										if (findex === -1) {
											openVarient.push(element.varient_id);
										} else {
											openVarient.splice(findex, 1);
										}
										setForceUpdate((f) => !f);
									}}
								>
									{findex === -1 ? (
										<FontAwesomeIcon icon={faChevronDown} />
									) : (
										<FontAwesomeIcon icon={faChevronUp} />
									)}
								</div>
							</div>

							<div
								className="wrap-table"
								style={{
									display: openVarient.includes(element.varient_id)
										? "flex"
										: "none",
								}}
							>
								<table>
									<tbody>
										<tr>
											<th>Name</th>
											<th>Value</th>
										</tr>

										{element.properties.map((item) => {
											return (
												<tr key={item.label}>
													<td>{item.label}</td>
													<td>{item.value}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
								<div className="media">
									<div className="wrap-image">
										<div className="drag-drop">
											{uploaded === 100 ? (
												<div className="dropzone">
													<Dropzone
														onDrop={(e) => {
															uploadFiles(e);
															setDraggingOverUpload(false);
														}}
														onDragEnter={() => {
															setDraggingOverUpload(true);
														}}
														onDragLeave={() => {
															setDraggingOverUpload(false);
														}}
													>
														{({ getRootProps, getInputProps }) => (
															<section>
																<div {...getRootProps()}>
																	<input
																		{...getInputProps()}
																		accept=".jpg,.jpeg,.png,.gif,.mp4"
																		maxLength={1}
																	/>
																	<div
																		className={
																			"upload-box" +
																			(draggingOverUpload ? " drag-over" : "")
																		}
																	>
																		<div className="text-content">
																			<FontAwesomeIcon icon={faArrowToTop} />
																			<div>UPLOAD IMAGES/VIDEOS</div>
																		</div>
																	</div>
																</div>
															</section>
														)}
													</Dropzone>
												</div>
											) : (
												<div className="upload-bar">
													<div
														className="progress-bar"
														style={{
															width: uploaded + "%",
														}}
													/>
												</div>
											)}
											<div
												className="add"
												onClick={() => {
													if (uploaded === 100) {
														// props.setModuleKeyInEdit("media_add");
														props.openOverlay("mediaChoose", {
															filter: { mediaType: ["images", "videos"] },
															singleMedia: false,
															unique: element.sp_uuid ?? element.varient_id,
														});
													}
												}}
											>
												<FontAwesomeIcon icon={faPlus} />
											</div>
										</div>

										<div className="images">
											{element.media
												.filter((x) => !x.deleted)
												.map((elm, i) => {
													let url = "";
													if (elm.external_url) {
														url = element.external_url;
													}
													if (elm.file_name) {
														url =
															env.protocol +
															env.env +
															"/uploads/" +
															elm.file_name +
															"_thumbnail." +
															elm.extension;
													}
													elm.order_value = i;
													return (
														<div key={elm.id + i} className="wrap-onemedia">
															{elm.media_id ? (
																<OneMedia
																	key={"oneMedia" + i}
																	media={{
																		id: elm.media_id,
																		extension: elm.extension,
																		alt: elm.alt,
																		file_name: elm.file_name,
																	}}
																	disableChange={true}
																	imgFormat="thumbnail"
																	videoThumbnail={true}
																	draggable={true}
																	onDragStart={(e) => {
																		setDraggingIndex(i);
																	}}
																	onDragEnd={(e) => {
																		setDraggingIndex(undefined);
																	}}
																	onDrop={(e) => {
																		const swap = (arr, a, b) => {
																			var temp = arr[a];
																			arr[a] = arr[b];
																			arr[b] = temp;
																			return arr;
																		};
																		if (draggingIndex !== undefined) {
																			let copyMedia = cloneDeep(
																				productState.media
																			);
																			swap(copyMedia, i, draggingIndex);
																			setProductState({
																				...productState,
																				media: copyMedia,
																			});
																			setDraggingIndex(undefined);
																		}
																	}}
																	onDragOver={(e) => {
																		e.preventDefault();
																	}}
																/>
															) : (
																<div className="one-media ">
																	<div className="upload-box">
																		<div
																			className="image-container"
																			draggable="true"
																		>
																			<img
																				alt="Upload media"
																				onError={(e) => {
																					e.target.onerror = null;
																					e.target.src =
																						"https://dæk.dk/import/product-images/.jpg?width=80&height=110&mode=pad&404=fotopaavej&bgcolor=ffffff";
																				}}
																				src={elm.external_url ?? url}
																			/>
																		</div>
																	</div>
																</div>
															)}
															<div
																onClick={() => {
																	element.media[i].deleted = true;
																	setForceUpdate((f) => !f);
																}}
																className="remove-image"
															>
																<FontAwesomeIcon icon={faTrashAlt} />
															</div>
														</div>
													);
												})}
										</div>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
