import { useEffect, useState } from "react";
import "./LinkToEdit.scss";
import "./IconSettings.scss";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { VfiCheckbox2 } from "../../VfiCheckbox";
import env from "../../../environment.json";

export default function IconSettings(props) {
  const [innerValues, setInnerValues] = useState(props.values);
  const [screen, setScreen] = useState("info");
  const { change, openInfo } = props;
  useEffect(() => {
    setInnerValues(props.values);
  }, [props.values]);
  const changeFunc = (name, value) => {
    const theChange = { ...innerValues, [name]: value };
    change(theChange);
    setInnerValues(theChange);
  };
  return (
    <div className="icon-settings edit-module" key={innerValues.key}>
      <div className="top">
        <div
          className={"top-choice " + (screen === "info" ? "active " : "")}
          onClick={() => setScreen("info")}
        >
          SELECT ICON
        </div>
        <div
          className="close"
          onClick={() => {
            props.close();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="content">
        <label className="option description">
          <div
            className="info"
            onClick={() => {
              if (openInfo) {
                openInfo(
                  <div>
                    <h1>Upload and select icon</h1>
                    <p>Is used to add a icon above the text area of image.</p>
                  </div>
                );
              }
            }}
          >
            i
          </div>
        </label>
        <label className="option whole-click">
          <div className="label">Enable Icon</div>
          <div className="value">
            <VfiCheckbox2
              style={{ pointerEvents: "none" }}
              checked={innerValues.iconSelected}
              isRadio={true}
              onChange={(e) => {
                changeFunc("iconSelected", e.target.checked);
              }}
            />
          </div>
        </label>
        {innerValues.iconSelected && (
          <label className="select-icon">
            <div className="label">Select Icon</div>
            <div className="wrap-icon">
              <div className="icon">
                <img
                  src={
                    innerValues.icon
                      ? env.protocol +
                        env.env +
                        "/uploads/" +
                        innerValues.icon.file_name +
                        "." +
                        innerValues.icon.extension
                      : ""
                  }
                />
                <div
                  className="add"
                  onClick={() => {
                    props.openOverlay(
                      "mediaChoose",
                      {
                        filter: { mediaType: ["svg", "icon"] },
                        singleMedia: true,
                      },
                      props.index,
                      "media_icon"
                    );
                    props.close();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            </div>
          </label>
        )}
      </div>
    </div>
  );
}
