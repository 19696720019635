import "./Analytics.scss";
import {
  AnalyticsDashboard,
  SessionsByDateChart,
  SessionsGeoChart,
  ActiveUsersChart,
  SessionsBySourceChart,
  PageViewsPerPathChart,
  SessionsByDeviceCategoryChart,
} from "react-analytics-charts";

import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import env from "../../../environment.json";
// MUST BE SWAPPED WITH VFI's SERVICE, AFTER IT IS SET UP
const boardId = "ga:32306756";
const clientId =
env.env.includes("vita.fo")
    ? "606871580021-608qsovg6cp9ffe1usslm8tjoeh2bi4b.apps.googleusercontent.com"
    : "693542265025-raio2ci5cvpvtm42t3na9kn787mf9qdr.apps.googleusercontent.com";
//397130117.1640538113

export const chartsShown = {
  geoChart: true,
  byDateChart: true,
  activeUsersChart: true,
  bySourceChart: true,
  byDeviceCategoryChart: true,
  pageViewsPerPathChart: true,
};

export default function Analytics({
  hideAuth = false,
  filters,
  viewId,
  charts = chartsShown,
}) {
  const days = 9;
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [boardId, setBoardId] = useState(null);
  const [startDate, changeStartDate] = useState(
    new Date(new Date() - 1000 * 60 * 60 * 24 * 7)
  ); // 7 days ago
  const [endDate, changeEndDate] = useState(new Date());
  const [startDateString, changeStartDateString] = useState("7daysAgo");
  const [endDateString, changeEndDateString] = useState("today");

  useEffect(() => {
    if (viewId === undefined) {
      axios
        .post(env.protocol + env.env + "/api/analytics", {
          userId: sessionStorage.getItem("vfiUser"),
        })
        .then((response) => {
          const opt = [];
          response.data.map((e) => {
            opt.push({
              value: e.siteId,
              label: e.siteName,
              viewId: e.viewId,
            });
          });
          setOptions(opt);
          setSelectedOption(opt[0]);
          setBoardId(opt[0].viewId);
        })
        .catch((error) => console.error(error));
    }
    if (viewId !== undefined) {
      setBoardId(viewId);
    }
  }, [viewId]);

  const handleChange = (opt) => {
    setSelectedOption(null);
    setTimeout(() => {
      setBoardId(opt.viewId);
      setSelectedOption(opt);
    }, 1);
  };

  const dimensionParam = () => {
    const daysDiff =
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);

    if (daysDiff < 100) {
      return 1;
    }
    if (daysDiff >= 100) {
      return 0;
    }
  };

  return (
    <>
      <div className="analytics-wrapper" key={"This is analytics"}>
        <h1 className="header-text">Analytics</h1>
        {selectedOption || viewId ? (
          boardId ? (
            <AnalyticsDashboard
              hideAuth={hideAuth}
              viewId={boardId}
              hideViewSelector={true}
              authOptions={{
                clientId,
              }}
              signOutButtonText="Sign Out of Analytics"
              renderCharts={(gapi, viewId2) => {
                return (
                  <div>
                    {viewId === undefined && (
                      <div className="header-sel">
                        <div className="select">
                          <Select
                            value={selectedOption}
                            onChange={handleChange}
                            options={options.map((e) => {
                              return {
                                ...e,
                                label:
                                  e.label +
                                  (!e.viewId
                                    ? " (missing google analytics)"
                                    : ""),
                              };
                            })}
                            isOptionDisabled={(option) => !option.viewId}
                          />
                        </div>
                      </div>
                    )}
                    <div className={"dates"}>
                      <span className="from">From</span>
                      <DatePicker
                        selected={startDate}
                        popperPlacement="bottom-start"
                        onChange={(date) => {
                          var given = moment(date, "YYYY-MM-DD");
                          var current = moment().startOf("day");
                          //Difference in number of days
                          var days = Math.ceil(
                            moment.duration(current.diff(given)).asDays()
                          );

                          changeStartDateString(days + "daysAgo");
                          changeStartDate(date);
                        }}
                        dateFormat="dd/MM/yy"
                        maxDate={endDate}
                      />
                      <span className="to">To</span>
                      <DatePicker
                        selected={endDate}
                        popperPlacement="bottom-start"
                        onChange={(date) => {
                          var given = moment(date, "YYYY-MM-DD");
                          var current = moment().startOf("day");
                          //Difference in number of days
                          var days = Math.ceil(
                            moment.duration(current.diff(given)).asDays()
                          );

                          changeEndDateString(days + "daysAgo");
                          changeEndDate(date);
                        }}
                        dateFormat="dd/MM/yy"
                        maxDate={new Date()}
                        minDate={startDate}
                      />
                    </div>
                    <div className="charts-wrapper">
                      {charts.geoChart && (
                        <div className="geo-chart-wrapper">
                          <SessionsGeoChart
                            gapi={gapi}
                            // viewId={viewId}
                            query={{
                              ids: viewId2,
                              "start-date": startDateString,
                              "end-date": endDateString,
                              metrics: "ga:sessions",
                              // filters: 'ga:pageTitle==About us'
                              filters,
                            }}
                            container="europe-traffic-geo-chart"
                            showPageViews
                            options={{
                              width: "100%",
                              height: "100px",
                            }}
                          ></SessionsGeoChart>
                        </div>
                      )}

                      {charts.byDateChart && (
                        <SessionsByDateChart
                          gapi={gapi}
                          query={{
                            ids: viewId2,
                            "start-date": startDateString,
                            "end-date": endDateString,
                            // filters: 'ga:pageTitle==About us'
                            filters,
                            dimensions: "ga:date",
                          }}
                          showPageViews
                          container="Session-by-date"
                          showUsers
                          options={{
                            title: "Sessions",
                            lineWidth: 1,
                            pointSize: dimensionParam(),
                          }}
                        />
                      )}
                      {charts.activeUsersChart && (
                        <ActiveUsersChart
                          gapi={gapi}
                          query={{
                            ids: viewId2,
                            "start-date": startDateString,
                            "end-date": endDateString,
                            // filters,
                            // dimensions:dimensionParam(),
                          }}
                          container="Active-users-chart"
                          options={{
                            lineWidth: 1,
                            pointSize: dimensionParam(),
                          }}
                          days={days}
                        />
                      )}
                      {charts.bySourceChart && (
                        <SessionsBySourceChart
                          gapi={gapi}
                          query={{
                            ids: viewId2,
                            "start-date": startDateString,
                            "end-date": endDateString,
                            filters,
                          }}
                          container="Session-by-source"
                          options={{
                            title: "Sessions By Source",
                          }}
                        />
                      )}
                      {charts.byDeviceCategoryChart && (
                        <SessionsByDeviceCategoryChart
                          gapi={gapi}
                          query={{
                            ids: viewId2,
                            "start-date": startDateString,
                            "end-date": endDateString,
                            filters,
                          }}
                          container="Session-by-device"
                          options={{
                            title: "Sessions By Device Category",
                          }}
                        />
                      )}
                      {charts.pageViewsPerPathChart && (
                        <PageViewsPerPathChart
                          gapi={gapi}
                          query={{
                            ids: viewId2,
                            "start-date": startDateString,
                            "end-date": endDateString,
                            filters,
                          }}
                          container="Page-view-per-path"
                        />
                      )}
                    </div>
                  </div>
                );
              }}
            />
          ) : (
            "No data to collect"
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}
