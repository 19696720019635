import React, { Component } from "react";

class BetweenBranch extends Component {
  onDragEnter = (e) => {};

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDragLeave = (e) => {};

  onDrop = (e) => {
    this.props.onDrop(e);
  };

  render() {
    const isOver = this.props.isOver ? "is-over " : "";
    const lastRoot = this.props.lastRoot ? "last-root " : "";

    return (
      <div
        className={"between-branch " + isOver + lastRoot}
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        childof={this.props.childOf}
        ordervalue={this.props.orderValue}
        isbetween="true"
        draggable="false"
      />
    );
  }
}

export default BetweenBranch;
