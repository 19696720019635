import { cloneDeep } from "lodash";
import "./DownloadModule.scss";
import OneMedia from "../OneMedia";
import VfiInputText from "../../VfiInputText/VfiInputText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowToBottom,
  faArrowUpRightFromSquare,
} from "@fortawesome/pro-light-svg-icons";
import VfiDivInput from "../../VfiDivInput";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { LinkToEdit } from "../VfiCarousel";
import { linkToEditLinkOptions } from "../VfiCarousel/LinkToEdit";
// const language = localStorage.getItem("language") ? localStorage.getItem("language") : "english";

export default function DownloadModule(props) {
  const { openOverlay, data, onChange } = props;

  const changeKey = (index, key, value) => {
    let copyData = cloneDeep(data);
    copyData.data[index][key] = value;
    onChange(copyData);
  };

  const changeTo = (index, value) => {
    let copyData = cloneDeep(data);
    copyData.data[index] = value;
    onChange(copyData);
  };

  return (
    <div
      style={props.style}
      className={`download box-amount${props.data.shown_amount} ${props.data.download_type}`} // grid or list
    >
      {data.data.map((dat, i) => {
        if (data.shown_amount <= i) return "";
        return (
          <div
            key={dat.key}
            className={`download-box box${i + 1} ${props.data.download_type}`}
          >
            <div className="media-wrap">
              <OneMedia
                media={{ src: dat.medium ? dat.medium.large : undefined }}
                openOverlay={(what, data) =>
                  openOverlay(what, data, i, "image")
                }
                whatMedia={["images"]}
              />
              <div className="middle-diamond" />
            </div>
            <div className="lower-wrap">
              {/* <VfiInputText className="title" defaultValue={dat.title} onBlur={(e)=>{change(i,"title",e.target.value)}} />
              <VfiInputText className="subtitle" defaultValue={dat.subtitle} onBlur={(e)=>{change(i,"subtitle",e.target.value)}} /> */}
              <VfiDivInput
                className="title"
                defaultValue={dat.title}
                onBlur={(e) => {
                  changeKey(i, "title", e.target.innerHTML);
                }}
              />
              <VfiDivInput
                className="subtitle"
                defaultValue={dat.subtitle}
                onBlur={(e) => {
                  changeKey(i, "subtitle", e.target.innerHTML);
                }}
              />
              <div className="button-wrap">
                <div className="button">
                  <VfiInputText
                    className="button-label"
                    defaultValue={dat.button_label}
                    onChange={(e) => {
                      changeKey(i, "button_label", e.target.value);
                    }}
                  />
                  {dat.link_option === "download" && (
                    <div className="download-button">
                      <FontAwesomeIcon icon={faArrowToBottom} />
                    </div>
                  )}
                  {dat.link_option === "external_url" && (
                    <div className="download-button">
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </div>
                  )}
                  <div
                    className="download-module-options"
                    onClick={(e) => {
                      props.setOptionsList(
                        <LinkToEdit
                          change={(e) => {
                            changeTo(i, e);
                          }}
                          // headline={}
                          selectedLanguage={props.selectedLanguage}
                          values={dat}
                          linkOptions={[
                            ...[{ label: "Download", value: "download" }],
                            ...linkToEditLinkOptions,
                          ]}
                          openOverlay={openOverlay}
                          customContents={{
                            download: (
                              <div>
                                <div className="option link fifty-fifty">
                                  <div
                                    className="label button"
                                    onClick={() => {
                                      openOverlay(
                                        "mediaChoose",
                                        {
                                          singleMedia: true,
                                        },
                                        i,
                                        "download"
                                      );
                                    }}
                                  >
                                    Select File
                                  </div>
                                  <div className="value">
                                    <VfiInputText
                                      placeholder="File Name"
                                      disabled={true}
                                      value={
                                        dat.medium_download?.info
                                          ? `${dat.medium_download.info.file_name}.${dat.medium_download.info.file_extension}`
                                          : ""
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            ),
                          }}
                        />,
                        { x: e.pageX, y: e.pageY },
                        true,
                        true
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
