
/**
 * Validator class
 * 
 * Has various validation methods for use with data validation
 * 
 * @author 					Pætur Mortensen
 */
export default class Validator{
	input;
	success = true;

	constructor(input : string | number){
		this.input = input;
	}


	/**
	 * Check that the input is not empty (not falsey value except int:0)
	 * 
	 * @author 					Pætur Mortensen
	 */
	not_empty() : Validator {
		if(!this.input && this.input !== 0) this.success = false;

		return this;
	}

	/**
	 * Check that input has at least the given number of characters
	 * 
	 * @author 					Pætur Mortensen
	 */
	min_chars(numChars : number) : Validator {
		// Only check if input is not empty (not empty is checked elsewhere)
		if(this.input){
			// Check against minimum length
			if(this.input.toString().length < numChars) this.success = false;
		}

		return this;
	}

	/**
	 * Check that the input has at most the given number of characters
	 * 
	 * @author 					Pætur Mortensen
	 */
	max_chars(numChars : number) : Validator {
		if(this.input.toString.length > numChars) this.success = false;

		return this;
	}

	/**
	 * Get validation success
	 * 
	 * @author 					Pætur Mortensen
	 */
	get_success() : boolean {
		return this.success;
	}

	/**
	 * Check valid email
	 * 
	 * @author 					Pætur Mortensen 
	 */
	valid_email() : Validator {
  	// Regex match email
  	if (
   	 String(this.input).match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/i) ===
   	 null
  	) this.success = false;

		return this;
	}

	/**
	 * Check valid FQDN (Fully Qualified Domain Name)
	 * 
	 * @author 					Pætur Mortensen 
	 */
	valid_FQDN() {
  	// Regex match fqdn
  	if(!String(this.input).match(
    	/(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/i
  	)) this.success = false;

		return this;
	}
}