import type { section, sectionView } from "../ArticlesDisplay/types";

interface site {
  siteId: number;
  name: string;
}

export function getUserSections(sites: site[]): section[] {
  const siteList: sectionView[] = sites.map((site) => ({
    name: site.name,
    key: "site" + site.siteId,
    checkType: "sites",
    checkId: site.siteId,
    checkMinimumRole: "Admin",
  }));

  return [
    {
      title: "All USERS",
      views: [{ name: "All", key: "1" }],
    },
    {
      title: "SITE",
      views: [
        // "smart phrases",
        { name: "All", key: "allSites", checkType: "sites" },
        ...siteList,
      ],
    },
    {
      title: "PLACES",
      views: [
        // "smart phrases",
        { name: "All places", key: "3", checkType: "places" },
      ],
    },
    {
      title: "TOUR PROVIDERS",
      views: [
        { name: "All tour providers", key: "3", checkType: "tourOperators" },
      ],
    },
  ];
}
