import noImage from "../../../images/empty.jpg"
import { IMedia } from "../types";

export const determineExtension = (media: IMedia, srcExtension: string | undefined, ext: string | null | undefined, foundExtension: string | null | undefined) => {
	if (!media) return undefined;
	if (srcExtension) return srcExtension;
	if (ext !== undefined && ext !== "") return ext;
	if (foundExtension !== undefined) return foundExtension;
	return undefined;
};

export const getFallbackImage = () => {
	return noImage;
};
