/**
 * Validate input as valid email address
 *
 * @param 		{string} 	input 									Input string to test against
 *
 * @returns 	{bool} 														Whether input string is valid email
 *
 * @author 					Pætur Mortensen
 */
function validate_email(input) {
  // Regex match email
  return (
    String(input).match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,63})+$/i) !==
    null
  );
}

/**
 * Validate input as FQDN (Fully Qualified Domain Name)
 *
 * @param 		{string} 	input 								Input string to test against
 *
 * @returns 	{bool} 													Whether input string is valid FQDN
 *
 * @author 						Pætur Mortensen
 */
function validate_FQDN(input) {
  // Regex match fqdn
  return String(input).match(
    /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/i
  );
}

export { validate_email, validate_FQDN };