import styles from './MediaCenter.module.scss';
import { MediaItemCardType, MediaMeta, DownloadSizes } from "./types";
import { faClose, faChevronRight, faChevronLeft, faDownload, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { handle_image_error } from "src/utils/mediaHelpers";
import { useState, useEffect } from 'react';
import Button from '../Buttons/Button';
import axios from 'axios';
import env from '../../environment.json';
import Preloader from '../../assets/Preloader';
import Modal from '../Modal';

/**
 * Card with single media item
 * 
 * @author 					Pætur Mortensen 
 */
export default function MediaItemCard({ 
	medium,
	close,
	shift,
	reload_media,
	insert_media,
} : MediaItemCardType ) : JSX.Element{
	// Media meta information (and original media for comparison)
	const [ mediaMeta, setMediaMeta ] = useState<MediaMeta>(
		{name:medium.name, description:medium.description}
	);
	const [ serverMeta, setServerMeta ] = useState<MediaMeta>(
		{name:medium.name, description:medium.description}
	);
	// Whether there are changes in media (user can call update)
	const [ hasUpdated, setHasUpdated ] = useState<boolean>(false);
	// Whether data is currently loading
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	// Is loading for the delete modal
	const [ isDeleteLoading, setIsDeleteLoading ] = useState<boolean>(false);
	// Whether we need to confirm deleting the item
	const [ confirmDelete, setConfirmDelete ] = useState<boolean>(false);
	// Whether we are selecting download type
	const [ selectDownload, setSelectDownload ] = useState<boolean>(false);
	// Selected download size if download is wanted
	const [ selectedDownloadSize, setSelectedDownloadSize ] = useState<DownloadSizes>('');
	

	// Possible download sizes for image media items
	const downloadSizes = [
		{label:'Original file', value:''},
		{label:'Large', value:'_large'},
		{label:'Medium', value:'_medium'},
		{label:'Thumbnail', value:'_thumbnail'}
	];

	// Check for changes
	useEffect(() => {
		if(hasUpdated && !check_for_changes()) setHasUpdated(false);
		if(!hasUpdated && check_for_changes()) setHasUpdated(true);
	},[mediaMeta, serverMeta]);

	/**
	 * Check whether there are changes (whether server and local meta are not the same)
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function check_for_changes() : boolean {
		return (mediaMeta.name !== serverMeta.name || mediaMeta.description !== serverMeta.description);
	}

	/**
	 * Delete the media item
	 * 
	 * Request the server to delete the item (set isDeleted to '1' in table media)
	 * 
	 * @author 					Pætur Mortensen
	 */
	function delete_media() : void {
		setIsDeleteLoading(true);
		axios
			.get(
				env.protocol + env.env + '/api/secured/media/DeleteMediaItem?mediaID=' + medium.mediaID
			)
			.then( response => {
				console.log(response.data);
				setIsDeleteLoading(false);
				setConfirmDelete(false);
				reload_media();
			})
			.catch( error => {
				console.error(error);
				setIsDeleteLoading(false);
				setConfirmDelete(false);
			});
	}

	/**
	 * Download the media element. Sends the user to the media url in new tab
	 * 
	 * @author 					Pætur Mortensen
	 */
	function download_media() : void {
		const downloadURL = 
			env.protocol + env.env + '/uploads/' + 
			medium.fileName + selectedDownloadSize + '.' + medium.fileExtension;

		window.open(downloadURL, '_blank');		
	}

	/**
	 * Insert the media
	 * 
	 * @author 					Pætur Mortensen
	 */
	function handle_insert() : void {
		insert_media(medium.mediaID);
	}

	/**
	 * Update media information
	 * 
	 * Request server with new information and retrieve updated data
	 * 
	 * @author 					Pætur Mortensen
	 */
	function update_media() : void {
		setIsLoading(true);
		axios
			.post(
				env.protocol + env.env + '/api/secured/media/SetMediaMeta',
				{...mediaMeta, mediaID:medium.mediaID}
			)
			.then( response => {
				setMediaMeta(response.data);
				setServerMeta(response.data);
				setIsLoading(false);
				reload_media();
			})
			.catch( error => {
				console.error(error);
				setIsLoading(true);
			});
	}

/***************************************************************************************************
 * 		
 * 																		RENDER
 * 
 **************************************************************************************************/

	/**
	 * Render the card header with card controls (previous, next, close card)
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_card_header() : JSX.Element {
		return (
			<div className={styles.mediaItemHeader}>
				<FontAwesomeIcon
					icon={faChevronLeft as IconProp}
					className={styles.mediaItemCPBtn}
					onClick={() => {
						shift("previous");
					}}
				/>
				<FontAwesomeIcon
					icon={faChevronRight as IconProp}
					className={styles.mediaItemCPBtn}
					onClick={() => {
						shift("next");
					}}
				/>
				<FontAwesomeIcon
					icon={faClose as IconProp}
					className={styles.mediaItemCPBtn}
					onClick={close}
				/>
			</div>
		);
	}

	/**
	 * Render media example with media, information and download/delete buttons
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_media_example(): JSX.Element {
		return (
			<div className={styles.mediaItemExample}>
				<div className={styles.mediaItem}>
					<img src={"imgSrc"} onError={handle_image_error} />
				</div>
				<div className={styles.mediaItemInfo}>
					<div>{medium.date}</div>
					<div>
						<b>{medium.fileSize}</b>
						<br />
						{medium.width > 0 && (
							<span className={styles.imageDimensions}>
								{medium.width} X {medium.height}
							</span>
						)}
					</div>
				</div>
				<div className={styles.mediaItemCP}>
					<FontAwesomeIcon
						icon={faDownload as IconProp}
						className={styles.mediaBtn}
						onClick={() => {setSelectDownload(true)}}
					/>
					{selectDownload && 
						<Modal 
							title="Download media" 
							type='confirmation'
							confirmButtonText='Download'
							cancelButtonText='Cancel'
							onConfirm={download_media}
							onCancel={() => {setSelectDownload(false)}}
							close={() => {setSelectDownload(false)}}
						>
							Select download size
							<select 
								value={selectedDownloadSize}
								onChange={(e) => {
									setSelectedDownloadSize(e.target.value as DownloadSizes);
								}}
							>
								{downloadSizes.map( item => {
									return <option key={item.value + '_'} value={item.value}>{item.label}</option>
								})}
							</select>
						</Modal>
					}
					<FontAwesomeIcon
						icon={faTrashAlt as IconProp}
						className={styles.mediaBtn}
						onClick={() => {setConfirmDelete(true)}}
					/>
					{confirmDelete && 
						<Modal 
							title="Delete media" 
							onConfirm={delete_media}
							onCancel={() => {setConfirmDelete(false)}}
							type="confirmation"
							close={() => {setConfirmDelete(false)}}
							confirmButtonText='Yes'
							cancelButtonText='No'
						>
							You are about to delete this media item. 
							<br />
							Are you sure you want to delete it?
							<br />
							<Preloader show={isDeleteLoading} />
						</Modal>}
				</div>
			</div>
		);
	}

	/**
	 * Render the media meta information form
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_media_meta() : JSX.Element {
		return (
			<div className={styles.mediaItemMeta}>
				<div className={styles.mediaMetaFields}>
				<div>
					Name
					<br />
					<input 
						type="text" 
						value={mediaMeta.name}
						onChange={ e => {
							setMediaMeta({...mediaMeta, name:e.target.value});
						}}
					/>
				</div>
				<div>
					Description
					<br />
					<textarea
						rows={8}
						value={mediaMeta.description}
						onChange={ e => {
							setMediaMeta({...mediaMeta, description:e.target.value});
						}}
					>
					</textarea>
				</div>
				</div>
				<div className={styles.mediaMetaButtons}>
					<Preloader show={isLoading} />
					<Button 
						size='large'
						onClick={update_media}
						type="black"
						disabled={!hasUpdated}
					>
						Update
					</Button>
					<Button 
						size='large'
						onClick={handle_insert}
						type="black"
					>
						Insert
					</Button>
				</div>
			</div>
		);
	}

	return (
		<tr key="mediaCard">
			<td colSpan={6}>
				<div className={styles.mediaItemCard}>
						{render_card_header()}
					<div className={styles.mediaItemMain}>
						{render_media_example()}
						{render_media_meta()}
					</div>
				</div>
			</td>
		</tr>
	);
}