// Importing the raw environment configuration.
import _environment from "../environment.json";

/**
 * Type definition for the application's environment.
 * This type ensures that the environment is one of the predefined values.
 */
export type AppEnvironment = 'production' | 'test' | 'development';

/**
 * Validates the structure and content of the imported environment configuration.
 * Specifically, it checks for the presence of the APP_ENV key.
 *
 * @param env - The raw environment configuration to validate.
 * @returns {boolean} - True if the configuration contains the APP_ENV key, otherwise false.
 */
export function validateEnvironment(env: any): env is { APP_ENV: string } {
	if (!env.APP_ENV) {
		console.error("Missing APP_ENV key in environment.json");
		return false;
	}
	return true;
}

// Immediately validate the imported environment configuration.
// If validation fails, an error is thrown, preventing further execution.
if (!validateEnvironment(_environment)) {
	throw new Error("Invalid environment configuration in environment.json");
}

// Export the validated environment configuration, ensuring it adheres to the expected structure.
export const environment = _environment as { APP_ENV: AppEnvironment, env: string, protocol: string, theme: string };
