import { cloneDeep } from "lodash";
import WhatsonList from "../../../../Components/Content/WhatsonDisplay/WhatsonList";
import { searchFilter, whatsonContentPush } from "../../../helperFunctions";
import "./WhatsonList.scss";
import { sortList as hSortList } from "../../../helperFunctions";
import { useEffect, useState } from "react";

const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export default function WhatsonList2(props) {
  const { data, onChange, allWhatson, whatsonTypes, search } = props;
  const [shownWhatson, setShownWhatson] = useState(allWhatson);
  const [sortedBy, setSortedBy] = useState("date");
  const [reverseSort, setReverseSort] = useState(false);
  const [showAmount, setShowAmount] = useState(30);
  // const allWhatsonSearchFiltered = searchFilter(allWhatson,search);

  useEffect(() => {
    let finalWhatsonList = cloneDeep(allWhatson);

    finalWhatsonList = searchFilter(finalWhatsonList, search);
    const sortedList = hSortList(
      sortedBy,
      finalWhatsonList.map((e) => whatsonContentPush(e)),
      reverseSort,
      true
    );
    let newSorted = [];
    sortedList.forEach((element) => {
      newSorted.push(finalWhatsonList.find((e) => e.id === element.id));
    });
    setShownWhatson(newSorted);
  }, [search, sortedBy, reverseSort, allWhatson]);

  const wTypeObj = whatsonTypes.find((e) => e.id === data.whatWhatson);
  let wTypeName = "";
  if (wTypeObj) wTypeName = wTypeObj.name;

  switch (wTypeName) {
    case "Places":
      wTypeName = "place";
      break;
    case "Hiking":
      wTypeName = "hiking";
      break;
    case "Tours":
      wTypeName = "tour";
      break;
    case "Events":
      wTypeName = "event";
      break;
    default:
      break;
  }

  const selectById = (id) => {
    let whatson_chosen_ids = cloneDeep(data.whatson_chosen_ids);
    if (whatson_chosen_ids.find((wci) => wci === id)) {
      whatson_chosen_ids = whatson_chosen_ids.filter((e) => e !== id);
    } else {
      whatson_chosen_ids.push(id);
    }
    onChange({ ...data, whatson_chosen_ids });
  };

  const sortList = (sortBy) => {
    // SORT IN PROGRESS...
    if (sortBy === sortedBy) {
      setReverseSort(!reverseSort);
    } else {
      setSortedBy(sortBy);
      setReverseSort(false);
    }
  };

  return (
    <div className="whatson-list-2">
      <div style={{ height: "100px" }} />
      <WhatsonList
        showAmount={showAmount}
        language={language}
        list={shownWhatson.map((e) => {
          // {console.log(e)}
          return {
            ...whatsonContentPush(e),
            selected: data.whatson_chosen_ids.find((wci) => wci === e.id)
              ? true
              : false,
          };
        })}
        selectAll={(checked) => {
          if (checked) {
            onChange({
              ...data,
              whatson_chosen_ids: shownWhatson.map((w) => w.id),
            });
          } else {
            onChange({ ...data, whatson_chosen_ids: [] });
          }
        }}
        selectById={selectById}
        //   whatWhatson={(e)=>{console.log(e)}}
        editContent={(e) => {
          selectById(e.data.id);
        }}
        whatWhatson={wTypeName}
        sortList={sortList}
      />
      {shownWhatson.length > showAmount && (
        <div className="showMore">
          <div
            onClick={() => {
              setShowAmount((s) => s + 30);
            }}
          >
            Show more
          </div>
        </div>
      )}
    </div>
  );
}
