import {
	faArrowLeftLong,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { slugify } from "../../../../assets/helperFunctions";
import "./Product.scss";
import PanelsColumn from "./PanelsColumn/PanelsColumn";
import InfoColumn from "./InfoColumn/InfoColumn";
import EditInventory from "./EditInventory/EditInventory";

export default function Product(props) {
	const {
		saveProduct,
		product,
		close,
		newData,
		configuration,
		deleteProduct,
		siteId,
		prices = configuration.physical.find(
			(x) => x.productTypeuuid === product.productTypeId
		)?.prices,
	} = props;
	
	const [draggingIndex, setDraggingIndex] = useState(undefined);
	const [uploaded, setUploaded] = useState(100);
	const [draggingOverUpload, setDraggingOverUpload] = useState(false);
	const [productState, setProductState] = useState(product);
	const [forceUpdate, setForceUpdate] = useState(false);

	const [editInventory, setEditInventory] = useState(null);
	
	useEffect(() => {
		if (!newData) {
			return;
		}
		let copyValues = cloneDeep(productState);
		newData.map((element) => {
			if (!element.used) {
				let maxKey = 0;
				if (element.unique) {
					copyValues.varient
						.find((x) => (x.sp_uuid ?? x.varient_id) === element.unique)
						.media.forEach((element) => {
							if (element.key > maxKey) maxKey = element.key;
						});
					copyValues.varient
						.find((x) => (x.sp_uuid ?? x.varient_id) === element.unique)
						.media.push({
							key: maxKey + 1,
							media_id: element.id,
							alt: element.alt,
							file_name: element.file_name,
							extension: element.fileExtension,
						});
				} else {
					copyValues.media.forEach((element) => {
						if (element.key > maxKey) maxKey = element.key;
					});

					copyValues.media.push({
						key: maxKey + 1,
						media_id: element.id,
						alt: element.alt,
						file_name: element.file_name,
						extension: element.fileExtension,
					});
				}
			}
			return (element.used = true);
		});
		setProductState(copyValues);
	}, [newData]);
	useEffect(() => {}, [forceUpdate]);

	return (
		<div className={`${"product overlay"}`}>
			<div className={`${editInventory && "mask"}`}></div>
			<div className="top">
				<div>
					<div className="title">
						<div
							onClick={() => {
								close();
							}}
						>
							<FontAwesomeIcon icon={faArrowLeftLong} />
						</div>
						<h1>Product</h1>
					</div>
					<div className="cta">
						<div
							onClick={() => {
								saveProduct(productState);
							}}
							className="save"
						>
							Save
						</div>
						<div
							onClick={() => {
								deleteProduct(productState);
							}}
							className="delete"
						>
							Delete
						</div>
					</div>
				</div>
			</div>
			<div className="max-width">
				<div className="content">
					<div className="wrap-column">
						<InfoColumn
							productState={productState}
							uploaded={uploaded}
							setUploaded={setUploaded}
							setProductState={setProductState}
							draggingOverUpload={draggingOverUpload}
							setDraggingOverUpload={setDraggingOverUpload}
							draggingIndex={draggingIndex}
							setDraggingIndex={setDraggingIndex}
							props={props}
							setForceUpdate={setForceUpdate}
							prices={prices}
							uuid={uuid}
							siteId={siteId}
							setEditInventory={setEditInventory}
						/>

						<PanelsColumn
							configuration={configuration}
							productState={productState}
							setForceUpdate={setForceUpdate}
							slugify={slugify}
							props={props}
						/>
					</div>

					{editInventory && (
						<EditInventory
							editInventory={editInventory}
							setEditInventory={setEditInventory}
							productState={productState}
							setForceUpdate={setForceUpdate}
							props={props}
							saveProduct={saveProduct}
							prices={prices}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
