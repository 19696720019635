import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import vfiLogo from "../../assets/images/vfi-logo.png";
import vitaLogo from "../../assets/images/vita-logo.png";
import env from "../../environment.json";
import "./NewPassword.scss";
import Goose from "../../assets/images/Goose";

export default function NewPassword({ hash, email }) {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRe, setNewPasswordRe] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gotRespond, setGotRespond] = useState(false);
  const [success, setSuccess] = useState(undefined);
  const [message, setMessage] = useState(false);

  const setPassword = () => {
    setLoading(true);

    axios
      .post(env.protocol + env.env + "/api/public/SetNewPassword.php", {
        hash,
        newPassword,
      })
      .then((response) => {
        setGotRespond(true);
        setSuccess(!!response.data.success);
        setMessage(response.data.message);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="new-password login-contents">
      <div className="content col-sm-6">
        <div className={"imgcontainer"}>
          <img
            className="vfi-logo"
            src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo}
            alt="vfi-logo"
          />

          <span className="vfi-text">
            {env.env.includes("vita.fo")
              ? [
                  <span>
                    <img className="vita-logo" src={vitaLogo} alt="vita-logo" />
                  </span>,
                  <span className="dash">|</span>,
                  <span className="type">CMS</span>,
                ]
              : "Visit Faroe Islands"}
          </span>
        </div>
        <div id="Login">
          {!gotRespond && (
            <div className="wrap-container">
              <div className="container">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <h1 className="password-title">Reset your password</h1>
                  <p className="password-info">
                    Almost there. Enter your new password, an you're good to go.
                  </p>
                  <input
                    type={"email"}
                    value={email}
                    disabled={true}
                    style={{ display: "none" }}
                  />
                  <input
                    className={"password"}
                    type={showPassword ? "text" : "password"}
                    placeholder="New password"
                    name="psw"
                    onChange={(e) => {
                      //   this.loginForm(e, "psw");
                      //   console.log(e.target.value);
                      setNewPassword(e.target.value);
                    }}
                    required
                  />
                  <div className="password-text-guide">
                    <div>
                      <ul>
                        <li>8 characters minimum</li>
                        <li>One lowercase character</li>
                      </ul>
                    </div>
                    <div>
                      <ul>
                        <li>One uppercase carahcter</li>
                        <li>One number / special character</li>
                      </ul>
                    </div>
                  </div>
                  <input
                    className={"password"}
                    type={showPassword ? "text" : "password"}
                    placeholder="Confirm new passowrd"
                    name="psw"
                    onChange={(e) => {
                      //   this.loginForm(e, "psw");
                      setNewPasswordRe(e.target.value);
                    }}
                    required
                  />
                  <div className="show-password-container">
                    <label className="show-password-label">
                      <input
                        type="checkbox"
                        checked={showPassword}
                        onChange={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                      <span className="show-password"> Show Password</span>
                    </label>
                  </div>
                  <button
                    onClick={() => {
                      //   this.login();
                      setPassword();
                    }}
                    type="submit"
                    disabled={
                      newPassword === "" ||
                      newPasswordRe === "" ||
                      newPassword !== newPasswordRe ||
                      loading
                    }
                  >
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      "Set new Password"
                    )}
                  </button>
                  <div className="errors">
                    {newPassword !== "" &&
                      newPasswordRe !== "" &&
                      newPassword !== newPasswordRe && (
                        <div className="error">
                          You haven't entered the same password in the boxes.
                        </div>
                      )}
                  </div>
                </form>
              </div>
            </div>
          )}
          {gotRespond &&
            (success ? (
              <div>
                <h1 className="password-title">Voilà, you’re all set.</h1>
                <p className="password-info">
                  You’ve succsessfully reset your password. You can now sign in
                  with your new password.
                </p>
                <button
                  type="submit"
                  onClick={() => {
                    window.location.href = env.protocol + env.env;
                  }}
                >
                  Login
                </button>
              </div>
            ) : (
              <div>{message}</div>
            ))}
        </div>
      </div>
    </div>
  );
}
