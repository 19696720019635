import hmm from "./1-hmm.png";
import really from "./2-really.png";
import cool from "./3-cool.png";
import thanks from "./4-thanks.png";

import hmmx2 from "./1-hmm@2x.png";
import reallyx2 from "./2-really@2x.png";
import coolx2 from "./3-cool@2x.png";
import thanksx2 from "./4-thanks@2x.png";

export default { hmm, really, cool, thanks, hmmx2, reallyx2, coolx2, thanksx2 };
