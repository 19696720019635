import { Component } from "react";
import "./App.scss";
import Main from "./Components/Main";
import Login from "./Components/Login/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import env from "./environment.json";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import ThemeSelector from "./ThemeSelector";
import { GlobalToolTip } from "./Components/ToolTips/GlobalToolTip";
import NotificationProvider from "./notifications/NotificationProvider";
import { requestNotificationPermission } from "./notifications/requestNotificationPermission";
import Overlays from "./Components/OverlayElements/Overlays";
import axios from "axios";
import DomainOverlay from "./Components/OverlayElements/Elements/DomainOverlay/DomainOverlay";
import { modulesConfig } from "./utils";

class App extends Component {
  _isMounted = false;
  timer = false;
  cancelSource = null;

  state = {
    loggedIn: false,
    idleLoggedOutAfterLoggedIn: false,
    sessionChecked: false,
    error: false,
    errorMessage: "",
    loggedInInfo: {},
  };

  constructor(props) {
    super(props);
    this.initializeLocalStorage();
    this.redirectToHttps();
  }

  initializeLocalStorage() {
    if (!localStorage.getItem("language")) {
      localStorage.setItem("language", "english");
    }
  }

  redirectToHttps() {
    if (
      window.location.hostname !== "localhost" &&
      window.location.hostname !== "127.0.0.1"
    ) {
      let redirect = false;
      let host = window.location.host;
      let protocol = window.location.protocol;

      if (host.substring(0, 4).toLocaleLowerCase() === "www.") {
        host = host.substring(4);
        redirect = true;
      }
      if (protocol === "http:") {
        protocol = "https:";
        redirect = true;
      }
      if (redirect) {
        window.location.replace(`${protocol}//${host}`);
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchSessionId();
    this.startCheckingLogin();

    // Only request notification permission if notifications are enabled
    if (modulesConfig.notifications.enabled) {
      requestNotificationPermission();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    if (this.cancelSource) {
      this.cancelSource.cancel("Operation canceled due to component unmount.");
    }
  }

  startCheckingLogin = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.checkLogin, 600000); // check every 10 minutes
  };

  checkLogin = () => {
    this.fetchSessionId();
    this.startCheckingLogin(); // start next check
  };

  logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    axios
      .post(env.protocol + env.env + "/api/public/LogOut.php")
      .then((response) => {
        window.location = "";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  fetchSessionId = () => {
    this.cancelSource = axios.CancelToken.source();

    const fromWhatson =
      new URLSearchParams(window.location.search).get("fromWhatsonFront") ===
      "true";
    // If we're coming in from whatson...
    if (fromWhatson) {
      // Set session as checked and don't log in the user (hacky fix because routing is in login)
      if (this._isMounted) {
        this.setState({ sessionChecked: true });
      }
    } else {
      axios
        .post(env.protocol + env.env + "/api/public/checkLogin.php", {
          cancelToken: this.cancelSource.token,
        })
        .then((response) => {
          if (response.data === "logggedIn") {
            if (this._isMounted) {
              this.setState({ sessionChecked: true, loggedIn: true });
            }
          } else {
            if (this._isMounted) {
              this.setState({ sessionChecked: true, loggedIn: false });
            }
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Request canceled", error.message);
          } else {
            console.log(error);
            if (this._isMounted) {
              this.setState({
                sessionChecked: true,
                error: true,
                errorMessage: error.message,
              });
            }
          }
        });
    }
  };

  render() {
    return (
      <NotificationProvider>
        <div
          onClick={() => {
            /* this.checkLogin */
          }}
          className="App"
        >
          <GlobalToolTip />
          <link
            rel="stylesheet"
            href="https://pro.fontawesome.com/releases/v5.10.1/css/all.css"
          ></link>
          {this.state.error ? (
            this.state.errorMessage
          ) : this.state.sessionChecked ? (
            this.state.loggedIn ? (
              <ThemeSelector themes={{ [env.theme]: true }}>
                <Main logout={this.logout} />
              </ThemeSelector>
            ) : (
              <Login
                loginSuccess={() => {
                  this.setState({ loggedIn: true });
                }}
              />
            )
          ) : (
            <FontAwesomeIcon
              className="loading-spinner"
              icon={faSpinner}
              spin
            />
          )}
          <Overlays />
          <DomainOverlay />
        </div>
      </NotificationProvider>
    );
  }
}

export default App;
