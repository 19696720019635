import "../EditProduct.scss";

/**
 * Product info column
 * 
 * @returns 		{JSX} 															Info column component
 * 
 * @author 					Pætur Mortensen 
 */
export default function InfoColumn({ children }) {	

	return (
		<div className="first-column">
			{children}
		</div>
	);
}
