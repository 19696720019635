import { faFile } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import env from "../../../../environment.json";
import "../Shop.scss";
import UserInfo from "./UserInfo";
import OrderLines from './OrderLines';
import StatusList from './StatusList';
import {
	capitalize,
	showDate,
	twoDigits,
} from "../../../../assets/helperFunctions";
import styles from "../Payment.module.scss";
import { cloneDeep } from "lodash";

export default function Payment({
	close,
	data: d,
	editable,
	giveChangedData = () => void 0,
}) {
	const [filterState, setFilterState] = useState(false);
	const [data, setData] = useState(cloneDeep(d));
	console.log('data: ',d);
	const filter = () => {
		setFilterState(filterState ? false : true);
	};

	if (!data) {
		return null;
	}

	return (
		<div className={`payment ${styles.payment}`}>
			<div className={styles["payment-wrap"]}>
				<div className={styles.content}>
					<div className={`${styles["user-info-row"]}`}>
						<UserInfo data={data} editable={editable} giveChangedData={giveChangedData} />
						<OrderLines data={data} editable={editable} giveChangedData={giveChangedData} />
					</div>
					<div className={styles["orders-row"]}>
						<div className={`${styles.box} ${styles["status-list-wrap"]}`}>
							<h2>Status List</h2>
							<div className={`${styles["status-list"]}`}>
								<div className={`${styles.status} ${styles["margin-top"]}`}>
									Order status:{" "}
									<span
										className={`status ${d.orderStatus} ${styles["margin-left"]}`}
									>
										{capitalize(d.orderStatus)}
									</span>
								</div>
								<div className={`${styles.status} ${styles["margin-top"]}`}>
									Payment status:{" "}
									<span
										className={`status ${d.paymentStatus} ${styles["margin-left"]}`}
									>
										{capitalize(d.paymentStatus)}
									</span>
								</div>
								<div className={`${styles.status} ${styles["margin-top"]}`}>
									Shipping status:{" "}
									<span
										className={`status ${d.shippingStatus} ${styles["margin-left"]}`}
									>
										{capitalize(d.shippingStatus)}
									</span>
								</div>
							</div>
						</div>
						<div className={`${styles.box} ${styles["order-history-wrap"]}`}>
							<h3>Order history</h3>
							<div className={`${styles["order-history"]}`}>
								{/* <div className={`${styles.header}`}>ID</div> */}
								<div className={`${styles.header}`}>Name</div>
								<div className={`${styles.header}`}>Date</div>
								{d.payments
									.map((payment) => {
										const { id, pdf_name, date } = payment;
										return [
											// <div key={id + "id"} className={`${styles.info}`}>
											//   {id}
											// </div>,
											<div key={id + "name"} className={`${styles.info}`}>
												<a
													href={`${env.protocol}${env.env}/pdf/${pdf_name}`}
													target="_blank"
												>
													<span className={styles.icon}>
														<FontAwesomeIcon icon={faFile} />
													</span>
													{pdf_name}
												</a>
											</div>,
											<div key={id + "date"} className={`${styles.info}`}>
												{showDate(date)}
											</div>,
										];
									})
									.reverse()}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
