import { Component } from "react";
import Verify from "../../../../assets/VerifyMethods";
import countryCodes from "../../../../assets/countryCodes.json";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
import Select from "../../../../assets/VfiCustomSelect";
import env from "../../../../environment.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faUserCircle } from "@fortawesome/pro-solid-svg-icons";
import Button from "../../../../assets/Button";

/**
 * Team member overlay
 * 
 * Overlay to add or edit team member in tour operator
 * 
 * @author 					Pætur Mortensen
*/
class TeamMemberOverlay extends Component {
	
	/**
	 * props:
	 * @property 	{object} 		newTourOperator 							New tour operator information
	 * @property 	{object} 		language 											Language strings for team
	 * @property 	{int} 			memberID 											ID of member being edited, if any
	 * @property 	{function} 	close													Close the overlay function
	 * @property 	{function} 	setSiteRef 										Set site reference
	 * @property 	{function} 	saveChanges 									Save changes made here
	 * 
	 * @author 					Pætur Mortensen
	 */
	constructor(props) {
		super(props);

		// Build country codes for phone cc select
		this.build_CCodes();

		// Set the error check sequences
		this.set_validation();

		// Set initial state
		this.init_state();
	}

	/**
	 * Set initial state
	 * 
	 * @author 					Pætur Mortensen
	 */
	init_state(){
		this.state = {
			member: {
				bio: "",
				email: "",
				lastName: "",
				phone: "",
				phoneCC: 298,
				profileImg: "",
				profileName: "",
				role: "",
				workplace: "",
			},
			editing: !!this.props.memberID,
			editingMemberID: this.props.memberID,
			beenEdit:{
				profileName:false,
				lastName:false,
				workplace:false,
				role:false,
				bio:false,
				email:false,
				phone:false,
			}
		};

		// If we are editing any member....
		if (this.state.editing) {
			// Set the member information for the form
			const member =
				this.props.newTourOperator.team.members[
					this.props.newTourOperator.team.members.findIndex(
						(element) => element.memberID === this.state.editingMemberID
					)
				];

			// We are in constructor, set state directly
			// NB: The member object is passed by reference, meaning that newTourOperator is updated 
			// directly with the state.

			this.state.member = member;
		}
	}

	/**
	 * Set validation rules for all fields
	 * 
	 * @author 					Pætur Mortensen
	 */
	set_validation()
	{
		this.validation = {
			profileName:[Verify.notEmpty, {function:Verify.minChars, args:[3]}],
			lastName:[Verify.notEmpty, {function:Verify.minChars, args:[3]}],
			workplace:[Verify.notEmpty, {function:Verify.minChars, args:[3]}],
			role:[Verify.notEmpty, {function:Verify.minChars, args:[2]}],
			bio:[Verify.notEmpty, {function:Verify.minChars, args:[15]}],
			email:[Verify.validEmail],
			phone:[Verify.notEmpty, {function:Verify.minChars, args:[3]}],
		};
	}

	/**
	 * Validate all fields
	 * 
	 * Will validate all member fields and flag them as beenEdit
	 * 
	 * @returns 	{bool} 											Whether all fields pass validation
	 * 
	 * @author 					Pætur Mortensen 
	 */
	validate_all(){
		
		// Init passed as true
		let passed = true;

		// For each field to validate
		for(const fieldName in this.validation){
			// Mark the field as beenEdit:true, so any validation errors are shown
			this.state.beenEdit[fieldName] = true;
			
			const rules = this.validation[fieldName];
			// For each rule for field
			for(const idx in rules){
				const rule = rules[idx];

				// If the rule is function type
				if(typeof rule === 'function'){
					// Run the validation function on the field value
					if(!!rule(this.state.member[fieldName])){
						// Validation returned truthy value, meaning it failed
						passed = false;
					}
				} else {
					// Rule is object of structure: {function:validationFunction, args:[arg1, arg2...]}
					if(!!rule.function(this.state.member[fieldName], ...rule.args)){
						passed = false;
					}
				}
			}
		}

		// No validation errors, all validation passed
		return passed;
	}

	/**
	 * Build the country codes array for the phone CC select
	 *
	 * @author 					Pætur Mortensen
	 */
	build_CCodes() {
		// Build the country codes for select
		const cCodes = [];
		// Register used codes so we get unique array
		const usedCodes = [];
		for (const idx in countryCodes) {
			const code = countryCodes[idx].dial_code;

			if (!usedCodes.includes(code)) {
				cCodes.push({
					value: code,
					label: "+" + code,
				});

				usedCodes.push(code);
			}
		}
		// Sort the country codes numerically
		this.countryCodes = cCodes.sort((a, b) => a.value - b.value);
	}

	/**
	 * Handle saving member information
	 * 
	 * Add member if we are adding, else do nothing, since edited members are updated automatically 
	 * via this.state.member
	 * 
	 * @author 					Pætur Mortensen
	 */
	handle_save() {
		
		// If all fields don't validate, we can't proceed with saving
		if(!this.validate_all()){
			this.setState({});
			return;
		}

		// If we are not editing (we are adding member)...
		if(!this.state.editing){
			// Add the member object
			this.props.newTourOperator.team.members.push(this.state.member);
		}

		// NB, there is no save handler for updating a member, since this.state.member is passed by 
		// reference from this.props.newTourOperator.team.members. This means that the member is updated 
		// with the state

		// Call save changes (save to server)
		this.props.saveChanges();

		// Close the overlay
		this.props.close();
	}

	/**
	 * onChange for all text fields
	 *
	 * @param 	{string} 	fieldName 					Name of field
	 * @param 	{string} 	value 							Value to set
	 *
	 * @author 					Pætur Mortensen
	 */
	onFieldChange(fieldName, value) {
		// Set field value and flag as been edited
		this.state.member[fieldName] = value;
		this.state.beenEdit[fieldName] = true;
		this.setState({ member: this.state.member, beenEdit:this.state.beenEdit });
	}

	/**
	 * Render the team member profile form
	 * 
	 * @returns 	{jsx} 												Team member profile form
	 * 
	 * @author 					Pætur Mortensen 
	 */
	render_profile_form() {
		return (
			<div className="profile-content ">
				<ul>
					<li className="text form-section-header">{this.props.language.name}</li>
					<li className="text">{this.props.language.profileName}</li>
					<li className="input">
						<VfiInputText
							value={this.state.member.profileName}
							onChange={(e) => {
								this.onFieldChange("profileName", e.target.value);
							}}
							errorCheckSequence={this.validation.profileName}
							hideError={!this.state.beenEdit.profileName}
						/>
					</li>
					<li className="text">{this.props.language.lastName}</li>
					<li className="input">
						<VfiInputText
							value={this.state.member.lastName}
							onChange={(e) => {
								this.onFieldChange("lastName", e.target.value);
							}}
							errorCheckSequence={this.validation.lastName}
							hideError={!this.state.beenEdit.lastName}
						/>
					</li>

					<li className="text form-section-header">{this.props.language.place}</li>
					<li className="text">{this.props.language.workplace}</li>
					<li className="input">
						<VfiInputText
							value={this.state.member.workplace}
							onChange={(e) => {
								this.onFieldChange("workplace", e.target.value);
							}}
							errorCheckSequence={this.validation.workplace}
							hideError={!this.state.beenEdit.workplace}
						/>
					</li>

					<li className="text">{this.props.language.role}</li>
					<li className="input">
						<VfiInputText
							value={this.state.member.role}
							onChange={(e) => {
								this.onFieldChange("role", e.target.value);
							}}
							errorCheckSequence={this.validation.role}
							hideError={!this.state.beenEdit.role}
						/>
					</li>

					<li className="text form-section-header">{this.props.language.bio}</li>
					<li className="text short-bio">{this.props.language.short_bio}</li>
					<li className="input">
						<VfiTextarea
							value={this.state.member.bio}
							onChange={(e) => {
								this.onFieldChange("bio", e);
							}}
							errorCheckSequence={this.validation.bio}
							hideError={!this.state.beenEdit.bio}
						/>
					</li>

					<li className="text form-section-header">{this.props.language.contact}</li>
					<li className="text">{this.props.language.email}</li>
					<li className="input email">
						<VfiInputText
							value={this.state.member.email}
							onChange={(e) => {
								this.onFieldChange("email", e.target.value);
							}}
							errorCheckSequence={this.validation.email}
							hideError={!this.state.beenEdit.email}
						/>
					</li>

					<li className="text">{this.props.language.phone}</li>
					<li className="input">
						<div className="phone-input">
							<Select
								className="country-code"
								defaultValue={{
									value: this.state.member.phoneCC,
									label: "+" + this.state.member.phoneCC,
								}}
								options={this.countryCodes}
								onChange={(e) => {
									this.onFieldChange("phoneCC", e.value);
								}}
							/>
							<VfiInputText
								className="phone-number"
								value={this.state.member.phone}
								onChange={(e) => {
									this.onFieldChange("phone", e.target.value);
								}}
								errorCheckSequence={this.validation.phone}
								hideError={!this.state.beenEdit.phone}
							/>
						</div>
					</li>

					<li className="text form-section-header">{this.props.language.picture}</li>
					<li className="text change-upload">{this.props.language.change_upload}</li>
					<li className="input">
						{this.state.member.imgFileName ? (
							<img
								className="profile-img"
								src={
									env.protocol + 
									env.env + 
									'/uploads/' + 
									this.state.member.imgFileName + 
									'.' + 
									this.state.member.imgFileExt
								}
								alt="profile image"
							/>
						) : (
							<FontAwesomeIcon className="profile-img" icon={faUserCircle} />
						)}
						<Button 
							uppercase 
							size="small" 
							className="update-btn"
							onClick={() => {
								this.props.setSiteRef(1);
								this.props.openOverlay("mediaChoose", {
									filter: { mediaType: ["images"] },
									afterFunc: (e) => {e.map((image) => {
										// Callback after inserting image
										
										// Build URL to new image
										const profileImg = 
											env.protocol + 
											env.env + 
											'/uploads/' + 
											image.file_name + 
											'.' + 
											image.fileExtension;

										// Update state with new profile image
										this.state.member.imgFileName = image.file_name;
										this.state.member.imgFileExt = image.fileExtension;
										this.state.member.mediaID = image.id;
									})},
								});
							}}
						>
							{this.props.language.update}
						</Button>
					</li>
					<li className="form-section-header"></li>
					<li className="text"></li>
					<li className="input">
						<Button
							className="add-btn"
							type="primary"
							size="large"
							block
							onClick={() => {
								this.handle_save();
							}}
						>
							{this.state.editing
								? this.props.language.update_team_member
								: this.props.language.add_team_member}
						</Button>
					</li>
				</ul>
				<br />
				<br />
				<br />
			</div>
		);
	}

	render() {
		return (
			<div className="overlayParent">
				<div className="overlay">
					<div className="wrapper">
						<div className="user-panel">
							<div className="top-header">
								<div className="header">
									<div
										className="close"
										onClick={() => {
											this.props.close();
										}}
									>
										<p className="icon">
											<FontAwesomeIcon icon={faTimes} />
										</p>
									</div>
									<div className="userInfo">
										{this.state.member.imgFileName ? (
											<img
												className="profile-img"
												src={
													env.protocol + 
													env.env + 
													'/uploads/' + 
													this.state.member.imgFileName + 
													'.' + 
													this.state.member.imgFileExt
												}
												alt="profile image"
											/>
										) : (
											<FontAwesomeIcon
												className="profile-img"
												icon={faUserCircle}
											/>
										)}

										<p className="name">{this.state.member.profileName}</p>
									</div>
								</div>
								<div className="panels">
									<ul>
										<li className="Profile Active">{this.props.language.profile}</li>
									</ul>
								</div>
							</div>
							<div className="body">{this.render_profile_form()}</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default TeamMemberOverlay;
