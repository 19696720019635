// Importing required types and utilities.
import { TFeatureConfig } from "src/types"; // Type definition for the configuration of features.
import { config } from "./configValidator"; // Validated feature configuration from modules.json.
import { environment, AppEnvironment } from "./environment"; // Environment-related utilities and types.

/**
 * A mapping of application environments to the statuses of features that should be enabled in each environment.
 * For example, in the 'production' environment, only features with the 'stable' status should be enabled.
 */
const statusMapping: Record<AppEnvironment, string[]> = {
	'production': ['stable'],
	'test': ['beta', 'stable'],
	'development': ['alpha', 'beta', 'stable']
};

/**
 * Resolves the dependencies between features and adjusts their enabled status based on environment and dependencies.
 * - Features not fitting the current environment's status criteria will be disabled.
 * - Features whose dependencies are not enabled will also be disabled.
 *
 * @param {Config} config - The configuration object containing the features and their dependencies.
 * @returns {Config} - The updated configuration object with dependencies resolved and features enabled/disabled based on environment.
 */
function resolveDependencies(config: TFeatureConfig): TFeatureConfig {
	// Determine the current application environment.
	const appEnv = environment.APP_ENV;
	// Get the list of feature statuses that are allowed in the current environment.
	const allowedStatuses = statusMapping[appEnv] || [];

	for (const feature in config) {
		// Disable features not fitting the current environment's status criteria.
		if (!allowedStatuses.includes(config[feature].status)) {
			config[feature].enabled = false;
		}

		// Check and resolve dependencies for each feature.
		const dependencies = config[feature].dependencies || [];
		for (const dependency of dependencies) {
			if (!config[dependency]?.enabled) {
				config[feature].enabled = false;
				console.warn(`Feature ${feature} was disabled because dependency ${dependency} is not enabled.`);
				break;
			}
		}
	}
	return config;
}

// Export the final configuration after resolving dependencies.
export const modulesConfig = resolveDependencies(config);
