//All sliders, including grids are in this EditModule

import {
  faArrowToTop,
  faPhotoFilm,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "./EditModule.scss";
import OneMedia from "../../../assets/PageModules/OneMedia/OneMedia";
import cloneDeep from "lodash.clonedeep";
import moduleIcons from "../../images/module icons";
import Dropzone from "react-dropzone";
import { uploadMedia } from "../../helperFunctions";
import { VfiCheckbox2 } from "../../VfiCheckbox";
import Select from "react-select";
import GridTemplateChoose from "../../../Components/Content/Overlay/GridTemplateChoose/GridTemplateChoose";

export default function EditModule(props) {
  const { values, change, isArticle } = props;
  const [innerValues, setInnerValues] = useState(values);
  const [screen, setScreen] = useState("media");
  const [draggingIndex, setDraggingIndex] = useState(undefined);
  const [uploaded, setUploaded] = useState(100);
  const [draggingOverUpload, setDraggingOverUpload] = useState(false);

  const setValues = (v) => {
    const newV = cloneDeep(v);
    change(newV);
    setInnerValues(newV);
  };
  useEffect(() => {
    setInnerValues(values);
  }, [values]);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      setUploaded((progressEvent.loaded * 100) / progressEvent.total);
    },
  };

  const uploadFiles = (e) => {
    props.setIsLoading(true);
    uploadMedia(e, props.siteId, config, (res) => {
      props.setIsLoading(false);
      // let copyValues = cloneDeep(innerValues);
      // res.data.forEach((element) => {
      //   let maxKey = 0;
      //   copyValues.media.forEach((element) => {
      //     if (element.key > maxKey) maxKey = element.key;
      //   });
      //   copyValues.media.push({
      //     key: maxKey + 1,
      //     id: element.id,
      //     alt: element.alt,
      //     file_name: element.file_name,
      //     extension: element.fileExtension,
      //   });
      //   copyValues.languages.push({
      //     headline: "",
      //     excerpt: "",
      //     button_active: false,
      //     button_text: "",
      //     button_direct: "",
      //   });
      // });
      // setValues(copyValues);
      props.setModuleKeyInEdit("media_add");
      props.openOverlay("mediaChoose", {
        filter: { mediaType: ["images", "videos"] },
        singleMedia: false,
        selectedMedias: res.data.map((e) => e.id),
      });
      props.close();
    });
  };

  let content = <p>Shouldn't be shown..</p>;
  switch (screen) {
    case "media":
      content = (
        <div className="media">
          {innerValues.media.map((element, i) => {
            return (
              <div className="medium" key={i + "|" + element.id}>
                <div
                  className="remove"
                  onClick={() => {
                    let copyMedia = cloneDeep(innerValues.media);
                    let copyLanguages = cloneDeep(innerValues.languages);
                    copyMedia.splice(i, 1);
                    copyLanguages.splice(i, 1);
                    setValues({
                      ...innerValues,
                      media: copyMedia,
                      languages: copyLanguages,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
                <OneMedia
                  media={{
                    id: element.id,
                    extension: element.extension,
                    alt: element.alt,
                    file_name: element.file_name,
                  }}
                  disableChange={true}
                  imgFormat="thumbnail"
                  videoThumbnail={true}
                  draggable={true}
                  onDragStart={(e) => {
                    setDraggingIndex(i);
                  }}
                  onDragEnd={(e) => {
                    setDraggingIndex(undefined);
                  }}
                  onDrop={(e) => {
                    if (draggingIndex !== undefined) {
                      let copyMedia = cloneDeep(innerValues.media);
                      let copyLanguages = cloneDeep(innerValues.languages);
                      const draggedMedium = innerValues.media[draggingIndex];
                      copyMedia.splice(draggingIndex, 1);
                      copyMedia.splice(i, 0, draggedMedium);
                      const draggedLanguageObj =
                        innerValues.languages[draggingIndex];
                      copyLanguages.splice(draggingIndex, 1);
                      copyLanguages.splice(i, 0, draggedLanguageObj);
                      setValues({
                        ...innerValues,
                        media: copyMedia,
                        languages: copyLanguages,
                      });
                      setDraggingIndex(undefined);
                    }
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            );
          })}
          <div
            className="add"
            onClick={() => {
              if (uploaded === 100) {
                props.setModuleKeyInEdit("media_add");
                props.openOverlay("mediaChoose", {
                  filter: { mediaType: ["images", "videos"] },
                  singleMedia: false,
                });
                props.close();
              }
            }}
          >
            <FontAwesomeIcon icon={faPhotoFilm} />
            <span>MEDIA GALLERY</span>
          </div>
          <div className="drag-n-drop">
            {uploaded === 100 ? (
              <Dropzone
                onDrop={(e) => {
                  uploadFiles(e);
                  setDraggingOverUpload(false);
                }}
                onDragEnter={() => {
                  setDraggingOverUpload(true);
                }}
                onDragLeave={() => {
                  setDraggingOverUpload(false);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        accept=".jpg,.jpeg,.png,.gif,.mp4"
                        maxLength={1}
                      />
                      <div
                        className={
                          "upload-box" +
                          (draggingOverUpload ? " drag-over" : "")
                        }
                      >
                        <div className="text-content">
                          <FontAwesomeIcon icon={faArrowToTop} />
                          <div>UPLOAD MEDIA</div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            ) : (
              <div className="upload-bar">
                <div
                  className="progress-bar"
                  style={{
                    width: uploaded + "%",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
      break;
    case "design":
      const linkAppearanceOptions = [
        { label: "None", value: "none" },
        { label: "On buttons", value: "on_buttons" },
      ];
      let captionOptions = [
        { label: "None", value: "none" },
        { label: "On hover", value: "on_hover" },
        {
          label: "Under images - individual",
          value: "under_images_individual",
        },
        {
          label: "Under images - one for all",
          value: "under_images_one_for_all",
        },
      ];
      let galleryAmountOptions = [
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
      ];

      if (innerValues.galleryType === "wall_grid") {
        captionOptions = captionOptions.filter(
          (e) => e.value !== "under_images_individual"
        );
      }

      content = (
        <div className="design">
          {innerValues.grid_type !== "instagram" && (
            <div className="gallery-types">
              <div
                className={
                  "g-type" +
                  (innerValues.galleryType === "slider_default"
                    ? " active"
                    : "")
                }
                onClick={() => {
                  setValues({
                    ...innerValues,
                    type: "carousel",
                    galleryType: "slider_default",
                  });
                }}
              >
                <img src={moduleIcons.slider} alt="slider_icon" />
                <div className="text">Slider</div>
              </div>
              {!isArticle && (
                <div
                  className={
                    "g-type" +
                    (innerValues.galleryType === "slider_with_thumb"
                      ? " active"
                      : "")
                  }
                  onClick={() => {
                    setValues({
                      ...innerValues,
                      type: "carousel",
                      galleryType: "slider_with_thumb",
                    });
                  }}
                >
                  <img
                    src={moduleIcons.thumbnailgallery}
                    alt="thumbnail_icon"
                  />
                  <div className="text">Thumbnail slider</div>
                </div>
              )}
              {!isArticle && (
                <div
                  className={
                    "g-type" +
                    (innerValues.galleryType === "slider_carousel"
                      ? " active"
                      : "")
                  }
                  onClick={() => {
                    setValues({
                      ...innerValues,
                      type: "carousel",
                      galleryType: "slider_carousel",
                    });
                  }}
                >
                  <img src={moduleIcons.carousel} alt="carousel_icon" />
                  <div className="text">Carousel</div>
                </div>
              )}
              {!isArticle && (
                <div
                  className={
                    "g-type" +
                    (innerValues.galleryType === undefined ? " active" : "")
                  }
                  onClick={() => {
                    setValues({
                      ...innerValues,
                      type: "media_grid",
                      galleryType: undefined,
                    });
                  }}
                >
                  <img src={moduleIcons.grid} alt="grid_icon" />
                  <div className="text">Grid</div>
                </div>
              )}
              <div
                className={
                  "g-type" +
                  (innerValues.galleryType === "wall_grid_3" ||
                  innerValues.galleryType === "wall_grid"
                    ? " active"
                    : "")
                }
                onClick={() => {
                  setValues({
                    ...innerValues,
                    type: "carousel",
                    galleryType: "wall_grid",
                    wall_grid_amount: innerValues.wall_grid_amount
                      ? innerValues.wall_grid_amount
                      : 3,
                    is_hero: false,
                  });
                }}
              >
                <img
                  src={moduleIcons.grid}
                  alt="grid_icon"
                  style={{ transform: "rotateZ(90deg)" }}
                />
                <div className="text">Gallery</div>
              </div>
            </div>
          )}
          {innerValues.galleryType === "wall_grid" && (
            <div>
              <div className="option">
                <div className="label">Gallery amount</div>
                <div className="value">
                  <Select
                    options={galleryAmountOptions}
                    value={galleryAmountOptions.find(
                      (e) => e.value === parseInt(innerValues.wall_grid_amount)
                    )}
                    onChange={(e) => {
                      setValues({ ...innerValues, wall_grid_amount: e.value });
                    }}
                  />
                </div>
              </div>
              <label className="option whole-click">
                <div className="label">Expand and collapse</div>
                <div className="value">
                  <VfiCheckbox2
                    style={{ pointerEvents: "none" }}
                    checked={innerValues.gallery_expand === true}
                    isRadio={true}
                    onChange={(e) => {
                      setValues({
                        ...innerValues,
                        gallery_expand: e.target.checked,
                      });
                    }}
                  />
                </div>
              </label>
            </div>
          )}
          {innerValues.type === "carousel" ? (
            <div>
              {innerValues.galleryType !== "wall_grid_3" &&
                innerValues.galleryType !== "wall_grid" &&
                !isArticle && (
                  <label
                    className="option whole-click"
                    // onClick={() => {
                    //   setValues({ ...values, is_hero: !values.is_hero });
                    // }}
                  >
                    <div className="label">Fullwidth</div>
                    {/* <div className="value"><input type="radio" checked={values.is_hero} /></div> */}
                    <div className="value">
                      <VfiCheckbox2
                        style={{ pointerEvents: "none" }}
                        checked={innerValues.is_hero}
                        isRadio={true}
                        onChange={(e) => {
                          setValues({
                            ...innerValues,
                            is_hero: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </label>
                )}
              {!isArticle && (
                <label
                  className="option whole-click"
                  // onClick={() => {
                  //   setValues({
                  //     ...values,
                  //     show_text: values.show_text ? false : true,
                  //   });
                  // }}
                >
                  <div className="label">Show title and description</div>
                  {/* <div className="value"><input type="radio" checked={values.show_text} /></div> */}
                  <div className="value">
                    <VfiCheckbox2
                      style={{ pointerEvents: "none" }}
                      checked={innerValues.show_text}
                      isRadio={true}
                      onChange={(e) => {
                        setValues({
                          ...innerValues,
                          show_text: e.target.checked,
                        });
                      }}
                    />
                  </div>
                </label>
              )}
              {innerValues.galleryType !== "wall_grid_3" && !isArticle && (
                <div className="option">
                  <div className="label">Link appearance</div>
                  <div className="value">
                    <Select
                      options={linkAppearanceOptions}
                      value={linkAppearanceOptions.find(
                        (e) => e.value === innerValues.link_appearance
                      )}
                      onChange={(e) => {
                        setValues({ ...innerValues, link_appearance: e.value });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="option">
                <div className="label">Caption</div>
                <div className="value">
                  <Select
                    options={captionOptions}
                    value={captionOptions.find(
                      (e) => e.value === innerValues.caption
                    )}
                    onChange={(e) =>
                      setValues({ ...innerValues, caption: e.value })
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {innerValues.type === "media_grid" ? (
            <div>
              <label className="option whole-click">
                <div className="label">Mirror</div>
                <div className="value">
                  <VfiCheckbox2
                    style={{ pointerEvents: "none" }}
                    checked={innerValues.swapped}
                    isRadio={true}
                    onChange={(e) => {
                      setValues({ ...innerValues, swapped: e.target.checked });
                    }}
                  />
                </div>
              </label>
              {innerValues.grid_type !== "instagram" && (
                <GridTemplateChoose
                  onlyTemplates={true}
                  // data={{
                  //   mediaAmount: values.media.length,
                  //   chosenPreset: values.preset_number,
                  // }}
                  returnV={(index) => {
                    setValues({ ...innerValues, preset_number: index });
                  }}
                  values={innerValues}
                  setValues={setValues}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="vfi-carousel edit-module">
      <div className="top">
        <div
          className={"top-choice " + (screen === "media" ? "active " : "")}
          onClick={() => setScreen("media")}
        >
          MEDIA
        </div>
        <div
          className={"top-choice " + (screen === "design" ? "active " : "")}
          onClick={() => setScreen("design")}
        >
          DESIGN
        </div>
      </div>
      <div className="content">{content}</div>
    </div>
  );
}
