import React, { useEffect, useState } from "react";
import "./ReadAlso.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import axios from "axios";
import env from "../../../environment.json";
import languages from "../language.json";

export function moduleOptions(index, contentData, setContentData) {
  return [
    // {
    //   is: "choice",
    //   icon: faFrown,
    //   text: "None implemented yet...",
    //   onClick: () => {
    //     let newContentData = Object.assign([], contentData);
    //     // newContentData.splice(i, 1);
    //     // newContentData[index].type = "first_letter_big";
    //     setContentData(newContentData);
    //   },
    //   style: { color: "red" },
    // },
  ];
}

export default function ReadAlso(props) {
  const { inEdit } = props;
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";
  const lang = languages[language].read_also;
  const [pageTitle, setPageTitle] = useState("No page implemented");

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchPage = async () => {
      try {
        const response = await axios.get(
          env.protocol +
            env.env +
            "/api/public/pages/GetPage.php?update=true&id=" +
            props.data.pageId +
            "&shared=" +
            (props.data.shared ? "1" : "0"),
          { cancelToken: source.token }
        );
        if (response.data.languages[props.selectedLanguage])
          setPageTitle(response.data.languages[props.selectedLanguage].title);
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.log("Request cancelled");
        } else {
          console.error(error);
        }
      }
    };
    fetchPage();

    return () => {
      source.cancel();
    };
  }, [props.data.pageId, props.selectedLanguage]);

  return (
    <div
      style={props.style}
      className={"read-also " + (props.className ? props.className : "")}
    >
      <div className="read-also-wrap" onClick={props.onClick}>
        <FontAwesomeIcon icon={faArrowRight} />{" "}
        <span className="read-also-sign">{lang.read_also}:</span>{" "}
        <span className="text">{pageTitle}</span>
      </div>
    </div>
  );
}
