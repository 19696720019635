import { useEffect, useState } from "react";
import styles from "./WhatsonSessionEditView.module.scss";

interface props {
  sessionStarted: number; // Unix timestamp
  sessionMaxAge: number; // Max lifetime in seconds
  iNeedMoreTimeClick: () => void;
  onSessionTimeOut?: () => void;
  typeName?: string;
}
export default function WhatsonSessionEditView(props: props) {
  const {
    sessionStarted,
    sessionMaxAge,
    iNeedMoreTimeClick,
    onSessionTimeOut,
    typeName,
  } = props;
  const [timeLeft, setTimeLeft] = useState<number>(1);

  useEffect(() => {
    // Update the time left immediately when the effect runs
    const updateTimer = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      const elapsedTime = currentTime - sessionStarted;
      const timeRemaining = sessionMaxAge - elapsedTime;
      setTimeLeft(timeRemaining);
    };

    // Call once on mount to initialize
    updateTimer();

    // Then set your interval
    const interval = setInterval(() => {
      // Use the functional update form to ensure we're decrementing
      // from the latest state
      setTimeLeft((prevTimeLeft) => {
        const newTimeLeft = prevTimeLeft - 1;
        return newTimeLeft > 0 ? newTimeLeft : 0; // Prevent negative values
      });
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [sessionStarted, sessionMaxAge]);

  useEffect(() => {
    if (timeLeft === 0) {
      if (onSessionTimeOut) onSessionTimeOut();
    }
  }, [timeLeft]);

  const timeLeftMinutes = Math.ceil(timeLeft / 60);
  const timeDisplay =
    timeLeftMinutes > 1 ? `${timeLeftMinutes} minutes` : `${timeLeft} seconds`;

  return (
    <div className={styles["whatson-session-edit-view"]}>
      {timeLeftMinutes <= 10 && (
        <div className={`${styles["box"]} ${styles["more-time"]}`}>
          <div className={styles["info-text"]}>
            Your session expires in {timeDisplay}
          </div>
          <button onClick={iNeedMoreTimeClick}>I need more time</button>
        </div>
      )}
      <div className={`${styles["box"]} ${styles["default"]}`}>
        You have {timeDisplay} remaining to edit this{" "}
        {typeName?.toLowerCase() ?? "element"}.
        <br />
        After that, any changes will be lost.
      </div>
    </div>
  );
}
