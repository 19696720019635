import React from "react";

function VfiCheckbox({
  className,
  checked,
  defaultChecked,
  onChange,
  name,
  disabled,
  children,
}) {
  return (
    <label className={className ? className : ""}>
      <input
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        name={name}
        disabled={disabled}
      />
      <div className="vfi-checkbox">
        <span className="left-shorter" />
        <span className="right-longer" />
      </div>
      {children}
    </label>
  );
}
export default VfiCheckbox;
