import { IPageExtendedInfo } from "../types/interfaces/IPageExtendedInfo";
import logo from "src/assets/images/vfi-logo.png";
import arrow from "src/assets/images/slider-right.svg";
import { TLangs } from "src/types";
import styles from "./Breadcrumbs.module.scss";
import "./Breadcrumbs.scss";

interface props {
  env: string;
  data: IPageExtendedInfo;
  shortLang: TLangs;
}
export default function Breadcrumbs(props: props) {
  const { env, data, shortLang } = props;

  const breadcrumbs = data.path?.split("/").slice(1) ?? [];

  return (
    <div className={`breadcrumbs-container ${styles["breadcrumbs-container"]}`}>
      <span
        // href="{{data.site.protocol}}www.{{data.site.domain_name}}/{{lang}}/"
        className={styles["breadcrumbs-logo"]}
      >
        {/* <img src="/assets/white_head.png" alt="" /> */}
        <img src={logo} alt="" />
      </span>
      {breadcrumbs.map((breadcrumb, i) => {
        if (i + 1 === breadcrumbs.length) {
          return (
            <span className={styles["breadcrumb-link"]} key={breadcrumb + i}>
              <div className={styles["breadcrumbs-arrow-container"]}>
                <img
                  src={arrow}
                  alt="Arrow pointing to the right, to the next link in the breadcrumb"
                />
              </div>
              <div className={styles["breadcrumb-text"]}>
                {data.languages[shortLang]?.title}
              </div>
            </span>
          );
        } else {
          return (
            <span className={styles["breadcrumb-link"]} key={breadcrumb + i}>
              <div className={styles["breadcrumbs-arrow-container"]}>
                <img
                  src={arrow}
                  alt="Arrow pointing to the right, to the next link in the breadcrumb"
                />
              </div>
              <div className={styles["breadcrumb-text"]}>
                {data.parent_pages[i].title}
              </div>
            </span>
          );
        }
      })}
      {/* {% for breadcrumb in
	breadcrumbs|slice(1, length) %} {% if loop.index == loop.length %}
	<a href="{{data.path}}" className="breadcrumb-link">
	  <div className="breadcrumbs-arrow-container">
		<img src="/assets/arrow-right-b.svg" alt="" />
	  </div>
	  <div className="breadcrumb-text">
		{{ data.languages[data.language_is].title }}
	  </div>
	</a>
	{% else %}
	<a href="{{data.parent_pages[loop.index0].path}}" className="breadcrumb-link">
	  <div className="breadcrumbs-arrow-container">
		<img
		  src="/assets/arrow-right-b.svg"
		  alt="Arrow pointing to the right, to the next link in the breadcrumb"
		/>
	  </div>
	  <div className="breadcrumb-text">{{data.parent_pages[loop.index0].title}}</div>
	</a>
	{% endif %} {% endfor %} */}
    </div>
  );
}
