import { create } from "zustand";
import { navigationElements } from "./navigationElements";
import { initRouteArray } from "./helper-functions";
import { setType } from "../ZustandStores/zustandTypes";
import { Route } from "./navigationElements";
import DefaultPopup from "src/assets/DefaultPopup";
import { overlayStore } from "../OverlayElements/OverlayStore";

function interceptRoute(
  set: (irs: any) => void,
  get: () => IRouteStore,
  success: () => void,
  cancel: () => void
) {
  if (!get().intercept_is_showing) {
    set({ intercept_is_showing: true });
    const { addOverlay } = overlayStore.getState();
    addOverlay("alert", (close) => {
      return (
        <DefaultPopup
          okClick={() => {
            success();
            close();
          }}
          cancelClick={() => {
            cancel();
            close();
          }}
          text={
            <>
              Are you sure you want to leave?
              <br />
              Information you've entered may not be saved.
            </>
          }
          okText="Yes, leave"
          okButtonStyle={{ backgroundColor: "#cf323b" }}
        />
      );
    });
  }
}

interface IRouteStore {
  route: Route[];
  setRouter: (state: Object) => void;
  setRouterStore: (state: Object) => void;
  navTo: (level: number, destination: any, callback?: () => void) => void;
  navByLink: (link: string) => void;
  back: () => void;
  initRoute: () => void;
  resetRoutes: () => void;
  route_intercept?: boolean;
  intercept_is_showing: boolean;
}

// Initial states
const initialState: any = {
  route: [navigationElements.default], //initRouteArray(undefined),
  route_intercept: false,
};

export const routerStore = (
  set: setType<IRouteStore>,
  get: () => IRouteStore
) => ({
  // Initialize states
  ...initialState,

  // Set routes
  // - setRoutes allows us to set states within the routesStore
  setRouter: (states: any) => {
    if (get().route_intercept) {
      interceptRoute(
        set,
        get,
        () =>
          set({
            ...states,
            route_intercept: false,
            intercept_is_showing: false,
          }),
        () => {
          set({ intercept_is_showing: false });
        }
      );
    } else {
      set({ ...states });
    }
  },
  setRouterStore: (states: any) => {
    set({ ...states });
  },

  // navTo will be deprecated in the future, since it's quite confusing to use.
  navTo: (level: number, destination: Route, callback?: () => void) => {
    // - navTo should let you navigate to a specific location in the system
    //   navTo will also set the url so it can be used for deep linking

    if (get().route_intercept) {
      interceptRoute(
        set,
        get,
        () => {
          set((state: IRouteStore) => {
            // get() might not be necessarry when we get the state from the set function.

            window.location.hash =
              (level ? "/" : "") +
              state.route
                .filter((r: Route, index: number) => index < level)
                .map((r: any) => r.nav)
                .join("/") +
              "/" +
              destination.nav;

            return {
              route: [
                ...state.route.filter(
                  (r: Route, index: number) => index < level
                ),
                {
                  nav: destination.nav,
                  sidebar: destination.sidebar,
                  displayName: destination.displayName,
                },
              ],

              intercept_is_showing: false,
              route_intercept: false,
            };
          });
          if (callback) callback();
        },
        () => {
          set({ intercept_is_showing: false });
        }
      );

      return;
    }

    set((state: IRouteStore) => {
      // get() might not be necessarry when we get the state from the set function.

      window.location.hash =
        (level ? "/" : "") +
        state.route
          .filter((r: Route, index: number) => index < level)
          .map((r: any) => r.nav)
          .join("/") +
        "/" +
        destination.nav;

      return {
        route: [
          ...state.route.filter((r: Route, index: number) => index < level),
          {
            nav: destination.nav,
            sidebar: destination.sidebar,
            displayName: destination.displayName,
          },
        ],
      };
    });
    if (callback) callback();
  },

  // navByLink let's you navigate to any location in the system by link
  navByLink: (link: string, get_parameters: any) => {
    window.location.hash =
      `/${link}` +
      (get_parameters
        ? "?" +
          Object.keys(get_parameters)
            .map((key: string) => {
              return `${key}=${get_parameters[key]}`;
            })
            .join("&")
        : "");

    let split_link = link.split("?")[0].split("/");
    let last_link = split_link[split_link.length - 1].split("-");
    let id: any = null;

    if (split_link.length > 1 && ["id", "edit"].includes(last_link[0])) {
      id = last_link[1];
    }

    if (get().route_intercept) {
      interceptRoute(
        set,
        get,
        () =>
          set(() => {
            return {
              route: split_link.map((r: string, index: number) => ({
                nav: id !== null && split_link.length - 1 === index ? id : r,
                sidebar: navigationElements[r]
                  ? navigationElements[r].sidebar
                  : "",
                displayName: navigationElements[r]
                  ? navigationElements[r].displayName
                  : "",
              })),
              intercept_is_showing: false,
              route_intercept: false,
            };
          }),
        () => {
          set({ intercept_is_showing: false });
        }
      );

      return;
    }

    set(() => {
      return {
        route: split_link.map((r: string, index: number) => ({
          nav: id !== null && split_link.length - 1 === index ? id : r,
          sidebar: navigationElements[r] ? navigationElements[r].sidebar : "",
          displayName: navigationElements[r]
            ? navigationElements[r].displayName
            : "",
        })),
      };
    });
  },

  // back - sets you back one level.
  back: () => {
    if (get().route_intercept) {
      interceptRoute(
        set,
        get,
        () =>
          set((state: IRouteStore) => {
            let arr = [...state.route];
            arr.pop();
            window.location.hash = "/" + arr.map((r: any) => r.nav).join("/");
            return {
              route: arr,
              intercept_is_showing: false,
              route_intercept: false,
            };
          }),
        () => {
          set({ intercept_is_showing: false });
        }
      );

      return;
    }

    set((state: IRouteStore) => {
      let arr = [...state.route];
      arr.pop();
      window.location.hash = "/" + arr.map((r: any) => r.nav).join("/");
      return { route: arr };
    });
  },

  // forward: () => {} - forward will let you go forward 1 level

  initRoute: (permCheckFunc: any) => {
    set(() => ({ route: initRouteArray(permCheckFunc) }));
  },

  // resetRoutes - resets the state of the routerStore
  resetRoutes: () => {
    set(() => ({ ...initialState }));
  },
});

export const useRouterStore = create(routerStore);

export const withRouterStore = (BaseComponent: any) => (props: any) => {
  const store = useRouterStore();
  return <BaseComponent {...props} routerStore={store} />;
};
