import axios from "axios";
import env from "../../environment.json";
import PropTypes from "prop-types";
import { setType } from "./zustandTypes";
import { create } from "zustand";
import { ComponentType } from "react";

interface siteListElement {
  siteId: number;
  name: string;
  type_of: string;
  rootPage?: boolean | number;
}

interface SitesState {
  allSites: siteListElement[];
  allSitesNoRootPages: siteListElement[];
  allSitesLoaded: boolean;
  getAllSites: (callback: (e: siteListElement[]) => void) => void;
}

const sitesStore = (set: setType<SitesState>) => ({
  allSites: [],
  allSitesNoRootPages: [],
  allSitesLoaded: false,
  getAllSites: (callback: (e: siteListElement[]) => void) => {
    set({ allSitesLoaded: false });
    axios
      .post(`${env.protocol}${env.env}/api/public/GetSites`, {
        userId: sessionStorage.getItem("vfiUser"),
        // ignoreRootPages: false,
      })
      .then((response) => {
        const data: siteListElement[] = response.data;
        set({
          allSites: data,
          allSitesNoRootPages: data.filter((d) => !d.rootPage),
          allSitesLoaded: true,
        });
        if (callback) callback(data);
      })
      .catch((error) => console.error(error));
  },
});

export const useSitesStore = create<SitesState>(sitesStore);

export const withSitesStore =
  (BaseComponent: ComponentType<any>) => (props: object) => {
    const store = useSitesStore();
    return <BaseComponent {...props} sitesStore={store} />;
  };
