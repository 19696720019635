import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAlt } from "@fortawesome/pro-regular-svg-icons";
import { faSortAlt as faSortAltSorted } from "@fortawesome/pro-duotone-svg-icons";
import VfiCheckbox from "../../../assets/VfiCheckbox/VfiCheckbox";
import WhatsonContent from "./WhatsonContent";
import { GlobalToolTipController } from "../../ToolTips/GlobalToolTip";
import { withUserStore } from "src/Components/ZustandStores/allOfStore";

// function listFullyFiltered(fullList)
// {
//   fullList = this.searchFilter(fullList);
//   fullList = this.checkboxFilter(fullList);
// }

class WhatsonList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedBy: "date",
    };

    this.language = require("./language.json");
    this.language = this.language[this.props.language];
  }

  checkboxFilter(list) {
    const filter = this.props.checkboxFilter;

    Object.keys(filter).forEach((key) => {
      if (filter[key].includes("all")) {
        return;
      }

      if (key === "mediaType") {
        let tempList = [];

        filter[key].forEach((element) => {
          if (element === "images") {
            const filteredList = list.filter(
              (q) =>
                q.fileExtension.toLowerCase() === "jpg" ||
                q.fileExtension.toLowerCase() === "jpeg" ||
                q.fileExtension.toLowerCase() === "png" ||
                q.fileExtension.toLowerCase() === "gif"
            );
            tempList = tempList.concat(filteredList);
          }

          if (element === "videos") {
            const filteredList = list.filter(
              (q) =>
                q.fileExtension.toLowerCase() === "mp4" ||
                q.fileExtension.toLowerCase() === "mov" ||
                q.fileExtension.toLowerCase() === "wmv" ||
                q.fileExtension.toLowerCase() === "flv" ||
                q.fileExtension.toLowerCase() === "avi" ||
                q.fileExtension.toLowerCase() === "mkv"
            );
            tempList = tempList.concat(filteredList);
          }
        });

        list = list.filter((l) => tempList.includes(l));
      }

      if (key === "format") {
        let tempList = [];

        filter[key].forEach((element) => {
          if (element === "landscape") {
            const filteredList = list.filter((q) => {
              const width = parseInt(q.fileDimensions.split("x")[0]);
              const height = parseInt(q.fileDimensions.split("x")[1]);

              return width > height;
            });
            tempList = tempList.concat(filteredList);
          }

          if (element === "portrait") {
            const filteredList = list.filter((q) => {
              const width = parseInt(q.fileDimensions.split("x")[0]);
              const height = parseInt(q.fileDimensions.split("x")[1]);

              return width < height;
            });
            tempList = tempList.concat(filteredList);
          }

          if (element === "square") {
            const filteredList = list.filter((q) => {
              const width = parseInt(q.fileDimensions.split("x")[0]);
              const height = parseInt(q.fileDimensions.split("x")[1]);

              return width === height;
            });
            tempList = tempList.concat(filteredList);
          }
        });

        list = list.filter((l) => tempList.includes(l));
      }
    });

    return list;
  }

  render() {
    let returnList = this.props.list;

    // const permissionWhatsonTypes = {
    //   Event: "events",
    //   Place: "places",
    //   Tour: "tours",
    //   "Tour operator": "tour_operators",
    //   Hiking: undefined,
    // };

    // const permissionType = permissionWhatsonTypes[this.props.whatWhatson];

    // returnList = returnList.filter((e) => {
    //   return permCheckFunc({
    //     minimumRole: "Collaborator",
    //     whereRule: "any",
    //     permissions: this.props.userStore.loggedInUser.permissions,
    //     type: permissionType,
    //     ignoreCheck: permissionType === undefined,
    //     id: e.id,
    //   });
    // });

    return (
      <div className="whatson-list">
        <table className="whatson-table">
          <thead>
            <tr>
              <th
                className={
                  this.state.sortedBy === "title" ? "title sorted-by" : "title"
                }
                onClick={() => {
                  this.props.sortList("title");
                  let reverse = false;
                  if (
                    this.state.sortedBy === "title" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({ sortedBy: "title", reverse: reverse });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Title</div>}
                >
                  {this.language.table_head.title}
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "title"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={
                  this.state.sortedBy === "author"
                    ? "author sorted-by"
                    : "author"
                }
                onClick={() => {
                  this.props.sortList("author.fullname");
                  let reverse = false;
                  if (
                    this.state.sortedBy === "author" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({ sortedBy: "author", reverse: reverse });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Author</div>}
                >
                  {this.language.table_head.author}
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "author"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={
                  this.state.sortedBy === "region"
                    ? "file-size sorted-by"
                    : "file-size"
                }
                onClick={() => {
                  this.props.sortList("region");
                  let reverse = false;
                  if (
                    this.state.sortedBy === "region" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({ sortedBy: "region", reverse: reverse });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Region</div>}
                >
                  {this.language.table_head.region}
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "region"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={
                  this.state.sortedBy === "location"
                    ? "location sorted-by"
                    : "location"
                }
                onClick={() => {
                  this.props.sortList("location");
                  let reverse = false;
                  if (
                    this.state.sortedBy === "location" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({ sortedBy: "location", reverse: reverse });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Location</div>}
                >
                  {this.language.table_head.location}
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "location"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={
                  this.state.sortedBy === "approvalStatus"
                    ? "approvalStatus sorted-by"
                    : "approvalStatus"
                }
                onClick={() => {
                  this.props.sortList("approvalStatus");
                  let reverse = false;
                  if (
                    this.state.sortedBy === "approvalStatus" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({
                    sortedBy: "approvalStatus",
                    reverse: reverse,
                  });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Approval Status</div>}
                >
                  {this.props.approvalRequired
                    ? this.language.table_head.approval
                    : this.language.table_head.status}
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "approvalStatus"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th
                className={
                  this.state.sortedBy === "date" ? "date sorted-by" : "date"
                }
                onClick={() => {
                  this.props.sortList("date");
                  let reverse = false;
                  if (
                    this.state.sortedBy === "date" &&
                    this.state.reverse === false
                  )
                    reverse = true;
                  this.setState({ sortedBy: "date", reverse: reverse });
                }}
              >
                <GlobalToolTipController
                  offset={{ x: 30, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Sort by Date</div>}
                >
                  Date
                  <FontAwesomeIcon
                    icon={
                      this.state.sortedBy === "date"
                        ? faSortAltSorted
                        : faSortAlt
                    }
                    flip={"vertical"}
                    transform={this.state.reverse ? {} : { rotate: 180 }}
                  />
                </GlobalToolTipController>
              </th>
              <th className="check-select">
                <GlobalToolTipController
                  offset={{ x: 10, y: 30 }}
                  priority={"right,bottom"}
                  title=""
                  delay={600}
                  toolTipElements={<div>Select all</div>}
                >
                  <VfiCheckbox
                    onChange={(e) => {
                      this.props.selectAll(e.target.checked);
                    }}
                  />
                </GlobalToolTipController>
              </th>
            </tr>
          </thead>
          <tbody>
            {returnList.map((element, i) => {
              if (this.props.showAmount > i)
                return (
                  <WhatsonContent
                    key={element.id}
                    element={element}
                    selectById={this.props.selectById}
                    selectAll={this.props.selectAll}
                    heartClicked={this.props.heartClicked}
                    expandSettings={this.expandSettings}
                    index={i}
                    editContent={this.props.editContent}
                    whatWhatson={this.props.whatWhatson}
                    approvalStatusChange={this.props.approvalStatusChange}
                    approvalRequired={this.props.approvalRequired}
                    language={this.props.language}
                  />
                );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
export default WhatsonList;
