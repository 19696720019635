
/**
 * This should absolutely be a general component, but this requires a larger rewrite which I don't have time for right now.
 * The overlay for editing pages, infoscreens and other stuff, seem really general, and have alot of the same features.
 * Currently alot of these features have been written many times, but for future reference we should try to generalise the CMS more so that common features,
 * have a single way of working.
 * For example editing pages, infoscreens articles or anything else that has a similar display and sidebar with common tools and features.
 */


import GoogleMapSearchBox from 'src/assets/GoogleMapSearchBox';
import styles from './SelectInfoscreenLocation.module.scss';
import ReactMapGlOpen from 'src/assets/ReactMapGlOpen';
import { Marker } from 'react-map-gl';
import { SearchBox, SimpleMap } from 'src/assets/GoogleMaps';
import { useEffect, useState } from 'react';
import axios from 'axios';
import env from '../../../../../../environment.json';
import { latLng } from 'src/assets/GoogleMaps/SimpleMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-solid-svg-icons';

interface Props {
    onSelect: (callback: any) => void;
    infoscreen_id: number;
    infoscreen_data: {
        [key: string]: any;
    };
}

interface IGeoData {
    location: {
        latitude: number | undefined,
        longitude: number | undefined,
    };
    address_name: string | undefined;
}

export default function SelectInfoscreenLocation({ onSelect, infoscreen_id, infoscreen_data }: Props) {

    const [loaded, setLoaded] = useState<boolean>(false);

    const [requesting, setRequesting] = useState<boolean>(false);

    const [geoData, setGeoData] = useState<IGeoData>({
        location: {
            latitude: Number(infoscreen_data.info.latitude),
            longitude: Number(infoscreen_data.info.longitude),
        },
        address_name: infoscreen_data.info.address_name,
    });

    const setInfoscreenData: (data: IGeoData) => void = (data) => {
        setRequesting(true);
        axios.post(env.protocol + env.env + "/api/secured/infoscreen/setInfoscreenProperties", {
            ...data,
            infoscreen_id: infoscreen_id,
        }).then((response) => {
            onSelect(data);
        }).catch((error: any) => {
            console.log(error);
        }).finally(() => {
            setRequesting(false);
        });
    }

    // Function for making multiple calls with the same data.

    // TODO: Create createMultiCall function that takes array of functions and returns a callMulti function
    const callMulti: (data: IGeoData) => void = (data) => {
        [setGeoData, setInfoscreenData].forEach((func: any) => {
            func(data);
        });
    }

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div className={`${styles.container} ${loaded ? styles.loaded : ''} ${requesting? styles.requesting : ''}`}>
            <div className={styles.input_container}>
                <div className={styles.physical_location}>PHYSICAL LOCATION</div>
                <div className={styles.input_address}>
                    <SearchBox
                        value={geoData.address_name}
                        change={(e: any) => {
                            if (requesting) return;
                            callMulti({
                                address_name: e?.formatted_address,
                                location: {
                                    latitude: e?.geometry?.location?.lat(),
                                    longitude: e?.geometry?.location?.lng(),
                                }
                            });
                        }}
                        onChange={(e) => {
                            setGeoData({
                                ...geoData,
                                address_name: e,
                            });
                        }}
                    />
                </div>
            </div>

            <div className={styles.map_container}>
                <SimpleMap
                    size={{
                        width: 320,
                        height: 320,
                    }}
                    marker={{
                        lat: geoData.location.latitude ?? 0,
                        lng: geoData.location.longitude ?? 0,
                     } as latLng}
                    onClick={(props) => {
                        if (requesting) return;
                        callMulti({
                            address_name: props.address,
                            location: {
                                latitude: props.mapMouseEvent.latLng?.lat(),
                                longitude: props.mapMouseEvent.latLng?.lng(),
                            }
                        });
                    }}
                />

                {requesting? <div className={styles.loader_wrapper}>Updating <div className={styles.spinner_wrapper}><FontAwesomeIcon className={styles.spinner} icon={faLoader as any} /></div></div> : <></>}
            </div>
        </div>
    );
}