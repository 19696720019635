import { FormEvent, useEffect, useRef, useState } from "react";
import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import libraries from "./libraries";
import VfiInputText from "../VfiInputText/VfiInputText";

interface props {
  change: (value: google.maps.places.PlaceResult | undefined) => void;
  onChange?: (value: string) => void;
  value?: string;
  defaultValue?: string;
}

const SearchBox = (props: props) => {
  const { onChange, change, value, defaultValue } = props;
  const [searchValue, setSearchValue] = useState(value);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
    // libraries: ["places", "marker"],
    libraries,
  });
  const searchBox = useRef<google.maps.places.SearchBox | null>(null);

  useEffect(() => {
    if (value !== undefined) setSearchValue(value);
  }, [value]);

  const handlePlacesChanged = () => {
    if (searchBox.current !== null) {
      const places = searchBox.current.getPlaces();
      if (change && places?.length) {
        const newSearchValue = places ? places[0] : undefined;
        change(newSearchValue); // Call parent's callback with the first place.
        const formattedAddress = newSearchValue?.formatted_address;
        if (formattedAddress && searchValue !== undefined)
          setSearchValue(formattedAddress);
      }
    }
  };

  return isLoaded ? (
    <div className="search-box">
      <StandaloneSearchBox
        onLoad={(ref) => (searchBox.current = ref)}
        onPlacesChanged={handlePlacesChanged}
        // bounds={new google.maps.LatLngBounds(
        //   new google.maps.LatLng(61.250240, -8.345192), // Southwest coordinates
        //   new google.maps.LatLng(62.594619, -5.467597) // Northeast coordinates
        // )}
      >
        <VfiInputText
          type="text"
          placeholder="Search for a place"
          value={searchValue}
          defaultValue={defaultValue}
          onChange={(e: React.FormEvent<HTMLInputElement>) => {
            if (onChange) onChange(e.currentTarget.value);
          }}
        />
      </StandaloneSearchBox>
      <ol></ol>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default SearchBox;
