
type InputItem = {[key:string] : any};

/**
 * Search array of objects by the given keys
 * 
 * Will filter an array of objects where the searchstring matches one or more of the given
 * keys
 * 
 * @author 					Pætur Mortensen 
 */
export function search_filter(
	// Search string to filter by
	searchString:string, 
	// Array to search
	inputArr:InputItem[], 
	// Keys to search in the array
	searchableKeys:string[]
) : InputItem[]{

	// Prepare the search string

	// Remove all non-word characters (but keep numbers)
	searchString = searchString.replace(/[^\p{L}\p{N}]/gu, " ");
	// Truncate all spaces to single spaces
	searchString = searchString.replace(/\s+/g, " ");
	// Set to lower case for case-insensitive matching
	searchString = searchString.toLowerCase();
	// Split the string into words for matching
	const searchWords = searchString.trim().split(/\s+/);

	/**
	 * Check whether the search string matches the searchable string
	 *
	 * ALL words in search string must match or partly match ANY of the words in searchable string
	 *
	 * @author 					Pætur Mortensen
	 */
	const search_matches = (searchable: string) : boolean => {
		// Split the searchable string into array of words
		const searchableWords = searchable.trim().split(/\s+/);

		/**
		 * Check if word partly matches
		 *
		 * Check if a single search word partly matches any of the searchable words
		 *
		 * @author 					Pætur Mortensen
		 */
		const word_partly_matches = (searchWord : string, searchableWords : string[]) : boolean => {
			// Return whether any search word partly matches any searchable word
			return searchableWords.some( word => word.includes(searchWord));		
		};

		// Check whether ALL words in the searchString partly match ANY words in searchable words
		return searchWords.every((searchWord) => word_partly_matches(searchWord, searchableWords));
	}

	/**
	 * Build and return a searchable string from input item and selected properties
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function get_searchable_string(item: InputItem) : string {
		let searchable = '';

		// For each searchable key..
		for(const key of searchableKeys){
			// If the item's key is set and is string, we add it to the searchable string
			if(['string', 'number'].includes(typeof item[key])){
				searchable += ' ' + item[key].toString().toLowerCase();
			}
		}

		return searchable;
	}

	// Initialize results as empty
	const results:InputItem[] = [];

	// For each input item...
	for(const item of inputArr) {
		// Build a searchable string from the item
		const searchable = get_searchable_string(item);

		// If the search matches this item, add it to the resulting array
		if(search_matches(searchable)){
			results.push(item);
		}
	};
	
	// Return the matching results
	return results;
}