import { create } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { createOverlay } from "./overlayTypeElements";
import { IOverlayProperties } from "./types";
import { setType } from "../ZustandStores/zustandTypes";
import { ReactElement } from "react";

interface IOverlays {
  [overlay: string]: IOverlayProperties[];
}

export interface IOverlayStore {
  overlays: IOverlays;
  setOverlay?: (states: Object) => void;
  closeOverlay: (type: string, id: string | number, customClose?: any) => void;
  addOverlay: (
    type: string,
    jsx: (close: () => void) => ReactElement | ReactElement[],
    customClose?: any
  ) => IOverlayProperties;
  resetOverlay?: () => void;
}

// Initial states
const initialState: any = {
  overlays: {
    card: [],
    side_popout_self_close: [],
  },
  closeOverlay: () => {},
};

export const overlayStore = create(
  (set: setType<IOverlayStore>, get: () => IOverlayStore) => ({
    // Initialize states
    ...initialState,

    // Set overlay
    // - setOverlay allows us to set states within the overlayStore
    setOverlay: (states: any) => set(() => ({ ...states })),

    closeOverlay: (
      type: string,
      id: number | string,
      customClose: any = () => {}
    ) => {
      // Apply custom calls on close function
      customClose();
      // Remove overlay from store
      set((state: IOverlayStore) => {
        return {
          overlays: {
            ...state.overlays,
            [type]: state.overlays[type]?.filter(
              (e: IOverlayProperties) => e.id !== id
            ),
          },
        };
      });

      // return {close};
    },

    // Add overlay
    // - addOverlay allows us to add an overlay
    addOverlay: (
      type: string,
      jsx: (close: () => void) => ReactElement | ReactElement[],
      customClose: any = () => {}
    ) => {
      // (type)         - type specifies the overlay type we're using.
      // (callback)     - callback returns a close function, when calling
      //                  callback we want to return the jsx we want in our overlay.
      // (customClose)  - customClose is an optional parameter taht takes a function that
      //                  gets called when you close the overlay.

      // Generate id for our overlay
      const id: string | number = uuidv4();
      // Get current state
      const state: IOverlayStore = get();
      // Handle for close function
      const close: () => void = () => {
        state.closeOverlay(type, id, customClose);
      };

      // Create properties
      let properties: IOverlayProperties = {
        id: id,
        type: type,
        close: close,
        // Create the overlay component and store in the overlay member of properties
        overlay: createOverlay({
          overlay: jsx(close),
          id: id,
          close: close,
          type: type,
        }),
      };

      // Update state
      set((state: IOverlayStore) => {
        return {
          overlays: {
            ...state.overlays,
            [type]: [...(state.overlays?.[type] ?? []), properties],
          },
        };
      });

      // Return properties
      return properties;
    },

    // Reset overlay
    // - resetOverlay resets the store to its initial state
    resetOverlays: () => {
      set(() => ({ ...initialState }));
    },
  })
);
