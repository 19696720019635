export default function getHashParams(url: string) {
  let params = url.split("?")[1];
  if (params) {
    return params.split("&").reduce(function (res, item) {
      let parts = item.split("=");
      res[parts[0]] = parts[1];
      return res;
    }, {} as { [key: string]: string });
  } else {
    return {};
  }
}
