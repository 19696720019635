import styles from "./MediaCenter.module.scss";
import Dropzone, { FileRejection } from "react-dropzone";
import { faArrowToTop } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState } from "react";
import axios from "axios";
import env from "../../environment.json";
import Preloader from "src/assets/Preloader";
import Modal from "../Modal";
import { MediaUploaderType } from "./types";

/**
 * Media uploader
 * 
 * This component will upload media to the server for insertion into the system media handler
 * 
 * @author 					Pætur Mortensen
 */
export default function MediaUploader({ onUpload } : MediaUploaderType ) : JSX.Element {
	const [uploading, setUploading] = useState<boolean>(false);
	// Whether the user is currently dragging over the dropzone
	const [draggingOverUpload, setDraggingOverUpload] = useState<boolean>(false);
	// Rejected files 
	const [ rejects, setRejects ] = useState<FileRejection[]>([]);

	// Set accepted file types
	const acceptedFileTypes = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.mp4'];

	/**
	 * Handle the upload success
	 * 
	 * Returns uploaded files and data from the server
	 * 
	 * @author 					Pætur Mortensen
	 */
	function handle_upload_success(
		files:Array<{ fileExtension:string, mediaID:number, name:string, fileName:string }>
	) : void{

		const returnArr = files.map( file => {
			return {
				mediaID:file.mediaID, 
				name:file.name, 
				extension:file.fileExtension, 
				fileName:file.fileName
			}
		});

		onUpload(returnArr);
	}

	/**
	 * Upload the requested files
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function uploadFiles(acceptedFiles: File[], rejectedFiles: FileRejection[]) : void {
		setDraggingOverUpload(false);
		setUploading(true);
		
		// If there are rejected files...
		if(rejectedFiles.length > 0){
			setRejects(rejectedFiles);
			setUploading(false);
			return;
		}

		// Build form data with accepted files
		const formData = new FormData();
		for(const idx in acceptedFiles){
			const file = acceptedFiles[idx];
			formData.append("file_"+idx, file);
		}

		// Post the files to server
		axios
			.post(
				env.protocol + env.env + "/api/secured/media/UploadMedia",
				formData,
				{
					headers:{'Content-Type': 'multipart/form-data'}
				})
			.then( response => {
				handle_upload_success(response.data);
				setUploading(false);
			})
			.catch( error => {
				setUploading(false);
				console.error(error)
			});
		
	}

/***************************************************************************************************
 * 
 *																		RENDER 
 * 
 **************************************************************************************************/

	/**
	 * Render the upload dropzone
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_dropzone() : JSX.Element {
		return (
			<div className={styles.dropzone}>
				<Dropzone
					onDrop={uploadFiles}
					onDragEnter={() => { setDraggingOverUpload(true) }}
					onDragLeave={() => { setDraggingOverUpload(false) }}
					accept={acceptedFileTypes.join(',')}
				>
					{({ getRootProps, getInputProps }) => (
						<section>
							<div {...getRootProps()}>
								<input
									{...getInputProps()}
									maxLength={3}
								/>
								<div
									className={ styles.uploadBox + (draggingOverUpload ? ' ' + styles.dragOver : '') }
								>
									<div>
										<FontAwesomeIcon icon={faArrowToTop as IconProp} />
										<div>UPLOAD IMAGES/VIDEOS</div>
									</div>
								</div>
							</div>
						</section>
					)}
				</Dropzone>
			</div>
		);
	}

	/**
	 * Render upload in progress section
	 * 
	 * @author 					Pætur Mortensen
	 */
	function render_upload_progress() : JSX.Element {
		return (
			<div className={styles.dropzone}>
				<div
					className={styles.uploadBox}
				>
					<div>
						<Preloader show />
						<div>
							UPLOADING. 
							<br />
							PLEASE WAIT
						</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * One or more files have been rejected, display notification modal
	 * 
	 * @author 						Pætur Mortensen 
	 */
	function render_rejected_notification() : JSX.Element {

		return (
			<Modal 
				title="Cannot upload" 
				close={()=>{
					setRejects([]);
				}}
			>
				<div>
					The following errors occurred while trying to upload:
					<div>
						{rejects.map( file => {
							return (
								<>
								<br />
								<div>
									{file.errors[0].message}
									<br /><b>File:</b> {file.file.name}
								</div>
								</>
							);
						})}
					</div>
				</div>
			</Modal>
		);
	}

	return (
		<div className={styles.uploader}>
			{rejects.length > 0 && render_rejected_notification()}
			{!uploading ? render_dropzone() : render_upload_progress()}
		</div>
	);
}
