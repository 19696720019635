import Axios from "axios";
import React, { useEffect, useState } from "react";
import "./RelatedFeature.scss";
import env from "../../environment.json";
import noImage from "../images/empty.jpg";
import { forceCheckLanguage } from "../helperFunctions";
import languages from "./language.json";

export default function RelatedFeature(props) {
  // A component that relates a page in a page
  const { inEdit, editPage, data } = props;
  const [page, setPage] = useState(undefined);
  const [time, setTime] = useState(0);
  const [fetched, setFetched] = useState(false);
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";

  useEffect(() => {
    let isMounted = true; // this flag denotes mount status
    const fetchPage = async () => {
      if (data.pageRelatedId) {
        try {
          const response = await Axios.get(
            `${env.protocol}${env.env}/api/public/pages/GetPage.php?update=true&id=${
              data.pageRelatedId
            }&shared=${data.shared ? "1" : "0"}`
          );
          if (isMounted) {
            // only update state if component is still mounted
            setPage(response.data);
            setTime(new Date().getTime());
            setFetched(true);
          }
        } catch (error) {
          if (isMounted) {
            console.error(error);
          }
        }
      }
    };

    fetchPage();

    return () => {
      isMounted = false; // cleanup toggles value, if unmounted
    };
  }, [data]);

  let pageTitle = "";
  let titleSet = false;
  if (page) {
    if (page.languages) {
      if (page.languages[props.selectedLanguage])
        if (page.languages[props.selectedLanguage].title) {
          pageTitle = page.languages[props.selectedLanguage].title;
          titleSet = true;
        }

      if (!titleSet) {
        const force = forceCheckLanguage(page.languages);
        if (force) {
          if (force.title) {
            pageTitle = force.title;
            titleSet = true;
          }
        }
      }
    }

    if (!titleSet && page.page) pageTitle = page.page.page_name;
  }

  // if (!fetched)
  //   return "";

  return (
    <div
      className={
        "related-feature" +
        (!inEdit && data.pageRelatedId ? " active" : "") +
        (props.className ? ` ${props.className}` : "")
      }
      onClick={() => {
        if (inEdit) {
          props.changeFeature();
        } else {
          if (data.pageRelatedId) editPage(data.pageRelatedId);
        }
      }}
    >
      <div className="wrap-related-feature">
        <hr className="top" />
        <span>
          <p className="top">{languages[language].related_feature}</p>
          <img
            src={
              data.pageRelatedId !== undefined &&
              data.pageRelatedId !== null &&
              page !== undefined
                ? page.page !== null
                  ? page.page.feature_media !== null
                    ? page.page.feature_image_dir.medium + "?time=" + time
                    : noImage
                  : noImage
                : noImage
            }
            alt={"id: " + data.pageRelatedId}
          />
        </span>
        <div className="image-error">
          {data.pageRelatedId !== undefined && page !== undefined
            ? page.page !== null
              ? page.page.feature_media !== null
                ? "" // All good :)
                : "No feature image set on page."
              : ""
            : ""}
        </div>
        <div className="title">{pageTitle}</div>
        <hr className="bottom" />
      </div>
    </div>
  );
}
