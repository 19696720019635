import {
  StandaloneSearchBox,
  useLoadScript,
  LoadScript,
} from "@react-google-maps/api";
import VfiInputText from "./VfiInputText/VfiInputText";
import React, { useRef, useState } from "react";

const SearchBox = (props) => {
  const searchBox = useRef(null);
  const handlePlacesChanged = () => {
    const places = searchBox.current.getPlaces();
    console.log(places);
    if (props.change) {
      props.change(places[0]); // Call parent's callback with the first place.
    }
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <StandaloneSearchBox
        onLoad={(ref) => (searchBox.current = ref)}
        onPlacesChanged={handlePlacesChanged}
      >
        <VfiInputText
          placeholder={props.placeholder}
          value={props.value}
          defaultValue={props.defaultValue}
          name={props.name}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
      </StandaloneSearchBox>
    </LoadScript>
  );
};

const libraries = ["places"];

function GoogleMapSearchBox(props) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [places, setPlaces] = useState([]);

  console.log(isLoaded);

  return (
    <div
      data-standalone-searchbox=""
      name="practicalInfo"
      className={props.className}
    >
      <SearchBox
        placeholder={props.placeholder}
        value={props.value}
        defaultValue={props.defaultValue}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
      {props.listResult === true ? (
        <ol>
          {places.map(
            ({ place_id, formatted_address, geometry: { location } }) => (
              <li key={place_id}>
                {formatted_address}
                {" at "}({location.lat()}, {location.lng()})
              </li>
            )
          )}
        </ol>
      ) : null}
    </div>
  );
}

export default GoogleMapSearchBox;
