import GridType from "./GridType.jsx";
import languages from "./language.json";
import "../MediaGrid/MediaGrid.scss";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faDiagramLeanCanvas,
  faCardClub,
} from "@fortawesome/pro-thin-svg-icons";
import CardType from "./CardType.jsx";
import ListType from "./ListType.jsx";

const l = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export default function MainGridSelect(props) {
  const { data, onChange, allWhatson, whatsonTypes } = props;

  const language = languages[l];

  const setDesignType = (key, value) => {
    onChange({ ...data, [key]: value });
  };

  return (
    <div className="main-grid-select">
      <div className="header">
        <div className="left">
          <span className="title">
            {language.main_grid_select.header.title}
          </span>
        </div>
      </div>
      <div className="choose-view">
        {/* <div
          className={`choice ${
            data.whatson_design_type === "grid" && "selected"
          }`}
          onClick={() => {
            setDesignType("whatson_design_type", "grid");
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faDiagramLeanCanvas} />
          </div>
          <div className="text">Grid</div>
        </div> */}
        <div
          className={`choice ${
            data.whatson_design_type === "card" && "selected"
          }`}
          onClick={() => {
            setDesignType("whatson_design_type", "card");
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faCardClub} />
          </div>
          <div className="text">Card</div>
        </div>
        <div
          className={`choice ${
            data.whatson_design_type === "list" && "selected"
          }`}
          onClick={() => {
            setDesignType("whatson_design_type", "list");
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faList} />
          </div>
          <div className="text">List</div>
        </div>
      </div>
      <div className="grid-preset-choose">
        {data.whatson_design_type === "grid" && (
          <GridType
            data={data}
            allWhatson={allWhatson}
            whatsonTypes={whatsonTypes}
            onChange={onChange}
          />
        )}
        {data.whatson_design_type === "card" && (
          <CardType
            data={data}
            allWhatson={allWhatson}
            whatsonTypes={whatsonTypes}
            onChange={onChange}
          />
        )}
        {data.whatson_design_type === "list" && (
          <ListType
            data={data}
            allWhatson={allWhatson}
            whatsonTypes={whatsonTypes}
            onChange={onChange}
          />
        )}
      </div>
    </div>
  );
}
