import "../Shop.scss";
import UserInfo from "./UserInfo";
import OrderLines from './OrderLines';
import StatusList from './StatusList';
import styles from './Orders.module.scss';
import { OrderDetailsType } from "./types";
import OrderHistory from "./OrderHistory";

/**
 * Order details
 * 
 * Contains all information about the current order
 * 
 * @author 					Pætur Mortensen 
 */
export default function OrderDetails({
	// Edited data
	data,
	// Set edited data
	setData,
	// Whether the details are editable (user has selected "edit" in the header)
	editable,
} : OrderDetailsType ) : JSX.Element {

	// If there's no data, just return nothing until next render
	if (!data) return <></>;

	return (
		<div className={`payment ${styles.order}`}>
			<div className={styles.orderWrap}>
				<div className={styles.orderContent}>
					<div className={`${styles.userInfoRow}`}>
						<UserInfo 
							data={data} 
							setData={setData} 
							editable={editable} 
						/>
						<OrderLines 
							data={data}
							setData={setData} 
							editable={editable} 
						/>
					</div>
					<div className={styles.ordersRow}>
						<StatusList 
							data={data}
						/>
						<OrderHistory 
							data={data}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
