import style from "./Preloader.module.scss";

/**
 * Spinner to show during preload
 *
 * @param 		{bool} 	show 							Whether to display the preloader
 *
 * @returns 	jsx
 *
 * @author 					Pætur Mortensen
 */
function Preloader({ show }) {
  // Set class by whether we are to display the element
  const display = show ? style.display : style.hidden;

  return (
    <div className={style.preloader}>
      <div className={style.inner + " " + display}>
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    </div>
  );
}

export default Preloader;