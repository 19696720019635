import { iDataPermissions } from "../PermCheck/PermCheck";
import { usePermCheckStore } from "../PermCheck/PermCheckStore";
import { Route, navigationElements } from "./navigationElements";

export function initRouteArray(permissionsCheck?: any): Route[] {
  if (permissionsCheck === undefined) {
    permissionsCheck = () => {
      return false;
    };
  }

  let partialLinks = window.location.hash.split("/");
  partialLinks.shift();

  let el: Route = navigationElements[partialLinks[0]];

  let obj: Route = {};
  let routeArr: Route[] = [];

  if (
    el &&
    permissionsCheck({
      type: el?.checkType,
      id: el?.checkId,
      minimumRole: el?.checkMinimumRole,
    })
  ) {
    obj = {
      nav: el.nav,
      sidebar: el.sidebar,
      displayName: el.displayName,
    };
  } else {
    obj = {
      nav: navigationElements.home.nav,
      sidebar: navigationElements.home.sidebar,
      displayName: navigationElements.home.displayName,
    };
  }

  if (el?.sections) obj["sections"] = el.sections;
  routeArr.push(obj);

  for (let i = 1; i < partialLinks.length; i++) {
    const refSection = obj.sections;
    if (refSection !== undefined) {
      const refObj = refSection[partialLinks[i]];
      obj =
        refObj &&
        permissionsCheck({
          type: refObj.checkType,
          id: refObj.checkId,
          minimumRole: refObj.checkMinimumRole,
        })
          ? refObj
          : refSection.default;

      let vals: string[] = partialLinks[i].split("?")[0].split("-");
      if (vals[0] === "edit") {
        obj.nav = partialLinks[i];
        obj.displayName = partialLinks[i];
      } 
      if (vals[0] === "id") {
        obj.nav = vals[1];
        obj.displayName = '';
      }
    }

    routeArr.push(obj);
  }

  return routeArr;
}
