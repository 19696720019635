import styles from "./Spinner.module.scss";
import { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  children?: ReactElement | ReactElement[];
  close?: () => void;
}


export default function Spinner({ children = <></>, close = () => {}, }: Props) {



  return (
    <div className={styles.container}>
      <FontAwesomeIcon icon={faSpinner as IconProp} spin />
    </div>
  );
}
