import "./WhatsonChooseType.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-light-svg-icons";

function ListType(props) {
  const {
    data,
    data: { whatson_list },
    selectedLanguage,
    allWhatson,
  } = props;

  let items = whatson_list;
  if (items === undefined) {
    const whatsons = allWhatson.filter((e) => e.type === "place");
    items = whatsons.filter((item) =>
      data.whatson_chosen_ids.includes(item.id)
    );
  }

  return (
    <div className="list-type whatson-module whatson-list">
      <div className="container">
        {items && items.length === 0 && (
          <div className="list-item empty">(Whatson list is empty)</div>
        )}
        {items &&
          items.map((element, i) => {
            const hasImage = !!element.image && element.image.info;

            const color = `background-color color${element.id % 5}`;
            return (
              <div className="list-item" key={`list-${element.id}-${i}`}>
                <div className={`image-container ${color}`}>
                  {hasImage && (
                    <img
                      src={element.image.medium}
                      alt={element?.info?.languages["en"]?.alt}
                    />
                  )}
                  {!hasImage && <div className="bottom-shadow"></div>}
                  <div className="heart-icon">
                    <FontAwesomeIcon icon={faHeart} />
                  </div>
                </div>
                <div className="text-container">
                  <div className="top-container">
                    <h3>{element.headline}</h3>
                    <p>{element.excerpt}</p>
                  </div>
                  <div className="bottom-container">
                    <p>{element?.region_name}</p>
                    <div className="icon-container">
                      {element.categories &&
                        element.categories
                          .filter((e, i) => {
                            return i <= 2;
                          })
                          .map((e, i) => {
                            return (
                              <div key={`cat-${i}`}  className="icon">
                                {e?.medium?.thumbnail && (
                                  <img src={e?.medium?.thumbnail} alt="" />
                                )}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ListType;
