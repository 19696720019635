// function importAll(r) {
//     return r.keys().map(r);
// }

// export default ()=>importAll(require.context('./', false, /\.(png|jpe?g|svg)$/));

import mapaccomodation from "./map-accomodation.svg";
import mapactivities from "./map-activities.svg";
import mapartperformance from "./map-art-performance.svg";
import mapartvisual from "./map-art-visual.svg";
import mapcarrental from "./map-car-rental.svg";
import mapchildrenyouth from "./map-children-youth.svg";
import mapconference from "./map-conference.svg";
import mapempty from "./map-empty.svg";
import mapfestivals from "./map-festivals.svg";
import mapfilm from "./map-film.svg";
import mapfooddrinks from "./map-food-drinks.svg";
import mapgalleriesmuseums from "./map-galleries-museums.svg";
import maphistoryheritage from "./map-history-heritage.svg";
import mapliterature from "./map-literature.svg";
import mapmusic from "./map-music.svg";
import mapnatureoutdoors from "./map-nature-outdoors.svg";
import mapnightlife from "./map-nightlife.svg";
import mapothers from "./map-others.svg";
import mapreligion from "./map-religion.svg";
import mapshopping from "./map-shopping.svg";
import mapsightsattractions from "./map-sights-attractions.svg";
import mapsportsrecreation from "./map-sports-recreation.svg";
import mapsports from "./map-sports.svg";
import mapworkshop from "./map-workshop.svg";

export default {
  mapaccomodation,
  mapactivities,
  mapartperformance,
  mapartvisual,
  mapcarrental,
  mapchildrenyouth,
  mapconference,
  mapempty,
  mapfestivals,
  mapfilm,
  mapfooddrinks,
  mapgalleriesmuseums,
  maphistoryheritage,
  mapliterature,
  mapmusic,
  mapnatureoutdoors,
  mapnightlife,
  mapothers,
  mapreligion,
  mapshopping,
  mapsightsattractions,
  mapsportsrecreation,
  mapsports,
  mapworkshop,
};
