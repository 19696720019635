import React, { Component } from "react";
import env from "../../environment.json";
// import helperFunctions from '../../assets/helperFunctions';
import "./CreateWhatson.scss";
import Verify from "../../assets/VerifyMethods";
import WhatsonCreateNew from "../Content/WhatsonDisplay/WhatsonCreateNew";
import LoginTable from "./LoginTable";
import axios from "axios";
import RegisterTable from "./RegisterTable";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserPlus } from "@fortawesome/pro-solid-svg-icons";

function verifySave(loginInfo) {
  if (
    Verify.validEmail(loginInfo.teldupostur).length !== 0 ||
    Verify.notEmpty(loginInfo.firstname).length !== 0 ||
    Verify.notEmpty(loginInfo.lastname).length !== 0 //||
    // Verify.notEmpty(loginInfo.telefon).length !== 0
  )
    return false;
  return true;
}

export function forward({
  loginInfo,
  setForward = () => void 0,
  afterFunc = () => void 0,
}) {
  setForward(true); //Go to new event

  if (verifySave(loginInfo)) {
    // axios
    //   .post(env.protocol + env.env + "/api/createWhatsonUser", {
    //     userData: loginInfo,
    //   })
    //   .then((response) => {
    //     if (response.data === parseInt(response.data, 10)) {
    //       if (
    //         window.location.hostname === "localhost" ||
    //         window.location.hostname === "127.0.0.1"
    //       ) {
    //         sessionStorage.setItem("vfiUser", response.data);
    //       }
    //       setForward(true); //Go to new event
    //       afterFunc(response.data);
    //     } else {
    //       console.log("ERROR");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  } else {
    console.log("Error");
  }
}

export const defaultLoginInfo = {
  auth: false,
  firstname: "",
  lastname: "",
  teldupostur: "",
  confirmEmail: "",
  imgurl: "",
  accessToken: "",
  userId: "",
  id: "",
  landakoda: 298,
  telefon: "",
  fyritøka: "",
  miðil: "",
  heimasíðu: "",
  password: "",
};
class CreateWhatson extends Component {
  constructor(props) {
    super(props);

    this.axios = require("axios");
    this.state = {
      whatsonSite: "",
      language: "english",
      userId: undefined,
      register: !props.register,
    };
    this.loginInfo = { ...defaultLoginInfo };
  }

  facebookClick = () => {};

  editloginInfo = (name, value) => {
    this.loginInfo[name] = value;
    this.setState({});
  };

  backward = () => {
    this.loginInfo = { ...defaultLoginInfo };
    this.setState({ forward: false });
  };

  exit = () => {
    if (this.props.urlToGoBack) {
      this.setState({ renderNothing: true });
      window.open(decodeURIComponent(this.props.urlToGoBack), "_self");
    } else {
      this.setState({ forward: false });
    }
  };

  render() {
    if (this.state.renderNothing) {
      return "";
    }

    let loginData = (
      <LoginTable
        loginInfo={this.loginInfo}
        editloginInfo={this.editloginInfo}
        backward={this.backward}
        forward={() =>
          forward({
            setForward: (enabled) => {
              this.setState({ forward: enabled });
            },
            loginInfo: this.loginInfo,
            afterFunc: (data) => {
              this.setState({ userId: data });
            },
          })
        }
        redirect={this.props.redirect}
        setNav={this.props.setNav}
        setForward={() => {
          this.state.forward = true;
        }}
        setId={(id) => {
          this.setState({ userId: id });
        }}
      />
    );

    let registerData = (
      <RegisterTable
        loginInfo={this.loginInfo}
        editloginInfo={this.editloginInfo}
        backward={this.backward}
        forward={() =>
          forward({
            setForward: (enabled) => {
              this.setState({ forward: enabled });
            },
            loginInfo: this.loginInfo,
            afterFunc: (data) => {
              this.setState({ userId: data });
            },
          })
        }
        redirect={this.props.redirect}
        setNav={this.props.setNav}
        setId={(id) => {
          this.setState({ userId: id });
        }}
      />
    );

    return this.state.forward ? (
      <div className="content-forWhatson">
        <div className="whatson-content">
          <WhatsonCreateNew
            language={this.state.language}
            newWhat={this.props.redirect}
            submitChange={"false"}
            updateData={undefined}
            close={this.exit}
            isFront={true}
            // setSiteRef={() => console.log("Set site ref")}
            setSiteRef={() => null}
            userFront={this.state.userId}
            register={this.props.register}
            creatingANew={true}
          />
        </div>
      </div>
    ) : (
      <>
        <div className="whatson-contents">
          <a
            className="register-login"
            onClick={() => {
              // if (this.props.redirect === 'none') this.props.setNav(this.props.navTo === 'register'? `create.${this.props.redirect}` : 'register');
              // if (this.props.redirect === 'none') this.props.setNav(this.props.navTo === 'register'? `create.Event` : 'register');
              // if (this.state.redirect === undefined /* "none" */) this.setState({ register: !this.state.register });
              if (
                this.state.redirect === "none" ||
                this.props.redirect === "Event"
              )
                this.setState({ register: !this.state.register });

              if (
                this.props.navTo === "register" &&
                this.props.redirect !== "Event"
              ) {
                window.parent.postMessage(
                  {
                    redirect: "https://preview.vfibackend.com",
                    // redirect: 'https://localhost:3000',
                  },
                  "*"
                );
              }
            }}
          >
            <p>
              {this.state.register
                ? "ALREADY HAVE A USER? LOGIN IN HERE"
                : "DON'T HAVE A USER? REGISTER HERE"}
            </p>
            <FontAwesomeIcon
              icon={
                faUser /* this.props.navTo === 'register'? faUser : faUserPlus */
              }
            />
          </a>
          <div className="content col-sm-6">
            {this.state.register ? registerData : loginData}
          </div>
        </div>
      </>
    );
  }
}

export default CreateWhatson;
