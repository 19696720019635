import React, { useState } from "react";
import env from "../../../environment.json";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faHeart } from "@fortawesome/pro-regular-svg-icons";
// import { faHeart as faHeartSolid } from "@fortawesome/pro-solid-svg-icons";
import VfiCheckbox from "../../../assets/VfiCheckbox/VfiCheckbox";
import axios from "axios";
import { showDate, capitalize } from "../../../assets/helperFunctions";
import noImage from "../../../assets/images/empty.jpg";
import language from "./language.json";
import "./WhatsonApprovalStatusButtons.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import VfiCustomSelect from "../../../assets/VfiCustomSelect";
import VfiCKeditor from "../../../assets/VfiCKeditor/VfiCKeditor";

export function approvalStatusChange(
  whatWhatson,
  id,
  value,
  afterFunc = () => void 0,
  mailText
) {
  axios
    .post(
      env.protocol + env.env + "/api/secured/whatson/ApprovalStatusChange",
      {
        whatWhatson,
        id,
        value,
        userId: sessionStorage.getItem("vfiUser"),
        mailText,
      }
    )
    .then((response) => {
      if (response.data.success) afterFunc(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function ChangeApprovalScreen({
  defaultInfo,
  updateApprovalStatus = () => void 0,
  close = () => void 0,
}) {
  const [info, setInfo] = useState(defaultInfo);
  const [mailText, setMailText] = useState("");
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const sendMailDecision = ["changes required", "deny"].includes(info.value);

  return (
    <div className="over-all">
      <div className="over-all-content">
        <div className="oa-head">
          <div>Change decision</div>
          <div
            onClick={() => {
              close();
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
        {info.state === "index" && (
          <>
            <div className="oa-approval">APPROVAL</div>
            <div className="oa-select">
              <VfiCustomSelect
                options={[
                  { label: "Approve", value: "approve" },
                  {
                    label: "Changes required",
                    value: "changes required",
                  },
                  { label: "Deny", value: "deny" },
                ]}
                defaultValue={{
                  label: capitalize(info.value),
                  value: info.value,
                }}
                onChange={(e) => {
                  setInfo({ ...info, value: e.value });
                }}
              />
            </div>
          </>
        )}
        {info.state === "mail" && (
          <>
            <div className="oa-approval">REASON</div>
            <div className="oa-mail-text">
              <VfiCKeditor
                placeholder={"Reason for " + info.value}
                onChange={(e) => {
                  setMailText(e);
                }}
                defaultValue={mailText}
              />
            </div>
          </>
        )}
        <div className="oa-buttons">
          <button
            disabled={buttonsDisabled}
            className="cancel-button"
            onClick={() => close()}
          >
            CANCEL
          </button>
          <button
            disabled={buttonsDisabled}
            className="save-button"
            onClick={() => {
              if (sendMailDecision && info.state !== "mail") {
                // Go to mail thingy.
                setInfo({ ...info, state: "mail" });
                setMailText("");
              } else {
                setButtonsDisabled(true);
                // axios post and save.
                approvalStatusChange(
                  info.type,
                  info.id,
                  info.value,
                  () => {
                    updateApprovalStatus(info.type, info.id, info.value);
                    close();
                  },
                  mailText
                );
              }
            }}
          >
            {sendMailDecision && info.state !== "mail" ? "NEXT" : "SAVE"}
          </button>
        </div>
      </div>
    </div>
  );
}

export function WhatsonApprovalStatusButtons({
  element,
  whatWhatson,
  onChange = () => void 0,
  clickFunc,
}) {
  const [status, setStatus] = useState(element.approvalStatus);
  const lang = localStorage.getItem("language");
  const sendFunc = clickFunc
    ? (s) => {
        clickFunc(s);
      }
    : (s) => {
        approvalStatusChange(whatWhatson.toLowerCase(), element.id, s, () => {
          setStatus(s);
          onChange({ ...element, approval_status: s });
        });
      };

  return (
    <div className="approval-wrap">
      <div
        className={status === "approve" ? "approve chosen" : "approve"}
        onClick={() => {
          sendFunc("approve");
          // approvalStatusChange(element.id, "approve");
        }}
      >
        {language[lang].table_body.approval.approve}
      </div>
      <div
        className={
          status === "changes required"
            ? "changes-required chosen"
            : "changes-required"
        }
        onClick={() => {
          sendFunc("changes required");
          // approvalStatusChange(element.id, "changes required");
        }}
      >
        {language[lang].table_body.approval.changes_required}
      </div>
      <div
        className={status === "deny" ? "deny chosen" : "deny"}
        onClick={() => {
          sendFunc("deny");
          // approvalStatusChange(element.id, "deny");
        }}
      >
        {language[lang].table_body.approval.deny}
      </div>
    </div>
  );
}

function WhatsonContent(props) {
  const protocolAndHost = env.protocol + env.env;

  let approvalState = props.element.approval_status;
  const approvalStateRenames = { approve: "approved", deny: "denied" };
  if (approvalStateRenames[approvalState]) {
    approvalState = approvalStateRenames[approvalState];
  }

  return (
    <tr>
      <td
        className="file"
        onClick={() => {
          if (props.whatWhatson.toLowerCase() === "event") {
            axios
              .post(
                env.protocol +
                  env.env +
                  "/api/whatson/events/GetEvent?for-edit",
                {
                  id: props.element.id,
                }
              )
              .then((response) => {
                props.editContent(response.data);
              })
              .catch((error) => console.error(error));
          }

          if (props.whatWhatson.toLowerCase() === "place") {
            axios
              .post(
                env.protocol +
                  env.env +
                  "/api/whatson/places/GetPlace?for-edit",
                {
                  id: props.element.id,
                }
              )
              .then((response) => {
                props.editContent(response.data);
              })
              .catch((error) => console.error(error));
          }

          if (props.whatWhatson.toLowerCase() === "hiking") {
            axios
              .post(
                env.protocol +
                  env.env +
                  "/api/whatson/hiking/GetHiking?for-edit",
                {
                  id: props.element.id,
                }
              )
              .then((response) => {
                props.editContent(response.data);
              })
              .catch((error) => console.error(error));
          }

          if (props.whatWhatson.toLowerCase() === "tour") {
            axios
              .post(
                env.protocol + env.env + "/api/whatson/tours/GetTour?for-edit",
                {
                  id: props.element.id,
                }
              )
              .then((response) => {
                props.editContent(response.data);
              })
              .catch((error) => console.error(error));
          }

          if (props.whatWhatson.toLowerCase() === "tour provider") {
            axios
              .post(
                env.protocol +
                  env.env +
                  "/api/secured/whatson/touroperators/GetTourOperator?for-edit",
                {
                  id: props.element.id,
                }
              )
              .then((response) => {
                const tourOperator = response.data.tourOperator;
                tourOperator.data = { id: tourOperator.id };
                props.editContent(tourOperator);
              })
              .catch((error) => console.error(error));
          }
        }}
      >
        {props.element.submittedChanges ? (
          <div className="changesIcon">1</div>
        ) : (
          ""
        )}
        <img
          src={
            props.element.mainImage.id !== null &&
            props.element.mainImage.id !== undefined &&
            props.element.mainImage.id !== 0 &&
            props.element.mainImage.extension !== null
              ? protocolAndHost +
                "/uploads/" +
                props.element.mainImage.file_name +
                "_thumbnail." +
                props.element.mainImage.extension
              : noImage
          }
          alt="media view"
        />
        <div className="file-info">
          <div className="name">{props.element.title}</div>
        </div>
      </td>
      <td className="author">
        <div className="author-name">
          {props.element.author.firstname + " " + props.element.author.lastname}
        </div>
      </td>
      <td className="region">
        <div className="what-region">
          {props.element.region ? props.element.region : ""}
        </div>
      </td>
      <td className="location">
        <div className="location-sites">
          {props.element.location ? props.element.location : ""}
        </div>
      </td>
      <td className="approval">
        {props.element.currentStatus === "published" && props.approvalRequired
          ? capitalize(approvalState)
          : capitalize(props.element.currentStatus)}
      </td>
      <td className="date">
        <div className="the-date">{showDate(props.element.date)}</div>
      </td>
      <td className="check-select">
        <VfiCheckbox
          checked={props.element.selected ?? false}
          onChange={() => props.selectById(props.element.id)}
        />
      </td>
    </tr>
  );
}

export default WhatsonContent;
