import React, { useState } from "react";
import env from "../../../../environment.json";
import VfiCheckbox from "../../../../assets/VfiCheckbox/VfiCheckbox";
import axios from "axios";
import { showDate, capitalize } from "../../../../assets/helperFunctions";
import noImage from "../../../../assets/images/empty.jpg";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";
// import language from "./language.json";
// import "./WhatsonApprovalStatusButtons.scss";

export function approvalStatusChange(
  whatWhatson,
  id,
  value,
  afterFunc = () => void 0
) {
  axios
    .post(
      env.protocol + env.env + "/api/secured/whatson/ApprovalStatusChange",
      {
        whatWhatson,
        id,
        value,
        userId: sessionStorage.getItem("vfiUser"),
      }
    )
    .then((response) => {
      afterFunc(response);
    })
    .catch((error) => {
      console.error(error);
    });
}

export function IsArticleApprovalStatusButtons({ element }) {
  const [status, setStatus] = useState(element.approvalStatus);
  const lang = localStorage.getItem("language");

  return (
    <div className="approval-wrap">
      Not yet implemented
    </div>
  );
}

function IsArticleContent(props) {
  const protocolAndHost = env.protocol + env.env;
  const languages = props.element.languages;

  return (
    <tr>
      <td
        className="file"
        onClick={() => {
          props.editContent(props.element.id);
        }}
      >
        {props.element.submittedChanges ? (
          <div className="changesIcon">1</div>
        ) : (
          ""
        )}
        <img
          src={
            props.element.mainImage?.info?.id
              ? props.element.mainImage.thumbnail
              : noImage
          }
          alt="media view"
        />
        <div className="file-info">
          <div className="name">
            {languages.en ? languages.en.title : languages.fo.title}
          </div>
        </div>
      </td>
      <td className="author">
        <div className="author-name">
          {props.element.author.firstname + " " + props.element.author.lastname}
        </div>
      </td>
      <td className="location">
        <GlobalToolTipController
          title=""
          style={{
            height: "100%",
          }}
          delay={750}
          offset={{ x: 20, y: 20 }}
          toolTipElements={
            <div
              style={{
                padding: "5px",
                minWidth: "250px",
                width: "fit-content",
                height: "100%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <h1 style={{ fontSize: "18px" }}>Locations</h1>
              {props.element.infoscreens.map((infoscreen, index) => {
                return (
                  <p key={`infoscreens-${index}-${infoscreen.is_name}`} style={{ fontSize: "15px", padding: "0", margin: "0" }}>
                    {infoscreen.is_name}
                  </p>
                );
              })}
            </div>
          }
        >
          <div className="location-sites">
            {props.element?.infoscreens.length ? (
              <div
                style={{
                  height: "100%",
                  cursor: "default",
                }}
              >
                {props.element?.infoscreens.length < 2
                  ? props.element?.infoscreens[0]?.is_name
                  : props.element?.infoscreens.length < 100
                  ? `${props.element?.infoscreens.length} infoscreens`
                  : "+99 infoscreens"}
              </div>
            ) : (
              ""
            )}
          </div>
        </GlobalToolTipController>
      </td>
      <td className="approval">
        {props.element.current_status === "published" &&
        props.approvalRequired ? (
          <IsArticleApprovalStatusButtons element={props.element} />
        ) : (
          capitalize(props.element.current_status)
        )}
      </td>
      <td className="date">
        <div className="the-date">{showDate(props.element.create_date)}</div>
      </td>
      <td className="check-select">
        <VfiCheckbox
          checked={props.element.selected}
          onChange={() => props.selectById(props.element.id)}
        />
      </td>
    </tr>
  );
}

export default IsArticleContent;
