import { faChevronLeft } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import Verify from "../../assets/VerifyMethods";
import VfiInputText from "../../assets/VfiInputText/VfiInputText";
import "./ForgotPassword.scss";
import env from "../../environment.json";

export default function ForgotPassword({ goBack = () => void 0 }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const submitEmail = () => {
    setLoading(true);
    axios
      .post(env.protocol + env.env + "/api/public/ResetPasswordRequest.php", {
        email,
      })
      .then((response) => {
        if (response.data.success) {
          setEmailSent(true);
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="forgot-password">
      {!emailSent ? (
        <div>
          <h1 className="password-title">Lost your password?</h1>
          <p className="info">
            Please enter your email address and we’ll send you an email with a
            link to create a new password.
          </p>
          <div className="text-n-button">
            <VfiInputText
              placeholder={"Email address"}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              errorCheckSequence={email !== "" ? [Verify.validEmail] : []}
              disabled={loading}
              type={"email"}
            />
            <button
              onClick={() => submitEmail()}
              type="submit"
              disabled={Verify.validEmail(email) !== "" || loading}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "Get new password"
              )}
            </button>
          </div>
        </div>
      ) : (
        <div>
          <h1 className="password-title">Check your email inbox</h1>
          <p className="info center">
            An email has been sent to{" "}
            <span className="blue">
              <a href={"mailto: " + email.toLowerCase()}>
                {email.toLowerCase()}
              </a>
            </span>
            <br />
            Please check your inbox.
          </p>
        </div>
      )}
      <div className="back" onClick={() => goBack()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        Back to Login
      </div>
    </div>
  );
}
