import "../EditProduct.scss";
import { useEffect, useState } from "react";
import Button from "src/Components/Buttons/Button";
import Preloader from "src/assets/Preloader";
import axios from "axios";
import env from "../../../../../environment.json";
import Checkbox from "src/Components/Checkbox/Checkbox";

/**
 * Publicity information
 *
 * @param 		{object} 		product 									Product state
 * @param 		{function} 	setProduct 								Set product state
 *
 * @returns 	{jsx} 																Product type panel
 *
 * @author 					Pætur Mortensen
 */
export default function PublicityPanel({
	product,
	setProduct,
}) {
	// Whether component is loading
	const [ isLoading, setIsLoading ] = useState(false);
	// Whether this product is a test winner
	const [ testWinner, setTestWinner ] = useState(
		{state:product.testWinner, URL:product.testWinnerURL}
	);
	// Whether the test winner information has been changed
	const [ publicityChanged, setPublicityChanged ] = useState(false);
	// Original (server) test winner state
	const [ originalTestWinnerState, setOriginalTestWinnerState ] = useState({
		state:product.testWinner, URL:product.testWinnerURL 
	});
	const [ originalReadMoreURL, setOriginalReadMoreURL ] = useState(product.readMoreURL);
	// Read more link
	const [ readMoreURL, setReadMoreUrl ] = useState(product.readMoreURL);

	// Check for changes when info has been changed
	useEffect(() => {
		// Compare original testwinner and readmore state and current to see whether there have been 
		// changes
		const newChangedState = (
			JSON.stringify(originalTestWinnerState) !== JSON.stringify(testWinner)
			||
			readMoreURL !== originalReadMoreURL
		);
		// If the changed state is different from before, set new state
		if(newChangedState !== publicityChanged) setPublicityChanged(newChangedState);
	},[testWinner, originalTestWinnerState, readMoreURL, originalReadMoreURL]);


	/**
	 * Save testwinner information
	 * 
	 * @author 					Pætur Mortensen
	 */
	function save_publicity(){
		setIsLoading(true);
		const publicity = {
			testWinnerState:testWinner.state,
			testWinnerURL:testWinner.URL,
			readMore:readMoreURL
		};

		axios
			.post(
				env.protocol + env.env + '/api/secured/shop/UpdateProductPublicity',
				{productID:product.productID, publicity }
			)
			.then( response => {
				// Set the product with new data
				setProduct(response.data);
				setIsLoading(false);
				setOriginalTestWinnerState(testWinner);
				setOriginalReadMoreURL(readMoreURL);
			})
			.catch( error => {
				console.error(error);
				setIsLoading(false);
			});
	}

/***************************************************************************************************
 * 
 * 																	RENDER
 * 
 **************************************************************************************************/
	
	/**
	 * Render field to set the URL for more information about the testwinner
	 * 
	 * @returns 	{JSX.Element}
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_test_winner_url_field(){
		return (
			<div className="testwinner-url-input-field">
				<label>Link</label>
				<input 
					type="text" 
					value={testWinner.URL} 
					onChange={ e => {
						setTestWinner({...testWinner, URL:e.target.value});
					}} 
				/>
		</div>
		);
	}

	/**
	 * Render the read more field
	 * 
	 * @returns 		{JSX.Element}
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_read_more_field(){
		return (
			<div className="testwinner-url-input-field">
				<label>Read more link</label>
				<input 
					type="text" 
					value={readMoreURL} 
					onChange={ e => {
						setReadMoreUrl(e.target.value);
					}} 
				/>
		</div>
		);
	}

	return (
		<div className="publicity">
			<h3>Publicity</h3>
			<div className="testwinner-check-field">
				<Checkbox 
					checked={testWinner.state}
					onChange={ e => {
						setTestWinner({...testWinner, state:e.target.checked});
					}} 
				>
					Best in test
				</Checkbox>
				<br />				
			</div>
			{testWinner.state && render_test_winner_url_field()}
			{render_read_more_field()}
			{publicityChanged && <Button onClick={ () => {save_publicity()}}>Save changes</Button>}
			<Preloader show={isLoading} /> 
		</div>
	);
}
