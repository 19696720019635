import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./fonts/Typodermic-LibelSuitEl-Regular.otf";
import "./fonts/Typodermic-LibelSuitBk-Regular.otf";
import "./fonts/Typodermic-LibelSuitLt-Regular.otf";
import "./fonts/Typodermic-LibelSuitRg-Regular.otf";
import "./fonts/Typodermic-LibelSuitRg-Bold.otf";
import "./fonts/Typodermic-LibelSuitSb-Regular.otf";
import * as serviceWorker from "./serviceWorker";

// FORCE PUSH3

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
