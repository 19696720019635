import Overlay from "src/Components/Overlay";
import Preloader from "src/assets/Preloader";
import styles from './RecalculatingOverlay.module.scss';

export default function RecalculatingOverlay() : JSX.Element {

	return (
		<Overlay>
			<div className={styles.overlayInner}>
				<h3>Recalculating...</h3>
				<p>
					Please wait
					<br />
					Prices have changed and must be recalculated.
					<br /> 
					This may take a few seconds
					<br />
				</p>
				<Preloader show={true} />
			</div>
		</Overlay>
	);
}