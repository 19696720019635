import {
  faAddressCard,
  faAlignLeft,
  faArrowToBottom,
  faCalendarAlt,
  faEllipsisHAlt,
  faMapLocationDot,
  faMapMarker,
  faMemoCircleCheck,
  faNewspaper,
  faPhotoVideo,
  faPlus,
  faScreenUsers,
  faSearch,
  faTasks,
  faText,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./PageModuleChoose.scss";
import "./PageModulesElements.scss";

import languages from "./language.json";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import { searchJson } from "../../../../assets/helperFunctions";
import moduleIcons from "../../../../assets/images/module icons";
import env from "../../../../environment.json";
import { GlobalToolTipController } from "../../../ToolTips/GlobalToolTip";
export function defaultHighlightSliderParams(chosenLanguages) {
  // let languages = {};
  // chosenLanguages.forEach((language) => {
  //   languages[language] = [];
  // });

  return {
    type: "carousel",
    languages: [],
    media: [],
    is_hero: false,
    show_text: false,
    link_appearance: "none",
    caption: "none",
    gallery_expand: false,
    side_description: "",
    side_url: "",
    highlightBoxes: true,
  };
}
export function defaultSliderParams(isArticle = false) {
  return {
    type: "carousel",
    languages: [],
    media: [],
    is_hero: false,
    show_text: !isArticle,
    link_appearance: isArticle ? "none" : "on_buttons",
    caption: "none",
    gallery_expand: true,
    side_description: "",
    side_url: "",
  };
}

const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export function PageModules(index, chosenLanguages, addIn, isArticle = false) {
  let modules = [
    {
      is: "title",
      active: true,
      text: languages[language].top.toUpperCase(),
    },
    {
      is: "choice",
      active: true,
      icon: faAlignLeft,
      text: languages[language].new_vfi_top,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = [{}];
      //   });
      //   return {
      //     type: "visit_faroe_islands_top",
      //     data: [{}],
      //     languages,
      //     moduleOptions: [{ is: "hr" }],
      //     is_hero: false,
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_vfi_top}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((e) => {
            //   languages[e] = [{}];
            // });
            addIn(index, {
              new: true,
              type: "visit_faroe_islands_top",
              data: [
                {
                  bottom_icon: "2",
                  media: {},
                  title_and_description: "light_text",
                  link_option: "page",
                  key: 1,
                },
              ],
              languages: [{}],
              is_hero: true,
              show_icons: true,
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.topslider} alt="slider_icon" />
            {/* <FontAwesomeIcon icon={faShapes} /> */}
          </div>
          <div className="text">{languages[language].new_vfi_top}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for front "page slider" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faAlignLeft,
      text: languages[language].new_social_module,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = {};
      //   });
      //   return {
      //     type: "social",
      //     data: {},
      //     languages,
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_social_module}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((e) => {
            //   languages[e] = {};
            // });
            addIn(index, {
              new: true,
              type: "social",
              data: {},
              languages,
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faEllipsisHAlt} />
          </div>
          <div className="text">{languages[language].new_social_module}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "socials" module</div>,
    },
    {
      is: "title",
      active: true,
      text: languages[language].basic.toUpperCase(),
      includeInArticle: true,
    },
    {
      is: "choice",
      active: true,
      icon: faAlignLeft,
      text: languages[language].new_text_section,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = { blocks: "" };
      //   });
      //   return {
      //     type: "ckeditor_5",
      //     languages,
      //     moduleOptions: [{ is: "hr" }],
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_text_section}
          className="choice"
          onClick={() => {
            let languages = {};
            chosenLanguages.forEach((e) => {
              languages[e] = { blocks: "" };
            });
            addIn(index, {
              new: true,
              type: "ckeditor_5",
              languages,
              moduleOptions: [{ is: "hr" }],
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faText} />
          </div>
          <div className="text">{languages[language].new_text_section}</div>
        </div>
      ),
      includeInArticle: true,
      toolTipElement: (
        <div>
          <ul style={{ margin: 0 }}>
            <li>Placeholder for "text" module</li>
            <li>This one is a bit more fancy B{")"}</li>
          </ul>
        </div>
      ),
    },
    // {
    //   is: "choice",
    // active: true,
    //   icon: faAlignLeft,
    //   text: languages[language].new_excerpt_section,
    //   returnV: () => {
    //     let languages = {};
    //     chosenLanguages.forEach((e) => {
    //       languages[e] = { blocks: "" };
    //     });
    //     return {
    //       type: "ckeditor_5_excerpt",
    //       languages,
    //       moduleOptions: [{ is: "hr" }],
    //     };
    //   },
    //   style: {},
    // },
    // {
    //   is: "choice",
    // active: true,
    //   icon: faAlignLeft,
    //   text: languages[language].new_text_section_big_letter,
    //   returnV: () => {
    //     let languages = {};
    //     chosenLanguages.forEach((e) => {
    //       languages[e] = { blocks: "" };
    //     });
    //     return {
    //       type: "first_letter_big",
    //       languages,
    //       moduleOptions: [{ is: "hr" }],
    //     };
    //   },
    //   style: {},
    // },
    {
      is: "choice",
      active: true,
      icon: faAlignLeft,
      text: languages[language].new_related_and_text_section,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = { blocks: "" };
      //   });
      //   return {
      //     pageRelatedId: undefined,
      //     type: "related_and_text",
      //     languages,
      //     moduleOptions: [{ is: "hr" }],
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_related_and_text_section}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((e) => {
            //   languages[e] = { blocks: "" };
            // });
            addIn(index, {
              new: true,
              pageRelatedId: undefined,
              type: "related_and_text",
              languages: { blocks: "" },
              moduleOptions: [{ is: "hr" }],
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.related} alt="related_icon" />
          </div>
          <div className="text">
            {languages[language].new_related_and_text_section}
          </div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "related" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faAlignLeft,
      text: languages[language].new_text_section_expand_section,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = [{ blocks: "" }, { blocks: "" }];
      //   });
      //   return {
      //     type: "text_and_expand",
      //     languages,
      //     moduleOptions: [{ is: "hr" }],
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_text_section_expand_section + 1}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((e) => {
            //   languages[e] = [{ blocks: "" }, { blocks: "" }];
            // });
            addIn(index, {
              new: true,
              type: "text_and_expand",
              languages: [{ blocks: "" }, { blocks: "" }],
              moduleOptions: [{ is: "hr" }],
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.facts} alt="facts_icon" />
          </div>
          <div className="text">
            {languages[language].new_text_section_expand_section}
          </div>
        </div>
      ),
      includeInArticle: true,
      toolTipElement: <div>Placeholder for "facts" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPlus,
      text: languages[language].new_read_also,
      // returnV: () => {
      //   let languages = {};
      //   return { type: "read_also", languages, pageId: undefined };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_read_also}
          className="choice"
          onClick={() => {
            let languages = {};
            addIn(index, {
              new: true,
              type: "read_also",
              languages,
              pageId: undefined,
              new: true,
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <div className="text">{languages[language].new_read_also}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "read also" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPhotoVideo,
      text: languages[language].new_factsimage,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = [{ blocks: "" }, { blocks: "" }];
      //   });
      //   return { type: "media_with_subtext_and_text", languages };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_factsimage}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((e) => {
            //   languages[e] = [{ blocks: "" }, { blocks: "" }];
            // });
            addIn(index, {
              new: true,
              type: "media_with_subtext_and_text",
              languages: [{ blocks: "" }, { blocks: "" }],
              media: {},
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.factsimage} alt="facts_img_icon" />
          </div>
          <div className="text">{languages[language].new_factsimage}</div>
        </div>
      ),
      includeInArticle: true,
      toolTipElement: <div>Placeholder for "facts image" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPhotoVideo,
      text: languages[language].new_media,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = {};
      //   });
      //   return { type: "media", languages };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_media}
          className="choice"
          onClick={() => {
            let languages = {};
            chosenLanguages.forEach((e) => {
              languages[e] = {};
            });
            addIn(index, {
              new: true,
              type: "media",
              size: "small",
              isVideoUrl: false,
              videoUrl: "",
              caption: "none",
              captionText: "",
              languages,
              videoUrlParams: { controls: true },
              media: {},
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faPhotoVideo} />
          </div>
          <div className="text">{languages[language].new_media}</div>
        </div>
      ),
      includeInArticle: true,
      toolTipElement: <div>Placeholder for "new media" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faNewspaper,
      text: languages[language].newsletter,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = {};
      //   });
      //   return { type: "media", languages };
      // },
      style: {},
      element: (
        <div
          key={languages[language].newsletter}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((e) => {
            //   languages[e] = {headline:"GET THE NEWEST ARTICLES ABOUT FAROE ISLANDS"};
            // });
            addIn(index, {
              new: true,
              type: "newsletter",
              selectedTypes: [],
              languages: {
                headline: "GET THE NEWEST ARTICLES ABOUT FAROE ISLANDS",
              },
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faNewspaper} />
          </div>
          <div className="text">{languages[language].newsletter}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "newsletter" module</div>,
    },
    {
      is: "choice",
      icon: faTasks,
      text: languages[language].serviceTabs,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = {};
      //   });
      //   return { type: "media", languages };
      // }
      active: env.theme === "DS",
      style: {},
      element: (
        <div
          key={languages[language].serviceTabs}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((e) => {
            //   languages[e] = {headline:"GET THE NEWEST ARTICLES ABOUT FAROE ISLANDS"};
            // });
            addIn(index, {
              new: true,
              type: "serviceTabs",
              selectedTabs: [],
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faTasks} />
          </div>
          <div className="text">{languages[language].serviceTabs}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "service tabs" module</div>,
    },
    {
      is: "choice",
      active: env.theme === "DS",
      icon: faPhotoVideo,
      text: languages[language].team,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = {};
      //   });
      //   return { type: "media", languages };
      // },
      style: {},
      element: (
        <div
          key={languages[language].team}
          className="choice"
          onClick={() => {
            let languages = {};
            chosenLanguages.forEach((e) => {
              languages[e] = {};
            });
            addIn(index, {
              new: true,
              type: "media_grid",
              languages: [],
              media: [],
              preset_number: 1,
              grid_type: "team",
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faScreenUsers} />
          </div>
          <div className="text" style={{ textTransform: "capitalize" }}>
            {languages[language].team}
          </div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "team" module</div>,
    },
    {
      is: "choice",
      active: env.theme === "DS",
      icon: faPhotoVideo,
      text: languages[language].location,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((e) => {
      //     languages[e] = {};
      //   });
      //   return { type: "media", languages };
      // },
      style: {},
      element: (
        <div
          key={languages[language].location}
          className="choice"
          onClick={() => {
            let languages = {};
            chosenLanguages.forEach((e) => {
              languages[e] = {};
            });
            addIn(index, {
              new: true,
              type: "location",
              headline: "",
              phone: "",
              email: "",
              moreInfo: "",
              geo: { latitude: 0, longitude: 0, location: "", url: "" },
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faMapLocationDot} />
          </div>
          <div className="text">{languages[language].location}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "location" module</div>,
    },
    {
      is: "title",
      active: true,
      text: languages[language].gallery_layouts.toUpperCase(),
      includeInArticle: true,
    },
    {
      is: "choice",
      active: env.theme === "DS",
      text: languages[language].sliderWithHighlight,
      style: {},
      element: (
        <div
          key={languages[language].sliderWithHighlight}
          className="choice"
          onClick={() => {
            addIn(index, {
              ...defaultHighlightSliderParams(chosenLanguages),
              galleryType: "slider_with_highlight",
            });
          }}
        >
          <div className="custom-icon">
            <div className="wrap-box">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="text">{languages[language].sliderWithHighlight}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "highlight box" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPhotoVideo,
      text: languages[language].new_slider,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((language) => {
      //     languages[language] = [];
      //   });
      //   return {
      //     type: "carousel",
      //     languages,
      //     media: [],
      //     galleryType: "slider_default",
      //     is_hero: false,
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_slider}
          className="choice"
          onClick={() => {
            addIn(index, {
              new: true,
              // type: "carousel",
              // languages,
              // media: [],
              // is_hero: false,
              // show_text:true,
              // link_appearance:"on_buttons",
              // caption:"none",
              ...defaultSliderParams(chosenLanguages, isArticle),
              galleryType: "slider_default",
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.slider} alt="slider_icon" />
          </div>
          <div className="text">{languages[language].new_slider}</div>
        </div>
      ),
      includeInArticle: true,
      toolTipElement: <div>Placeholder for "slider" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPhotoVideo,
      text: languages[language].new_thumbnail_slider,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((language) => {
      //     languages[language] = [];
      //   });
      //   return {
      //     type: "carousel",
      //     languages,
      //     media: [],
      //     galleryType: "slider_with_thumb",
      //     is_hero: false,
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_thumbnail_slider}
          className="choice"
          onClick={() => {
            let languages = {};
            chosenLanguages.forEach((language) => {
              languages[language] = [];
            });
            addIn(index, {
              new: true,
              ...defaultSliderParams(chosenLanguages, isArticle),
              galleryType: "slider_with_thumb",
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.thumbnailgallery} alt="thumbnail_icon" />
          </div>
          <div className="text">{languages[language].new_thumbnail_slider}</div>
        </div>
      ),
      includeInArticle: false,
      toolTipElement: <div>Placeholder for "thumbnail slider" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPhotoVideo,
      text: languages[language].new_carousel,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((language) => {
      //     languages[language] = [];
      //   });
      //   return {
      //     type: "carousel",
      //     languages,
      //     media: [],
      //     galleryType: "slider_carousel",
      //     is_hero: false,
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_carousel}
          className="choice"
          onClick={() => {
            let languages = {};
            chosenLanguages.forEach((language) => {
              languages[language] = [];
            });
            addIn(index, {
              new: true,
              ...defaultSliderParams(chosenLanguages, isArticle),
              galleryType: "slider_carousel",
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.carousel} alt="carousel_icon" />
          </div>
          <div className="text">{languages[language].new_carousel}</div>
        </div>
      ),
      includeInArticle: false,
      toolTipElement: <div>Placeholder for "carousel" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPhotoVideo,
      text: languages[language].new_media_grid,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((language) => {
      //     languages[language] = [];
      //   });
      //   return {
      //     type: "media_grid",
      //     languages,
      //     media: [],
      //     preset_number: 1,
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_media_grid}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((language) => {
            //   languages[language] = [];
            // });
            addIn(index, {
              new: true,
              type: "media_grid",
              languages: [],
              media: [],
              preset_number: 1,
              grid_type: "gallery",
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.grid} alt="grid_icon" />
          </div>
          <div className="text">{languages[language].new_media_grid}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "grid" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPhotoVideo,
      text: languages[language].instagram,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((language) => {
      //     languages[language] = [];
      //   });
      //   return {
      //     type: "media_grid",
      //     languages,
      //     media: [],
      //     preset_number: 1,
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].instagram}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((language) => {
            //   languages[language] = [];
            // });
            addIn(index, {
              new: true,
              type: "media_grid",
              languages: [],
              media: [],
              preset_number: 1,
              grid_type: "instagram",
              paddingTop: 20,
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faInstagram} />
          </div>
          <div className="text">{languages[language].instagram}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "instagram" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faPhotoVideo,
      text: languages[language].new_gallery_3,
      // returnV: () => {
      //   let languages = {};
      //   chosenLanguages.forEach((language) => {
      //     languages[language] = [];
      //   });
      //   return {
      //     type: "media_grid",
      //     languages,
      //     media: [],
      //     preset_number: 1,
      //   };
      // },
      style: {},
      element: (
        <div
          key={languages[language].new_gallery_3}
          className="choice"
          onClick={() => {
            // let languages = {};
            // chosenLanguages.forEach((language) => {
            //   languages[language] = [];
            // });
            addIn(index, {
              new: true,
              ...defaultSliderParams(chosenLanguages, isArticle),
              galleryType: "wall_grid",
              wall_grid_amount: 3,
            });
          }}
        >
          <div className="image">
            <img src={moduleIcons.gallery} alt="grid_icon" />
          </div>
          {/* <div className="text">{languages[language].new_media_grid}</div> */}
          <div className="text">{languages[language].new_gallery_3}</div>
        </div>
      ),
      includeInArticle: true,
      toolTipElement: <div>Placeholder for "gallery" module</div>,
    },
    {
      is: "title",
      active: true,
      text: languages[language].inspiration.toUpperCase(),
    },
    // {
    //   is: "choice",
    //   active: true,
    //   icon: faThLarge,
    //   text: languages[language].whats_on,
    //   returnV: () => {
    //     // let languages = {};
    //     // chosenLanguages.forEach((language) => {
    //     //   languages[language] = {};
    //     // });
    //     return {
    //       type: "whatson_module",
    //       languages: {},
    //       what_is_on: "events",
    //       regions: [],
    //     };
    //   },
    //   element: (
    //     <div
    //       key={languages[language].whats_on}
    //       className="choice"
    //       onClick={() => {
    //         let languages = {};
    //         chosenLanguages.forEach((language) => {
    //           languages[language] = {};
    //         });
    //         addIn(index, {
    //           new: true,
    //           type: "whatson_module",
    //           languages,
    //           what_is_on: "events",
    //           regions: [],
    //         });
    //       }}
    //     >
    //       <div className="image">
    //         <FontAwesomeIcon icon={faCalendarAlt} />
    //       </div>
    //       {/* <div className="text">{languages[language].new_social_module}</div> */}
    //       <div className="text">{languages[language].whats_on}</div>
    //     </div>
    //   ),
    // },
    {
      is: "choice",
      active: true,
      icon: faThLarge,
      text: languages[language].places,
      returnV: () => {
        return {
          type: "whatson_module",
          what_is_on: "events",
          regions: [],
        };
      },
      element: (
        <div
          key={languages[language].places}
          className="choice"
          onClick={() => {
            addIn(index, {
              new: true,
              type: "whatson_filter_show",
              openOverlay: "whatsonChoose",
              data: {
                type: "whatson_grid",
                whatWhatson: "",
                category_filters: [],
                whatson_chosen_ids: [],
                amount_per_grid: 1,
                grid_preset: 1,
                moduleIndex: index,
                whatson_design_type: "card",
              },
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faMapMarker} />
          </div>
          {/* <div className="text">{languages[language].new_social_module}</div> */}
          <div className="text">{languages[language].places}</div>
        </div>
      ),
      includeInArticle: true,
      toolTipElement: <div>Placeholder for "places" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faThLarge,
      text: languages[language].download,
      returnV: () => {
        return {
          type: "download_module",
          data: [{ key: 1 }],
        };
      },
      element: (
        <div
          key={languages[language].download}
          className="choice"
          onClick={() => {
            let data = Array(4);

            for (let i = 0; i < data.length; i++) {
              const obj = {
                medium: undefined,
                medium_download: undefined,
                title: "Download module headline",
                subtitle: "Sub headline",
                button_label: "BUTTON LABEL",
                link_option: "download",
              };
              data[i] = obj;
              data[i].key = i + 1;
            }

            addIn(index, {
              new: true,
              type: "download_module",
              download_type: "grid",
              data,
              shown_amount: 1,
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faArrowToBottom} />
          </div>
          {/* <div className="text">{languages[language].new_social_module}</div> */}
          <div className="text">{languages[language].download}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "download" module</div>,
    },
    {
      is: "title",
      active: true,
      text: languages[language].forms.toUpperCase(),
    },
    {
      is: "choice",
      active: true,
      icon: faThLarge,
      text: languages[language].closed_en,
      returnV: () => {
        return {
          type: "closed_en",
          data: [{ key: 1 }],
        };
      },
      element: (
        <div
          key={languages[language].closed_en}
          className="choice"
          onClick={() => {
            let data = Array(4);

            addIn(index, {
              new: true,
              type: "form",
              form_type: "closed_en",
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faMemoCircleCheck} />
          </div>
          {/* <div className="text">{languages[language].new_social_module}</div> */}
          <div className="text">{languages[language].closed_en}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "closed 2023" module</div>,
    },
    {
      is: "choice",
      active: true,
      icon: faThLarge,
      text: languages[language].closed_fo,
      returnV: () => {
        return {
          type: "closed_fo",
          data: [{ key: 1 }],
        };
      },
      element: (
        <div
          key={languages[language].closed_fo}
          className="choice"
          onClick={() => {
            let data = Array(4);

            addIn(index, {
              new: true,
              type: "form",
              form_type: "closed_fo",
            });
          }}
        >
          <div className="image">
            <FontAwesomeIcon icon={faMemoCircleCheck} />
          </div>
          {/* <div className="text">{languages[language].new_social_module}</div> */}
          <div className="text">{languages[language].closed_fo}</div>
        </div>
      ),
      toolTipElement: <div>Placeholder for "closed 2023 fo" module</div>,
    },
  ];

  if (isArticle) {
    modules = modules.filter((e) => {
      return e.includeInArticle === true;
    });
  }

  return modules;
}

// export function RenderPageModules(index, chosenLanguages, addIn, search, setSearch)
export function RenderPageModules({
  index,
  chosenLanguages,
  addIn,
  isArticle,
  language = "en", // Provide a default value
}) {
  const [search, setSearch] = useState("");
  const handleFocus = (e) => {
    e.target.placeholder = "";
  };
  const handleBlur = (e) => {
    if (languages[language]) {
      e.target.placeholder = languages[language].search_placeholder;
    } else {
      // console.error(`Undefined language key: ${language}`);
    }
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleAddIn = (i, e) => {
    addIn(i, e);
    setSearch("");
  };

  // Make sure the language key exists
  const placeholderText = languages[language]
    ? languages[language].search_placeholder
    : "";

  const modules = PageModules(index, chosenLanguages, handleAddIn, isArticle)
    .filter((x) => x.active)
    .map((e, i) => {
      if (e.is === "title") {
        return (
          <div key={`el-${i}-${e.text}`} className="title">
            {e.text}
          </div>
        );
      }

      if (
        e.is === "choice" &&
        searchJson(e, search, ["element", "toolTipElement"])
      ) {
        return (
          <GlobalToolTipController
            key={`el-${i}-${e.text}`}
            offset={{ x: 30, y: 30 }}
            priority={"right"}
            delay={500}
            title={""} // Give the element a Title, so you can insert e.title as a parameter
            toolTipElements={e.toolTipElement}
          >
            {e.element}
          </GlobalToolTipController>
        );
      }

      return null;
    });

  return (
    <div className="render-page-modules">
      <div className="page-modules-list search">
        <FontAwesomeIcon icon={faSearch} />
        <input
          placeholder={placeholderText}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{ outline: "none" }}
        />
      </div>
      {modules}
    </div>
  );
}

export default function SvgChoose(props) {
  const [moduleIndexSelected, setModuleIndexSelected] = useState(undefined);
  const chosenLanguages = props.data.chosenLanguages;

  return (
    <div className="page-module-choose">
      <div className="close" onClick={() => props.close()}>
        <FontAwesomeIcon icon={faTimes} />
      </div>
      <div className="content">
        <h2>Select the desired module</h2>
        <div className="modules">
          {PageModules(chosenLanguages).map((element, i) => {
            switch (element.is) {
              case "title":
                return (
                  <div
                    className="title"
                    key={`chosenLanguages-${element.is}-${i}`}
                  >
                    {element.text}
                  </div>
                );
              case "choice":
                return (
                  <div
                    key={`chosenLanguages-${element.is}-${i}`}
                    className={
                      "choice " + (moduleIndexSelected === i ? "selected " : "")
                    }
                    onClick={() => {
                      setModuleIndexSelected(i);
                    }}
                  >
                    <FontAwesomeIcon icon={element.icon} />
                    <div className="text">{element.text}</div>
                  </div>
                );
              case "hr":
                return <hr key={`chosenLanguages-${element.is}-${i}`} />;
              default:
                return (
                  <div key={`chosenLanguages-${element.is}-${i}`}>
                    The "is": {element.is}, is not defined.
                  </div>
                );
            }
          })}
        </div>
        <div className="final-options">
          <div
            className={
              "option select " +
              (moduleIndexSelected === undefined ? "inactive" : "")
            }
            onClick={() => {
              if (moduleIndexSelected !== undefined) {
                props.returnValue(
                  PageModules(chosenLanguages)[moduleIndexSelected].returnV()
                );
                props.close();
              }
            }}
          >
            Select
          </div>
          <div className="option cancel" onClick={() => props.close()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
}
