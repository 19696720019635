import "./PageHistory.scss";
import languages from "../language.json";
import { showDate } from "../../../../assets/helperFunctions";

export default function PageHistory({
  history,
  selectedLanguage,
  onClick = (e) => void 0,
}) {
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";
  const lang = languages[language].edit_page.option_section.history;
  // return <table className="page-history">
  //     <tbody>
  //         <tr>
  //             <th>{lang.post}</th>
  //             <th>{lang.date}</th>
  //             {/* <th>{lang.user}</th> */}
  //         </tr>
  //         {history[selectedLanguage].map(e=><tr key={e.id}>
  //             {/* <td>{e.posts}</td>
  //             <td>{e.create_date}</td>
  //             <td>{e.firstname+" "+e.lastname}</td> */}
  //             {/* <td>{e.firstname+" "+e.lastname}</td> */}
  //         </tr>)}
  //     </tbody>
  // </table>
  return (
    <table className="page-history">
      <tbody>
        <tr>
          <th>{lang.id}</th>
          <th>{lang.date}</th>
          <th>{lang.user}</th>
        </tr>
        {history[selectedLanguage].map((e) => {
          return (
            <tr
              onClick={(f) => onClick({ ...e, pageX: f.pageX, pageY: f.pageY })}
              className={"data-row" + (!e.history ? " selected" : "")}
              key={e.id}
            >
              {/* <td>{e.posts}</td>
                <td>{e.create_date}</td>
                */}
              <td>{e.id}</td>
              <td>{showDate(new Date(e.create_date))}</td>
              <td>{e.firstname + " " + e.lastname}</td>
              {/* <td>{e.firstname+" "+e.lastname}</td> */}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
