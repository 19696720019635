import "../../EditProduct.scss";
import { faPencil, faClose, faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditInventory from "./EditInventory";
import { useState } from "react";
import Preloader from "src/assets/Preloader";
import axios from "axios";
import env from "../../../../../../environment.json";
import { cloneDeep } from "lodash";

/**
 * Variant details section
 * 
 * @param 		{object} 		variant 								Information about current variant
 * @param 		{object} 		product 								Product information state
 * @param 		{object} 		productConfig 					Configuration information about product
 *  
 * @returns  	{jsx}																Variant details element
 * 
 * @author 					Pætur Mortensen
 */
export default function VariantDetails({
	variant,
	product,
	setProduct,
	productConfig,
	recalculate_prices,
}){
	// Whether to edit the prices
	const [ editPrices, setEditPrices ] = useState(false);
	// Whether we are editing the variant label
	const [ editLabel, setEditLabel ] = useState(false);
	// Variant label value
	const [ labelValue, setLabelValue ] = useState(variant.label);
	// Whether label edit is loading (system is calling server with changes and updating)
	const [ labelEditIsLoading, setLabelEditIsLoading ] = useState(false);

	/**
	 * Save edited label
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function save_label(){

		// If label value has not changed, do nothing
		if(labelValue === variant.label) return;
		
		setLabelEditIsLoading(true);
		const variantID = variant.variantID;

		axios
			.post(
				env.protocol + env.env + '/api/secured/shop/SetVariantLabel',
				{label:labelValue, variantID}
			)
			.then( response => {
				const newProduct = cloneDeep(product);
				const variantIdx = newProduct.variants.findIndex( item => item.variantID === variantID);
				newProduct.variants[variantIdx].label = response.data.label;
				setProduct(newProduct);
				setLabelEditIsLoading(false);
				setEditLabel(false);
			})
			.catch( error => {
				console.error(error);
				setLabelEditIsLoading(false);
				setEditLabel(false);
				setLabelValue(variant.value);
			});
	}

	/**
	 * Render the button for enabling editing of prices
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_edit_prices_btn(){
		return (
			<div className="edit-details-btn">
				<div
					className="edit-price"
					onClick={() => {
						setEditPrices(true);
					}}
				>
					<FontAwesomeIcon icon={faPencil} />
				</div>
			</div>
		);
	}

	/**
	 * Render the label
	 * 
	 * @returns 	{jsx} 										Label and edit control
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_label(){

		return (
			<>
				<span>
					{variant.label}
				</span>
				{!product.synced &&
					<FontAwesomeIcon 
						onClick={() => {setEditLabel(true)}}
						className="edit-label-button"
						icon={faPencil} 
					/>
				}
			</>
		);
	}

	/**
	 * Render the edit label input field
	 * 
	 * @returns 	{jsx} 									Edit label input field
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_edit_label(){

		return (
			<>
				<input 
					value={labelValue} 
					onChange={ e => setLabelValue(e.target.value)} 
					className="edit-label-input"
				/>
				<FontAwesomeIcon 
					icon={faClose} 
					className="edit-label-button cancel-button"
					onClick={() => {
						setEditLabel(false);
						setLabelValue(variant.label);
					}}
				/>
				<FontAwesomeIcon 
					icon={faCheck} 
					className="edit-label-button check-button"
					onClick={save_label}
				/>
				<Preloader show={labelEditIsLoading} />
			</>
		);
	}

	return (
		<div className="variant-details">
				<h3>Details</h3>
				<div className="variant-label">
					<div className="label-label">Label:</div>
					{editLabel ? render_edit_label() : render_label() }
				</div>
				<table>
					<tbody>
						<tr>
							<th>Cost Price</th>
							<td>{variant.netPrice}</td>
						</tr>
						<tr>
							<th>Selling price</th>
							<td>{variant.costPrice}</td>
						</tr>
						<tr>
							<th>Stock</th>
							<td>{variant.quantity}</td>
						</tr>
						<tr>
							<th>Remote stock</th>
							<td>{variant.remoteQuantity}</td>
						</tr>
					</tbody>
				</table>
				{editPrices ? 
					<EditInventory 
						product={product} 
						productConfig={productConfig}
						variant={variant}
						close={() => {setEditPrices(false)}}
						recalculate_prices={recalculate_prices}
					/>
					: 
					render_edit_prices_btn()
				}
			</div>
		);
}