import styles from "./DefaultPopup.module.scss";
interface props {
  text?: React.ReactNode | string;
  cancelButtonStyle?: React.CSSProperties;
  okButtonStyle?: React.CSSProperties;
  cancelClick?: React.MouseEventHandler<HTMLButtonElement>;
  okClick?: React.MouseEventHandler<HTMLButtonElement>;
  cancelText?: string;
  okText?: string;
}
export default function DefaultPopup(props: props) {
  const {
    text,
    cancelButtonStyle,
    okButtonStyle,
    cancelClick,
    okClick,
    cancelText,
    okText,
  } = props;
  return (
    <div className={`${styles["default-popup"]} default-popup`}>
      <p className={`${styles["info"]} info`}>{text}</p>
      <div className={`${styles["bottom-buttons"]} bottom-buttons`}>
        <button
          className={`${styles["button"]} ${styles["button-cancel"]} button-cancel`}
          style={cancelButtonStyle}
          onClick={cancelClick}
        >
          {cancelText ?? "Cancel"}
        </button>
        <button
          className={`${styles["button"]} ${styles["button-ok"]} button-ok`}
          style={okButtonStyle}
          onClick={okClick}
        >
          {okText ?? "OK"}
        </button>
      </div>
    </div>
  );
}
