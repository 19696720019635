import Button from 'src/Components/Buttons/Button';
import styles from './Brands.module.scss';
import { useState } from 'react';
import Modal from 'src/Components/Modal';
import axios from "axios";
import env from "../../../../environment.json";
import { ProductTypeOption } from '../types';
import shopConfig from '../shopConfig';
import Select from 'react-select';
import { BrandsHeaderType } from './types';

/**
 * Header for the brands component
 * 
 * @author 					Pætur Mortensen 
 */
export default function BrandsHeader({ reload_brands, productTypes } : BrandsHeaderType ) : JSX.Element {
	// Whether we are adding a brand (open the add brand modal)
	const [ addingBrand, setAddingBrand ] = useState<boolean>(false);
	// Whether the component is loading
	const [ isLoading, setIsLoading] = useState<boolean>(false);
	// Selected product type option
	const [ selectedOption, setSelectedOption ] = useState<ProductTypeOption | null>(null);
	// New brand name
	const [ brandName, setBrandName ] = useState<string>('');
	
	// Options in the product type select
	const selectOptions = productTypes.map( item => ({label:item.name, value:item.UUID }));
	

	/**
	 * Request the server to add the brand
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function add_brand() : void {
		// If product type has not been selected, we can't continue
		if(selectedOption === null) return;

		setIsLoading(true);

		const productTypeUUID = selectedOption.value;

		axios
			.post(
				env.protocol + env.env + '/api/secured/shop/AdminAddBrand',
				{productTypeUUID, brandName, siteID:shopConfig.siteID}
			)
			.then( response => {
				console.log(response);
				setIsLoading(false);
				setAddingBrand(false);
				reload_brands();
			})
			.catch( error => {
				console.error(error);
				setIsLoading(false);
				setAddingBrand(false);
			});
	}

/***************************************************************************************************
 * 
 * 																	RENDER
 * 
 **************************************************************************************************/
	
	/**
	 * Render the form to add a brand
	 * 
	 * @author 					Pætur Mortensen
	 */
	function render_add_brand_form() : JSX.Element {

		return(
			<Modal
				title="Add new brand"
				type="confirmation"
				close={() => {setAddingBrand(false)}}
				onCancel={() => {setAddingBrand(false)}}
				onConfirm={add_brand}
				showPreloader={isLoading}
			>
				<div className={styles.brandForm}>
					<div className={styles.brandField}>
						<label>Product type</label>
						<Select
							options={selectOptions}
							value={selectedOption}
							onChange={ e => { setSelectedOption(e) }} 
						/>
					</div>
					<div className={styles.brandField}>
						<label>Name</label>
						<input 
							type='text'
							value={brandName}
							onChange={ e => {setBrandName(e.target.value)}}
						/>
					</div>
				</div>
			</Modal>
		);
	}

	return(
		<div className={styles.brandsHeader}>
			{addingBrand && render_add_brand_form()}
			<h1>Brands</h1>
			<Button
				onClick={() => setAddingBrand(true)}
			>
				Add brand
			</Button>
		</div>
	);
}