import { useEffect, useState } from 'react';
import styles from './Brands.module.scss';
import { Brand, BrandListType } from './types';
import axios from 'axios';
import env from "../../../../environment.json";
import Preloader from 'src/assets/Preloader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faClose, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Button from 'src/Components/Buttons/Button';
import Modal from 'src/Components/Modal';
import PriceRules from './PriceRules';

/**
 * List of brands on the system
 * 
 * @author 					Pætur Mortensen 
 */
export default function BrandList({ 
	serverBrands,
	reload_brands, 
	priceRules,
	isLoading,
	setIsLoading,
} : BrandListType ) : JSX.Element{
	// List of brands on system
	const [ brands, setBrands ] = useState<Brand[]>([]);
	// Whether we need to confirm deletion of a brand
	const [ confirmDeleteBrand, setConfirmDeleteBrand ] = useState<boolean>(false);
	// Which brand we are editing
	const [ editBrand, setEditBrand ] = useState<string>('');
	// Active row (e..g has just been moved)
	const [ activeRow, setActiveRow ] = useState<string | null>(null);
	// Name of edited brand
	const [ editBrandName, setEditBrandName ] = useState<string>('');
	
	// Whether the brand order has changed
	const orderChanged = check_order_changed();
	// Whether there are changes in edit brand form
	const editChanged = check_edit_changed();
	
	// Set and sort the brands whenever server brands change
	useEffect(() => {
		setBrands(get_sorted_brands(serverBrands));
	},[serverBrands]);

	/**
	 * Get sorted brands
	 * 
	 * Sort brands by their orderValue and return sorted array
	 * 
	 * @author 					Pætur Mortensen
	 */
	function get_sorted_brands(brands:Brand[]) : Brand[] {
		return brands.sort( (a, b) => a.orderValue - b.orderValue);
	}

	/**
	 * Check whether the brand order has changed
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function check_order_changed() : boolean {
		// If the server and local brands don't have the same length, there have been changes
		if(serverBrands.length !== brands.length) return true;

		// For each element in serverBrands...
		for(const idx in serverBrands){
			// If the same element in both brands does not have the same UUID, 
			// there have been changes in order
			if(serverBrands[idx].UUID !== brands[idx].UUID) return true;
		}

		return false;
	}

	/**
	 * Check whether the edit form has changed
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function check_edit_changed() : boolean {
		// If we're not editing a brand, this is not relevant
		if(editBrand === '') return false;

		const serverBrand = serverBrands.find( brand => brand.UUID === editBrand );
		// If we can't find a server brand, we just exit
		if(serverBrand === undefined) return false;

		// If name has changed, return true
		if(serverBrand.name !== editBrandName) return true;

		// No changes found, return false
		return false;
	}

	/**
	 * Move a brand up or down in the order
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function move_brand(UUID:string, direction:'up' | 'down') : void {
		// Get information on current item
		const currentIdx = brands.findIndex( brand => brand.UUID === UUID);
		
		// Skip if brand is first and we are moving it upwards
		if(currentIdx === 0 && direction === 'up') return;
		// Skip if brand is the last one and we are moving it downwards
		if(currentIdx === brands.length -1 && direction === 'down') return;

		let counter = 1;
		for(const idx in brands){
			const index = parseInt(idx);

			// If this is the target brand, skip this iteration
			if(index === currentIdx) continue;

			// If we are to move the brand up..
			if(direction === 'up'){
			
				// If this is the index before the target index set order value of this iteration
				if(index === currentIdx -1){
					brands[currentIdx].orderValue = counter;
					counter ++;
				}

				brands[index].orderValue = counter;
			} else {
				// We are to move the brand down

				brands[index].orderValue = counter;

				// If this is the index after the target index, set order after the item
				if(index === currentIdx +1){
					counter ++;
					brands[currentIdx].orderValue = counter;
				}
			}

			counter ++;
		}

		setBrands(get_sorted_brands([...brands]));
		setActiveRow(UUID);
	}

	/**
	 * Save the brand order
	 * 
	 * @author 					Pætur Mortensen
	 */
	function save_order() : void {
		setIsLoading(true);

		// Build the request data
		const brandOrder = [];
		for(const brand of brands){
			brandOrder.push({UUID:brand.UUID, orderValue:brand.orderValue});
		}

		axios
			.post(
				env.protocol + env.env + '/api/secured/shop/AdminSetBrandOrder',
				{brandOrder}
			)
			.then( response => {
				reload_brands();
				setIsLoading(false);
			})
			.catch( error => {
				setIsLoading(false);
				console.error(error);
			});
	}

	/**
	 * Close the edit brand form and clean up
	 * 
	 * @author 					Pætur Mortensen
	 */
	function close_edit_form() : void {
		setEditBrand('');
		setEditBrandName('');
		setActiveRow(null);
	}

	/**
	 * Save changes to brand and reload brands
	 * 
	 * @author 					Pætur Mortensen
	 */
	function save_brand() : void {
		setIsLoading(true);

		axios.post(
			env.protocol + env.env + '/api/secured/shop/AdminUpdateBrandName',
			{name:editBrandName, UUID:editBrand}
		).then( () => {
			setIsLoading(false);
			reload_brands();
		})
		.catch( error => {
			console.error(error);
			setIsLoading(false);
		});
	}

	/**
	 * Delete a brand
	 * 
	 * This function requests the server to delete a brand (and all its descendants)
	 * 
	 * @author 					Pætur Mortensen
	 */
	function delete_brand() : void {
		setIsLoading(true);

		axios.post(
			env.protocol + env.env + '/api/secured/shop/AdminDeleteBrand',
			{UUID:editBrand}
		).then( () => {
			setIsLoading(false);
			setConfirmDeleteBrand(false);
			reload_brands();
		})
		.catch( error => {
			console.error(error);
			setIsLoading(false);
			setConfirmDeleteBrand(false);
		});
	}

/***************************************************************************************************
 * 
 * 																				RENDER
 * 
 **************************************************************************************************/
	
	/**
	 * Render the save order changes button CP 
	 * 
	 * @author 					Pætur Mortensen
	 */
	function render_order_changes_cp() : JSX.Element {

		return (
			<div className={styles.orderChangeCP}>
				<div className={styles.message}>
					Order has changed
				</div>
				<Button 
					onClick={save_order}
					size="small">Save order</Button>
				<Button 
					type="secondary"
					size="small"
					onClick={ () => {
						setBrands([...serverBrands]);
					}}
				>
					Reset
				</Button>
			</div>
		);
	}

	/**
	 * Render the row with brand editing form
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_edit_brand_row( brand : Brand ) : JSX.Element {
		const brandRules = priceRules.filter( rule => rule.brandUUID === brand.UUID);

		return (
			<tr className={styles.editRow}>
				<td colSpan={3}>
					<div className={styles.editRowCP}>
						<FontAwesomeIcon 
							className={styles.closeButton}
							icon={faClose as IconProp} 
							onClick={close_edit_form}
						/>
					</div>
					<div className={styles.nameEditField}>
						<span>Name</span>
						<input 
							type="text"
							value={editBrandName}
							onChange={ e => setEditBrandName(e.target.value)}
						/>
						{editChanged && 
							<Button
								size="small"
								onClick={save_brand}
							>
								Save changes
							</Button>
						}
						<FontAwesomeIcon 
							className={styles.deleteBrandBtn}
							icon={faTrashAlt as IconProp} 
							onClick={() => {setConfirmDeleteBrand(true)}}
						/>
					</div>
					<PriceRules brand={brand} priceRules={brandRules} reload_brands={reload_brands} />					
				</td>
			</tr>
		);
	}


	/**
	 * Render a single brand row
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_brand_row(brand: Brand) : JSX.Element {

		return (
			<>
				<tr key={brand.UUID} className={activeRow === brand.UUID ? styles.activeRow : ''}>
					<td
						className={styles.clickableTd}
						onClick={() => {
							setEditBrand(brand.UUID);
							setActiveRow(brand.UUID);
							setEditBrandName(brand.name);
						}}
					>
						{brand.name}
					</td>
					<td>{brand.numProducts}</td>
					<td>
						<div className={styles.sortCP}>
							<FontAwesomeIcon
								className={styles.sortBtn}
								onClick={() => {
									move_brand(brand.UUID, "up");
								}}
								icon={faChevronUp as IconProp}
							/>
							<FontAwesomeIcon
								className={styles.sortBtn}
								onClick={() => {
									move_brand(brand.UUID, "down");
								}}
								icon={faChevronDown as IconProp}
							/>
						</div>
					</td>
				</tr>
				{editBrand === brand.UUID && render_edit_brand_row( brand )}
			</>
		);
	}

	/**
	 * Render the brand list table
	 * 
	 * @author 					Pætur Mortensen
	 */
	function render_brand_list() : JSX.Element {

		return (
			<table>
				<tbody>
					<tr>
						<th>Brand</th>
						<th>Products</th>
						<th></th>
					</tr>
					{brands.map( brand => render_brand_row( brand ))}
				</tbody>
			</table>
		);
	}

	/**
	 * Render the "Confirm deletion" modal before deleting a brand
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_confirm_delete() : JSX.Element {
		const brand = brands.find( brand => brand.UUID === editBrand) as Brand;

		return (
			<Modal
				title="Delete entire brand"
				type="confirmation"
				onCancel={()=>{setConfirmDeleteBrand(false)}}
				close={()=>{setConfirmDeleteBrand(false)}}
				onConfirm={delete_brand}
				confirmButtonText='Yes, delete brand'
			>
				<div>
					You are about to delete <b>{brand.name}</b> and all of its products
					<br />
					<br />
					Number of products: <b>{brand.numProducts}</b>
					<br />
					<br />
					Are you sure that you want to do this? (This can not be undone)
				</div>
			</Modal>
		);
	}

	return (
		<div className={styles.brandList}>
			{confirmDeleteBrand && render_confirm_delete()}
			<div className={styles.topCP}>
				<Preloader show={isLoading} />
				{orderChanged && render_order_changes_cp()}
			</div>
			{!isLoading && render_brand_list()}
		</div>
	);
}