import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SitesMenuFrontEnd.scss";

export default function SitesMenuFrontEnd(props) {
  return (
    <div className={"Sites-display-wrap"}>
      <div className="siteMenuWrap">
        {Object.keys(props.contentData.ckeditor_5).length > 0 && (
          <span
            dangerouslySetInnerHTML={{
              __html:
                props.contentData.ckeditor_5.languages[props.selectedLanguage]
                  .blocks,
            }}
          />
        )}
      </div>
      <div className="follow">
        <div className="follow_content">
          <p className="text">Follow Us</p>
          <div className="socialIcons">
            {props.contentData.follow.length > 0 &&
              props.contentData.follow.map((e, i) => (
                <div
                  className="socialIconItem"
                  key={i}
                  dangerouslySetInnerHTML={{ __html: e.block }}
                ></div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
