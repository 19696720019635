import styles from "./Orders.module.scss";
import { OrderHistoryType } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import env from "../../../../environment.json";
import { get_relative_date_format } from "src/utils/StringHelpers";

/**
 * Get the history for this order
 * 
 * @author 					Pætur Mortensen 
 */
export default function OrderHistory({ data }: OrderHistoryType): JSX.Element {
	
	return (
		<div className={`${styles.box} ${styles.orderHistoryWrap}`}>
			<h3>Order history</h3>
			<div className={`${styles.orderHistory}`}>
				<div className={`${styles.header}`}>Name</div>
				<div className={`${styles.header}`}>Date</div>
				{data.payments
					.map( payment => {
						const { id, pdf_name, date } = payment;
						const dateString = get_relative_date_format(
							Math.floor(new Date(date).getTime() / 1000)
						);

						return [
							<div key={id + "name"} className={`${styles.info}`}>
								<a
									href={`${env.protocol}${env.env}/pdf/${pdf_name}`}
									target="_blank"
								>
									<span className={styles.icon}>
										<FontAwesomeIcon icon={faFile as IconProp} />
									</span>
									{pdf_name}
								</a>
							</div>,
							<div key={id + "date"} className={styles.info}>
								{dateString}
							</div>,
						];
					})
					.reverse()}
			</div>
		</div>
	);
}
