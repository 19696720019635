import styles from './Brands.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { useState } from "react";
import Preloader from "src/assets/Preloader";
import Button from "src/Components/Buttons/Button";
import axios from "axios";
import env from "../../../../environment.json";
import shopConfig from '../shopConfig';
import Select from 'react-select';
import { PriceRule, PriceRulesType } from './types';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import RecalculatingOverlay from '../EditProduct/RecalculatingOverlay';

/**
 * Price rules table and form for the current brand
 * 
 * @author 					Pætur Mortensen
 */
export default function PriceRules({ 
	brand, 
	priceRules, 
	reload_brands 
} : PriceRulesType) : JSX.Element {
	// Default empty rule form
	const emptyRuleForm = { rate: "", rateType: "percent", label: "" };
	// Whether this component is loading
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// Whether we are currently adding a rule
	const [addingRule, setAddingRule] = useState<boolean>(false);
	// Price rule for data
	const [ruleForm, setRuleForm] = useState(emptyRuleForm);
	// Whether component is currently recalculating
	const [ isRecalculating, setIsRecalculating ] = useState<boolean>(false);

	// Options for the rate type select
	const rateTypeOptions = [
		{ label: "Fixed", value: "fixed" },
		{ label: "Percent", value: "percent" },
	];

	/**
	 * Recalculate prices on the system
	 * 
	 * After price changes, prices must be recalculated on the system and the product must be updated
	 * This is the quickest consistent way to update prices without a lot of management
	 * 
	 * @author 					Pætur Mortensen
	 */
	function recalculate_prices() : void {
		setIsRecalculating(true);
		
		axios.get( env.protocol + env.env + "/api/secured/system/RecalculatePrices")
			.then( () => {
				setIsRecalculating(false);
				reload_brands();
			})
			.catch( error => {
				setIsRecalculating(false);
				console.error(error)
			});
	}

	/**
	 * Add a price rule
	 * 
	 * @author 					Pætur Mortensen
	 */
	function add_price_rule() : void {
		setIsLoading(true);

		axios
			.post(env.protocol + env.env + "/api/secured/shop/AdminAddBrandPriceRule", {
				...ruleForm,
				branduuid: brand.UUID,
				siteID: shopConfig.siteID,
			})
			.then( () => {
				recalculate_prices();
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	}

	/**
	 * Delete a price rule
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function delete_price_rule(uuid:string) : void {
		setIsLoading(true);

		axios
			.post(
				env.protocol + env.env + "/api/secured/shop/DeletePriceRule",
				{uuid}
			)
			.then( () => {
				recalculate_prices();
				setIsLoading(false);
			})
			.catch( error => {
				console.log(error);
				setIsLoading(false);
			});
	}

	/***************************************************************************************************
	 *
	 * 																		RENDER
	 *
	 **************************************************************************************************/

	/**
	 * Render the button to add a rule
	 *
	 * @author 					Pætur Mortensen
	 */
	function render_add_rule_button() : JSX.Element {
		return (
			<Button
				block
				onClick={() => {
					setAddingRule(true);
				}}
			>
				Add rule
			</Button>
		);
	}

	/**
	 * Render the form to add a rule
	 *
	 * @author 					Pætur Mortensen
	 */
	function render_add_rule_form() : JSX.Element {
		return (
			<div>
				<input
					className={styles.priceRuleLabelInput}
					value={ruleForm.label}
					placeholder="Rule name"
					onChange={(e) => setRuleForm({ ...ruleForm, label: e.target.value })}
				/>
				<div className={styles.formInputContainer}>
					<input
						className={styles.rateInput}
						placeholder="rate"
						value={ruleForm.rate}
						onChange={(e) => setRuleForm({ ...ruleForm, rate: e.target.value })}
					/>
					<Select
						options={rateTypeOptions}
						value={rateTypeOptions.find(
							(option) => option.value === ruleForm.rateType
						)}
						onChange={ e => {
							if(e === null) return;
							setRuleForm({ ...ruleForm, rateType: e.value });
						}}
					/>
				</div>
				<div className={styles.addCPBtnContainer}>
					<Button
						type="secondary"
						onClick={() => {
							setAddingRule(false);
							setRuleForm(emptyRuleForm);
						}}
					>
						Cancel
					</Button>
					<Button onClick={add_price_rule}>Add</Button>
				</div>
			</div>
		);
	}

	/**
	 * Render the proice rules for this product
	 * 
	 * @returns 	{JSX} 												Price rules table
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_price_rules() : JSX.Element {
		
		/**
		 * Render a single price rule
		 * 
		 * @param 		{object} 	rule 						Rule to render
		 *  
		 * @returns 	{JSX} 										Price rule row
		 * 
		 * @author 					Pætur Mortensen 
		 */
		function render_rule(rule:PriceRule) : JSX.Element {
			// Extract and format rule data
			const rate = Math.abs(rule.rate);
			const rateSign = rule.rate >= 0 ? "+" : "-";
			const rateTypeSign = rule.rateType.value === "percent" ? "%" : ",-";
	
			return (
				<tr key={rule.UUID}>
					<td>{rule.label}</td>
					<td>
						{rateSign}
						{rate}
						{rateTypeSign}
					</td>
					<td>
						<FontAwesomeIcon 
							className={styles.deleteBtn}
							icon={faTrashAlt as IconProp} 
							onClick={() => delete_price_rule(rule.UUID)}
						/>
					</td>
				</tr>
			);
		}

		return (
			<table>
				<tbody>
					<tr>
						<th>Rule</th>
						<th>Rate</th>
						<th></th>
					</tr>
					{priceRules.map((rule) => {
						return render_rule(rule);
					})}
				</tbody>
			</table>
		);
	}

	return (
		<>
			<div className={styles.brandPrices}>
				<div>
					<h3>Brand prices</h3>
					<p>
						Price adjustments for this brand and all its products and variants
					</p>
				</div>
				<div className={styles.priceRules}>
					{priceRules.length === 0
						? "There are no price rules for this product"
						: render_price_rules()}
				</div>
				<div className={styles.loadContainer}>
					<Preloader show={isLoading} />
				</div>
				{addingRule ? render_add_rule_form() : render_add_rule_button()}
			</div>
			{isRecalculating && <RecalculatingOverlay />}
		</>
  );
}
