import Verify from "../../assets/VerifyMethods";
import VfiInputText from "../../assets/VfiInputText/VfiInputText";
import env from "../../environment.json";
import vfiLogo from "../../assets/images/vfi-logo.png";
import vitaLogo from "../../assets/images/vita-logo.png";
import FacebookLogin from "react-facebook-login";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import VfiCustomSelect from "../../assets/VfiCustomSelect";
import countryCodes from "../../assets/countryCodes.json";
import { allObjTrue } from "../../assets/helperFunctions";
import languages from "./language.json";
import VfiCheckbox from "../../assets/VfiCheckbox";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import { GlobalToolTipController } from "../ToolTips/GlobalToolTip";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

const language = localStorage.getItem("language") ?? "english";

export default function RegisterTable({
  loginInfo,
  editloginInfo = (key, value) => {},
  backward = () => {},
  forward = () => {},
  redirect,
  setNav = () => {},
  setId,
  defaultLogin = (id) => {},
}) {
  const [a, setA] = useState(0);
  const [changedValues, setChangedValues] = useState({
    firstname: false,
    lastname: false,
    password: false,
    phone: false,
    email: false,
    privacyPolicy: false,
    confirmEmail: false,
  });

  const redirects = ["Event", "Place", "Tour", "Tour-operator"];

  const [confirmEmail, setConfirmEmail] = useState(false);
  const [confirmEmailSuccess, setConfirmEmailSuccess] = useState(false);
  const [invalidRegister, setInvalidRegister] = useState(null);

  const [userId, setUserId] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [verificationCode, setVerificationCode] = useState(null);
  const [verifyError, setVerifyError] = useState(false);

  const langText = languages[language].personal_info;

  const formRef = useRef(null);

  const responseFacebook = (res) => {
    var e = res;

    axios
      .post(env.protocol + env.env + "/api/public/facebookAuth.php", {
        access_token: e.accessToken,
      }) //userData)
      .then((response) => {
        var data = response.data;

        if (
          data.msg === "Login successful" ||
          data.msg === "Registration successful"
        ) {
          editloginInfo("firstname", e.first_name);
          editloginInfo("lastname", e.last_name);
          editloginInfo("teldupostur", e.email);
          // editloginInfo("imgurl", e.picture.data.url);
          editloginInfo("accessToken", e.accessToken);
          editloginInfo("id", data.id);
          // setA(new Date().getTime());
          editloginInfo("auth", true);

          // sessionStorage.setItem("vfiUser", data.id);
          setId(data.id);

          defaultLogin(data.id);

          if (redirect === "Event") {
            window.parent.postMessage(
              {
                resize: {
                  height: 1200,
                },
              },
              "*"
            );

            forward();
          } else if (redirect === "change") {
            forward();
          } else {
            setNav("");
            window.parent.postMessage(
              JSON.stringify({
                redirect: env.protocol + env.env,
              }),
              "*"
            );
          }
        }
      });

    // Verify via access_token in backend, and login/forward if login success (valid facebook user)
    // Create new enpoint for facebook login/register
  };

  const registerWithoutFacebook = () => {
    editloginInfo("auth", true);
    setA(new Date().getTime());
  };

  const mapCountryCodes = countryCodes.map((e) => {
    return { label: `+${e.dial_code} ${e.name}`, value: e.dial_code };
  });

  const mapCountryCodesAdd = countryCodes.map((e) => {
    return { label: `+${e.dial_code}`, value: e.dial_code };
  });

  const checkInfo = (userInfo) => {
    // Verify and validate required info
    if (
      userInfo.teldupostur !== "" &&
      userInfo.teldupostur === userInfo.confirmEmail &&
      userInfo.firstname !== "" &&
      userInfo.lastname !== "" &&
      userInfo.telefon !== "" &&
      !(Verify.validPassword(userInfo.password) === "Not valid password.") &&
      privacyPolicy
    ) {
      let obj = {
        userData: {
          profilename: loginInfo.firstname,
          email: loginInfo.teldupostur,
          firstname: loginInfo.firstname,
          lastname: loginInfo.lastname,
          facebook: "",
          psw: loginInfo.password,
          bio: "",
          company: "",
          regions: [],
          places: [],
          permission_info: [],
        },
      };

      axios
        .post(env.protocol + env.env + "/api/public/registerUser.php", obj) //userData)
        .then((response) => {
          var data = response.data;

          document.querySelector(".whatson-contents").scrollTo(0, 0);

          if (
            data.msg === "User successfully created" ||
            data.msg === "User already pending"
          ) {
            if (rememberMe) {
              // Do something here
            }

            window.parent.postMessage(
              {
                type: "register",
                msg: "User successfully created",
              },
              "*"
            );

            editloginInfo("id", data.id);

            setId(data.id);
            setUserId(data.id);
            setConfirmEmail(true);
          } else {
            window.parent.postMessage(
              {
                type: "register",
                msg: "User already exists",
              },
              "*"
            );

            setInvalidRegister("Email already taken");

            setUserId(null);
          }
        });
    } else {
      document.querySelector(".whatson-contents").scrollTo(0, 0);

      setChangedValues({
        ...changedValues,
        email: true,
        password: true,
      });

      setInvalidRegister("Required fields missing");

      window.parent.postMessage(
        {
          type: "register",
          msg: "Invalid userinfo",
        },
        "*"
      );
    }
  };

  const verifyEmail = (code) => {
    axios
      .post(env.protocol + env.env + "/api/public/verifyEmail.php", {
        code: code,
        email: loginInfo.teldupostur,
        userId: userId,
      }) //userData)
      .then((response) => {
        var data = response.data;

        if (data.msg === "Verification successful") {
          // If remember me is true set local storage

          // sessionStorage.setItem("vfiUser", data.id);
          setId(data.id);

          defaultLogin(data.id);

          // setNav('create.' + redirect);
          if (
            redirect === "Event"
            // redirect === "Place"
          ) {
            editloginInfo("id", data.id);

            window.parent.postMessage(
              {
                resize: {
                  height: 1200,
                },
              },
              "*"
            );

            forward();
          } else if (redirect === "change") {
            forward();
          } else {
            window.parent.postMessage(
              {
                redirect: env.protocol + env.env,
              },
              "*"
            );

            setConfirmEmailSuccess(true);
            setTimeout(() => {
              window.location.replace(env.protocol + env.env);
            }, 4000);
          }
        } else {
          setVerifyError(true);
        }
      });
  };

  useEffect(() => {
    let height = formRef.current.clientHeight + 300;

    window.parent.postMessage(
      {
        height: height,
      },
      "*"
    );

    const handleMessage = (e) => {
      if (e.data?.msg) {
        if (e.data?.msg === "loaded") {
          let height = formRef.current.clientHeight + 300;
          window.parent.postMessage(
            {
              height: height,
            },
            "*"
          );
        }
      }
    };

    window.addEventListener("message", handleMessage);
    window.addEventListener("resize", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
      window.removeEventListener("resize", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (formRef.current) {
      let height = formRef.current.clientHeight + 300;
      // let height = formRef.current.clientHeight + 1200;

      window.parent.postMessage(
        {
          resize: {
            height: height,
          },
        },
        "*"
      );
    }

    return () => {
      // window.removeEventListener("message", handleMessage);
      // window.removeEventListener("resize", handleResize);
    };
  }, [formRef]);

  if (confirmEmailSuccess)
    return (
      <div ref={formRef} id="LogoContainer">
        <div className="imgcontainer">
          <img
            src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo}
            alt="vfi-logo"
          />
          <div className="text">
            <p className="whatson-text">What's on</p>
            {/* <p className="vfi-text">The Faroe Islands</p> */}
          </div>
        </div>
        <div className="container">
          You have successfully verified your user!
        </div>
        <div className="container">
          Redirecting to login <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      </div>
    );

  if (confirmEmail)
    return (
      <div ref={formRef} id="LogoContainer">
        <div className="imgcontainer">
          <img
            src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo}
            alt="vfi-logo"
          />
          <div className="text">
            <p className="whatson-text">What's on</p>
            {/* <p className="vfi-text">The Faroe Islands</p> */}
          </div>
        </div>
        <div className="container">
          <p>
            An email has been sent to{" "}
            <span style={{ fontWeight: "bold" }}>{loginInfo.teldupostur}</span>
          </p>
          <input
            className={`verifyEmailInput ${verifyError ? "invalidCode" : ""}`}
            placeholder="Enter Verification Code"
            onChange={(e) => {
              setVerificationCode(e.target.value);
            }}
            onFocus={(e) => {
              e.target.placeholder = "";
            }}
            onBlur={(e) => {
              e.target.placeholder = "Enter Verification Code";
            }}
          ></input>
          <div className="verifyButtons">
            <button
              className="verifyEmailBtn"
              onClick={() => {
                verifyEmail(verificationCode);
              }}
            >
              Verify email
            </button>
            <button
              className="backBtn"
              onClick={() => {
                setConfirmEmail(false);
                setPrivacyPolicy(false);
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <div ref={formRef} id="LogoContainer">
      <div className="imgcontainer">
        <img
          src={env.env.includes("vita.fo") ? vitaLogo : vfiLogo}
          alt="vfi-logo"
        />
        <div className="text">
          <p className="whatson-text">What's on</p>
          {/* <p className="vfi-text">The Faroe Islands</p> */}
        </div>
      </div>
      <div className="container">
        {(redirect === "Event" ||
          redirect === "login" ||
          redirect === "change") && (
          <>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name,first_name,last_name,email,picture"
              callback={responseFacebook}
              // onClick={this.facebookClick}
              version={"v16.0"}
              xfbml={true}
              cssClass="my-facebook-button-class"
              textButton={
                <div>
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                    alt="fb-logo"
                  />
                  <span className="fb-text">Continue with Facebook</span>
                </div>
              }
            />
            <div className="seperator">or</div>
          </>
        )}

        <div className="loginTable" key={a}>
          {/* <h5 className="headline1">Fortel eitt sindur um teg</h5> */}
          {/* <p className="headline2">Hetta verður ikki gjørt alment</p> */}
          {/* <h5
            className="headline1"
            dangerouslySetInnerHTML={{ __html: langText.title }}
          />
          <p
            className="headline2"
            dangerouslySetInnerHTML={{ __html: langText.description }}
          /> */}

          {invalidRegister && (
            <div style={{ color: "rgb(255, 0, 0)" }}>{invalidRegister}</div>
          )}

          {(redirect === "Event" ||
            redirect === "login" ||
            redirect === "change") && <h5>REGISTER WITH EMAIL</h5>}

          <div className="form">
            <div
              className={`email ${
                invalidRegister === "Required fields missing" &&
                loginInfo.teldupostur === ""
                  ? "missingField"
                  : ""
              }`}
            >
              <p>What is your email?</p>

              <VfiInputText
                name="email"
                defaultValue={loginInfo.teldupostur}
                placeholder="Enter email"
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Enter email";
                }}
                onChange={(e) => {
                  editloginInfo("teldupostur", e.target.value);
                  setChangedValues({ ...changedValues, email: true });
                }}
                errorCheckSequence={
                  changedValues.email ? [Verify.validEmail] : []
                }
              />
            </div>

            <div
              className={`confirm-email ${
                invalidRegister === "Required fields missing" &&
                loginInfo.confirmEmail === ""
                  ? "missingField"
                  : ""
              }`}
            >
              <p>Confirm your email</p>

              <VfiInputText
                name="confirmEmail"
                defaultValue={loginInfo.confirmEmail}
                placeholder="Enter email again"
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Enter email again";
                }}
                onChange={(e) => {
                  editloginInfo("confirmEmail", e.target.value);
                  setChangedValues({ ...changedValues, confirmEmail: true });
                }}
                errorCheckSequence={
                  changedValues.email
                    ? [
                        Verify.validEmail,
                        (e) => Verify.confirmEmail(e, loginInfo.teldupostur),
                      ]
                    : []
                }
              />
            </div>

            <div
              className={`password ${
                invalidRegister === "Required fields missing" &&
                loginInfo.password === ""
                  ? "missingField"
                  : ""
              }`}
            >
              <div className="title-wrapper">
                <p>Create a password</p>
                <GlobalToolTipController
                  solid
                  priority="right,bottom,top,left"
                  className="password-requirenments"
                  toolTipElements={
                    <>
                      {/* <p style={{
                        padding: "0 0 5px 0",
                        margin: 0,
                        fontSize: '15px',
                      }}>The password should contain</p> */}
                      <ul
                        className="password-tooltip"
                        style={{
                          width: "100%",
                          height: "100%",
                          padding: "10px 0 0 20px",
                          textAlign: "left",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                        }}
                      >
                        <li>Atleast 8 characters</li>
                        <li>Atleast 1 uppercase character</li>
                        <li>Atleast 1 number</li>
                        <li>Atleast 1 special character</li>
                      </ul>
                    </>
                  }
                  delay={500}
                  title=""
                >
                  <FontAwesomeIcon icon={faCircleQuestion} />
                </GlobalToolTipController>
              </div>

              <VfiInputText
                name="password"
                defaultValue={loginInfo.password}
                placeholder="Create a password"
                type="password"
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Create a password";
                }}
                onChange={(e) => {
                  editloginInfo("password", e.target.value);
                  setChangedValues({ ...changedValues, password: true });
                }}
                errorCheckSequence={
                  changedValues.password ? [Verify.validPassword] : []
                }
              />
            </div>

            <div className={`name`}>
              <p>What are we calling you?</p>

              <div className="inputs">
                <div
                  className={`firstname  ${
                    invalidRegister === "Required fields missing" &&
                    loginInfo.firstname === ""
                      ? "missingField"
                      : ""
                  }`}
                >
                  <VfiInputText
                    name="fname"
                    defaultValue={loginInfo.firstname}
                    placeholder="First name"
                    onFocus={(e) => {
                      e.target.placeholder = "";
                    }}
                    onBlur={(e) => {
                      e.target.placeholder = "First name";
                    }}
                    onChange={(e) => {
                      editloginInfo("firstname", e.target.value);
                      setChangedValues({ ...changedValues, firstname: true });
                    }}
                    errorCheckSequence={
                      changedValues.firstname ? [Verify.notEmpty] : []
                    }
                  />
                </div>
                <div
                  className={`lastname ${
                    invalidRegister === "Required fields missing" &&
                    loginInfo.lastname === ""
                      ? "missingField"
                      : ""
                  }`}
                >
                  <VfiInputText
                    name="lname"
                    defaultValue={loginInfo.lastname}
                    placeholder="Last name"
                    onFocus={(e) => {
                      e.target.placeholder = "";
                    }}
                    onBlur={(e) => {
                      e.target.placeholder = "Last name";
                    }}
                    onChange={(e) => {
                      editloginInfo("lastname", e.target.value);
                      setChangedValues({ ...changedValues, lastname: true });
                    }}
                    errorCheckSequence={
                      changedValues.lastname ? [Verify.notEmpty] : []
                    }
                  />
                </div>
              </div>
            </div>
            <div className="containerLanda_tele">
              <p>What is your phone number?</p>

              <div className="phoneInputs">
                <div className="landakoda">
                  <VfiCustomSelect
                    className="landakoda2"
                    name="Landakoda"
                    defaultValue={loginInfo.landakoda}
                    placeholder="Landakoda"
                    onChange={(e) => {
                      editloginInfo("landakoda", e.value);
                    }}
                    options={mapCountryCodes}
                    value={mapCountryCodesAdd.find(
                      (e) => e.value === loginInfo.landakoda
                    )}
                    classNames={{
                      menu: (state) => "menu",
                    }}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        width: "100px",
                        height: "51px",
                      }),
                      valueContainer: (base, state) => ({
                        ...base,
                        height: "100%",
                      }),
                      container: (base) => ({
                        ...base,
                        height: "100%",
                      }),
                    }}
                  />
                </div>
                <div
                  className={`telefon ${
                    invalidRegister === "Required fields missing" &&
                    loginInfo.telefon === ""
                      ? "missingField"
                      : ""
                  }`}
                >
                  <VfiInputText
                    name="phone"
                    defaultValue={loginInfo.telefon}
                    placeholder="Phone number"
                    onFocus={(e) => {
                      e.target.placeholder = "";
                    }}
                    onBlur={(e) => {
                      e.target.placeholder = "Phone number";
                    }}
                    onChange={(e) => {
                      editloginInfo("telefon", e.target.value);
                      setChangedValues({ ...changedValues, phone: true });
                    }}
                    inputType="int"
                    errorCheckSequence={
                      changedValues.phone ? [Verify.notEmpty] : []
                    }
                  />
                </div>
              </div>
            </div>

            <div className="work">
              <p>Where do you work? (Optional)</p>

              <VfiInputText
                name="Work"
                defaultValue={loginInfo.fyritøka}
                placeholder="Company name"
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Company name";
                }}
                onChange={(e) => {
                  editloginInfo("fyritøka", e.target.value);
                }}
              />
            </div>
            <div className="website">
              <p>Do you have a website? (Optional)</p>

              <VfiInputText
                name="Website"
                defaultValue={loginInfo.heimasíðu}
                placeholder="Enter website link"
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Enter website link";
                }}
                onChange={(e) => {
                  editloginInfo("heimasíðu", e.target.value);
                }}
              />
            </div>
            <div className="social-media">
              <p>Do you have a social media account? (Optional)</p>
              <VfiInputText
                name="Media"
                defaultValue={loginInfo.miðil}
                placeholder="Enter social media link"
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = "Enter social media link";
                }}
                onChange={(e) => {
                  editloginInfo("miðil", e.target.value);
                }}
              />
            </div>

            {/* <div className="remember-me">
              <VfiCheckbox
              // checked={false}
              onChange={() => {
                setRememberMe(!rememberMe);
              }}
              />
              <p>Remember me</p>
            </div> */}

            <div
              className={`accept-policy ${
                invalidRegister === "Required fields missing" && !privacyPolicy
                  ? "missingField"
                  : ""
              }`}
            >
              <VfiCheckbox
                // checked={privacyPolicy}
                onChange={() => {
                  setPrivacyPolicy(!privacyPolicy);
                }}
              />
              <p>
                I accept the{" "}
                <a href="#" target="_blank">
                  privacy policy
                </a>{" "}
                and{" "}
                <a href="#" target="_blank">
                  terms of condition
                </a>
              </p>
            </div>
          </div>

          <div className="buttons">
            <button
              className="register"
              onClick={() => {
                checkInfo(loginInfo);
                // forward();
                // setChangedValues({ ...allObjTrue(changedValues) });
                // setA(new Date().getTime());
              }}
            >
              REGISTER
            </button>

            {/* <div
              className="back"
              onClick={() => {
                backward();
                setA(new Date().getTime());
              }}
            >
              <span className="text">back</span>
            </div>
            <div
              className="forward"
              onClick={() => {
                forward();
                setChangedValues({ ...allObjTrue(changedValues) });
                setA(new Date().getTime());
              }}
            >
              <span className="text">Forward</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
