import { Enum, EnumValues } from "./Enum";

/**
 * `SQUARE_BUTTON` object
 * 
 * This is a runtime representation of the different types of square buttons, created by providing strings directly.
 * Each string will become both a key and a value in the resulting object.
 * 
 * @example
 * if (someButton.type === SQUARE_BUTTON.button) {
 *     // handle button type
 * }
 */
export const SQUARE_BUTTON = Enum("button", "submit", "reset");

/**
 * Example of how `Enum` can be used with key-value pairs:
 * 
 * @example
 * ```typescript
 * const SQUARE_BUTTON = Enum({
 *     button: "button",
 *     submit: "submit",
 *     reset: "reset"
 * });
 * 
 * if (someButton.type === SQUARE_BUTTON.button) {
 *     // handle button type
 * }
 * ```
 */

/**
 * `SquareButtonType` type
 * 
 * This is a type-safe union of all possible values of `SQUARE_BUTTON`.
 * Use this type to ensure that variables or parameters are constrained to one of the valid square button types.
 * 
 * @example
 * function setButtonType(type: SquareButtonType) {
 *     // ...
 * }
 */

export type SquareButtonType = EnumValues<typeof SQUARE_BUTTON>;
