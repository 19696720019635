import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SliderOptions.scss";
import VfiCheckbox2 from "../../VfiCheckbox/VfiCheckbox2";
import React, { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { faEllipsisV, faTimes } from "@fortawesome/pro-light-svg-icons";
import Select from "react-select";
import zipCodes from "../../../assets/zipCodes.json";
import openWeather from "../../../assets/openWeatherApi.json";

export default function SliderOptions(props) {
  const [, setFakeState] = useState(false);
  const [firstSlider, setFirstSlider] = useState();
  const [secondSlider, setSecondSlider] = useState();
  useEffect(() => {
    if (props.selectedOptions.selectedCity) return;

    setFirstSlider(
      props.selectedOptions.menu_module.filter(
        (x) => x.slider_index.splideIndex === 0
      ).length
    );
    setSecondSlider(
      props.selectedOptions.menu_module.filter(
        (x) => x.slider_index.splideIndex === 1
      ).length
    );
  }, [props]);
  const newZipCodes = [];
  openWeather.forEach((element) => {
    newZipCodes.push({
      value: element.name,
      label: element.name,
    });
  });

  return (
    <div className="slider-options">
      <div>
        <div id="handle">
          <FontAwesomeIcon icon={faEllipsisV} />
          <FontAwesomeIcon icon={faEllipsisV} />
        </div>
        <div
          onClick={() => {
            props.close();
          }}
          className="close"
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
      </div>
      <div className="top">
        <div
          className={"top-choice "}
          // onClick={() => setScreen("pages")}
        >
          Options
        </div>
      </div>
      <div className="content">
        {props.selectedOptions.selectedCity ? (
          <div>
            <Select
              key={new Date().valueOf()}
              placeholder="Cities"
              options={newZipCodes}
              defaultValue={props.selectedOptions.selectedCity}
              onChange={(e) => {
                props.selectedOptions.selectedCity = e;
                props.setWeatherOptions(props.selectedOptions);
              }}
            />
          </div>
        ) : (
          <div className="options">
            <div>
              <div className="label">Split</div>
              <div className="value">
                <VfiCheckbox2
                  checked={props.selectedOptions.splide.split}
                  onChange={(e) => {
                    props.selectedOptions.splide.split = e.target.checked;
                    if (e.target.checked) {
                      props.selectedOptions.splide.length = [0, 1];
                      props.selectedOptions.menu_module.push({
                        a_label: "",
                        a_value: "",
                        slider_index: { splideIndex: 1 },
                        new: true,
                        language_id: props.langId,
                        valueOf: new Date().valueOf(),
                      });
                    } else {
                      props.selectedOptions.splide.length = [0];
                      props.selectedOptions.menu_module =
                        props.selectedOptions.menu_module.filter(
                          (x) => x.slider_index.splideIndex === 0
                        );
                    }
                    let options = cloneDeep(props.selectedOptions);
                    props.setSliderOptions(options);
                    setFakeState((f) => (f ? false : true));
                  }}
                  isRadio={true}
                />
              </div>
            </div>
            <div>
              <div>
                <div className="label">First slider</div>
                <div className="value">
                  <input
                    type="number"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    tabIndex="0"
                    // value={firstSlider}
                    defaultValue={firstSlider}
                    min={1}
                    onChange={(e) => {
                      if (firstSlider < JSON.parse(e.target.value)) {
                        props.selectedOptions.menu_module.push({
                          a_label: "",
                          a_value: "",
                          slider_index: { splideIndex: 0 },
                          new: true,
                          language_id: props.langId,
                          valueOf: new Date().valueOf(),
                        });
                      } else {
                        let findIndex;
                        props.selectedOptions.menu_module.forEach(
                          (elm, index) => {
                            if (elm.slider_index.splideIndex === 0) {
                              findIndex = index;
                            }
                          }
                        );
                        props.deleteMenuAttribute(
                          props.selectedOptions.menu_module[findIndex].id
                        );
                        props.selectedOptions.menu_module.splice(findIndex, 1);
                      }
                      let options = cloneDeep(props.selectedOptions);
                      props.setSliderOptions(options);
                      setFirstSlider(JSON.parse(e.target.value));
                    }}
                  />
                </div>
              </div>
              {props.selectedOptions.splide.split ? (
                <div>
                  <div className="label">Second slider</div>

                  <div className="value">
                    <input
                      type="number"
                      value={secondSlider}
                      // defaultValue={secondSlider}
                      min={1}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      tabIndex="0"
                      onChange={(e) => {
                        if (secondSlider < JSON.parse(e.target.value)) {
                          props.selectedOptions.menu_module.push({
                            a_label: "",
                            a_value: "",
                            slider_index: { splideIndex: 1 },
                            new: true,
                            language_id: props.langId,
                            valueOf: new Date().valueOf(),
                          });
                        } else {
                          let findIndex;
                          props.selectedOptions.menu_module.forEach(
                            (elm, index) => {
                              if (elm.slider_index.splideIndex === 1)
                                findIndex = index;
                            }
                          );
                          props.deleteMenuAttribute(
                            props.selectedOptions.menu_module[findIndex].id
                          );
                          props.selectedOptions.menu_module.splice(
                            findIndex,
                            1
                          );
                        }
                        let options = cloneDeep(props.selectedOptions);
                        props.setSliderOptions(options);
                        setSecondSlider(JSON.parse(e.target.value));
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
