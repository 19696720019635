import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faRedo } from "@fortawesome/pro-regular-svg-icons";
import "./WhatsonNavigation.scss";
import language_x from "./language.json";
import OptionsList from "../../../assets/OptionsList/OptionsList";

function WhatsonNavigation(props) {
  const language = language_x[props.language].navigation;

  const [listIsOpen, setListIsOpen] = useState(false);

  return (
    <div
      className={
        props.className
          ? "whatson-navigation " + props.className
          : "whatson-navigation"
      }
    >
      {props.preview && (
        <div
          className="save-draft-container"
          onClick={() => props.showPreview()}
        >
          <span className="save-draft">{language.preview}</span>
        </div>
      )}
      {props.previewListOptions ? (
        <div
          className={
            props.saveDraftDisabled
              ? "preview-container hidden"
              : "preview-container"
          }
          onClick={(e) => {
            setListIsOpen(true);
          }}
        >
          <span className="preview">{language.preview}</span>
          <OptionsList
            isOpen={listIsOpen}
            close={() => {
              setListIsOpen(false);
            }}
            options={props.previewListOptions}
          />
        </div>
      ) : (
        ""
      )}
      {!props.isFront ? (
        <div
          className={
            props.saveDraftDisabled
              ? "save-draft-container hidden"
              : "save-draft-container"
          }
          onClick={() => props.saveDraft(true)}
        >
          <span className="save-draft">{language.save_draft}</span>
        </div>
      ) : (
        ""
      )}
      <div
        className={props.undoAndRedoDisabled === true ? "undo hidden" : "undo"}
        onClick={() => {}}
      >
        <FontAwesomeIcon icon={faUndo} />
      </div>
      <div
        className={props.undoAndRedoDisabled === true ? "redo hidden" : "redo"}
        onClick={() => {}}
      >
        <FontAwesomeIcon icon={faRedo} />
      </div>
      <div className="controls">
        <div
          className={props.backwardDisabled === true ? "back hidden" : "back"}
          onClick={() => {
            if (props.customBackwardFunc) props.customBackwardFunc();
            else props.indexChange(-1);
          }}
        >
          <span>
            {props.customBackwardText
              ? props.customBackwardText
              : language.back}
          </span>
        </div>
        <div
          className={
            props.forwardDisabled === true
              ? "forward hidden"
              : "forward" + ` ${props.frontStyle}`
          }
          onClick={() => {
            if (props.customForwardFunc) props.customForwardFunc();
            else if (props.returnToSubmit) props.returnToSubmit();
            else props.indexChange(1);
          }}
        >
          <span>
            {props.customForwardText
              ? props.customForwardText
              : language.forward}
          </span>
        </div>
      </div>
    </div>
  );
}
export default WhatsonNavigation;
