import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function CopyTextIcon({ value, className, faIcon, style }) {
  return (
    <div className={className ?? ""} style={{ ...style, cursor: "pointer" }}>
      <FontAwesomeIcon
        icon={faIcon ?? faCopy}
        onClick={() => {
          navigator.clipboard.writeText(value);
        }}
      />
    </div>
  );
}
