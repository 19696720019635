import "../Product.scss";
import { useState } from "react";

export default function AvailablePanel({
	productState,
	setForceUpdate,
	props,
	slugify
}) {
	const availability = props.availability;
	const [availableState] = useState(availability);

	return (
		<div className="availability">
			<h3>Availability</h3>
			{availableState.map((element) => {
				let name_slug = slugify(element.name);
				return (
					<div key={name_slug} className="storage">
						<label htmlFor={name_slug}>{element.name}</label>
						<input
							onChange={(e) => {
								productState.available = name_slug;
								setForceUpdate((f) => (f ? false : true));
							}}
							id={name_slug}
							name={"available"}
							value={name_slug}
							checked={productState.available === name_slug}
							type={"radio"}
						/>
					</div>
				);
			})}
		</div>
	);
}
