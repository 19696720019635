import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import VfiTextarea from "../../../../assets/VfiTextarea/VfiTextarea";
import EnableEditingCheck from "./EnableEditingCheck";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import MediaInsert from "./MediaInsert";
import { useState } from "react";
import FormGroup from "../../../../assets/FormGroup";
import { GlobalToolTipController } from "src/Components/ToolTips/GlobalToolTip";

/**
 * Build an empty itinerary part object for the newTour.itinerary array
 *
 * @param 		{int} 	partNo 							Part number
 *
 * @returns 	{object} 										Empty data object
 *
 * @author 					Pætur Mortensen
 */
function build_empty_itinerary_part(partNo) {
  // Build and add empty itinerary object
  const emptyLang = { headline: "", description: "", media: [] };

  return {
    partNo,
    english: JSON.parse(JSON.stringify(emptyLang)),
    faroese: JSON.parse(JSON.stringify(emptyLang)),
  };
}

/**
 * Check whether an itinerary part is empty
 *
 * @param 		{object} 	part 								Itinerary part to check (from parent.newTour)
 *
 * @returns 	{bool} 												Whether part is empty
 *
 * @author 					Pætur Mortensen
 */
function is_empty_itinerary_part(part) {
  // List the available languages here.
  const languages = ["faroese", "english"];

  // Init empty as true (will change if there is any content)
  let partEmpty = true;
  // For each language...
  languages.map((lang) => {
    // If there is ANY content in the language for the part...
    if (
      part[lang].headline ||
      part[lang].description ||
      part[lang].media.length !== 0
    ) {
      // Part is not empty
      partEmpty = false;
    }
  });

  return partEmpty;
}

/**
 * The itinerary form group for newtour->quick facts
 *
 * @param 	{object} 		parent 							Parent object (must be NewTour)
 *
 * @returns 	jsx 													The itinerary
 *
 * @author 					Pætur Mortensen
 */
function Itinerary({ parent }) {
  const [itineraryCount, setItineraryCount] = useState(
    parent.newTour.itinerary.length
  );
  const [updateIteration, setUpdateIteration] = useState(1);

  // Extracting some vars from parent
  const submitChange = parent.props.submitChange === "true";
  const beenEdit = parent.state.beenEdit;
  const showEn = parent.newTour.textEn.showIt;
  const showFo = parent.newTour.textFo.showIt;
  const language = parent.language;

  /**
   * Remap the itinerary parts.
   * Iterate over the parts and check whether any need removing and part numbers remapping
   *
   * @author 					Pætur Mortensen
   */
  function remap_itinerary_parts() {
    const initialLength = parent.newTour.itinerary.length;
    // Get index of last itinerary part
    const lastIdx = initialLength - 1;

    // First, iterate over itinerary parts to see if any need removing
    parent.newTour.itinerary.map((part, idx) => {
      // If this part is empty, and it is not the last part..
      if (is_empty_itinerary_part(part) && idx !== lastIdx) {
        // Remove the part
        parent.newTour.itinerary.splice(idx, 1);
      }
    });

    // Now we need to remap the part numbers
    let partNo = 1;
    parent.newTour.itinerary.map((part) => {
      part.partNo = partNo;
      partNo++;
    });

    // If the number of itinerary parts has changed...
    if (parent.newTour.itinerary.length !== initialLength) {
      // Set the new itinerary count and update state
      setItineraryCount(parent.newTour.itinerary.length);
      setUpdateIteration(updateIteration + 1);
    }
  }

  /**
   * Handle what happens when a field is blurred
   *
   * @author 					Pætur Mortensen
   */
  function handle_field_blur() {
    // Remap the itinerary parts (remove empty etc.)
    remap_itinerary_parts();
  }

  // If no itinerary info has been added to new tour, add the first one
  if (parent.newTour.itinerary.length === 0) {
    // Itinerary will hold array, since the may be multiple parts to it
    parent.newTour.itinerary.push(build_empty_itinerary_part(1));
  }

  // If there are images to add...
  if (parent.props.addOne) {
    // For each image to add (maybe always just one, but mapping anyway)
    parent.props.addOne.map((item, index) => {
      // If this is an itinerary part image...
      if (item.callbackArg && item.callbackArg.type === "itinerary-part-img") {
        // Extract useful information from item
        const media = {
          alt: item.alt,
          file_extension: item.fileExtension,
          file_name: item.file_name,
          id: item.id,
          key: item.index,
        };

        // Save the media information
        parent.newTour.itinerary[item.callbackArg.index][
          item.callbackArg.fieldLang
        ].media.push(media);
      }

      // Remove the item from addOne
      parent.props.addOne.splice(index, 1);
    });
  }

  /**
   * Render a headline field
   *
   * @param 	{string} 	fieldLang 						Language to be used for field ('english' or 'faroese')
   * @param 	{object} 	part 									Data object (parent.newTour.itinerary[index])
   *
   * @returns 	jsx														Headline text input
   *
   * @author 						Pætur Mortensen
   */
  function render_headline_field(fieldLang, part) {
    return (
      <div className="two-col-field" key={part.partNo + "-" + fieldLang}>
        <label className="field-label with-tooltip-2">
          {language.headline}
          <GlobalToolTipController
            solid
            title={""}
            className={"tootlip-icon"}
            priority={"bottom,right,top,left"}
            toolTipElements={
              <div className={"tooltip-card"}>
                Name the tour section - e.g. Day 1, evening: The Northern isles
                or Day 3, morning: Kayaking in Tjørnuvík.
              </div>
            }
          >
            <FontAwesomeIcon icon={faQuestionCircle} />
          </GlobalToolTipController>
        </label>
        <VfiInputText
          className="field-input"
          defaultValue={part[fieldLang].headline}
          onChange={(e) => {
            part[fieldLang].headline = e.target.value;
            // If itinerary has not been flagged as edited...
            if (!beenEdit.itinerary) {
              // Flag as edited
              parent.setState({
                beenEdit: { ...beenEdit, itinerary: true },
              });
            }
          }}
          onBlur={(e) => {
            // Call common onBlur for all itinerary fields
            handle_field_blur();
          }}
          // Disabled if we're submitting changes from front AND editing not enabled
          disabled={submitChange && !parent.submit.includes("itinerary")}
        />
      </div>
    );
  }

  /**
   * Render description field for itinerary part
   *
   * @param 	{string} 	fieldLang 							Language to use with field ('english' or 'faroese')
   * @param 	{part} 		part 										Data object (parent.newTour.itinerary[index])
   *
   * @returns 	jsx 														Description field
   *
   * @author 					Pætur Mortensen
   */
  function render_description_field(fieldLang, part) {
    return (
      <div className="two-col-field">
        <label className="field-label">{language.description}</label>
        <VfiTextarea
          defaultValue={part[fieldLang].description}
          onChange={(e) => {
            part[fieldLang].description = e;
            // If itinerary has not been flagged as edited...
            if (!beenEdit.itinerary) {
              // Flag as edited
              parent.setState({
                beenEdit: { ...beenEdit, itinerary: true },
              });
            }
          }}
          onBlur={(e) => {
            // Call common onBlur for all itinerary fields
            handle_field_blur();
          }}
          // Disabled if we're submitting changes from front AND editing not enabled
          disabled={submitChange && !parent.submit.includes("itinerary")}
          maxLength={400}
        />
      </div>
    );
  }

  /**
   * Render the media insert form part for itinerary part
   *
   * @param 	{string} 	fieldLang 						Language to use for field ('english' or 'faroese')
   * @param 	{object} 	part 									Data object (parent.newTour.itinerary[index])
   * @param 	{int} 		index 								Array index for part (parent.newTour.itinerary[index])
   *
   * @returns 		jsx
   *
   * @author 					Pætur Mortensen
   */
  function render_media_insert(fieldLang, part, index) {
    return (
      <div className="two-col-field">
        <label></label>
        <MediaInsert
          parent={parent}
          media={part[fieldLang].media}
          fieldLang={fieldLang}
          index={index}
          onChange={handle_field_blur}
        />
      </div>
    );
  }

  /**
   * Render a single language box
   *
   * @param 		{string} 	boxLang 						Language to use with box ('english' or 'faroese')
   * @param 		{object} 	part 								Data object (parent.newTour.itinerary[index])
   * @param 		{bool} 		display 						Whether to display this form (false: return empty)
   * @param 		{int} 		index 							Array index for part object
   * 																					(parent.newTour.itinerary[index])
   *
   * @returns    jsx
   *
   * @author          Pætur Mortensen
   */
  function render_language_box(boxLang, part, display, index) {
    // Set active className if box language is active (showFo or showEn)
    const activeClassName = display ? " active" : "";

    // String used as key for parent.submit (which fields are to be submitted)
    const includeStr =
      boxLang === "english" ? "textEn.goodToKnow" : "textFo.goodToKnow";

    // Set content if it is to be displayed
    const content = display && (
      <>
        {render_headline_field(boxLang, part)}
        {render_description_field(boxLang, part)}
        {render_media_insert(boxLang, part, index)}
      </>
    );

    return (
      <div
        className={"language-box " + boxLang + " col-lg-6" + activeClassName}
        key={"fields_" + boxLang + part.partNo + "_" + updateIteration}
      >
        {content}
      </div>
    );
  }

  /**
   * Render all the language boxes (forms in both languages)
   *
   * @returns   jsx                 The language boxes
   *
   * @author          Pætur Mortensen
   */
  function render_language_boxes() {
    /**
     * Render the header showing the part number
     *
     * @param 		{int} 	partNo 					Part number
     *
     * @returns 	jsx											Part number header
     *
     * @author 					Pætur Mortensen
     */
    function render_part_no_header(partNo) {
      return (
        <div className="form-group-part-desc" key={"partNo_" + partNo}>
          {language.quick_facts.part + " " + partNo}
        </div>
      );
    }

    const content = [];
    // For each part to render...
    parent.newTour.itinerary.map((part, index) => {
      content.push(render_part_no_header(part.partNo));
      content.push(render_language_box("english", part, showEn, index));
      content.push(render_language_box("faroese", part, showFo, index));
    });

    return <>{content}</>;
  }

  /**
   * Add a part to the itinerary
   *
   * @author          Pætur Mortensen
   */
  function handle_add_part() {
    // Get the last itinerary item for validation
    const lastItItem =
      parent.newTour.itinerary[parent.newTour.itinerary.length - 1];

    // If the currently last form has some input in it...
    if (
      lastItItem.english.headline ||
      lastItItem.english.description ||
      lastItItem.faroese.headline ||
      lastItItem.faroese.description
    ) {
      const newCount = itineraryCount + 1;
      // Build and add empty itinerary part data object to newTour.itinerary
      parent.newTour.itinerary.push(build_empty_itinerary_part(newCount));
      // Set new itinerary count state
      setItineraryCount(newCount);
    }
  }

  return (
    <FormGroup header={language.quick_facts.itinerary}>
      {/* If user is submitting change from front, show the enable editing check  */}
      {!!submitChange && (
        <EnableEditingCheck parent={parent} type={"itinerary"} />
      )}
      <br />
      {/* Render all the input forms */}
      {render_language_boxes()}
      {/* Button to add another part to the itinerary */}
      <div className="add-part-btn-container">
        <button onClick={handle_add_part} className="add-part-btn">
          {language.quick_facts.add_part}
          <FontAwesomeIcon icon={faPlus} className="add-part-btn-icon" />
        </button>
      </div>
    </FormGroup>
  );
}

export default Itinerary;
