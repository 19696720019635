import "../Product.scss";
import Select from "react-select";

export default function ProductLinePanel({ productState, configuration, setForceUpdate }) {
	
	return (
		<div className="product-box">
			<h3>Product line</h3>
			{productState.productTypeId && (
				<>
					<div className="select-brand">
						<label>Brand</label>
						<Select
							key={productState.brand}
							className="select"
							placeholder="Select..."
							options={
								configuration.physical.find(
									(x) => x.productTypeuuid === productState.productTypeId
								).brands
							}
							defaultValue={productState.brand}
							onChange={(e) => {
								productState.brand = e;
								productState.product = null;
								setForceUpdate((f) => !f);
							}}
						/>
					</div>
					{productState.brand && (
						<>
							<div className="select-product">
								<label>Product</label>

								<Select
									key={productState.product}
									className="select"
									placeholder="Select..."
									options={
										configuration.physical
											.find(
												(x) => x.productTypeuuid === productState.productTypeId
											)
											.brands.find((x) => x.id === productState.brand.id)
											.products
									}
									defaultValue={productState.product}
									onChange={(e) => {
										productState.product = e;
										e.varients.forEach((element) => {
											productState.varient = productState.varient ?? [];

											let findex = productState.varient.findIndex(
												(x) => x.uuid === element.uuid
											);
											if (findex === -1) {
												let newVarient = {
													id: element.id,
													value: element.value,
													label: element.label,
													costPrice: 0,
													rateType: { label: "DKK", value: "fixed" },
													unit: { label: "Pieces", value: "pcs" },
													quantity: 0,
													uuid: element.uuid,
													addCost: 0,
													allocated: 0,
													properties: element.properties,
													media: [],
												};
												productState.varient.push(newVarient);
											}
											productState.varient = productState.varient.map((x) => {
												x.checked = true;
												return x;
											});
										});
										setForceUpdate((f) => !f);
									}}
								/>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
}
