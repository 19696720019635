import styles from './Modal.module.scss';
import Overlay from '../Overlay';
import Button from '../Buttons/Button';
import { faClose } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Preloader from 'src/assets/Preloader';
import { ModalType } from './types';

export default function Modal({ 
	children,
	title,
	closeButtonText = 'Close', 
	type = 'notification',
	hideCloseButton = false,
	close = ()=>{},
	buttons = <></>,
	onConfirm = ()=>{},
	onCancel = ()=>{},
	confirmButtonText = 'OK',
	cancelButtonText = 'Cancel',
	showPreloader = false,
} : ModalType) : JSX.Element{
	const confirmText = 'confirmtext';
	const cancelText = 'cancelText';

	function render_modal_buttons() : JSX.Element {

		/**
		 * Render a close button
		 * 
		 * @author 					Pætur Mortensen 
		 */
		function render_close_button() : JSX.Element {
			return (
				<Button
					onClick={() => {close()}}
					>
						{ closeButtonText }
					</Button>
				);
		}

		/**
		 * Render confirm and cancel buttons
		 * 
		 * @author 					Pætur Mortensen
		 */
		function render_confirmation_buttons() : JSX.Element {
			return (
				<>
					<Button	
						type="secondary"
						onClick={onCancel}
					>
						{ cancelButtonText }
					</Button>
					<Button 
						type="primary"
						onClick={onConfirm}
					>
						{ confirmButtonText}
					</Button>
				</>
			);
		}

		switch(type){
			case 'notification':
				return render_close_button();
			case 'confirmation':
				return render_confirmation_buttons();
			default: 
				return buttons as JSX.Element;
		}
	}

	return (
		<Overlay display>
			<div className={styles.modalContainer}>
				<div className={styles.modal}>
					<div className={styles.header}>
						<div className={styles.titleContainer}>
							{ title }
						</div>
						<div className={styles.preloadContainer}>
							<Preloader show={showPreloader} />
						</div>
						{!hideCloseButton &&
							<FontAwesomeIcon 
								className={styles.closeButton} 
								icon={faClose as IconProp} 
								onClick={() => {close()}}
							/>
						}
					</div>
					<div className={styles.content}>
						{ children }
					</div>
					<div className={styles.controls}>
						{render_modal_buttons()}
					</div>
				</div>
			</div>
		</Overlay>
	);
}