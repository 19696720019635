import { useEffect, useRef, useState } from 'react';
import styles from './SidePopout.module.scss';
import CloseBtn from '../../buttons/closeBtn';
import { overlayStore } from '../../OverlayStore';

interface Props {
    children: any;
    close: () => void;
}

export default function SidePopoutSelfClose({ children, close }: Props) {

    const [loaded, setLoaded] = useState<boolean>(false);

    const [closed, setClosed] = useState<boolean>(false);

    const [collapsed, setCollapsed] = useState<boolean>(false);

    const ref = useRef<any>(null);
    const closeBtnRef = useRef<any>(null);

    useEffect(() => {
        closeBtnRef.current?.focus();

        setTimeout(() => {

            setCollapsed(true);

            setTimeout(() => {
                if (!closed) close();
            }, 200);
        }, 2000);

        setLoaded(true);
    }, []);

    return (
        <div
            className={`${styles.container} ${(loaded && !collapsed) ? styles.loaded : ''}`}
            style={{
                width: 'fit-content',
                height: '50px',
                bottom: '60px',
                backgroundColor: 'rgba(0, 0, 0, 0)',
                color: '#fff',
                opacity: '0.8',
            }}>

            {/* Close button */}

            <button
                className={styles.closeBtn}
                ref={closeBtnRef}
                onClick={() => {
                    close();
                    setClosed(true);
                }}
            >
                <CloseBtn width={20} height={20} style={{ fill: '#fff' }} />
            </button>

            {/* Content */}

            <div className={styles.content}>{children}</div>
        </div>
    );
}