import "../Product.scss";
import { useState } from "react";

export default function WarehousePanel({
	productState,
	setForceUpdate,
	slugify,
	props,
}) {
	const warehouse = props.warehouse;
	const [warehouseState] = useState(warehouse);

	return (
		<div className="warehouse">
			<h3>Warehouse</h3>
			{warehouseState.map((element) => {
				let name_slug = slugify(element.name);
				let object = "remote";
				if (name_slug === "in-stock") {
					object = "inStock";
				}
				return (
					<div key={name_slug} className="storage">
						<label htmlFor={name_slug}>{element.name}</label>
						<input
							disabled
							onChange={(e) => {
								productState.warehouse[object] = e.target.checked;
								setForceUpdate((f) => (f ? false : true));
							}}
							id={name_slug}
							name={name_slug}
							checked={productState.warehouse[object]}
							type={"checkbox"}
						/>
					</div>
				);
			})}
		</div>
	);
}
