import "../EditProduct.scss";
import { useState } from "react";
import Preloader from "src/assets/Preloader";
import Button from "src/Components/Buttons/Button";
import axios from "axios";
import env from "../../../../../environment.json";

/**
 * Availability panel
 * 
 * Set published state of product
 * 
 * @param 		{object} 		product 											Product state
 * @param 		{function} 	setProduct 										Set product state
 * @param 		{object} 		productConfig 								Product config object
 * 
 * @returns 	{jsx} 																		Available panel
 * 
 * @author 					Pætur Mortensen 
 */
export default function AvailablePanel({
	product,
	setProduct,
	productConfig,
}) {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ available, setAvailable ] = useState(product.available);

	// Whether availability has changed
	const hasChanged = (available !== product.available);
	
	/**
	 * Update product available state
	 * 
	 * @author 					Pætur Mortensen
	 */
	function update_available(){
		setIsLoading(true);
		axios
			.post(
				env.protocol + env.env + '/api/secured/shop/SetProductAvailability',
				{productID:product.productID, availability:available}
			)
			.then( response => {
				setProduct(response.data);
				setIsLoading(false);
			})
			.catch( error => {
				console.error(error);
				setIsLoading(false);
			});
	}
/***************************************************************************************************
 * 
 * 																	RENDER
 * 
 **************************************************************************************************/

	/**
	 * Render update and reset buttons (if there have been changes)
	 * 
	 * @returns 	{JSX} 																Update buttons
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_update_btns(){

		return (
			<div className="brand-update-btn-container">
				<Preloader show={isLoading} />
				<Button
					type="secondary"
					onClick={ () => {
						setAvailable(product.available);
					}}
				>
					Reset
				</Button>
				<Button
					onClick={update_available}
				>
					Update
				</Button>
			</div>
		);
	}

	return (
		<div className="availability">
			<h3>Availability</h3>
			{productConfig.availabilityTypes.map( type => {
				return (
					<div key={type} className="storage">
						<label htmlFor={type}>{type}</label>
						<input
							onChange={ () => {
								setAvailable(type);
							}}
							id={type}
							name={"available"}
							value={type}
							checked={available === type}
							type={"radio"}
						/>
					</div>
				);
			})}
			{hasChanged && render_update_btns()}
		</div>
	);
}
