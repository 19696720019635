import React, { Component } from "react";
import env from "../../environment.json";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./VfiTextarea.scss";
import { removeTags } from "../../assets/helperFunctions";
// import TestEditor from "./TestEditor";

function cleanWordPaste(in_word_text, length) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = in_word_text;
  var newString = tmp.textContent || tmp.innerText;
  // this next piece converts line breaks into break tags
  // and removes the seemingly endless crap code
  newString = newString
    .replace(/\n\n/g, "<br /><br />")
    .replace(/.*<!--.*-->/g, "");
  // this next piece removes any break tags (up to 10) at beginning
  while (newString.substr(0, 6) === "<br />") {
    newString = newString.replace("<br />", "\n");
  }
  newString = newString.replaceAll("<br />", "\n");
  newString = newString.substr(0, length);
  return newString;
}

class VfiTextarea extends Component {
  constructor(props) {
    super(props);
    this.maxLengthReach = false;
    this.state = {
      focus: false,
      errorMessages: [],
      currentChars: this.props.defaultValue
        ? this.props.defaultValue.length
        : 0,
      text: "",
    };
  }

  componentDidMount() {
    this.errorCheck(this.props.value || this.props.defaultValue || "");
  }

  thisChange(e) {
    this.setState({ currentChars: e.length });
    this.errorCheck(removeTags(e));
  }

  errorCheck(value) {
    let errors = [];
    if (this.props.errorCheckSequence) {
      this.props.errorCheckSequence.forEach((element) => {
        // If a function has been passed...
				let error = "";
				if(typeof element === 'function'){
					error = element(value);
				} else if(typeof element === 'object') {
					// If an object has passed, we expect the following structure:
					// {function:validationFunction(), args:[argument1, argument2...]}
					error = element.function(value, ...element.args);
				}
				
        if (error !== "" && error !== undefined) {
          errors.push(error);
        }
      });
    }
    this.setState({ errorMessages: errors });
  }

  render() {
    const inputError = this.state.errorMessages.length > 0 ? true : false;

    let extraClassName = "";
    if (typeof this.props.className === "string") {
      extraClassName += this.props.className;
    }
    if (this.state.focus) {
      extraClassName += " focus";
    }
    if (inputError && !this.props.hideError) {
      extraClassName += " error";
    }

    const height = this.props.height
      ? {
          height: `${this.props.height}${isNaN(this.props.height) ? "" : "px"}`,
        }
      : {};

    return (
      <div className={"vfi-text-area " + extraClassName}>
        <textarea
          style={{ ...height }}
          maxLength={this.props.maxLength}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          onChange={(e) => {
            this.thisChange(
              cleanWordPaste(e.target.value, this.props.maxLength)
            );
            if (this.props.onChange) {
              this.props.onChange(
                cleanWordPaste(e.target.value, this.props.maxLength)
              );
            }
            this.setState({ text: e.target.value });
          }}
          onBlur={(event, editor) => {
            if (this.props.onBlur) {
              this.props.onBlur(event.target.value);
            }
            this.setState({ focus: false });
          }}
          onFocus={(event, editor) => {
            if (this.props.onFocus) {
              this.props.onFocus(editor);
            }
            this.setState({ focus: true });
          }}
          config={{
            //toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', '|',  'alignment', '|', 'imageUpload'],
            toolbar: [
              "heading",
              "bold",
              "italic",
              "blockQuote",
              "|",
              "undo",
              "redo",
              "|",
              /*"ckfinder", "imageTextAlternative", "imageUpload",*/ "imageStyle:full",
              "imageStyle:side",
              "link",
              "numberedList",
              "bulletedList",
              "mediaEmbed" /*, "|", "insertTable", "tableColumn", "tableRow", "mergeTableCells"*/,
            ],
            ckfinder: {
              uploadUrl: env.protocol + env.env + "/api/secured/UploadMediaFile",
              //uploadUrl: env.protocol+env.env+"/ckfinder/connector/?command=QuickUpload&type=Images"
            },
            content: {
              height: 300,
              filebrowserUploadUrl:
                env.protocol + env.env + "/api/secured/UploadMediaFile",
            },
            placeholder: this.props.placeholder ? this.props.placeholder : "",
          }}
          // ImageUploadProgress={() => {
          // }}
          disabled={this.props.disabled ? true : false}
          placeholder={this.props.placeholder}
          // placeholder={"Hello, it's me"}
        />
        {this.props.maxLength ? (
          <div className="max-chars">
            {this.props.value
              ? this.props.value.length
              : this.state.text.length > 0
              ? this.state.text.length
              : this.props.defaultValue
              ? this.props.defaultValue.length
              : 0}
            /{this.props.maxLength}
          </div>
        ) : (
          ""
        )}
        <div className="errors">
          {this.state.errorMessages.map((element, i) => {
            return !this.props.hideError ? (
              <div className="error" key={i}>
                {element}
              </div>
            ) : (
              ""
            );
          })}
        </div>
      </div>
    );
  }
}

export default VfiTextarea;
