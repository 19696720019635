import "../Product.scss";
import Select from "react-select";

export default function ProductTypePanel({
	configuration,
	productState,
	setForceUpdate,
}) {
	
	return (
		<div className="type">
			<h3>Type</h3>
			<div className="product-type">
				<label>Product type</label>
				<Select
					isDisabled={productState.brand !== null}
					className="select"
					placeholder="Select..."
					options={configuration.productTypes}
					defaultValue={configuration.productTypes.find(
						(x) => x.uuid === productState.productTypeId
					)}
					onChange={(e) => {
						productState.productTypeId = e.uuid;
						setForceUpdate((f) => !f);
					}}
				/>
			</div>
		</div>
	);
}
