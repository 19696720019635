import { AxiosErrorLike, Safe, SafeConfig, SafeError } from "src/types";

/**
 * Overloaded function `safe` that provides a mechanism to handle asynchronous and synchronous operations safely.
 * It wraps operations in a "Safe" container that makes it easier to manage success, error states, and data.
 * 
 * @template T - The type of the successful data response.
 *
 * @param promiseOrFunc - Either a Promise or a synchronous function to be safely executed.
 * @param config - Configuration for enhanced error handling. Can also be a string for custom error messages.
 * 
 * @returns A Safe wrapped response containing either the data or error information.
 * 
 * @example
 * Basic Usage with Promises:
 * ```javascript
 * const fetchData = async () => await fetch('https://api.example.com/data');
 * const response = await safe(fetchData());
 * ```
 *
 * Basic Usage with Synchronous Functions:
 * ```javascript
 * const getData = () => { return "Hello World"; }
 * const response = safe(getData);
 * ```
 *
 * Using Config for Custom Error Messages:
 * ```javascript
 * const fetchData = async () => { throw new Error("Failed to fetch"); };
 * const response = await safe(fetchData(), "Custom error message on fetch failure");
 * ```
 *
 * Using Config for Enhanced Error Handling:
 * ```javascript
 * const config = {
 *     logError: false, // To disable console logging of errors
 *     customLogger: (err) => console.log("Custom error:", err), // Use a custom logger
 *     onError: (err) => alert("An error occurred!"), // Execute a callback function on error
 *     customError: "A custom error occurred" // Set a custom error message
 * };
 * const response = await safe(fetchData(), config);
 * ```
 *
 * Using with Axios:
 * ```javascript
 * import axios from 'axios';
 *
 * const fetchUserData = async (userId: number) => {
 *   const response = await axios.get(`https://api.example.com/users/${userId}`);
 *   return response.data;
 * };
 *
 * const safeResponse = await safe(fetchUserData(1), "Failed to fetch user data");
 * if (safeResponse.success) {
 *   console.log("Fetched User Data:", safeResponse.data);
 * } else {
 *   console.error("Error:", safeResponse.error?.message);
 * }
 * ```
 */
function safe<T>(promise: Promise<T>, config?: SafeConfig | string): Promise<Safe<T>>;
function safe<T>(func: () => T, config?: SafeConfig | string): Safe<T>;
function safe<T>(
	promiseOrFunc: Promise<T> | (() => T),
	config: SafeConfig | string = { logError: true }
): Promise<Safe<T>> | Safe<T> {
	let configObj: SafeConfig = typeof config === "string" ? { logError: true, customError: config } : config;

	if (promiseOrFunc instanceof Promise) {
		return safeAsync(promiseOrFunc, configObj);
	}
	return safeSync(promiseOrFunc, configObj);
}

export { safe };

/**
 * Safely handles asynchronous operations and ensures consistent error handling.
 * This function distinguishes between Axios errors and fetch-like errors to 
 * provide a consistent error response format. For other errors, it delegates
 * to the `handleError` function.
 * 
 * @param promise - The promise to be safely executed.
 * @param config - Configuration for enhanced error handling.
 * 
 * @returns A Safe wrapped response containing either the data or error information.
 */
async function safeAsync<T>(
	promise: Promise<T>,
	config: SafeConfig
): Promise<Safe<T>> {
	try {
		const data = await promise;
		return enhanceWithMap({ data, success: true });
	} catch (e: unknown) {
		// Handle configuration-based actions first
		if (config.onError) config.onError(e);
		if (config.logError) {
			config.customLogger ? config.customLogger(e) : console.error(e);
		}

		// Check if error resembles an Axios error and handle it
		if (e && typeof e === 'object' && 'response' in e) {
			const axiosError = e as AxiosErrorLike;
			const errorObj: SafeError = {
				message: axiosError.message || "Request failed",
				code: axiosError.response?.status.toString(),
				details: axiosError.response?.data
			};
			return { success: false, error: errorObj };
		}

		// Handle fetch-like errors
		if (e && typeof e === 'object' && 'message' in e) {
			const fetchError = e as { message: string; response?: Response };
			const errorObj: SafeError = {
				message: fetchError.message,
				code: fetchError.response?.status.toString()
			};
			return { success: false, error: errorObj };
		}

		// For all other unknown errors, use handleError to get the error object
		const errorObj = handleError(e, config);

		return { success: false, error: errorObj };
	}
}




/**
 * Safely handles synchronous operations, capturing any thrown errors.
 * For errors, it delegates to the `handleError` function to generate a consistent 
 * error object based on the provided configuration.
 * 
 * @param func - The synchronous function to be safely executed.
 * @param config - Configuration for enhanced error handling.
 * 
 * @returns A Safe wrapped response containing either the data or error information.
 */
function safeSync<T>(
	func: () => T,
	config: SafeConfig
): Safe<T> {
	try {
		const data = func();
		return enhanceWithMap({ data, success: true });
	} catch (e: unknown) {
		// Handle configuration-based actions first
		if (config.onError) config.onError(e);
		if (config.logError) {
			config.customLogger ? config.customLogger(e) : console.error(e);
		}

		// Handle the error and get the error object
		const errorObj = handleError(e, config);

		return { success: false, error: errorObj };
	}
}

/**
 * Centralized error handling function. It's designed to generate a consistent 
 * error object, leveraging the provided configuration for custom behavior.
 * This function does not perform any side-effects (like logging); it solely 
 * focuses on creating the error object.
 *
 * @param error - The caught error.
 * @param config - Configuration for enhanced error handling.
 * 
 * @returns An error object conforming to the SafeError type.
 */
function handleError(error: unknown, config: SafeConfig): SafeError {
	let errorObj: SafeError = { message: "Something went wrong" };

	// Use custom error message if provided in the config
	if (config.customError) {
		errorObj.message = config.customError;
	} else if (error && typeof error === 'object' && 'message' in error) {
		errorObj.message = (error as Error).message;
	}

	return errorObj;
}


/**
 * Enhances a Safe object by adding a 'map' function to it.
 * The map function allows for transformation of data within the Safe container, 
 * providing flexibility in handling the returned data.
 * 
 * @param result - The Safe object to enhance.
 * 
 * @returns An enhanced Safe object.
 */
function enhanceWithMap<T>(result: Safe<T>): Safe<T> {
	// If the operation was successful, add a 'map' function for data transformation
	if (result.success) {
		result.map = function <U>(callback: (value: T) => U): Safe<U> {
			try {
				return enhanceWithMap({ data: callback(result.data!), success: true });
			} catch (e) {
				if (e instanceof Error) {
					return { success: false, error: { message: e.message } };
				}
				// Generic error message for unforeseen errors during mapping
				return { success: false, error: { message: "Something went wrong during data transformation" } };
			}
		};
	}
	return result;
}
