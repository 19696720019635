import React, { Component } from "react";
import VfiInputText from "../../../../../assets/VfiInputText/VfiInputText";
import Verify from "../../../../../assets/VerifyMethods";
import "./Panel.scss";
var generator = require("generate-password-browser");

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      psw: "",
    };

    this.generatePsw = this.generatePsw.bind(this);
  }

  generatePsw() {
    var password = generator.generate({
      length: 15,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
    });

    this.props.onChange("psw", password);
    this.setState({ psw: password });
    this.props.showUpdateBtn();
  }

  pswOnChange(e) {
    this.props.onChange("psw", e.target.value);
    this.setState({ psw: e.target.value });
  }

  render() {
    return (
      <div className="profile-content ">
        <ul>
          <li className="text name">Name</li>
          <li className="text">Profile Name</li>
          <li className="input">
            <VfiInputText
              value={this.props.data.profilename}
              onChange={(e) => {
                this.props.onChange("profilename", e.target.value);
                this.props.showUpdateBtn();
              }}
            />
          </li>
          <li className="text">First Name</li>
          <li className="input">
            <VfiInputText
              value={this.props.data.firstname}
              onChange={(e) => {
                this.props.onChange("firstname", e.target.value);
                this.props.showUpdateBtn();
              }}
            />
          </li>
          <li className="text">Last Name</li>
          <li className="input">
            <VfiInputText
              value={this.props.data.lastname}
              onChange={(e) => {
                this.props.onChange("lastname", e.target.value);
                this.props.showUpdateBtn();
              }}
            />
          </li>
          <li className="text">Password</li>

          {/* Want to hide password if it is already set. But if not set, we want to see the new psw */}
          <li className="input password">
            <VfiInputText
              value={this.state.psw}
              onChange={(e) => {
                this.pswOnChange(e);
                this.props.onChange("psw", e.target.value);
                this.props.showUpdateBtn();
              }}
            />
          </li>
          {/* } */}

          <li className="generatePsw" onClick={this.generatePsw}>
            Generate password
          </li>

          <li className="text place">Place</li>
          <li className="text">Workplace</li>
          <li className="input">
            <VfiInputText
              value={this.props.data.company}
              onChange={(e) => {
                this.props.onChange("company", e.target.value);
                this.props.showUpdateBtn();
              }}
            />
          </li>

          <li className="text bio">Bio</li>
          <li className="text">Short bio</li>
          <li className="input">
            <VfiInputText
              value={this.props.data.bio}
              onChange={(e) => {
                this.props.onChange("bio", e.target.value);
                this.props.showUpdateBtn();
              }}
            />
          </li>

          <li className="text contact">Contact</li>
          <li className="text">Email</li>
          <li className="input email">
            <VfiInputText
              value={this.props.data.email}
              onChange={(e) => {
                this.props.onChange("email", e.target.value);
                this.props.showUpdateBtn();
              }}
              errorCheckSequence={[Verify.validEmail]}
            />
          </li>

          <li className="text">Facebook url</li>
          <li className="input">
            <VfiInputText
              value={this.props.data.facebook}
              onChange={(e) => {
                this.props.onChange("facebook", e.target.value);
                this.props.showUpdateBtn();
              }}
            />
          </li>
        </ul>
      </div>
    );
  }
}
export default Profile;
