import React, { useEffect, useState } from "react";
import "./MediaGrid.scss";
import "./MediaGridRelated.scss";
import "./MediaGridTeam.scss";
import "./MediaGridInstagram.scss";
import noImage from "../../images/empty.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIcons,
  faLink,
  faPen,
  faPlus,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import OptionsList from "../../OptionsList/OptionsList";
import cloneDeep from "lodash.clonedeep";
import OneMedia from "../OneMedia/OneMedia";
import VfiCKeditor from "../../VfiCKeditor/VfiCKeditor";
import { theGalleryTemplates } from "../../../Components/Content/Overlay/GridTemplateChoose/GridTemplateChoose";
import languages from "../language.json";
import { LinkToEdit } from "../VfiCarousel";
import SideDescription from "../VfiCarousel/SideDescription";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import VfiDivInput from "../../VfiDivInput";
import IconSettings from "../VfiCarousel/IconSettings";
import env from "../../../environment.json";
import { overlayStore } from "src/Components/OverlayElements/OverlayStore";
import RemoveMedia from "../VfiCarousel/components/RemoveMedia/RemoveMedia";
const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export function moduleOptions(index, contentData, setContentData) {
  return [
    // {
    //   is: "choice",
    //   icon: faFrown,
    //   text: "None implemented yet...",
    //   onClick: () => {
    //     let newContentData = Object.assign([], contentData);
    //     // newContentData.splice(i, 1);
    //     // newContentData[index].type = "first_letter_big";
    //     setContentData(newContentData);
    //   },
    //   style: { color: "red" },
    // },
  ];
}

export default function MediaGrid(props) {
  const [listIsOpen, setListIsOpen] = useState(false);
  const [optionsListPos, setOptionsListPos] = useState({ x: 0, y: 0 });
  // const [mediaLength, setMediaLength] = useState(data.media.length);
  const [indexOnDrag, setIndexOnDrag] = useState(undefined);

  const { addOverlay } = overlayStore((state) => state);

  const theLanguage = languages[language].media_grid;

  const {
    data,
    data: { media, preset_number },
    maxLength,
    chosenLanguages,
    updateData,
    customDisplay: CustomDisplay,
    hideAddButton,
    inEdit,
    editPage,
  } = props;
  useEffect(() => {
    let copyData = cloneDeep(data);
    if (copyData.media.length > maxLength) {
      copyData.media.splice(maxLength, copyData.media.length - maxLength);

      if (copyData.languages)
        copyData.languages.splice(maxLength, copyData.media.length - maxLength);
    }

    if (media.length > maxLength) {
      // let copyData = cloneDeep(data);
      copyData.media.splice(maxLength, media.length - maxLength);
      if (copyData.languages)
        copyData.languages.splice(maxLength, media.length - maxLength);
      updateData(copyData);
      return;
    }

    if (
      media.length > 0 &&
      media.length <= maxLength &&
      copyData.grid_type !== "related"
    )
      if (
        theGalleryTemplates[media.length - 1][
          parseInt(copyData.preset_number) - 1
        ]
      ) {
      } else {
        copyData.preset_number = 1;
        updateData(copyData);
      }

    // setMediaLength(media.length);
    // updateData(copyData);
  }, [media.length, maxLength, chosenLanguages, updateData, data]);

  const addOptions = [
    {
      is: "choice",
      text: theLanguage.add.add_image_video,
      onClick: () => {
        props.openOverlay(
          "mediaChoose",
          {
            filter: { mediaType: ["images", "videos"] },
            singleMedia: false,
          },
          undefined,
          "media_add"
        );
      },
    },
    {
      is: "choice",
      text: theLanguage.add.add_page,
      onClick: () => {
        props.openOverlay(
          "pagechoose",
          { disableBottomSelect: true },
          "add_page",
          "page_add"
        );
      },
    },
  ];

  return (
    <div
      style={props.style}
      className={"media-grid" + " media-grid-" + data.grid_type}
    >
      <OptionsList
        isOpen={listIsOpen}
        close={() => setListIsOpen(false)}
        options={addOptions}
        position={optionsListPos}
      />
      {!hideAddButton && (
        <div
          className="add-media"
          onClick={(e) => {
            setListIsOpen(true);
            setOptionsListPos({ x: e.pageX, y: e.pageY });
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
        </div>
      )}
      {/* {data.media.length > 1 ? (
          <div className="choose-template" onClick={()=>{
              props.openOverlay("gridtemplatechoose", {
                mediaAmount: mediaLength,
                chosenPreset: parseInt(preset_number),
              },"choose_template");
          }}>
              <FontAwesomeIcon icon={faThLarge} />
          </div>
        ) : (
          ""
        )} */}

      {data.grid_type === "instagram" && (
        <div className="instagram-header">
          <FontAwesomeIcon icon={faInstagram} />
          <div className="instagram-headline">
            <VfiDivInput
              defaultValue={data.instagramTitle}
              onBlur={(e) => {
                let copyData = cloneDeep(data);
                copyData.instagramTitle = e.target.innerText;
                props.updateData(copyData);
              }}
            />
          </div>
        </div>
      )}

      <div
        className={
          "grid-container grid-amount" +
          (media.length ? media.length : "1") +
          "-pre" +
          (preset_number ? preset_number : "1") +
          (data.swapped ? " swapped" : "")
        }
      >
        {data.grid_type !== "team" &&
        data.grid_type !== "instagram" &&
        media.length === 0 ? (
          <div className="item item1">
            <div className="no-image"></div>

            <p className="max-text">Max {props.maxLength} items</p>
          </div>
        ) : (
          ""
        )}
        {data.grid_type === "instagram" && media.length === 0 && (
          <div className="item item1">
            <div className="no-image"></div>

            <p className="max-text">
              The instagram module supports 3, 5, and 9 images
            </p>
          </div>
        )}
        {!CustomDisplay && [
          media.map((element, i) => {
            const clickable = () =>
              (element.link_option === "page" && element.page_id) ||
              (element.link_option === "external_url" && element.external_link);
            if (i < props.maxLength)
              return (
                <div
                  className={
                    "item item" +
                    (i + 1) +
                    (indexOnDrag === i ? " in-drag" : "") +
                    (!inEdit ? " inactive" : "")
                  }
                  key={element.key}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                  onDrop={(e) => {
                    setIndexOnDrag(undefined);
                    props.onDrop(i);
                  }}
                >
                  {!inEdit && clickable() && (
                    <div
                      className="image-redirect"
                      onClick={() => {
                        if (element.link_option === "external_url") {
                          window.open(element.external_link, "_blank");
                        }
                        if (element.link_option === "page") {
                          editPage(element.page_id);
                        }
                      }}
                    />
                  )}

                  <OneMedia
                    media={element}
                    openOverlay={(what, data) => {
                      props.openOverlay(what, data, i, "update_media");
                      props.setInnerIndex(i);
                    }}
                    whatMedia={["images", "videos"]}
                    onDragStart={(e) => {
                      setIndexOnDrag(i);
                      props.onStartDrag(i);
                    }}
                    onDragEnd={(e) => {
                      setIndexOnDrag(undefined);
                    }}
                    draggable={true}
                  />
                  {/* <div className="type-icon">
                      {element.is === "media" ? (
                        <FontAwesomeIcon icon={faImage} />
                      ) : (
                        ""
                      )}
                      {element.is === "page" ? (
                        <FontAwesomeIcon icon={faFile} />
                      ) : (
                        ""
                      )}
                    </div> */}
                  <div className="bottom-shadow" />
                  {data.media[i].iconSelected ? (
                    <div className="icon">
                      <img
                        src={
                          data.media[i].icon
                            ? env.protocol +
                              env.env +
                              "/uploads/" +
                              data.media[i].icon.file_name +
                              "." +
                              data.media[i].icon.extension
                            : ""
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {!props.isDragging && data.grid_type !== "team" ? (
                    <VfiCKeditor
                      className="title"
                      toolbar={[]}
                      blockToolbar={[]}
                      value={data.languages[i].title}
                      onBlur={(e) => {
                        let copyData = cloneDeep(data);
                        copyData.languages[i].title = e;
                        props.updateData(copyData);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  {data.grid_type === "team" ? (
                    <VfiCKeditor
                      className="team-info"
                      value={data.languages[i].title}
                      onBlur={(e) => {
                        let copyData = cloneDeep(data);
                        copyData.languages[i].title = e;
                        props.updateData(copyData);
                      }}
                    />
                  ) : (
                    ""
                  )}

                  <div className="top-choices">
                    <div
                      className="choice change-page"
                      onClick={(e) => {
                        props.setOptionsList(
                          <SideDescription
                            key={`${i} ${data.key}`}
                            values={data.media[i]}
                            openOverlay={props.openOverlay}
                            close={props.closeOptionsList}
                            selectedLanguage={props.selectedLanguage}
                            change={(e) => {
                              let media = cloneDeep(data.media);
                              media[i] = e;
                              props.onChangeObj({ ...data, media });
                            }}
                            // headline="Select where the grid part should be linked to."
                            openInfo={props.openInfo}
                            index={i}
                          />,
                          { x: e.pageX - 210, y: e.pageY + 30 },
                          true,
                          false
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </div>
                    {data.grid_type !== "instagram" && (
                      <>
                        <div
                          className="choice change-page"
                          onClick={(e) => {
                            props.setOptionsList(
                              <IconSettings
                                key={`${i} ${data.key}`}
                                values={data.media[i]}
                                openOverlay={props.openOverlay}
                                close={props.closeOptionsList}
                                selectedLanguage={props.selectedLanguage}
                                change={(e) => {
                                  let media = cloneDeep(data.media);
                                  media[i] = e;
                                  props.onChangeObj({ ...data, media });
                                }}
                                setModuleKeyInEdit={props.setModuleKeyInEdit}
                                // headline="Select where the grid part should be linked to."
                                openInfo={props.openInfo}
                                index={i}
                              />,
                              { x: e.pageX - 210, y: e.pageY + 30 },
                              true,
                              false
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faIcons} />
                        </div>
                        <div
                          className="choice change-page"
                          onClick={(e) => {
                            props.setOptionsList(
                              <LinkToEdit
                                values={data.media[i]}
                                openOverlay={props.openOverlay}
                                close={props.closeOptionsList}
                                selectedLanguage={props.selectedLanguage}
                                change={(e) => {
                                  // onChangeMediaObj(carouselIndex,e);
                                  let media = cloneDeep(data.media);
                                  media[i] = e;
                                  props.onChangeObj({ ...data, media });
                                }}
                                // headline="Select where the grid part should be linked to."
                              />,
                              { x: e.pageX - 210, y: e.pageY + 30 },
                              true
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faLink} />
                        </div>
                      </>
                    )}

                    <div
                      className="choice remove"
                      onClick={() => {

                        addOverlay('card', (close) => {
                          return (
                            <RemoveMedia close={close} removeMedia={() => {
                              let copyData = cloneDeep(data);
                              copyData.media.splice(i, 1);
                              copyData.languages.splice(i, 1);
                              props.updateData(copyData);
                            }} />
                          );
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </div>
                  </div>
                </div>
              );

            return "";
          }),
          media.length < props.maxLength &&
            inEdit &&
            data.grid_type === "team" && (
              <div
                className="add-team item"
                onClick={() => {
                  props.openOverlay(
                    "mediaChoose",
                    {
                      filter: { mediaType: ["images", "videos"] },
                      singleMedia: false,
                    },
                    undefined,
                    "media_add"
                  );
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </div>
            ),
        ]}
        {CustomDisplay &&
          media &&
          media.length < props.maxLength &&
          inEdit &&
          data.grid_type === "location" && (
            <div
              className="add-team item"
              onClick={() => {
                props.openOverlay(
                  "mediaChoose",
                  {
                    filter: { mediaType: ["images", "videos"] },
                    singleMedia: false,
                  },
                  "media_add"
                );
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          )}
        {CustomDisplay &&
          media &&
          media.map((element, i) => {
            return (
              <div
                className={
                  "item item" + (i + 1) + (indexOnDrag === i ? " in-drag" : "")
                }
                key={element.id + " " + i}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                onDrop={(e) => {
                  setIndexOnDrag(undefined);
                  props.onDrop(i);
                }}
                onDragStart={(e) => {
                  // setIndexOnDrag(i);
                  props.onStartDrag(i);
                }}
                onDragEnd={(e) => {
                  setIndexOnDrag(undefined);
                }}
                draggable={true}
              >
                <CustomDisplay data={element} index={i} />
              </div>
            );
          })}
      </div>
    </div>
  );
}
