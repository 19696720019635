import { faArrowToTop } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import { useState } from "react";
import Dropzone from "react-dropzone";
import { uploadMedia } from "../../../helperFunctions";
import "./UploadOrFindImage.scss";

export default function UploadOrFindImage({
  siteId,
  index,
  openOverlay = () => void 0,
  close = () => void 0,
  afterUpload = () => void 0,
}) {
  const [uploaded, setUploaded] = useState(100);
  const [draggingOverUpload, setDraggingOverUpload] = useState(false);

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      setUploaded((progressEvent.loaded * 100) / progressEvent.total);
    },
  };
  const uploadFiles = (e) => {
    uploadMedia(e, siteId, config, (res) => {
      afterUpload(res);
    });
  };

  return (
    <div className="upload-or-find-image">
      {uploaded === 100 ? (
        <Dropzone
          onDrop={(e) => {
            uploadFiles(e);
            setDraggingOverUpload(false);
          }}
          onDragEnter={() => {
            setDraggingOverUpload(true);
          }}
          onDragLeave={() => {
            setDraggingOverUpload(false);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input
                  {...getInputProps()}
                  accept=".jpg,.jpeg,.png,.gif,.mp4"
                  maxLength={1}
                />
                <div className="upload-or-find-image">
                  <div
                    className={
                      "big-box" + (draggingOverUpload ? " drag-over" : "")
                    }
                  >
                    <FontAwesomeIcon icon={faArrowToTop} />
                  </div>
                  {/* <div className="text">UPLOAD OR FIND IMAGE</div> */}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      ) : (
        <div className="upload-or-find-image">
          <div className="big-box">
            <div className="upload-bar">
              <div
                className="progress-bar"
                style={{
                  width: uploaded + "%",
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className="upload-or-find-image choose-media"
        onClick={() => {
          // props.setModuleKeyInEdit("medium");
          openOverlay(
            "mediaChoose",
            {
              filter: { mediaType: ["images", "videos"] },
              singleMedia: true,
            },
            index,
            "medium"
          );
          close();
        }}
      >
        {/* <div className="big-box">
              <FontAwesomeIcon icon={faArrowToTop} />
            </div> */}
        <div className="text">UPLOAD OR FIND IMAGE</div>
      </div>
    </div>
  );
}
