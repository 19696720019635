import {
  faArrowRight,
  faChevronRight,
  faIcons,
  faPlus,
  faSearch,
  faSpinner,
  faTimes,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import VfiInputText from "../../VfiInputText/VfiInputText";
import "./ServiceTabs.scss";
import languages from "../language.json";
import axios from "axios";
import env from "../../../environment.json";
// import cloneDeep from "clone-deep";
import cloneDeep from "lodash.clonedeep";
import VfiCheckbox from "../../VfiCheckbox";
import IconSettings from "../VfiCarousel/IconSettings";

const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export function ServiceTabsMO(props) {}

export default function ServiceTabs(props) {
  const [values, setValues] = useState(props.values);
  const { change, menues, contentEditEnabled, size } = props;
  const [selectedTabs, setServiceTabs] = useState(
    values.selectedTabs.length > 0 ? values.selectedTabs : [{ newTab: true }]
  );
  const [select, setSelect] = useState(
    values.selectedTabs.length > 0 && menues.length > 0 ? 0 : -1
  );
  const [list, setList] = useState([]);
  const pageChild = (entireObj, keyToFind, valToFind) => {
    let foundObj;
    JSON.stringify(entireObj, (_, nestedValue) => {
      if (
        nestedValue &&
        nestedValue[keyToFind] === valToFind &&
        nestedValue.site_id
      ) {
        foundObj = nestedValue;
      }
      return nestedValue;
    });
    return foundObj;
  };

  useEffect(() => {
    if (values.selectedTabs.length > 0 && menues.length > 0) {
      if (!selectedTabs.some((x) => x.newTab) && selectedTabs.length < 4) {
        selectedTabs.push({ newTab: true });
        setServiceTabs(selectedTabs);
      }
      setSelect(0);
    }
  }, [menues]);
  useEffect(() => {
    setServiceTabs(
      props.values.selectedTabs.length > 0
        ? props.values.selectedTabs
        : [{ newTab: true }]
    );
  }, [props.values]);
  useEffect(() => {
    if (select >= 0) {
      setList(
        pageChild(menues, "id", JSON.parse(selectedTabs[select].page_id))
          .children
      );
    }
  }, [selectedTabs, select]);

  return (
    <div
      style={props.style}
      className={(size <= 375 && "phone ") + " serviceTabs"}
    >
      <div className="top">
        {selectedTabs.map((response, i) => {
          if (response.newTab && !contentEditEnabled) {
            return;
          }
          if (response.newTab && !response.page_id) {
            return (
              <div
                className="add-tab"
                onClick={() => {
                  props.openOverlay("pageChoose", {
                    afterFunc: (e) => {
                      selectedTabs[i].page_id = e.id;
                      selectedTabs[i].newTab = false;
                      setValues({ ...values, selectedTabs });
                      change({ ...values, selectedTabs });
                      setSelect(i);
                    },
                    headline: props.headline,
                    disableBottomSelect: true,
                  });
                  if (selectedTabs.length < 4) {
                    selectedTabs.push({ newTab: true });
                  }
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </div>
            );
          }
          return (
            <div className={(select === i && "selected") + " wrap-tab"}>
              {contentEditEnabled && (
                <div
                  className="remove"
                  onClick={() => {
                    selectedTabs.splice(i, 1);
                    setSelect(selectedTabs.length - 2);
                    setValues({ ...values, selectedTabs });
                    change({ ...values, selectedTabs });
                    if (
                      selectedTabs.length < 4 &&
                      !selectedTabs.some((x) => x.newTab)
                    ) {
                      selectedTabs.push({ newTab: true });
                    }
                  }}
                >
                  <FontAwesomeIcon color="red" icon={faTrashAlt} />
                </div>
              )}

              {contentEditEnabled && (
                <div
                  className="change-icon"
                  onClick={(e) => {
                    props.setOptionsList(
                      <IconSettings
                        values={response}
                        openOverlay={props.openOverlay}
                        close={props.closeOptionsList}
                        change={(e) => {
                          selectedTabs[i] = e;
                          props.change({ ...values, selectedTabs });
                        }}
                        setModuleKeyInEdit={props.setModuleKeyInEdit}
                        // headline="Select where the grid part should be linked to."
                        openInfo={props.openInfo}
                        index={i}
                      />,
                      { x: e.pageX - 210, y: e.pageY + 30 },
                      true,
                      false
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faIcons} />
                </div>
              )}
              {response.iconSelected && (
                <div className="icon">
                  <img
                    src={
                      response.icon
                        ? env.protocol +
                          env.env +
                          "/uploads/" +
                          response.icon.file_name +
                          "." +
                          response.icon.extension
                        : ""
                    }
                  />
                </div>
              )}
              <div
                onClick={() => {
                  setSelect(i);
                }}
              >
                {menues.length > 0 &&
                  pageChild(menues, "id", JSON.parse(response.page_id))
                    .pl_title}
              </div>
            </div>
          );
        })}
      </div>
      {select >= 0 && (
        <div
          className="list"
          style={{ height: list.length * 54 + (list.length - 1) * 40 + "px" }}
        >
          <div className="links">
            {list.map((element) => {
              return (
                <div>
                  {element.pl_title} <FontAwesomeIcon icon={faChevronRight} />
                </div>
              );
            })}
          </div>
          <div className="feature-image">
            <img
              src={
                pageChild(
                  menues,
                  "id",
                  JSON.parse(selectedTabs[select].page_id)
                ).feature_image_dir.large
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
