import "../Product.scss";

export default function ShippingPanel({productState}) {

  return (
    <div className="shipping">
      <h3>Shipping</h3>
      <div className="shipping-type">
        <label>Weight KG</label>
        <input
          defaultValue={productState.shipping.weight}
          onChange={(e) => {
            productState.shipping.weight = e.target.value;
          }}
        />
      </div>
      <div className="shipping-type">
        <label>Dimension L/W/H</label>
        <input
          defaultValue={productState.shipping.dimension}
          onChange={(e) => {
            productState.shipping.dimension = e.target.value;
          }}
        />
      </div>
    </div>
  );
}
