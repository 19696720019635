import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";

export default function C_CategoryFilter(props) {
  const { headline, data, onChange, whatsonPages, categories, index } = props;

  // const[allCategories,setAllCategories] = useState([]);
  const [loaded, setLoaded] = useState(false);

  let manualShow = false;

  if (index === 0 && data.whatWhatson) {
    manualShow = true;
  } else {
    if (
      data.category_filters[index - 1] &&
      data.category_filters[index - 1] !== 0
    ) {
      manualShow = true;
    }
  }

  useEffect(() => {
    if (data.category_filters) setLoaded(true);
    else setLoaded(false);
  }, [data.category_filters]);

  const setIndexToId = (id) => {
    let category_filters = cloneDeep(data.category_filters);
    category_filters[index] = id;
    category_filters[index + 1] = 0;
    category_filters.length = index + 2;
    onChange({ ...data, category_filters: category_filters });
  };

  let filteredCategories = cloneDeep(categories);

  // Filter previous categories away
  filteredCategories = filteredCategories.filter((category) => {
    return !data.category_filters.find((e, i) => {
      if (index - 1 < i) {
        return false;
      }

      return e === category.id;
    });
  });

  filteredCategories = filteredCategories.filter((category) => {
    let categoryFound = false;
    whatsonPages.forEach((p) => {
      if (p.categories.find((c) => c.id_category === category.id)) {
        categoryFound = true;
      }
    });
    return categoryFound;
  });

  return (
    <div className="c-category-filter">
      <div className={"the-list" + (loaded && manualShow ? " loaded" : "")}>
        {headline && <div className="headline">{headline}</div>}
        <div className="top-margin" />
        <div
          className={
            "option" + (data.category_filters[index] === 0 ? " active" : "")
          }
          onClick={() => {
            setIndexToId(0);
          }}
        >
          All
        </div>
        {loaded &&
          filteredCategories.map((e) => (
            <div
              key={e.id}
              className={
                "option " +
                e.category_en.toLowerCase() +
                (data.category_filters[index] === e.id ? " active" : "")
              }
              onClick={() => {
                setIndexToId(e.id);
              }}
            >
              {e.category_en}
            </div>
          ))}
      </div>
    </div>
  );
}
