import axios from "axios";
import env from "../../environment.json";
import { create } from "zustand";
import { setType } from "./zustandTypes";
import { ComponentType } from "react";

interface whatsonListElement {
  type: string;
  id: number;
}

interface IWhatsonDefaultShort {
  id: number;
  headline: string;
  excerpt: string | null;
}

interface IPlace extends IWhatsonDefaultShort {
  region_id: number;
}
interface IEventShort extends IWhatsonDefaultShort {}
interface ITourShort extends IWhatsonDefaultShort {}
interface IHikingShort extends IWhatsonDefaultShort {}
interface ITourOperatorShort extends IWhatsonDefaultShort {}

interface WhatsonState {
  places: IPlace[];
  events: IEventShort[];
  tours: ITourShort[];
  hiking: IHikingShort[];
  tourOperators: ITourOperatorShort[];
  getAllWhatson: () => void;
  getTourOperators: () => void;
}

const whatsonStore = (set: setType<WhatsonState>) => ({
  places: [],
  events: [],
  tours: [],
  hiking: [],
  tourOperators: [],
  getAllWhatson: () => {
    axios
      .get(
        `${env.protocol}${env.env}/api/whatson/GetAllWhatsonList?all&ignore_today_and_later`
      )
      .then((response) => {
        const data = response.data;
        const places = data.filter(
          (f: whatsonListElement) => f.type === "place"
        );
        const events = data.filter(
          (f: whatsonListElement) => f.type === "event"
        );
        const tours = data.filter((f: whatsonListElement) => f.type === "tour");
        const hiking = data.filter(
          (f: whatsonListElement) => f.type === "hike"
        );

        set({ places, events, tours, hiking });
      })
      .catch((error) => console.error(error));
  },
  getTourOperators: () => {
    axios
      .get(
        `${env.protocol}${env.env}/api/secured/whatson/touroperators/GetTourOperatorsList`
      )
      .then((response) => {
        set({ tourOperators: response.data });
      })
      .catch((error) => console.error(error));
  },
});

export const useWhatsonStore = create<WhatsonState>(whatsonStore);

export const withWhatsonStore =
  (BaseComponent: ComponentType<any>) => (props: object) => {
    const store = useWhatsonStore();
    return <BaseComponent {...props} whatsonStore={store} />;
  };
