import { useEffect, useState } from "react";
import styles from "./AddAmenity.module.scss";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faLoader,
} from "@fortawesome/pro-solid-svg-icons";

import env from "../../../../../../../environment.json";
import { IOverlayStore, overlayStore } from "src/Components/OverlayElements/OverlayStore";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  width?: number;
  height?: number;
  close?: () => any;
  success: () => any;
  forms?: any[];
  idx?: number;
  edit?: boolean;
}

// TODO: Create from type

const emptyForms: any[] = [
  {
    amenityID: null,
    title: {
      en: "",
      fo: "",
    },
    backend: {
      en: "",
      fo: "",
    },
    frontend: {
      en: "",
      fo: "",
    },
  },
];

export default function AddAmenity({
  width = 600,
  height = 600,
  close = () => {},
  success = () => {},
  forms = emptyForms,
  idx = 0,
  edit = false,
}: Props) {
  const { addOverlay } = overlayStore();

  const [formData, setFormData] = useState<any>(forms[idx]);
  const [index, setIndex] = useState<number>(idx);

  const [loading, setLoading] = useState<boolean>(false);

  const save: any = () => {
    setLoading(true);

    // Add/Edit amenity
    axios
      .post(
        env.protocol +
              env.env + (edit
                ? "/api/secured/whatson/places/EditPlaceAmenity"
                : "/api/secured/whatson/places/AddPlaceAmenity"),
        edit
          ? {
              amenityID: formData.amenityID,
              enName: formData.title.en,
              foName: formData.title.fo,
              tooltipEn: formData.backend.en,
              tooltipFo: formData.backend.fo,
              tooltipFrontendEn: formData.frontend.en,
              tooltipFrontendFo: formData.frontend.fo,
            }
          : {
              enName: formData.title.en,
              foName: formData.title.fo,
              tooltipEn: formData.backend.en,
              tooltipFo: formData.backend.fo,
              tooltipFrontendEn: formData.frontend.en,
              tooltipFrontendFo: formData.frontend.fo,
            }
      )
      .then((response: any) => {
        if (response.data.amenityID) {
          addOverlay("side_popout_self_close", (close: () => void) => {
            return (
              <div
                style={{
                  padding: "10px 20px",
                  paddingRight: "50px",
                  width: "fit-content",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 160, 255, 0.8)",
                }}
              >
                {edit ? "Edit successful!" : "Amenity successfully created!"}
              </div>
            );
          });
          success();
        }
      })
      .catch((error: any) => {
        setLoading(false);
        addOverlay("side_popout_self_close", (close: () => void) => {
          return (
            <div
              style={{
                padding: "10px 20px",
                paddingRight: "50px",
                width: "fit-content",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 0, 0, 0.8)",
              }}
            >
              {edit ? "Failed to edit amenity!" : "Failed to add amenity!"}
            </div>
          );
        });
      })
      .finally(() => {
        // close();
        close();
      });
  };

  // Delete amenity
  const deleteAmenity: any = () => {
    setLoading(true);

    axios
      .post(env.protocol + env.env + "/api/secured/whatson/places/DeletePlaceAmenity", {
        amenityID: formData.amenityID,
      })
      .then((response: any) => {
        if (response.data.status === "success") {
          addOverlay("side_popout_self_close", (close: () => void) => {
            return (
              <div
                style={{
                  padding: "10px 20px",
                  paddingRight: "50px",
                  width: "fit-content",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                }}
              >
                Amenity successfully deleted!
              </div>
            );
          });
          success();
        }
      })
      .catch((error: any) => {
        setLoading(false);
        addOverlay("side_popout_self_close", (close: () => void) => {
          return (
            <div
              style={{
                padding: "10px 20px",
                paddingRight: "50px",
                width: "fit-content",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 0, 0, 0.8)",
              }}
            >
              Failed to delete amenity!
            </div>
          );
        });
        success();
      })
      .finally(() => {
        // close();
        close();
      });
  };

  useEffect(() => {
    setFormData(forms[index]);

    if (edit) {
      let str: string[] = window.location.hash.split("-");
      str.pop();
      window.location.hash = str.join("-") + "-" + (index + 1);
    }
  }, [index]);

  // Loading (To initialize the load animation)
  if (loading)
    return (
      <div
        className={styles.container}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        <div className={styles.loadingContainer}>
          <div className={styles.loader}>
            <FontAwesomeIcon icon={faLoader as IconProp} />
          </div>
        </div>
      </div>
    );

  // Main return
  return (
    <div
      className={styles.container}
      style={{
        width: `${width}px`,
        height: `${height}px`,
      }}
    >
      {/* Header */}

      <div className={styles.header}>
        <p>Amenity</p>
        {edit && (
          <div className={styles.navigate}>
            <button
              className={`${styles.left} ${index === 0 && styles.disabled}`}
              onClick={() => {
                if (index > 0) {
                  setIndex(index - 1);
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft as IconProp} />
            </button>
            <div className={styles.index}>
              {index + 1} / {forms.length}
            </div>
            <button
              className={`${styles.right} ${
                index === forms.length - 1 && styles.disabled
              }`}
              onClick={() => {
                if (index < forms.length - 1) {
                  setIndex(index + 1);
                }
              }}
            >
              <FontAwesomeIcon icon={faChevronRight as IconProp} />
            </button>
          </div>
        )}
      </div>

      {/* Title inputs */}

      <div className={styles.titleSection}>
        <p className={styles.title}>Title</p>
        <div className={styles.inputs}>
          {Object.keys(formData.title).map((data: any) => {
            return (
              <input
                key={`title-${data}`}
                type="text"
                value={formData.title[data]}
                placeholder={`${data === "en" ? "English" : "Faroese"} title`}
                onFocus={(e) => {
                  e.target.placeholder = "";
                }}
                onBlur={(e) => {
                  e.target.placeholder = `${
                    data === "en" ? "English" : "Faroese"
                  } title`;
                }}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    title: {
                      en: data === "en" ? e.target.value : formData.title.en,
                      fo: data === "fo" ? e.target.value : formData.title.fo,
                    },
                  });
                }}
              />
            );
          })}
        </div>
      </div>

      {/* Tooltip section */}

      <div className={styles.tooltipSection}>
        {["backend", "frontend"].map((type: string) => {
          return (
            <div 
              key={`amenity-${type}`}
              className={`${styles[type]} ${styles.tooltip}`}>
              <p className={styles.title}>TOOLTIP ({type.toUpperCase()})</p>
              <div className={styles.inputs}>
                {Object.keys(formData[type]).map((data: string) => {
                  return (
                    <div 
                      key={`tooltip-${type}-${data}`}
                      className={styles.largeTextField}>
                      <textarea
                        value={formData[type][data]}
                        placeholder={`${
                          data === "en" ? "English" : "Faroese"
                        } tooltip`}
                        onFocus={(e) => {
                          e.target.placeholder = "";
                        }}
                        onBlur={(e) => {
                          e.target.placeholder = `${
                            data === "en" ? "English" : "Faroese"
                          } tooltip`;
                        }}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [type]: {
                              en:
                                data === "en"
                                  ? e.target.value
                                  : formData[type].en,
                              fo:
                                data === "fo"
                                  ? e.target.value
                                  : formData[type].fo,
                            },
                          });
                        }}
                      />
                      <div
                        className={`${styles.capacity} ${
                          formData[type][data].length > 100
                            ? styles.invalid
                            : ""
                        }`}
                      >
                        {formData[type][data].length}/100
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.submit}>
        {/* Cancel button */}

        <button
          className={styles.cancel}
          onClick={() => {
            close();
          }}
        >
          CANCEL
        </button>

        {/* Delete button */}

        {edit && (
          <button
            className={styles.delete}
            onClick={() => {
              if (
                window.confirm("Are you sure you want to delete this amenity?")
              )
                deleteAmenity();
            }}
          >
            DELETE
          </button>
        )}

        {/* Edit/Save button */}

        <button
          className={`${styles.save} ${
            formData.backend.en.length > 100 ||
            formData.backend.fo.length > 100 ||
            formData.frontend.en.length > 100 ||
            formData.frontend.fo.length > 100
              ? styles.disabled
              : ""
          }`}
          onClick={() => {
            if (
              !(
                formData.backend.en.length > 100 ||
                formData.backend.fo.length > 100 ||
                formData.frontend.en.length > 100 ||
                formData.frontend.fo.length > 100
              )
            )
              save();
          }}
        >
          {edit ? "SAVE" : "SAVE AND CLOSE"}
        </button>
      </div>
    </div>
  );
}
