import React, { useEffect, useRef, useState } from "react";
import "./OptionsList.scss";
import languages from "./language.json";
import OptionsInList from "./OptionsInList";
import PropTypes from "prop-types";
import { ViewSelector } from "react-analytics-charts";
import stylePropType from "react-style-proptype";

function OptionsList(props) {
  const wrapperRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "english";
  const [extraListShown, setExtraListShown] = useState([]);
  const [extraListPosition, setExtraListPosition] = useState({ x: 0, y: 0 });

  const { ignoreKeepInScreen, backgroundBlur } = props;

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        if (props.close && props.deep === undefined) {
          props.close();
          setExtraListShown([]);
        }
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (props.position !== undefined) {
      let posX = props.position.x;
      let posY = props.position.y;

      let posStyle = {
        left: posX ? posX : "unset",
        top: posY ? posY : "unset",
      };

      if (wrapperRef.current) {
        if (
          posX + wrapperRef.current.clientWidth > document.body.clientWidth &&
          !ignoreKeepInScreen
        ) {
          posStyle = { ...posStyle, left: "unset", right: "5px" };
        }
        if (
          posY + wrapperRef.current.clientHeight > document.body.clientHeight &&
          !ignoreKeepInScreen
        ) {
          posStyle = {
            ...posStyle,
            top: "unset",
            bottom: "5px",
            // transformOrigin: "center center",
            transformOrigin:
              "center " +
              (wrapperRef.current.clientHeight -
                (document.body.clientHeight - posY)) +
              "px",
          };
        }
      }
      setPosition(posStyle);
    }
  }, [props.position]);
  // eslint-disable-next-line
  let amountAdded = 0;

  if (props.customElements) {
    return (
      <div className="options-list-container">
        <div
          className={
            "options-list-blur" +
            (backgroundBlur && props.isOpen ? "" : " hidden")
          }
        />
        <div
          className={
            "options-list custom-elements" + (props.isOpen ? " open" : "")
          }
          ref={wrapperRef}
          style={position ? { ...props.style, ...position } : ""}
        >
          {props.options}
        </div>
      </div>
    );
  }

  return (
    <div className="options-list-container">
      <div
        className={
          "options-list-blur" +
          (backgroundBlur && props.isOpen ? "" : " hidden")
        }
      />
      <div
        className={
          "options-list" +
          (props.isOpen ? " open" : "") +
          (!props.customElements ? " padd" : "")
        }
        ref={wrapperRef}
        style={{ ...props.style, ...position }}
      >
        {props.options.length === 0 ? (
          <div className="options-empty">
            {languages[language].list_is_empty}
          </div>
        ) : (
          ""
        )}
        {props.options.map((e, i) => {
          let result = (
            <OptionsInList
              key={(e.text ?? "") + i}
              element={e}
              i={i}
              close={props.close}
              setExtraListShown={(list, e) => {
                setExtraListShown(list);
                setExtraListPosition({
                  x: e.target.scrollWidth,
                  // +62
                  y: e.target.scrollHeight - 24,
                });
              }}
            />
          );
          if (result !== "") amountAdded++;
          return result;
        })}
        {/* {amountAdded === 0 ? (
        <div className="options-empty">{languages[language].list_is_empty}</div>
      ) : (
        ""
      )} */}
        {props.options.length > 0 && (
          <OptionsList
            isOpen={extraListShown.length > 0}
            options={extraListShown}
            position={extraListPosition}
            deep={props.deep ? props.deep + 1 : 1}
            close={() => {
              setExtraListShown([]);
              props.close();
            }}
          />
        )}
      </div>
    </div>
  );
}

OptionsList.propTypes = {
  close: PropTypes.func,
  // deep: PropTypes.number, // Options list uses it recursively
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  isOpen: PropTypes.bool.isRequired,
  // options: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     is: PropTypes.oneOf([
  //       "choice",
  //       "choice_list",
  //       "title",
  //       "hr",
  //       "empty",
  //       "custom",
  //     ]),
  //     icon: PropTypes.any,
  //     text: PropTypes.string,
  //     onClick: PropTypes.func,
  //     style: PropTypes.object,
  //   })
  // ),
  options: PropTypes.any, // JSX
  customElements: PropTypes.any, // JSX
  style: stylePropType,
  ignoreKeepInScreen: PropTypes.bool,
};

export default OptionsList;
