import React, { Component } from "react";

class ListOption extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.onClick) this.props.onClick();
  }

  insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  optionClicked(e) {
    this.listOptions.forEach((element, i) => {
      this.listOptions[i].selected = false;

      if (element.index === e.index) {
        this.listOptions[i].selected = true;
      }
    });

    if (e.index === "0") {
      this.setState({ allSelected: true });
    } else {
      this.setState({ allSelected: false });
    }
  }

  render() {
    const outsideClassName = this.props.className ? this.props.className : "";
    const selected = this.props.selected ? "selected " : "";
    const theClassName = "list-option " + selected + outsideClassName;
    return (
      <div
        id={this.props.id}
        className={theClassName}
        onClick={this.onClick}
        draggable={this.props.draggable}
        onDragStart={this.onDragStart}
        onDragOver={this.onDragOver}
        onDragEnd={this.onDragEnd}
      >
        {this.props.html}
      </div>
    );
  }
}

export default ListOption;
