import React, { Component } from "react";
import env from "../../../environment.json";
import VfiInputText from "../../../assets/VfiInputText/VfiInputText";
import "./UsersDisplay.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons";
import UsersList from "./UsersList";
import helperFunctions, {
  cancelGetUsers,
  getUsers,
} from "./../../../assets/helperFunctions";
import globalObject from "../../../assets/globalVariables";
import SidebarElement from "../../../assets/SidebarElement";
import { withAllOfStore } from "../../ZustandStores/allOfStore";
import Leaflet from "../Leaflet/Leaflet";
import { getUserSections } from "./sections";

class UsersDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchValue: "",
      sidebarType: undefined,
      sidebarId: undefined,
      sidebarSelectedName: "",
      sidebarSelectedKey: "",
    };
    this.roles = [];
    this.axios = require("axios");
    //  this.openFilter = this.openFilter.bind(this);
    this.closeFilter = this.closeFilter.bind(this);
    this.sitesChecked = [];
    this.search = { bool: true, searchValue: "" };

    this.value = [
      { id: 1, text: "Publisher", selected: false },
      { id: 1, text: "Admin", selected: false },
    ];

    this.filterContent = [
      {
        headline: "Roles",
        value: this.value,
        style: "checkbox",
      },
    ];
  }

  addUserBeforeRefresh = (info) => {
    this.props.userContents.push(info);
  };

  editUserBeforeRefresh = (info) => {
    var id = info.userId;
    const index = this.props.userContents.findIndex((x) => x.userId === id); //Find updated row
    this.props.userContents[index] = info;
  };

  GetUsers = () => {
    getUsers(this.props.site, (response) => {
      this.props.handleUserData(response);
      helperFunctions.sortList("firstname", this.props.userContents, false);

      if (this.props.createNew.toLowerCase() === "user") {
        this.props.createUser("newUser");
        this.props.SetState("createNew", "");
      }
      this.setState({ loading: false });
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.GetUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
    cancelGetUsers();
  }

  componentDidUpdate(prevProps) {
    this._isMounted = true;
    if (prevProps.site !== this.props.site) {
      this.GetUsers();
      this.setState({ addFilterDroppedDown: false }); //close filter btn and make white
    }
  }

  refresh() {
    this.GetUsers();
  }

  closeFilter() {
    if (this.props.addFilterDroppedDown[0] === true) {
      this.props.openCloseFilter([false, "users"], {});
    }
  }

  onCheckboxChange = (e) => {
    // When checking a checkbox we document it and update the array
    const index = this.sitesChecked.indexOf(e.target.name); // Document if object is selected or not
    if (index > -1) {
      this.sitesChecked.splice(index, 1);
    } else {
      this.sitesChecked.push(e.target.name);
    }

    // Update selected
    this.value = this.value.map((element) => ({
      text: element.text,
      selected: this.sitesChecked.includes(element.text),
    }));
    this.filterContent[0].value = this.filterContent[0].value.map(
      (element) => ({
        text: element.text,
        selected: this.sitesChecked.includes(element.text),
      })
    );

    this.props.SetState("filterdata.content", this.filterContent); //update the array in main
  };

  onSearchChange = (e) => {
    //Change search value
    this.search.searchValue = e.target.value;
    this.props.SetState("filterdata.search", this.search);

    // Change list depending on search
    // var filteredList = JSON.parse(JSON.stringify(this.filterContent)); //To avoid that object filterContent wont change
    this.filterContent[0].value = helperFunctions.searchFilter(
      this.value,
      this.search.searchValue
    );
    this.props.SetState("filterdata.content", this.filterContent);
  };

  render() {
    const filterHidden =
      this.props.addFilterDroppedDown[0] &&
      this.props.addFilterDroppedDown[1] === "users"
        ? ""
        : "hidden";

    const { sidebarType, sidebarId } = this.state;
    const userContents = this.props.userContents.filter((user) => {
      let keyName = "";
      switch (sidebarType) {
        case "sites":
          keyName = "site_id";
          break;
        case "places":
          keyName = "place_id";
          break;
        case "tourOperators":
          this.setState({ sidebarType: "tour_operators" });
          break;
        case "tour_operators":
          keyName = "tour_operators";
          break;
        default:
          break;
      }

      return (
        sidebarType === undefined ||
        (sidebarId !== undefined &&
          (user.permissions ?? [])[sidebarType]?.find(
            (site) => site[keyName] === sidebarId
          ) !== undefined) ||
        (sidebarId === undefined &&
          Object.keys(user.permissions ?? []).find(
            (permissionType) => permissionType === sidebarType
          ))
      );
    });

    // const SidebarButton = ({ children, type, id }) => {
    //   return (
    //     <button
    //       active={sidebarType === type && sidebarId === id ? "true" : "false"}
    //       onClick={() => {
    //         console.log({
    //           sidebarType: type,
    //           sidebarId: id,
    //         });
    //         this.setState({
    //           sidebarType: type,
    //           sidebarId: id,
    //         });
    //       }}
    //     >
    //       {children}
    //     </button>
    //   );
    // };

    return (
      <div className="users-display">
        <div className="users-display-left-side">
          {/* <SidebarElement>
            <>
              <h1>Users</h1>
              <p>ALL USERS</p>
              <SidebarButton type={undefined} id={undefined}>
                All
              </SidebarButton>
              <p>SITE</p>
              <SidebarButton type={"sites"} id={undefined}>
                All
              </SidebarButton>
              {this.props.store.sitesStore.allSitesNoRootPages.map((site) => {
                return (
                  <SidebarButton
                    key={site.siteId}
                    type={"sites"}
                    id={site.siteId}
                  >
                    {site.name}
                  </SidebarButton>
                );
              })}
              <p>PLACES</p>
              <SidebarButton type={"places"} id={undefined}>
                All places
              </SidebarButton>
              {this.props.store.whatsonStore.places
                .filter(
                  (place) =>
                    globalObject.vfiUser.permissions.places.find(
                      (userPermPlace) => userPermPlace.place_id === place.id
                    ) !== undefined
                )
                .map((place) => (
                  <SidebarButton key={place.id} type={"places"} id={place.id}>
                    {place.headline}
                  </SidebarButton>
                ))}
              <p>TOUR OPERATORS</p>
            </>
          </SidebarElement> */}
          <Leaflet
            title="Users"
            sections={getUserSections(
              this.props.store.sitesStore.allSitesNoRootPages
            )}
            selectedView={{
              name: this.state.sidebarSelectedName,
              key: this.state.sidebarSelectedKey,
            }}
            setSelectedView={(e) => {
              this.setState({
                sidebarType: e.checkType,
                sidebarId: e.checkId,
                sidebarSelectedName: e.name,
                sidebarSelectedKey: e.key,
              });
            }}
            // customCall={}
          />
        </div>
        <div className="users-display-right-side">
          <div className="header-panel">
            <div className="header-title">
              <h1>Users</h1>
            </div>
            <div className="add-new-user-parent">
              <div
                className="add-new-user"
                onClick={() => this.props.createUser("newUser")}
              >
                <div className="text">Add new user</div>
              </div>
            </div>
            <div className="search-parent">
              <div className="search">
                <VfiInputText
                  value={this.state.searchValue}
                  placeholder="Search user..."
                  onChange={(e) => {
                    this.setState({ searchValue: e.target.value });
                  }}
                  isSearch={true}
                />
              </div>
            </div>
            <div className="all-sort-and-filter">
              <div
                className={"filter-button " + filterHidden}
                onClick={() =>
                  this.props.openCloseFilter(
                    [!this.props.addFilterDroppedDown[0], "users"],
                    {
                      type: "users",
                      search: this.search,
                      style: {},
                      content: this.filterContent,
                      executeCheckbox: (e) => {
                        // What happens when elements are clicked
                        this.onCheckboxChange(e);
                      },
                      executeSearch: (e) => {
                        this.onSearchChange(e);
                      },
                    }
                  )
                }
              >
                <span className="text">Filter</span>
                <FontAwesomeIcon
                  className="slide-icon"
                  icon={faSlidersH}
                  flip={"vertical"}
                />
              </div>
            </div>
          </div>
          <UsersList
            userContents={userContents}
            loading={this.state.loading}
            site={this.props.site}
            searchValue={this.state.searchValue}
            openEdit={this.props.openEdit}
            checkboxFiltering={this.props.checkboxFiltering}
            closeFilter={this.closeFilter}
            sitesChecked={this.sitesChecked}
          />
        </div>
      </div>
    );
  }
}

export default withAllOfStore(UsersDisplay);
