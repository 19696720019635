// Checkbox.tsx
import React from "react";

import styles from "./checkbox.module.scss";
import { CheckboxProps } from "./types/CheckboxTypes";

const Checkbox: React.FC<CheckboxProps> = ({
  children,
  checked,
  disabled = false,
  onChange,
  labelLeft = false,
}) => {
  return (
    <label className={styles.checkboxContainer}>
      {labelLeft && children}
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={styles.checkboxInput}
      />
      <div className={styles.checkmark}>
        <span className={styles.leftShorter} />
        <span className={styles.rightLonger} />
      </div>
      {!labelLeft && children}
    </label>
  );
};

export default Checkbox;
