import { Component } from "react";
import env from "../../../environment.json";
import "./WhatsonSidebar.scss";
import WhatsonOption from "./WhatsonOption";
import { withAllOfStore } from "src/Components/ZustandStores/allOfStore";

const level = 1;

const menuGroups = [
  {
    name: "Events",
    items: [
      { permCheck: /*"events"*/ undefined, name: "Events" },
      { permCheck: /*"places"*/ undefined, name: "Places" },
    ],
  },
  {
    name: "Tours",
    items: [
      { permCheck: ["tours", "tourOperators"], name: "Tours" },
      { permCheck: "tourOperators", name: "Tour Providers" },
    ],
  },
  {
    name: "Hiking",
    permCheck: "specifics",
    ids: ["hiking"],
    items: [
      {
        name: "Hiking",
        //  id: "hiking"
      },
    ],
  },
];

const siteSelections = {
  events: {
    name: "events",
  },
  places: {
    name: "places",
  },
  tours: {
    name: "tours",
  },
  tour_operator: {
    name: "tour operator",
  },
  hiking: {
    name: "hiking",
  },
};

class WhatsonSidebar extends Component {
  constructor(props) {
    super(props);

    this.whatsonList = [];
    this.axios = require("axios");
  }

  componentDidMount() {
    this.axios
      .post(env.protocol + env.env + "/api/public/GetWhatsonSites.php", {
        userId: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        this.whatsonList = response.data !== "" ? response.data : [];
        this.setState({});

        if (Array.isArray(this.whatsonList)) {
          if (this.whatsonList[0]) {
            if (this.props.store.routerStore.route.length > 1) {
              let site =
                siteSelections[this.props.store.routerStore.route[1].nav];
              let whatsonSite = this.whatsonList.find(
                (e) => e.name.toLowerCase() === site.name.toLowerCase()
              );

              this.optionClicked({
                ...whatsonSite,
                index: whatsonSite.id,
              });
            }

            let index = 0;
            this.whatsonList.forEach((element, i) => {
              if (
                element.name === sessionStorage.getItem("whatson_site_name")
              ) {
                index = i;
              }
            });

            this.optionClicked({
              ...this.whatsonList[index],
              index: this.whatsonList[index].id,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  optionClicked(e) {
    this.whatsonList.forEach((element, i) => {
      this.whatsonList[i].selected = false;

      if (element.id === e.index) {
        this.whatsonList[i].selected = true;
      }
    });

    this.props.siteSelect(e);

    sessionStorage.setItem("whatson_site_name", e.name);
  }

  render() {
    return (
      <div className="whatson-sidebar">
        <h1>What's On</h1>
        <div
          className="list-parent"
          onDrop={this.drop}
          onDragOver={this.dragOver}
        >
          {menuGroups
            .filter((group) => {
              if (group.permCheck !== undefined) {
                return this.props.store.permCheckStore.permCheckFunc({
                  type: group.permCheck,
                  minimumRole: "Collaborator",
                  id: group.ids,
                });
              } else {
                return !group.items
                  .map((item) => item.permCheck)
                  .includes(undefined)
                  ? this.props.store.permCheckStore.permCheckFunc({
                      type: group.items.map((item) => item.permCheck),
                      minimumRole: "Collaborator",
                    })
                  : true;
              }
            })
            .map((group) => {
              return (
                <div key={group.name}>
                  <p className="small-gray">{group.name}</p>
                  {group.items
                    .filter((item) => {
                      return item.permCheck !== undefined
                        ? this.props.store.permCheckStore.permCheckFunc({
                            type: item.permCheck,
                            minimumRole: "Collaborator",
                            id: item.id,
                          })
                        : true;
                    })
                    .map((item) => {
                      const menuItem =
                        this.whatsonList[
                          this.whatsonList.findIndex(
                            (element) => element.name === item.name
                          )
                        ];

                      if (!menuItem) {
                        return null;
                      }

                      return (
                        <WhatsonOption
                          key={menuItem.id}
                          html={
                            <div className="one-list-option">
                              {menuItem.name}
                            </div>
                          }
                          onClick={() => {
                            this.props.store.routerStore.navTo(
                              level,
                              {
                                nav: menuItem.name
                                  .toLowerCase()
                                  .replace(" ", "_"),
                                sidebar: "",
                                displayName: menuItem.name,
                              },
                              () => {
                                this.optionClicked({
                                  ...menuItem,
                                  index: menuItem.id,
                                });
                              }
                            );
                          }}
                          selected={menuItem.selected}
                          draggable={false}
                        />
                      );
                    })}
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}
export default withAllOfStore(WhatsonSidebar);
