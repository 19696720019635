import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cloneDeep from "lodash.clonedeep";
import React, { useState } from "react";
import "./GridTemplateChoose.scss";

export const theGalleryTemplates = [
  // One
  [{}],
  // Two
  [{ name: "Template 1" }],
  // Three
  [{ name: "Template 1" }],
  // Four
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Five
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Six
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Seven
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Eight
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Nine
  [{ name: "Template 1" }],
  // Ten
  [{ name: "Template 1" }],
];

export const theRelatedTemplates = [
  // One
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Two
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Three
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Four
  [{ name: "Template 1" }, { name: "Template 2" }],
  // Five
  [{ name: "Template 1" }, { name: "Template 2" }],
];

export default function GridTemplateChoose(props) {
  // const[templIndex,setTemplIndex] = useState(props.data.chosenPreset);
  const { onlyTemplates, values, setValues, hideGridTypes } = props;
  const [maxImageAmount, setMaxImage] = useState(10);

  // useEffect(()=>{
  //     returnV(templIndex);
  //     // eslint-disable-next-line
  // },[templIndex])
  const mAmount = Math.min(props.values.media.length ?? 0, maxImageAmount);

  const amountArr = new Array(mAmount).fill("");

  const grid_type = values.grid_type;

  const gridTypeChange = (type) => {
    let copyValues = cloneDeep(values);

    if (type === "gallery") {
      if (copyValues.media.length > 10) {
        // copyValues.media.length = 10;
        // copyValues.languages.length = 10;
      }
      setMaxImage(10);
      if (theGalleryTemplates[mAmount - 1] === undefined) {
        copyValues.preset_number = 1;
      } else if (
        theGalleryTemplates[mAmount - 1][values.preset_number - 1] === undefined
      )
        copyValues.preset_number = 1;
    }
    if (type === "related") {
      if (copyValues.media.length > 5) {
        // copyValues.media.length = 5;
        // copyValues.languages.length = 5;
      }
      setMaxImage(5);

      if (theRelatedTemplates[mAmount - 1] === undefined) {
        copyValues.preset_number = 1;
      } else if (
        theRelatedTemplates[mAmount - 1][values.preset_number - 1] === undefined
      )
        copyValues.preset_number = 1;
    }

    setValues({ ...copyValues, grid_type: type });
  };

  return (
    <div className="grid-template-choose">
      {props.close ? (
        <div className="close" onClick={() => props.close()}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      ) : (
        ""
      )}
      <div className="content">
        {!onlyTemplates ? <h2>Select the desired template</h2> : ""}
        <div className="templates">
          {!hideGridTypes && (
            <div className="grid-types">
              <div
                className={
                  "grid-type" + (grid_type === "gallery" ? " active" : "")
                }
                onClick={() => {
                  gridTypeChange("gallery");
                }}
              >
                Gallery
              </div>
              <div
                className={
                  "grid-type" + (grid_type === "related" ? " active" : "")
                }
                onClick={() => {
                  gridTypeChange("related");
                }}
              >
                Related
              </div>
            </div>
          )}
          {mAmount > 0
            ? (grid_type === "related"
                ? theRelatedTemplates
                : theGalleryTemplates)[mAmount - 1].map((template, i) => {
                return (
                  <div
                    key={i}
                    className={
                      "template " +
                      (i === values.preset_number - 1 ? "selected " : "")
                    }
                    onClick={() => {
                      // setTemplIndex(i+1)
                      setValues({ ...values, preset_number: i + 1 });
                    }}
                  >
                    <div
                      className={
                        (grid_type === "gallery" ? "media-grid" : "") +
                        (grid_type === "related" ? "media-grid-related" : "") +
                        " choice"
                      }
                    >
                      <div
                        className={
                          "grid-container grid-amount" +
                          mAmount +
                          "-pre" +
                          (i + 1)
                        }
                      >
                        {amountArr.map((e, i) => {
                          return (
                            <div key={i} className={"item item" + (i + 1)} />
                          );
                        })}
                      </div>
                    </div>
                    <div className="text">{template.name}</div>
                  </div>
                );
              })
            : ""}
        </div>
        {!onlyTemplates ? (
          <div className="final-options">
            <div
              className={"option select"}
              onClick={() => {
                props.return(values.preset_number);
                props.close();
              }}
            >
              Select
            </div>
            <div className="option cancel" onClick={() => props.close()}>
              Cancel
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
