import Modal from "src/Components/Modal";
import { useState, useEffect } from "react";
import Select from "react-select";
import Preloader from "src/assets/Preloader";
import Button from "src/Components/Buttons/Button";
import axios from "axios";
import env from "../../../environment.json";
import { AddProductType, BrandPTResponse, Brands, SelectOption } from "./types";
import Validator from "src/utils/Validator";
import shopConfig from "./shopConfig";

export default function AddProduct({ onClose, onAdd } : AddProductType ) : JSX.Element {
	// Product info
	const [ productInfo, setProductInfo ] = useState({name:'', description:''});
	// Component is loading
	const [isLoading, setIsLoading ] = useState<boolean>(true);
	// Ready to create
	const [ canCreate, setCanCreate] = useState<boolean>(false);
	// Product type select options
	const [ productTypeOptions, setProductTypeOptions ] = useState<Array<SelectOption>>([]);
	// Selected product type
	const [ selectedProductType, setSelectedProductType ] = useState<SelectOption | null>(null);
	// Brand select options
	const [ brandOptions, setBrandOptions ] = useState<Array<SelectOption>>([]);
	// Selected brand
	const [ selectedBrand, setSelectedBrand ] = useState<SelectOption | null>(null);
	// All brands on system
	const [ allBrands, setAllBrands ] = useState<Brands>([]);
	

	// Get brands and product types from system
	useEffect(() => {
		// Get the brands and product types from the system
		axios
			.get(
				env.protocol + env.env + '/api/secured/shop/GetBrandsAndProductTypes',
			)
			.then( response => {
				const data = response.data as BrandPTResponse;
				// Set the product type options
				const options = data.productTypes.map( productType => {
					return {label:productType.name, value:productType.UUID};
				});
				setProductTypeOptions(options);
				setAllBrands(data.brands);
				setIsLoading(false);
			})
			.catch( error => {
				console.error(error);
				setIsLoading(false);
			});
	},[]);

	useEffect(() => {
		// If there are no product type options, we don't need to do anything here
		if(selectedProductType === null) return;

		// Build options for the brand select
		const options = allBrands.map( brand => {
			if(brand.productTypeID === selectedProductType.value){
				return {label:brand.name, value:brand.UUID};
			}
		}).filter( item => item !== undefined) as Array<SelectOption>;
		
		// Set the selected brand options and resset selected brand
		setBrandOptions(options);
		setSelectedBrand(null);
		
	},[selectedProductType]);

	// Whenever a value changes, validate and check whether we can create the product
	useEffect(() => {
		setCanCreate(get_can_create());
	},[selectedProductType, selectedBrand, productInfo]);

	/**
	 * Check whether we can create the product (all required values are set and valid)
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function get_can_create() : boolean{
		
		// If no product type has been selected, can't create
		if(selectedProductType === null) return false;
		
		// If no brand has been selected, can't create
		if(selectedBrand === null) return false;
		
		// Validate product name input
		const nameValidation = new Validator(productInfo.name)
			.not_empty()
			.min_chars(5)
			.max_chars(80)
			.get_success();
		if(!nameValidation) return false;

		// Validate product description input
		const descriptionValidation = new Validator(productInfo.description)
			.min_chars(5)
			.max_chars(15000)
			.get_success();
		if(!descriptionValidation) return false;

		// No validation fails, we can create the product
		return true;
	}

	function create_product(){
		// Validate brand and product are set (for typescript)
		if(!selectedBrand || !selectedProductType) return;

		setIsLoading(true);

		axios
			.post(
				env.protocol + env.env + '/api/secured/shop/CreateProduct',
				{
					name:productInfo.name,
					description:productInfo.description,
					brandUUID:selectedBrand.value,
					productTypeID:selectedProductType.value,
					siteID:shopConfig.siteID,
				}
			)
			.then( response => {
				onAdd(response.data);
				setIsLoading(false);
			})
			.catch( error => {
				setIsLoading(false);
				console.error(error);
			});
	}

	function render_modal_buttons(){

		return(
			<>
				<Preloader show={isLoading} />
				<Button
					type="secondary"
					onClick={onClose}
				>
					Cancel
				</Button>
				<Button
					onClick={create_product}
					disabled={!canCreate}
				>
					Create
				</Button>
			</>
		);
	}
	return (
		<Modal 
			title="Add product"
			type="custom"
			close={onClose}
			buttons={render_modal_buttons()}
		>
			<div>
				<label>Product type</label>
				<Select
					options={productTypeOptions}
					isDisabled={isLoading}
					value={selectedProductType}
					onChange={ e => {
						setSelectedProductType(e);
					}}
				/>
				<br />
				<label>Brand</label>
				<Select
					options={brandOptions}
					isDisabled={selectedProductType === null}
					value={selectedBrand}
					onChange={e => {
						setSelectedBrand(e);
					}}
				/>
				<br />
				<label>Product name</label>
				<input 
					type="text"
					value={productInfo.name}
					disabled={isLoading}
					maxLength={80}
					onChange={ e => {setProductInfo({...productInfo, name:e.target.value})}}
				/>
				<br />
				<br />
				<label>Description</label>
				<textarea
					value={productInfo.description}
					disabled={isLoading}
					onChange={ e => {setProductInfo({...productInfo, description:e.target.value})}}
				></textarea>
			</div>
		</Modal>
	);
}