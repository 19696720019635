import { useState } from "react";
import RegisterTable from "./RegisterTable";
import LoginTable from "./LoginTable";
import { defaultLoginInfo } from "./CreateWhatson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserPlus } from "@fortawesome/pro-regular-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import env from "../../environment.json";

interface Props {
  loginSuccess: () => {};
  navTo: string;
}

export default function LoginOrRegister({ loginSuccess, navTo }: Props) {
  const [register, setRegister] = useState<boolean>(false);
  const [loginInfo, setLoginInfo] = useState<any>({ ...defaultLoginInfo });

  const login = (e: any) => {
    // this.setState({ loading: true });

    axios
      .post(env.protocol + env.env + "/api/public/login.php", loginInfo)
      .then((response) => {
        if (response.data.loginSuccess) {
          sessionStorage.setItem("vfiUser", response.data.id);
          loginSuccess();
        } else {
          // this.setState({ loading: false });
          // this.setState({ error: "Email or password is incorrect" });
        }
      });
  };

  return (
    <div className="whatson-contents whatson-common">
      <div className="content">
        {register ? (
          <RegisterTable
            loginInfo={loginInfo}
            redirect={navTo}
            editloginInfo={(key: any, value: any) => {
              loginInfo[key] = value;
              setLoginInfo(loginInfo);
            }}
            backward={() => {
              setLoginInfo({ ...defaultLoginInfo });
            }}
            forward={() => {}}
            setId={() => {}}
            defaultLogin={(id: string) => {
              // sessionStorage.setItem("vfiUser", id);

              // loginSuccess();

              setLoginInfo({ ...defaultLoginInfo });
              setRegister(false);
            }}
          />
        ) : (
          <LoginTable
            loginInfo={loginInfo}
            redirect={navTo}
            editloginInfo={(key: any, value: any) => {
              loginInfo[key] = value;
              setLoginInfo(loginInfo);
            }}
            backward={() => {
              setLoginInfo({ ...defaultLoginInfo });
            }}
            forward={() => {}}
            setId={() => {}}
            defaultLogin={(id: string) => {
              sessionStorage.setItem("vfiUser", id);

              loginSuccess();
            }}
          />
        )}
      </div>

      <a
        className="register-login"
        onClick={() => {
          setRegister(!register);
        }}
      >
        <p>
          {register
            ? "ALREADY HAVE A USER? LOGIN IN HERE"
            : "DON'T HAVE A USER? REGISTER HERE"}
        </p>
        <FontAwesomeIcon
          icon={register ? (faUser as IconProp) : (faUserPlus as IconProp)}
        />
      </a>
    </div>
  );
}
