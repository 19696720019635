import {
  faArrowLeftLong,
  faList,
  faMagnifyingGlass,
  faReceipt,
  faRotateRight,
} from "@fortawesome/pro-light-svg-icons";
import { faSliders } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import env from "../../../environment.json";
import "./Shop.scss";
import {
  capitalize,
  searchFilter,
  showDate,
} from "../../../assets/helperFunctions";
import Payment from "./payment/Payment";
import axios from "axios";
import { cloneDeep } from "lodash";

const filterObj = { search: "" };
export default function Payments({
  close,
  data,
  setData = () => void 0,
  allProducts,
  setLoading = () => void 0,
  refresh = () => void 0,
  siteSelected,
}) {
	console.log(allProducts);
  const [filterState, setFilterState] = useState(false);
  const [viewState, setViewState] = useState("order");
  const [searchHidden, setSearchHidden] = useState(false);
  const [filters, setFilters] = useState(filterObj);
  const [selectedPayment, setSelectedPayment] = useState(undefined);
  const [editedPayment, setEditedPayment] = useState(undefined);
  const [headerState, setHeaderState] = useState("default");
  const [filteredData, setFilteredData] = useState([...data]);

  useEffect(() => {
    if (viewState === "specific_order") {
      setSearchHidden(true);
      setHeaderState("editable");
    } else {
      setSearchHidden(false);
      setHeaderState("default");
    }
  }, [viewState]);

  useEffect(() => {
    if (headerState === "in-edit") {
      setEditedPayment(cloneDeep(selectedPayment));
    }
  }, [headerState]);

  useEffect(() => {
    let nFilteredData = [...data].reverse();
    nFilteredData = searchFilter(nFilteredData, filters.search);
    setFilteredData(nFilteredData);
  }, [filters, data]);

  const filter = () => {
    setFilterState(filterState ? false : true);
  };
  const view = (e) => {
    setViewState(e);
  };
	
  const apiData = editedPayment
    ? {
        info: {
          id: editedPayment.ID,
          firstName: editedPayment.firstName,
          lastName: editedPayment.lastName,
          email: editedPayment.email,
          phone: editedPayment.phone,
          licensePlate: editedPayment.licensplate,
          street: editedPayment.address,
          area: editedPayment.city,
          zip: editedPayment.zip,
          deliveryStreet: editedPayment.deliveryAddress,
          deliveryArea: editedPayment.deliveryCity,
          deliveryZip: editedPayment.deliveryZip,
          comment: editedPayment.comments,
          basket: editedPayment.binds.map((b) => {
            return {
              ...b,
              count: b.amount,
              sellingPrice: b.price_pr,
              isInStock: parseInt(b.storage),
              parrentID: b.product_info.product_id,
            };
          }),
          totalDKK: editedPayment.binds.reduce(
            (acc, cur) => acc + cur.amount * cur.price_pr,
            0
          ),
        },
        orderProducts: allProducts.filter((p) =>
          editedPayment.binds
            .map((payment) => payment.product_info.spP_id)
            .includes(parseInt(p.productId))
        ),
        services: editedPayment.binds
          .filter((b) => b.service_id)
          .map((b) => {
            const {
              amount,
              price_pr,
              service_info: { Title, Description },
            } = b;
            return {
              name: Title,
              description: Description,
              count: amount,
              price: price_pr,
            };
          }), // Needs to be populated correctly..
        inUpdatePhase: true,
      }
    : {};

  const { domain_name, protocol } = siteSelected;

  const savePayment = () => {
    setLoading(true);
    axios
      .post(env.protocol + env.env + "/api/public/shop/placeOrder.php", {
        ...apiData,
        orderType: "changeOrder",
        orderNo: selectedPayment.ID,
        manualBilling: selectedPayment.paymentStatus === "manual",
      })
      .then((response) => {
        if (response.data.success) {
          const index = data.findIndex((d) => d.ID === editedPayment.ID);
          if (index !== -1) {
            data[index] = editedPayment;
            data[index].payments.push({
              id: "new" + new Date().getTime(),
              date: new Date(),
              name: response.data.newFileName,
            });
            setData([...data]);
          }
          setSelectedPayment(editedPayment);
          setHeaderState("editable");
        }
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const sendLatestOrder = () => {
    setLoading(true);
    axios
      .post(env.protocol + env.env + "/api/secured/shop/sendLatestOrder", {
        ...apiData,
        from: "no-reply@cms.vita.fo",
        to: apiData.info.email,
        subject: "Bílegging",
      })
      .then((response) => {
        if (response.data.success) {
          const index = data.findIndex((d) => d.ID === editedPayment.ID);
          if (index !== -1) {
            data[index] = editedPayment;
            data[index].payments.push({
              id: "new" + new Date().getTime(),
              date: new Date(),
              name: response.data.newFileName,
            });
            setData([...data]);
          }
          setSelectedPayment(editedPayment);
          setHeaderState("editable");
        }
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const changeOrderValue = (key, value) => {
    setLoading(true);

    axios
      .post(
        env.protocol + env.env + "/api/secured/shop/changeShopOrderValues",
        {
          id: selectedPayment.ID,
          label: key,
          value,
        }
      )
      .then((response) => {
        const index = data.findIndex((d) => d.ID === editedPayment.ID);
        if (index !== -1) {
          data[index] = { ...editedPayment, ...response.data.newObj };
          setData([...data]);
        }
        setSelectedPayment({ ...data[index] });
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const changeEditData = (keys, value) => {
    keys = keys.split(".");
    let editedPaymentDeep = editedPayment;
    for (let i = 0; i < keys.length; i++) {
      if (i !== keys.length - 1) {
        // GO DEEPER
        editedPaymentDeep = editedPaymentDeep[keys[i]];
      } else {
        // ASSIGN
        editedPaymentDeep[keys[i]] = value;
      }
    }
    setEditedPayment({ ...editedPayment });
  };

  const makeTransaction = (orderId) => {
    setLoading(true);
    axios
      .post(env.protocol + env.env + "/api/secured/quickpay/MakeTransaction", {
        orderId,
      })
      .then((response) => {
        if (response.data.success) {
          setEditedPayment({ ...editedPayment, paymentStatus: "done" });
          setSelectedPayment({ ...selectedPayment, paymentStatus: "done" });
        }
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

  /**
   * Set order payment status to done
   *
   * @param 	{int} 	orderId 				ID of order to mark as paid
   *
   * @author 					Pætur Mortensen
   */
  function mark_as_paid(orderId) {
    // Show loader
    setLoading(true);
    // POST to backend
    axios
      .post(env.protocol + env.env + "/api/secured/shop/setOrderPaid", {
        orderId,
      })
      .then((response) => {
        // Update the payment data
        if (response.data.success) {
          setEditedPayment({ ...editedPayment, paymentStatus: "done" });
          setSelectedPayment({ ...selectedPayment, paymentStatus: "done" });
        }
        setLoading(false);
      })
      .catch((error) => console.error(error));
  }

  return (
    <div className="payments list">
      <div className="top">
        <div>
          <div className="title">
            <div
              onClick={() => {
                if (viewState === "specific_order") {
                  setViewState("order");
                } else {
                  close();
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
            </div>
            <h1>Payments</h1>
          </div>
          <div className="right-side">
            {headerState === "default"
              ? ""
              : headerState === "editable"
              ? [
                  !["done"].includes(selectedPayment.paymentStatus) && (
                    <button
                      key={"edit-button"}
                      className={"edit-button"}
                      onClick={() => setHeaderState("in-edit")}
                    >
                      Edit
                    </button>
                  ),
                  // <button key="open-pdf" className="open-pdf">
                  //   Print Order
                  // </button>,
                  <button
                    key="send-pdf"
                    className="send-pdf"
                    onClick={() => {
                      sendLatestOrder();
                    }}
                  >
                    Send Latest Order
                  </button>,
                  <button
                    key="approve"
                    className="approve"
                    onClick={() => {
                      changeOrderValue("orderStatus", "approved");
                    }}
                    disabled={selectedPayment.orderStatus === "approved"}
                  >
                    Approve
                  </button>,
                  <button
                    key="deny"
                    className="deny"
                    onClick={() => {
                      changeOrderValue("orderStatus", "denied");
                    }}
                    disabled={selectedPayment.orderStatus === "denied"}
                  >
                    Deny
                  </button>,
                  selectedPayment.paymentStatus === "ready" && (
                    <button
                      key="send-payment-with-email"
                      className="send-payment-with-email"
                      onClick={() => {
                        makeTransaction(selectedPayment.ID);
                      }}
                    >
                      Make Transaction
                    </button>
                  ),
                  selectedPayment.paymentStatus === "manual" &&
                    selectedPayment.orderStatus === "approved" && (
                      <button
                        key="manual-payment-done"
                        className="send-payment-with-email"
                        onClick={() => {
                          mark_as_paid(selectedPayment.ID);
                        }}
                      >
                        Mark as paid
                      </button>
                    ),
                ]
              : headerState === "in-edit"
              ? [
                  <button
                    key={"discard-button"}
                    className={"discard-button"}
                    onClick={() => setHeaderState("editable")}
                  >
                    Discard
                  </button>,
                  <button
                    key={"save-button"}
                    className={"save-button"}
                    onClick={() => {
                      savePayment();
                    }}
                  >
                    Save
                  </button>,
                ]
              : ""}
          </div>
        </div>
        <div className={"search-section " + (searchHidden && "hidden")}>
          <div className="search">
            <input
              type={"text"}
              placeholder="Search payments..."
              onChange={(e) => {
                setFilters({ ...filters, search: e.target.value });
              }}
            ></input>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </div>
          <div className="wrap-filter">
            <div className="refresh-button" onClick={refresh} title="REFRESH">
              <FontAwesomeIcon icon={faRotateRight} />
            </div>
            <div className={viewState + " views"}>
              <div
                onClick={() => {
                  view("order");
                }}
              >
                <FontAwesomeIcon icon={faReceipt} />
              </div>
              <div
                onClick={() => {
                  view("list");
                }}
              >
                <FontAwesomeIcon icon={faList} />
              </div>
            </div>
            <div
              onClick={() => {
                filter();
              }}
              className={(filterState && "open") + " filter"}
            >
              Filter
              <FontAwesomeIcon icon={faSliders} />
            </div>
          </div>
        </div>
      </div>
      {viewState === "order" && (
        <div className="order">
          <table>
            <tbody>
              <tr>
                <th>Order Id</th>
                <th>Name</th>
                <th>Type</th>
                <th>Products</th>
                <th>Price</th>
                <th>Status</th>
                <th>Dato</th>
              </tr>
              {filteredData.map((element) => {
                const productsAmount = element.binds.filter(
                  (e) => e.product_id
                ).length;
                const servicesAmount = element.binds.filter(
                  (e) => e.service_id
                ).length;
                return (
                  <tr
                    key={element.ID}
                    onClick={() => {
                      setSelectedPayment(element);
                      setEditedPayment(cloneDeep(element));
                      setViewState("specific_order");
                    }}
                  >
                    <td>{element.ID}</td>
                    <td>
                      <div className="wrap-name">
                        {`${element.firstName} ${element.lastName}`}
                      </div>
                    </td>
                    <td>Order</td>
                    <td>
                      Products:{productsAmount} Services:{servicesAmount}
                    </td>
                    <td>
                      {element.binds.reduce(
                        (acc, cur) => acc + parseInt(cur.price_pr) * cur.amount,
                        0
                      )}{" "}
                      kr
                    </td>
                    <td>
                      <span className={"status " + element.orderStatus}>
                        {capitalize(element.orderStatus)}
                      </span>
                    </td>
                    <td>{showDate(element.date)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      
      {viewState === "specific_order" && (
        <Payment
          key={headerState}
          data={selectedPayment}
          setData={setData}
          close={() => {
            setViewState("order");
          }}
          editable={headerState === "in-edit"}
          giveChangedData={changeEditData}
        />
      )}
    </div>
  );
}
