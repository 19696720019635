import PropType from "prop-types";
import styles from "./SidebarElement.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

/**
 *  How to use this component proppely:
 *  ```
 *  ```
 *  Make sure the styling gives this component the propper placement on the screen.
 *  ```
 *  ```
 *  Use `h1` for title
 *  ```
 *  ```
 *  Use `p` for small labels
 *  ```
 *  ```
 *  Use `button` for options, and add active parameter to "true"(string), when intention is that it is selected.
 *
 * @param {ReactChildren} children
 * @returns
 */
function OverlayElement({ children }) {
  return <div className={styles.sidebarElement}>{children}</div>;
}

OverlayElement.propTypes = {
  children: PropType.element,
};

export default OverlayElement;
