//All sliders, including grids are in this EditModule
import { useEffect, useState } from "react";
import "./EditModule.scss";
import { VfiCheckbox2 } from "../../VfiCheckbox";
import GridTemplateChoose from "../../../Components/Content/Overlay/GridTemplateChoose/GridTemplateChoose";
import { cloneDeep } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import OneMedia from "../OneMedia";
import noImage from "../../images/empty.jpg";

export default function EditModule(props) {
  const { values, change } = props;
  const [screen, setScreen] = useState("media");
  const [innerValues, setInnerValues] = useState(values);
  const [draggingIndex, setDraggingIndex] = useState(undefined);

  const setValues = (v) => {
    change(v);
    setInnerValues(v);
  };
  useEffect(() => {
    setInnerValues(values);
  }, [values]);

  let content = <p>Shouldn't be shown..</p>;
  switch (screen) {
    case "media":
      content = (
        <div className="media">
          {innerValues.data.map((element, i) => {
            return (
              <div className="medium" key={i + "|" + element.id}>
                <div
                  className="remove"
                  onClick={() => {
                    let copyData = cloneDeep(innerValues.data);
                    let copyLanguages = cloneDeep(innerValues.languages);
                    copyData.splice(i, 1);
                    copyLanguages.splice(i, 1);
                    setValues({
                      ...innerValues,
                      data: copyData,
                      languages: copyLanguages,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
                <OneMedia
                  media={{
                    src: element.media.medium
                      ? element.media.medium.medium
                      : noImage,
                  }}
                  disableChange={true}
                  imgFormat="thumbnail"
                  videoThumbnail={true}
                  draggable={true}
                  onDragStart={(e) => {
                    setDraggingIndex(i);
                  }}
                  onDragEnd={(e) => {
                    setDraggingIndex(undefined);
                  }}
                  onDrop={(e) => {
                    if (draggingIndex !== undefined) {
                      let copyData = cloneDeep(innerValues.data);
                      let copyLanguages = cloneDeep(innerValues.languages);
                      const draggedMedium = innerValues.data[draggingIndex];
                      copyData.splice(draggingIndex, 1);
                      copyData.splice(i, 0, draggedMedium);
                      const draggedLanguageObj =
                        innerValues.languages[draggingIndex];
                      copyLanguages.splice(draggingIndex, 1);
                      copyLanguages.splice(i, 0, draggedLanguageObj);
                      setValues({
                        ...innerValues,
                        data: copyData,
                        languages: copyLanguages,
                      });
                      setDraggingIndex(undefined);
                    }
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            );
          })}
          {/* <div className="drag-n-drop">
            {uploaded === 100 ? (
              <Dropzone
                onDrop={(e) => {
                  uploadFiles(e);
                  setDraggingOverUpload(false);
                }}
                onDragEnter={() => {
                  setDraggingOverUpload(true);
                }}
                onDragLeave={() => {
                  setDraggingOverUpload(false);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        accept=".jpg,.jpeg,.png,.gif,.mp4"
                        maxLength={1}
                      />
                      <div
                        className={
                          "upload-box" +
                          (draggingOverUpload ? " drag-over" : "")
                        }
                      >
                        <div className="text-content">
                          <FontAwesomeIcon icon={faArrowToTop} />
                          <div>UPLOAD OR FIND IMAGES/VIDEOS</div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            ) : (
              <div className="upload-bar">
                <div
                  className="progress-bar"
                  style={{
                    width: uploaded + "%"
                  }}
                />
              </div>
            )}
          </div>
          <div
            className="add"
            onClick={() => {
              if (uploaded === 100) {
                props.setModuleKeyInEdit("media_add");
                props.openOverlay("mediaChoose", {
                  filter: { mediaType: ["images", "videos"] },
                  singleMedia: false
                });
                props.close();
              }
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </div> */}
        </div>
      );
      break;

    case "design":
      content = (
        <div className="design">
          <div>
            <div
              className="option whole-click"
              onClick={() => {
                setValues({ ...innerValues, is_hero: !innerValues.is_hero });
              }}
            >
              <div className="label">Fullwidth</div>
              <div className="value">
                <VfiCheckbox2
                  style={{ pointerEvents: "none" }}
                  checked={innerValues.is_hero}
                  readOnly={true}
                  isRadio={true}
                />
              </div>
            </div>
            <div
              className="option whole-click"
              onClick={() => {
                setValues({
                  ...innerValues,
                  show_icons: innerValues.show_icons ? false : true,
                });
              }}
            >
              <div className="label">Show icons</div>
              <div className="value">
                <VfiCheckbox2
                  style={{ pointerEvents: "none" }}
                  checked={innerValues.show_icons}
                  readOnly={true}
                  isRadio={true}
                />
              </div>
            </div>
          </div>
          {values.type === "media_grid" ? (
            <div>
              <GridTemplateChoose
                onlyTemplates={true}
                data={{
                  mediaAmount: innerValues.data.length,
                  chosenPreset: innerValues.preset_number,
                }}
                returnV={(index) => {
                  setValues({ ...innerValues, preset_number: index });
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <div className="visit-faroe-islands-top edit-module">
      <div className="top">
        <div
          className={"top-choice " + (screen === "media" ? "active " : "")}
          onClick={() => setScreen("media")}
        >
          MEDIA
        </div>
        <div
          className={"top-choice " + (screen === "design" ? "active " : "")}
          onClick={() => setScreen("design")}
        >
          DESIGN
        </div>
      </div>
      <div className="content">{content}</div>
    </div>
  );
}
