import { faList, faPause } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Select from "react-select";
import "./MainWhatson.scss";
import WhatsonLeaflet from "./WhatsonShowTypes/WhatsonLeaflet";
import languages from "./language.json";
import WhatsonList from "./WhatsonShowTypes/WhatsonList";
import env from "../../../environment.json";
import axios from "axios";
import VfiInputText from "../../VfiInputText/VfiInputText";
import ToolTip from "../../../Components/ToolTips/ToolTip";

const l = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export default function MainWhatson(props) {
  const { data, onChange, allWhatson, whatsonTypes } = props;
  const [showAs, setShowAs] = useState("leaflet");
  const [whatsonFiltered, setWhatsonFiltered] = useState([]);
  // const [whatsonTypes,setWhatsonTypes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [categories, setCategories] = useState([]);
  const language = languages[l];

  // useEffect(() => {
  // }, []);

  useEffect(() => {
    if (data.whatWhatson !== undefined) {
      const sayWhat = whatsonTypes.find((e) => e.id === data.whatWhatson);
      if (sayWhat !== undefined) {
        if (sayWhat.name === "Events") {
          setWhatsonFiltered(allWhatson.filter((e) => e.type === "event"));
        }
        if (sayWhat.name === "Tours") {
          setWhatsonFiltered(allWhatson.filter((e) => e.type === "tour"));
        }
        if (sayWhat.name === "Hiking") {
          setWhatsonFiltered(allWhatson.filter((e) => e.type === "hike"));
        }
        if (sayWhat.name === "Places") {
          setWhatsonFiltered(allWhatson.filter((e) => e.type === "place"));
          axios
            .get(
              env.protocol + env.env + "/api/whatson/places/GetPlaceCategories"
            )
            .then((response) => {
              setCategories(response.data);
            })
            .catch((error) => console.error(error));
        }
      }
    }
  }, [data.whatWhatson, whatsonTypes, allWhatson]);

  const whatsonType = whatsonTypes.find((type) => type.id === data.whatWhatson);
  const typeSelected = {
    label: whatsonType ? whatsonType.name : "Select...",
    value: whatsonType ? whatsonType.id : 0,
  };

  return (
    <div className="main-whatson">
      <div
        className={(showAs === "list" ? "header" : "header absolute") + " row"}
      >
        <div className="left col-lg-10">
          <span className="title">{language.main_whatson.header.title}</span>
          {/* {<Select
                className="language-select"
                options={availableLanguages}
                value={selectedLanguage}
                onChange={
                  (e)=>{
                    setSelectedLanguage(e);
                  }
                }
                isClearable={true}
              />} */}
          {showAs === "list" && (
            <Select
              className="whatson-select"
              options={whatsonTypes.map((type) => {
                return { label: type.name, value: type.id };
              })}
              value={typeSelected}
              onChange={(e) => {
                onChange({ ...data, whatWhatson: e.value });
              }}
            />
          )}
          {showAs === "list" && (
            <VfiInputText
              value={searchValue}
              placeholder="Search media..."
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              isSearch={true}
            />
          )}
        </div>
        <div className="right col-lg-2">
          <div
            className={
              "pages-show-option" + (showAs === "leaflet" ? " selected" : "")
            }
            onClick={() => {
              // setSelectedLanguage(undefined);
              setShowAs("leaflet");
            }}
          >
            <FontAwesomeIcon icon={faPause} />
          </div>
          <div
            className={
              "pages-show-option" + (showAs === "list" ? " selected" : "")
            }
            onClick={() => {
              // setSelectedLanguage(undefined);
              setShowAs("list");
            }}
          >
            <FontAwesomeIcon icon={faList} />
          </div>
        </div>
      </div>

      {showAs === "leaflet" && (
        <WhatsonLeaflet
          data={data}
          onChange={onChange}
          allWhatson={/*allWhatson*/ whatsonFiltered}
          whatsonTypes={whatsonTypes}
          categories={categories}
        />
      )}
      {showAs === "list" && (
        <WhatsonList
          data={data}
          onChange={onChange}
          allWhatson={/*allWhatson*/ whatsonFiltered}
          whatsonTypes={whatsonTypes}
          search={searchValue}
        />
      )}
    </div>
  );
}
