import React, { Component } from "react";
import "./filter.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faAngleDown,
  faCalendar,
} from "@fortawesome/pro-light-svg-icons";
import VfiInputText from "../../assets/VfiInputText/VfiInputText";
import VfiCheckbox from "../../assets/VfiCheckbox/VfiCheckbox";
import DatePicker from "react-datepicker";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: undefined,
      endDate: undefined,
    };

    this.toggle = [];
  }

  componentDidUpdate(prevProps) {
    if (this.props.addFilterDroppedDown[0] === true) {
      if (
        //prevProps.currentSite !== this.props.currentSite ||
        prevProps.contentShow !== this.props.contentShow ||
        prevProps.panel !== this.props.panel
      ) {
        this.props.SetState("addFilterDroppedDown", [false, ""]);
      }
    }
    if (this.props.filterdata !== prevProps.filterdata) {
      if (Object.keys(this.props.filterdata).length > 0) {
        this.toggle = Array(this.props.filterdata.content.length).fill(true);
        this.setState({});
      }
    }
  }

  render() {
    const filterHidden = this.props.addFilterDroppedDown[0] ? "" : "hidden";
    const searchHidden = this.props.filterdata.search ? "" : "hidden";

    if (this.props.filterdata.content) {
      return (
        <div
          className={"filter-panel " + filterHidden}
          style={this.props.filterdata.style}
        >
          <div className="padding">
            <div className="filterHeader">
              <ul className="closeAndText">
                <li
                  className="close"
                  onClick={() => {
                    this.props.openCloseFilter([false, ""], {});
                  }}
                >
                  <p className="icon">
                    <FontAwesomeIcon icon={faTimes} />
                  </p>
                </li>
                <li className="text">
                  {" "}
                  {"Filter " + this.props.filterdata.type}
                </li>
              </ul>
              <div className={"search-parent " + searchHidden}>
                <div className="search">
                  <VfiInputText
                    value={this.props.filterdata.search.searchValue}
                    placeholder="Search..."
                    onChange={(e) => {
                      this.props.filterdata.executeSearch(e);
                      // this.props.SetState("searchValue", e.target.value);
                      // this.props.SetState("filterdata.searchValue", e.target.value);
                    }}
                    isSearch={true}
                  />
                </div>
              </div>
            </div>

            {this.mapContent(this.props.filterdata.content)}

            {/* Only show list to filter for if isset in props */}
            {/* {this.props.filterdata.listToMap !== undefined ?
              <div className="filterSite">
                <div className="dropAndText">
                  <p className="text"> {this.props.filterdata.listToMapName}</p>
                  <FontAwesomeIcon icon={faAngleDown} className={"drop " + toggle2} onClick={
                    () => {
                      this.setState({ toggle2: !this.state.toggle2 })
                    }
                  } />
                </div>

                <ul className={"sites " + toggle2}>
                  <li className={"checkbox "}>
                    <VfiCheckbox
                      checked={this.props.checkboxFilter.filter.includes("all")}
                      onChange={(e) => this.filterChange("filter.all", e.target.checked)}
                    />
                  </li>
                  <li className="listsite">
                    {"All " + this.props.filterdata.listToMapName.toLowerCase()}
                  </li>
                </ul>
                {this.mapList()}
              </div> :
              ""} */}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  mapContent = (data) => {
    var content = data.map((element, i) => {
      if (element.disable) {
        return "";
      }
      return (
        <div key={i} className={"filterSite"}>
          <div className="dropAndText">
            <p className="text"> {element.headline}</p>
            <FontAwesomeIcon
              icon={faAngleDown}
              className={"drop " + (this.toggle[i] ? "" : "hidden")}
              onClick={() => {
                this.toggle[i] = !this.toggle[i];
                this.setState({});
              }}
            />
          </div>
          <div>{this.mapList(element, i)}</div>
        </div>
      );
    });

    return content;
  };

  mapList(data, j) {
    var list = data.value.map((element, i) => {
      if (data.style === "checkbox") {
        return (
          <ul
            key={`filterjs-list-${i}`}
            className={"sites " + (this.toggle[j] ? "" : "hidden")}
          >
            <li className={"checkbox "}>
              <VfiCheckbox
                name={element.text}
                checked={element.selected}
                onChange={(e) => this.props.filterdata.executeCheckbox(e)}
              />
            </li>
            <li className="listsite">{element.text}</li>
          </ul>
        );
      } else if (data.style === "bobbleList") {
        return (
          <ul
            key={`filterjs-list-${i}`}
            className={"bobbleList " + (this.toggle[j] ? "" : "hidden")}
          >
            <li
              className={element.selected ? "listsite choosen" : "listsite"}
              onClick={() => {
                this.props.filterdata.execute(element.id);
              }}
            >
              {element.text}
            </li>
          </ul>
        );
      } else if (data.style === "date") {
        const CustomD = (props) => {
          return (
            <div
              key={`filterjs-list-${i}`}
              className="custom-datePicker-container"
            >
              <DatePicker {...props} />
              <FontAwesomeIcon icon={faCalendar} />
            </div>
          );
        };
        return (
          <div
            key={`filterjs-list-${i}`}
            className={"dates " + (this.toggle[j] ? "" : "hidden")}
          >
            <CustomD
              selected={element.startDate}
              popperPlacement="bottom-end"
              onChange={(date) => {
                this.props.filterdata.executeDate("startDate", date);
              }}
              dateFormat="dd/MM/yy"
            />
            <div className="to">to</div>
            <CustomD
              selected={element.endDate}
              popperPlacement="bottom-end"
              onChange={(date) => {
                this.props.filterdata.executeDate("endDate", date);
              }}
              dateFormat="dd/MM/yy"
            />
          </div>
        );
      }
      return element;
    });

    return list;
  }
}
export default Filter;
