import { faSlidersH } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import VfiInputText from "../../../../assets/VfiInputText/VfiInputText";
import "./IsArticlesDisplay.scss";
import IsArticleList from "./IsArticlesList";
import { searchFilter, sortList } from "../../../../assets/helperFunctions";
import { useEffect } from "react";
import env from "../../../../environment.json";
import axios from "axios";
import RightSideInfo from "../../../../assets/RightSideInfo/RightSideInfo";
import { cloneDeep } from "lodash";

const artilclesFilter = {
  search: "",
  status: "all",
};

function IsArticlesDisplay({
  articlesType,
  createIsArticle = () => void 0,
  editIsArticle = (id) => void 0,
}) {
  // const [statusFilter, setStatusFilter] = useState("all");
  const [blurScreen, setBlurScreen] = useState(false);
  const [filter, setFilter] = useState(artilclesFilter);
  const [showAmount, setShowAmount] = useState(30);
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]); // Use this to filter the list, to display.
  const [filterOpened, setFilterOpened] = useState(false);
  const [lastSort, setLastSort] = useState("");

  useEffect(() => {
    setBlurScreen(true);
    axios
      .get(
        env.protocol +
          env.env +
          "/api/public/infoscreen/infoscreenArticles/getArticlesIsList.php?type=" +
          encodeURIComponent(articlesType)
      )
      .then((response) => {
        setBlurScreen(false);
        setList(response.data);
      })
      .catch((error) => console.error(error));
  }, [articlesType]);

  const changeFilter = useCallback(
    (key, val) => {
      let keys = key.split(".");
      let innFilter = filter;
      for (let i = 0; i < keys.length - 1; i++) {
        innFilter = innFilter[keys[i]];
      }
      innFilter[keys[keys.length - 1]] = val;
      setFilter({ ...filter });
    },
    [filter]
  );

  useEffect(() => {
    let listToFilter = cloneDeep(list);

    // DO FILTER TASKS HERE..
    listToFilter = searchFilter(listToFilter, filter.search);

    listToFilter = listToFilter.filter((e) => {
      if (filter.status === "all") {
        return e.current_status !== "deleted";
      }

      return e.current_status === filter.status;
    });

    setFilteredList(listToFilter);
  }, [list, filter]);

  const sortIsList = useCallback(
    (key) => {
      // sortList
      const keys = key.split(".");
      if (lastSort === key) {
        list.reverse();
      } else {
        list.sort((e1, e2) => {
          let value1 = e1,
            value2 = e2;
          for (let i = 0; i < keys.length; i++) {
            value1 = value1[keys[i]];
            value2 = value2[keys[i]];
          }

          if (["reg_date", "create_date"].includes(keys[keys.length - 1])) {
            value1 = new Date(value1);
            value2 = new Date(value2);
          }

          return value1 > value2;
        });
      }
      setLastSort(key);
      setList([...list]);
    },
    [list]
  );

  const editContent = useCallback((e) => {
    editIsArticle(e);
  }, []);

  const approvalStatusChange = useCallback((e) => {
  }, []);

  const selectById = useCallback(
    (e) => {
      const el = list.find((l) => l.id === e);

      if (el) {
        el.selected = !el.selected;
      }

      setList([...list]);
    },
    [list]
  );

  const selectAll = useCallback(
    (e) => {
      if (e === true) {
        for (let i = 0; i < filteredList.length; i++) {
          list.find((l) => l.id === filteredList[i].id).selected = true;
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          list[i].selected = false;
        }
      }
      setList([...list]);
    },
    [list, filteredList]
  );

  const approvalRequired = useCallback((e) => {
  }, []);

  return (
    <div className="is-articles-display">
      <div className="header-panel">
        <div className="header-title">
          <h1>{articlesType}</h1>
        </div>
        <div className="status-filter-parent">
          <span
            className={
              filter.status === "all"
                ? "status-filter selected"
                : "status-filter"
            }
            onClick={() => {
              changeFilter("status", "all");
            }}
          >
            All{" "}
            <span className="status-filter-amount">

              {list.filter((e) => e.current_status !== "deleted").length}
            </span>
          </span>
          |
          <span
            className={
              filter.status === "published"
                ? "status-filter selected"
                : "status-filter"
            }
            onClick={() => {
              changeFilter("status", "published");
            }}
          >
            Published{" "}
            <span className="status-filter-amount">
              {/* {this.publishedAmount} */}
              {list.filter((e) => e.current_status === "published").length}
            </span>
          </span>
          |
          <span
            className={
              filter.status === "draft"
                ? "status-filter selected"
                : "status-filter"
            }
            onClick={() => {
              changeFilter("status", "draft");
            }}
          >
            Drafts{" "}
            <span className="status-filter-amount">
              {/* {this.draftsAmount} */}
              {list.filter((e) => e.current_status === "draft").length}
            </span>
          </span>
          |
          <span
            className={
              filter.status === "awaiting approval"
                ? "status-filter selected"
                : "status-filter"
            }
            onClick={() => {
              changeFilter("status", "awaiting approval");
            }}
          >
            Awaiting approval{" "}
            <span className="status-filter-amount">
              {/* {this.awatingApprovalAmount} */}
              {
                list.filter((e) => e.current_status === "awaiting approval")
                  .length
              }
            </span>
          </span>
          |
          <span
            className={
              filter.status === "deleted"
                ? "status-filter selected"
                : "status-filter"
            }
            onClick={() => {
              changeFilter("status", "deleted");
            }}
          >
            Deleted{" "}
            <span className="status-filter-amount">
              {list.filter((e) => e.current_status === "deleted").length}
            </span>
          </span>
        </div>
        <div className="add-new-media-parent">
          <div className="add-new-media" onClick={createIsArticle}>
            <div className="text">Add new infoscreen article</div>
          </div>
        </div>
        <div className="search-parent">
          <div className="search">
            <VfiInputText
              value={filter.search}
              placeholder="Search media..."
              onChange={(e) => {
                changeFilter("search", e.target.value);
              }}
              isSearch={true}
            />
          </div>
        </div>
        <div className="all-sort-and-filter">
          <div
            className={"filter-button " + (filterOpened ? "" : "hidden")}
            onClick={() => {
              // this.setState({
              //     //addFilterDroppedDown: !this.state.addFilterDroppedDown
              // });

              // this.props.openCloseFilter(
              //     [!this.props.addFilterDroppedDown[0], "whatsonDisplay"],
              //     {
              //         type: "whatson",
              //         search: false,
              //         style: {},
              //         content: this.filterContent,
              //         executeDate: (whatdate, date) => {
              //             // When date changes
              //             this.onDateChange(whatdate, date)
              //         }
              //     })

              setFilterOpened(!filterOpened);
            }}
          >
            <span className="text">Filter</span>
            <FontAwesomeIcon
              className="slide-icon"
              icon={faSlidersH}
              flip={"vertical"}
            />
          </div>
          {/* <div
        className={
          contentSelected.length > 0
            ? "delete-selected-button"
            : "delete-selected-button hidden"
        }
        onClick={() => {
          if (
            window.confirm(
              "Are you sure you want to delete the selected rows?"
            )
          ) {
            this.deleteSelected();
          }
        }}
      >
        Delete selected
      </div> */}
        </div>
      </div>
      {
        <IsArticleList
          list={filteredList}
          searchFilter={filter.search}
          sortList={sortIsList}
          editContent={editContent}
          approvalStatusChange={approvalStatusChange}
          selectById={selectById}
          selectAll={selectAll}
          approvalRequired={approvalRequired}
          showAmount={showAmount}
        />
      }
      <div className={"blur-screen" + (blurScreen ? "" : " hidden")} />
      <RightSideInfo
        title="Filter Articles"
        isShowing={filterOpened}
        close={() => setFilterOpened(false)}
      />
    </div>
  );
}

IsArticlesDisplay.propTypes = {
  articlesType: PropTypes.string.isRequired,
  createIsArticle: PropTypes.func,
  editIsArticle: PropTypes.func,
};

export default IsArticlesDisplay;
