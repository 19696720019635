import "../Product.scss";
import GeneralInfo from "./GeneralInfo";
import Media from "./Media";
import Attribute from "./Attribute";
import Inventory from "./Inventory";
import { uploadMedia } from "../../../../../assets/helperFunctions";
import { cloneDeep } from "lodash";

export default function InfoColumn({
	productState,
	uploaded,
	setUploaded,
	setProductState,
	draggingOverUpload,
	setDraggingOverUpload,
	draggingIndex,
	setDraggingIndex,
	props,
	setForceUpdate,
	prices,
	uuid,
	siteId,
	setEditInventory,
}) {	
	const config = {
		headers: {
			"Content-Type": "multipart/form-data",
		},
		onUploadProgress: (progressEvent) => {
			setUploaded((progressEvent.loaded * 100) / progressEvent.total);
		},
	};

	const uploadFiles = (e) => {
		uploadMedia(e, siteId, config, (res) => {
			let copyValues = cloneDeep(productState);

			res.data.forEach((element) => {
				let maxKey = 0;
				copyValues.media.forEach((element) => {
					if (element.key > maxKey) maxKey = element.key;
				});
				copyValues.media.push({
					key: maxKey + 1,
					media_id: element.id,
					alt: element.alt,
					file_name: element.file_name,
					extension: element.file_extension,
				});
			});
			setProductState(copyValues);
		});
	};

	return (
		<div className="first-column">
			<GeneralInfo productState={productState} />
			<Media
				uploaded={uploaded}
				productState={productState}
				setProductState={setProductState}
				uploadFiles={uploadFiles}
				draggingOverUpload={draggingOverUpload}
				setDraggingOverUpload={setDraggingOverUpload}
				draggingIndex={draggingIndex}
				setDraggingIndex={setDraggingIndex}
				props={props}
				setForceUpdate={setForceUpdate}
			/>
			<Attribute
				productState={productState}
				setProductState={setProductState}
				setForceUpdate={setForceUpdate}
				uploaded={uploaded}
				uploadFiles={uploadFiles}
				draggingOverUpload={draggingOverUpload}
				setDraggingOverUpload={setDraggingOverUpload}
				draggingIndex={draggingIndex}
				setDraggingIndex={setDraggingIndex}
				props={props}
			/>
			<Inventory
				productState={productState}
				prices={prices}
				uuid={uuid}
				siteId={siteId}
				setEditInventory={setEditInventory}
			/>
		</div>
	);
}
