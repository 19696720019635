import "../EditProduct.scss";
import Select from "react-select";
import { useEffect, useState } from "react";
import Button from "src/Components/Buttons/Button";
import Preloader from "src/assets/Preloader";
import axios from "axios";
import env from "../../../../../environment.json";

/**
 * Product type information
 *
 * @param 		{object} 		product 									Product state
 * @param 		{function} 	setProduct 								Set product state
 * @param 		{object} 		productConfig 						Product config information
 *
 * @returns 	{jsx} 																Product type panel
 *
 * @author 					Pætur Mortensen
 */
export default function ProductTypePanel({
	product,
	setProduct,
	productConfig,
}) {
	// Options for the brands select
	const [brandOptions, setBrandOptions] = useState([]);
	// Selected brand option
	const [ selectedBrand, setSelectedBrand ] = useState(product.brandID);
	// Whether component is loading
	const [ isLoading, setIsLoading ] = useState(false);

	// Whether brand is different from product's original brand
	const brandHasChanged = (product.brandID !== selectedBrand);
	
	useEffect(() => {
		// Build the brands options (filter by product type ID)
		const options = productConfig.brands
			.filter((brand) => brand.productTypeID === product.productTypeID)
			.map((brand) => {
				return { label: brand.label, value: brand.brandID };
			});

		setBrandOptions(options);
	}, []);

	/**
	 * Update the product's brand
	 * 
	 * @author 					Pætur Mortensen
	 */
	function update_brand(){
		setIsLoading(true);

		axios
			.post(
				env.protocol + env.env + '/api/secured/shop/AdminChangeProductBrand',
				{productID:product.productID, brandID:selectedBrand}
			)
			.then( response => {
				// Set the product with new data
				setProduct(response.data);
				setIsLoading(false);
			})
			.catch( error => {
				console.error(error);
				setIsLoading(false);
			});
	}

/***************************************************************************************************
 * 
 * 																	RENDER
 * 
 **************************************************************************************************/

	/**
	 * Render update and reset buttons (if there have been changes)
	 * 
	 * @returns 	{JSX} 																Update buttons
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_update_btns(){

		return (
			<div className="brand-update-btn-container">
				<Preloader show={isLoading} />
				<Button
					type="secondary"
					onClick={ () => {
						setSelectedBrand(product.brandID);
					}}
				>
					Reset
				</Button>
				<Button
					onClick={update_brand}
				>
					Update
				</Button>
			</div>
		);
	}

	return (
		<div className="type">
			<h3>Type</h3>
			<div className="product-type">
				<label>Product type</label>
				<Select
					isDisabled={!productConfig.isCreating}
					className="select"
					placeholder="Select..."
					options={productConfig.productTypes}
					defaultValue={productConfig.productTypes.find(
						(x) => x.uuid === product.productTypeID
					)}
					onChange={(e) => {
						setProduct({ ...product, productTypeID: e.uuid });
					}}
				/>
			</div>
			<h3>Brand</h3>
			{product.productTypeID && (
				<div className="select-brand">
					<label>Brand</label>
					<Select
						className="select"
						placeholder="Select..."
						options={brandOptions}
						isDisabled={product.synced}
						value={brandOptions.find( (option) => option.value === selectedBrand )}
						onChange={(e) => {
							setSelectedBrand(e.value);
						}}
					/>
				</div>
			)}
			{brandHasChanged && render_update_btns()}
		</div>
	);
}
