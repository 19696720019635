import "../EditProduct.scss";
import { cloneDeep } from "lodash";
import axios from "axios";
import env from "../../../../../environment.json";
import MediaCenter from "src/Components/MediaCenter";
import { useState } from "react";
import Preloader from "src/assets/Preloader";

/**
 * Product media
 * 
 * @param 		{object} 		product 										The product information
 * @param 		{function} 	setProduct 									Set the product state 
 * 
 * @returns 	{JSX} 																	Media component
 * 
 * @author 					Pætur Mortensen 
 */
export default function Media({ 
	product, 
	setProduct,
}) {
	// Whether the component is currently loading something
	const [ isLoading, setIsloading ] = useState(false);
	
	/**
	 * Handle change in product media
	 * 
	 * @param 	{array} 	media 								Array of media elements to set
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function handle_change(media){

		// If media has not changed, do nothing
		if(!media_has_changed(media)) return;

		setIsloading(true);

		axios
			.post(
				env.protocol + env.env + '/api/secured/shop/SetProductMedia',
				{productID:product.productID, media}
			)
			.then( response => {
				const newProduct = cloneDeep(product);
				newProduct.media = media;
				setProduct(newProduct);
				setIsloading(false);
			})
			.catch( error => {
				console.error(error);
				setIsloading(false);
			});
	}

	/**
	 * Check whether media has changed
	 * 
	 * @param 		{array} 	media 							Media items from media center
	 *  
	 * @returns 	{boolean} 										Whether media has changed 
	 * 
	 * @author 					Pætur Mortensen
	 */
	function media_has_changed(media){
		// If there is not the same number of elements, media has changed
		if(media.length !== product.media.length) return true;

		// Compare each media item
		for(const idx in media){
			if(media[idx].mediaID !== product.media[idx].mediaID) return true;
		}

		// We've reached here, media has not changed
		return false;
	}

/***************************************************************************************************
 * 
 * 																		RENDER
 * 
 **************************************************************************************************/
	
	/**
	 * Render external media for product
	 * 
	 * @returns 	{jsx} 												List of external media
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_external_media(){

		return(
			<div className="external-media">
				<h3>External media</h3>
				<p>Media directly from suppliers. These cannot be edited</p>
				<div className="external-items-container">
					{product.externalMedia.map( (item, idx ) => {
						return (
							<div key={idx} className="image-container">
								<img src={item.url} alt="external media item" />
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	return (
		<div className="media">
			<div className="media-preload-container">
				<Preloader show={isLoading} />
			</div>
			<h3>Common images</h3>
			<MediaCenter 
				mediaItems={product.media}
				onChange={handle_change}
			/>
			{product.externalMedia.length > 0 && render_external_media()}			
		</div>
	);
}
