import { v4 as uuid } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp
} from "@fortawesome/pro-light-svg-icons";
import "./ShopSettings.scss";
import axios from "axios";
import env from "../../../../../environment.json";
import { useState } from "react";

export default function Settings(props) {
  const [openSettings, setOpenSettings] = useState(false);
  const { configuration, updateConf } = props

  const updateSettings = (label, value, type) => {
    const findex = configuration.settings.findIndex(x => x.label === label)
    if (findex !== -1) {
      configuration.settings[findex].value = value
    } else {
      configuration.settings.push({ label, value, type, new: true })
    }
    updateConf.settings = configuration.settings;
  }

  const getSettings = (label) => {
    const findex = configuration.settings.findIndex(x => x.label === label)
    if (findex !== -1) {
      return configuration.settings[findex].value
    } else {
      return ''
    }
  }
  return (
    <div className="confSettings">
      <div className={"box"}>
        <div className="wrap-top">
          <div>
            <h3>Settings</h3>
            <p>Set global settings</p>
          </div>
          <div
            className="expand"
            onClick={() => {
              setOpenSettings((f) => !f);
            }}
          >
            <FontAwesomeIcon
              icon={openSettings ? faChevronUp : faChevronDown}
            ></FontAwesomeIcon>
          </div>
        </div>

        {openSettings && (
          <div className="boxContent">
            <div>
              <p>Set's VAT on all varients prices, the calculation will be the last of the price handeling</p>
              <div>
                <label>VAT in %</label>
                <input
                  defaultValue={getSettings("VAT")}
                  onChange={(e) => {
                    updateSettings("VAT", e.target.value, 'price')
                  }} />
              </div>
            </div>
            <div>
              <p> Set's duty on all varients prices, the calculation will be added to cost price (netto).</p>
              <div>
                <label>Duty in %</label>
                <input
                  defaultValue={getSettings("DUTY")}
                  onChange={(e) => {
                    updateSettings("DUTY", e.target.value, 'price')
                  }} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div >
  );
}
