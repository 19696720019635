import "./MainGridSelect.scss";
import languages from "./language.json";
import { theGalleryTemplates } from "../../../Components/Content/Overlay/GridTemplateChoose/GridTemplateChoose";
import "../MediaGrid/MediaGrid.scss";
import { useEffect, useState } from "react";
import noImage from "../../images/empty.jpg";

const l = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export default function GridType(props) {
  const { data, onChange, allWhatson, whatsonTypes } = props;
  const [whatsonFiltered, setWhatsonFiltered] = useState([]);

  const language = languages[l];

  const change = (key, value) => {
    onChange({ ...data, [key]: value });
  };

  useEffect(() => {
    if (data.whatWhatson !== undefined) {
      const sayWhat = whatsonTypes.find((e) => e.id === data.whatWhatson);
      if (sayWhat !== undefined) {
        if (sayWhat.name === "Events") {
          setWhatsonFiltered(allWhatson.filter((e) => e.type === "event"));
        }
        if (sayWhat.name === "Tours") {
          setWhatsonFiltered(allWhatson.filter((e) => e.type === "tour"));
        }
        if (sayWhat.name === "Hiking") {
          setWhatsonFiltered(allWhatson.filter((e) => e.type === "hike"));
        }
        if (sayWhat.name === "Places") {
          setWhatsonFiltered(allWhatson.filter((e) => e.type === "place"));
        }
      }
    }
  }, [data.whatWhatson, whatsonTypes, allWhatson]);

  const AmountChoose = ({ amount }) => {
    return (
      <div
        className={
          "option" +
          (data.amount_per_grid === amount ? " selected" : "") +
          (data.whatson_chosen_ids.length >= amount ? "" : " disabled")
        }
        onClick={() => {
          change("amount_per_grid", amount);
        }}
      >
        {amount}
      </div>
    );
  };

  return (
    <div className="main-grid-select">
      <div className="grid-description">
        {language.main_grid_select.header.grid_description}
      </div>
      <div className="amount-per-grid">
        <AmountChoose amount={1} />
        <AmountChoose amount={2} />
        <AmountChoose amount={3} />
        <AmountChoose amount={4} />
        <AmountChoose amount={5} />
        <AmountChoose amount={6} />
        <AmountChoose amount={7} />
        <AmountChoose amount={8} />
      </div>
      <div className="grid-preset-choose">
        {data.amount_per_grid &&
          theGalleryTemplates[data.amount_per_grid - 1].map((template, i) => {
            return (
              <div
                key={`${data.amount_per_grid}${template.name}`}
                className={`template-wrap ${
                  data.grid_preset === i + 1 ? "selected" : ""
                }`}
                onClick={() => {
                  change("grid_preset", i + 1);
                }}
              >
                <div className="template">
                  <div className="media-grid choice">
                    <div
                      className={`grid-container grid-amount${
                        data.amount_per_grid
                      }-pre${i + 1}`}
                    >
                      {/* <AmountGenerate /> */}
                      {[...Array(data.amount_per_grid)].map((e, i) => {
                        const whatsonObj = whatsonFiltered.find(
                          (oneWhat) => oneWhat.id === data.whatson_chosen_ids[i]
                        );

                        return (
                          <div key={i} className={`item item${i + 1}`}>
                            <img
                              src={
                                whatsonObj
                                  ? whatsonObj.image && whatsonObj.image.info
                                    ? whatsonObj.image.medium
                                    : noImage
                                  : noImage
                              }
                              alt={"Inside grid"}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
