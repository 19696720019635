import { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  StreetViewPanorama,
} from "@react-google-maps/api";
import {
  geocode,
  RequestType,
  setLocationType,
  setKey,
  enableAddressDescriptor,
} from "react-geocode";
import libraries from "./libraries";

export interface latLng {
  lat: number;
  lng: number;
}

interface props {
  marker?: latLng;
  markerHidden?: boolean;
  onClick?: (e: {
    mapMouseEvent: google.maps.MapMouseEvent;
    address?: string;
    geocode?: any;
  }) => void;
  showStreetView?: boolean;
  size?: {
    width: number,
    height: number,
  };
}
const SimpleMap = (props: props) => {
  const { marker, markerHidden, onClick, showStreetView } = props;
  const [markerPosition, setMarkerPosition] = useState<latLng>(
    marker ?? { lat: 62.01091098697576, lng: -6.773021411790148 }
  );
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
    // libraries: ["places", "marker"],
    libraries,
  });

  useEffect(() => {
    if (marker !== undefined) setMarkerPosition(marker);
  }, [marker]);

  const onMapClick = (e: google.maps.MapMouseEvent) => {
    const lat = e.latLng?.lat();
    const lng = e.latLng?.lng();
    if (lat && lng) {
      setMarkerPosition({ lat, lng });
      setLocationType("ROOFTOP");
      enableAddressDescriptor(true);
      setKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "");
      geocode(RequestType.LATLNG, `${lat},${lng}`)
        .then((response: any) => {
          let geocode;
          let address: string = "";
          if (response.results.length > 0) {
            address = response.results[0].formatted_address;
            geocode = response;
          }
          if (onClick) onClick({ mapMouseEvent: e, address, geocode });
        })
        .catch((/*error*/) => {
          // console.error(error) // geocode failed
          if (onClick) onClick({ mapMouseEvent: e });
        });
    }
  };

  if (showStreetView) {
    return isLoaded ? (
      <GoogleMap
        key="street"
        mapContainerStyle={{ height: "300px" }}
        center={markerPosition}
        zoom={markerHidden ? 8 : 15}
        onClick={onMapClick}
        options={{ streetViewControl: false, mapTypeControl: false }}
      >
        {showStreetView ? (
          <StreetViewPanorama
            // @ts-ignore
            // Using visible prop based on actual library behavior, even though TypeScript types don't recognize it.
            visible={true}
            options={{ position: marker }}
          />
        ) : (
          <></>
        )}
      </GoogleMap>
    ) : (
      <div>Loading...</div>
    );
  }

  return isLoaded ? (
    <GoogleMap
      key="plain"
      mapContainerStyle={{ height: props?.size? `${props.size.height}px` : "400px" }}
      center={markerPosition}
      zoom={markerHidden ? 8 : 15}
      onClick={onMapClick}
      options={{ streetViewControl: false, mapTypeControl: false }}
    >
      {!markerHidden ? <MarkerF position={markerPosition}></MarkerF> : <></>}
    </GoogleMap>
  ) : (
    <div>Loading...</div>
  );
};

export default SimpleMap;
