import { useEffect, useState } from "react";
import Leaflet from "../Leaflet/Leaflet";
import styles from "./ArticlesDisplay.module.scss";
import { getArticlesSections } from "./sections";
import InfoscreenView from "../InfoscreenView/InfoscreenView";
import InfoscreenArticles from "../InfoscreenView/InfoscreenArticles/InfoscreenArticles";
import { sectionView } from "./types";
import { useArticlesStore } from "src/Components/ZustandStores/articlesStore";

interface Props {
  language: string;
  adminMenu: string;
  setSiteRef: any;
  openOverlay: any;
  infoscreenMenu: any;
  setConfirmBeforeNavigate: any;
  addOne: any;
}

export default function ArticlesDisplay({
  language,
  adminMenu,
  setSiteRef,
  openOverlay,
  addOne,
  infoscreenMenu,
  setConfirmBeforeNavigate,
}: Props) {
  const [selectedView, setSelectedView] = useState<sectionView>({
    key: "",
    name: "See & Do",
  });
  // const { articleTypes } = useArticlesStore();
  const { articleTypes } = useArticlesStore();

  const [articleLocation, setArticleLocation] = useState<string>('');

  return (
    <div className={styles.container}>
      {!(articleLocation === 'edit')? (
        <Leaflet
        title={"Articles"}
        sections={getArticlesSections(articleTypes)}
        selectedView={selectedView}
        setSelectedView={(view) => setSelectedView(view)}
      />
      ) : (
        <></>
      )}

      <div className={styles.content}>
        {selectedView.name === "See & Do" && (
          <div className="infoscreen-content">
            <span className="shadow-maker" />
            <InfoscreenArticles
              hideList={true}
              articleInitialType={selectedView.name}
              openOverlay={openOverlay}
              setConfirmBeforeNavigate={setConfirmBeforeNavigate}
              setSiteRef={setSiteRef}
              newData={addOne}
              setArticleLocation={setArticleLocation}
            />
          </div>
        )}
        {selectedView.name === "Eat & Drink" && (
          <div className="infoscreen-content">
            <span className="shadow-maker" />
            <InfoscreenArticles
              hideList={true}
              articleInitialType={selectedView.name}
              openOverlay={openOverlay}
              setConfirmBeforeNavigate={setConfirmBeforeNavigate}
              setSiteRef={setSiteRef}
              newData={addOne}
              setArticleLocation={setArticleLocation}
            />
          </div>
        )}
        {selectedView.name === "Transport" && (
          <div className="infoscreen-content">
            <span className="shadow-maker" />
            <InfoscreenArticles
              hideList={true}
              articleInitialType={selectedView.name}
              openOverlay={openOverlay}
              setConfirmBeforeNavigate={setConfirmBeforeNavigate}
              setSiteRef={setSiteRef}
              newData={addOne}
              setArticleLocation={setArticleLocation}
            />
          </div>
        )}
      </div>
    </div>
  );
}
