import { useState, useEffect } from "react";
import styles from "./Orders.module.scss";
import { OrderItem, OrdersType } from "./types";
import Order from "./Order";
import OrderList from "./OrderList";
import axios from 'axios';
import env from "../../../../environment.json";
import { isEqual } from "lodash";


export default function Orders({ setCurrentLocation } : OrdersType ): JSX.Element {
	const [ orders, setOrders ] = useState<OrderItem[]>([]);
	// Order to display (NULL = display orders list)
	const [ displayOrder, setDisplayOrder ] = useState<number|null>(null);
	// Whether we are currently loading
	const [ isLoading, setIsLoading ] = useState<boolean>(false);
	// Filtered and sorted orders ready for table
	const [ filteredOrders, setFilteredOrders ] = useState<OrderItem[]>([]);
	// Search state
	const [ search, setSearch ] = useState<string>('');


	/**
	 * Reload the orders
	 * 
	 * @author 					Pætur Mortensen
	 */
	function reload_orders() : void {
		fetch_orders();
	}

	/**
	 * Close the orders view
	 * 
	 * @author 					Pætur Mortensen
	 */
	function close_orders() : void {
		setCurrentLocation('main');
	}

	/**
	 * Fetch the orders
	 * 
	 * @author 					Pætur Mortensen 
	 */
	const fetch_orders = () :void => {
		// If we're showing a single order, don't get list
		if(displayOrder !== null) return;

		setIsLoading(true);

		axios
			.get( env.protocol + env.env + '/api/secured/shop/GetAdminOrders' )
			.then( response => {
				const data = response.data;
				// If our local orders are not equal to the order from the server, update the order list
				if(!isEqual(data, orders)){
					setOrders(data);
				}
			})
			.catch( error => console.log(error) )
			.finally( () => setIsLoading(false) );
	}

	// Set order list update interval
	useEffect(() => {
		// If single order is not being shown, get list
		if(displayOrder === null){
			fetch_orders();
		}
		
		const intervalID = setInterval(fetch_orders, 30000);
		return () => {
			clearInterval(intervalID);
		}
	},[displayOrder]);

/***************************************************************************************************
 * 
 * 																		RENDER
 * 
 **************************************************************************************************/
	

	return (
		<div className={styles.orders}>
			{displayOrder !== null ? 
				<Order 
					close={() => {setDisplayOrder(null)}}
					orderID={displayOrder}
				/> 
				: 
				<OrderList
					reload_orders={reload_orders}
					orders={orders}
					setDisplayOrder={setDisplayOrder}
					close={close_orders}
					isLoading={isLoading}
					filteredOrders={filteredOrders}
					setFilteredOrders={setFilteredOrders}
					search={search}
					setSearch={setSearch}
				/>}
		</div>
	);
}
