import React, { Component } from "react";
import "./HomeDisplay.scss";

import { faSlidersH, faSort } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import env from "../../../environment.json";

import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/pro-light-svg-icons";
import ToolTip from "../../ToolTips/ToolTip";
import { GlobalToolTipController } from "../../ToolTips/GlobalToolTip";

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fal" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

class HomeDisplay extends Component {
  constructor(props) {
    super(props);
    let timeOfDay = "";
    let date = new Date().getHours();
    if (date >= 18 || date <= 4) {
      timeOfDay = "Good evening, ";
    }
    if (date >= 4 && date <= 12) {
      timeOfDay = "Good morning, ";
    }
    if (date >= 13 && date < 18) {
      timeOfDay = "Good afternoon, ";
    }
    this.state = {
      myInfo: { name: "", company: "", superAdmin: "" },
      checkVal: false,
      show: "Home",
      whatson: "",
      showItems: 5,
      sortBtn: false,
      timeOfDay,
    };

    this.axios = require("axios");
    this.homeInfo = [];
    this.posts = [];
    this.choosenUserPost =
      JSON.parse(localStorage.getItem("choosenUserPost")) === null
        ? []
        : JSON.parse(localStorage.getItem("choosenUserPost"));
  }

  componentDidMount() {
    this.axios
      .post(env.protocol + env.env + "/api/secured/getHomeInfo", {
        vfiUser: sessionStorage.getItem("vfiUser"),
      })
      .then((response) => {
        this.homeInfo = [];
        this.posts = [];

        this.setState({
          myInfo: {
            ...this.state.myInfo,
            name:
              response.data.userInfo.firstname +
              " " +
              response.data.userInfo.lastname,
            company: response.data.userInfo.company,
            superAdmin: response.data.userInfo.superAdmin,
          },
        });

        if (response.data.postInfo.length > 0) {
          response.data.postInfo.forEach((e) => {
            this.posts.unshift({
              id: e.postId,
              postName: e.postName,
              text: e.postText,
              postIcon: e.postIcon,
              postHeadline: e.postHeadline,
              whatsonId: e.whatsonId ? e.whatsonId : null,
              selected:
                response.data.choosenUserPost.filter(
                  (elm) => elm.postId === e.postId
                ).length > 0,
            });
          });
        }

        if (this.choosenUserPost.length === 0) {
          this.choosenUserPost = this.posts.filter((elm) => elm.selected);
          localStorage.setItem(
            "choosenUserPost",
            JSON.stringify(this.choosenUserPost)
          );
          this.setState({});
        }
        // if (this.choosenUserPost.length === 0) {
        //   // console.log(choosenUserPost)
        //   if (response.data.choosenUserPost.length > 0) {
        //     response.data.choosenUserPost.forEach((e) => {
        //       this.choosenUserPost.unshift({
        //         postId: e.postId,
        //         postName: e.name,
        //         postText: e.text,
        //         postIcon: e.icon,
        //         postHeadline: e.headline,
        //         whatsonId: e.whatsonId ? e.whatsonId : null
        //       });
        //     });
        //     localStorage.setItem('choosenUserPost', JSON.stringify(this.choosenUserPost));
        //     this.setState({});
        //   }
        // }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  approvalStatusChange = (id, value) => {
    this.homeInfo.forEach((element, i) => {
      if (element.id === id) {
        if (element.approvalStatus === "awaiting approval") {
          this.awatingApprovalAmount--;
        }

        element.approvalStatus = value;
      }
    });
    this.setState({});
  };

  selectById = (id) => {
    this.homeInfo.forEach((element, i) => {
      if (element.id === id) {
        element.selected = !element.selected;
        this.setState({});
      }
    });
  };

  handleShowMore() {
    var increment = 30;
    this.setState({
      showItems:
        this.state.showItems + increment >= this.homeInfo.length
          ? this.homeInfo.length
          : this.state.showItems + increment,
    });
  }

  handleCheckBox = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  onFilterChange = (id, index) => {
    // When managing values in filter
    this.axios
      .post(env.protocol + env.env + "/api/secured/updateUserPost", {
        vfiUser: sessionStorage.getItem("vfiUser"),
        postId: id,
        value: !this.posts[index].selected, //Next value, i.e. we want to set it to be false and hence delete row in table
      })
      .then((response) => {
        if (response.data === 1) {
          if (this.posts[index].selected) {
            // If it is already selected we want to remove it
            var choosenUserPostIndex = this.choosenUserPost.findIndex(
              (element) => element.id === id
            );
            this.choosenUserPost.splice(choosenUserPostIndex, 1);
            localStorage.setItem(
              "choosenUserPost",
              JSON.stringify(this.choosenUserPost)
            );
            this.posts[index].selected = !this.posts[index].selected;
          } else {
            // If it was not selected before we want to insert it to the array
            this.choosenUserPost.push({
              id: this.posts[index].id,
              postName: this.posts[index].postName,
              text: this.posts[index].text,
              postIcon: this.posts[index].postIcon,
              postHeadline: this.posts[index].postHeadline,
              whatsonId: this.posts[index].whatsonId
                ? this.posts[index].whatsonId
                : null,
            });

            localStorage.setItem(
              "choosenUserPost",
              JSON.stringify(this.choosenUserPost)
            );
            this.posts[index].selected = !this.posts[index].selected;
          }
          this.setState({}, () => {
            // Update content in main
            this.updateOrOpenCloseFilter(this.props.addFilterDroppedDown[0]);
          });
        }
      });
  };

  updateOrOpenCloseFilter = (optionsExtended) => {
    var filterContent = [
      {
        headline: "Review",
        value: this.posts.filter((obj) => obj.postHeadline === "Review"),
        style: "bobbleList",
        disable: env.env.includes("vita.fo"),
      },
      {
        headline: "Manage",
        value: this.posts.filter((obj) => obj.postHeadline === "Manage"),
        style: "bobbleList",
      },
      {
        headline: "Create",
        value: this.posts.filter((obj) => obj.postHeadline === "Create"),
        style: "bobbleList",
      },
      {
        headline: "Edit",
        value: this.posts.filter((obj) => obj.postHeadline === "Edit"),
        style: "bobbleList",
      },
    ];

    this.props.openCloseFilter([optionsExtended, "Overview"], {
      type: "Overview",
      search: false,
      style: {},
      content: filterContent,
      execute: (id) => {
        // What happens when elements are clicked
        var index = this.posts.findIndex((element) => element.id === id);
        this.onFilterChange(id, index);
      },
    });
  };

  // getTimeText = () => {
  //   let date = new Date().getHours();
  //   if (date >= 4 && date <= 12) {
  //     return 'Good morning, ';
  //   }
  //   if (date > 13 && date < 18) {
  //     return 'Good afternoon, ';
  //   }
  //   return 'Good evening, ';
  // };

  render() {
    return (
      <div className="home-display">
        <div className="header">
          <ToolTip
            offset={{ x: 30, y: 30 }}
            title=""
            delay={600}
            toolTipElements={<div>Toggle options panel</div>}
            className={
              this.props.addFilterDroppedDown[0]
                ? "Options_btn extended"
                : "Options_btn"
            }
            onClick={() => {
              this.updateOrOpenCloseFilter(!this.props.addFilterDroppedDown[0]);
            }}
          >
            {/* {type: "users", searh:true, content: {Roles: ["Publisher", "Admin"]} }*/}
            Options
            <FontAwesomeIcon icon={faSlidersH} />
          </ToolTip>
          <div className="home-headline">
            <h1>{this.state.timeOfDay + this.state.myInfo.name}</h1>
            <p>What would you like to do?</p>
          </div>
        </div>
        <div className="home-buttons">{this.mapCreateNew()}</div>
        <div
          className={this.state.sortBtn ? "SortBtn choosen" : "SortBtn"}
          onClick={() => {
            if (!this.state.sortBtn) {
              this.setState({ sortBtn: !this.state.sortBtn });
            }
          }}
        >
          <span>
            Sort view
            <FontAwesomeIcon icon={faSort} />
          </span>
        </div>
        {this.state.sortBtn ? (
          <div
            className="okBtn"
            onClick={() => this.setState({ sortBtn: !this.state.sortBtn })}
          >
            OK
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  navigate = (data) => {
    if (!this.state.sortBtn) {
      // Not in sorting mode
      switch (data.text) {
        case "Manage events":
          this.props.store.routerStore.navByLink("whatson/events");
          break;
        case "Manage tours":
          this.props.store.routerStore.navByLink("whatson/tours");
          break;
        case "Manage hiking routes":
          this.props.store.routerStore.navByLink("whatson/hiking");
          break;
        case "Manage places":
          this.props.store.routerStore.navByLink("whatson/places");
          break;
        case "Create new hiking route":
          this.props.store.routerStore.navByLink("whatson/hiking/add");
          break;
        case "Create new place":
          this.props.store.routerStore.navByLink("whatson/places/add");
          break;
        // return this.props.navigateWhatson(
        //   "Whatson",
        //   data.whatsonId,
        //   data.postHeadline === "Create" ? data.postName : ""
        // );
        case "Manage pages":
          this.props.store.routerStore.navByLink("pages");
          break;
        case "Manage infoskermar":
          this.props.store.routerStore.navByLink("admin/infoscreens");
          break;
        case "Create new page":
          // return this.props.navigatePages();
          this.props.store.routerStore.navByLink("pages/create_page");
          break;
        case "Manage users":
          this.props.store.routerStore.navByLink("users");
          break;
        case "Create new user":
          this.props.store.routerStore.navByLink("users/create_user");
          break;
        // return this.props.navigateUser(
        //   data.postHeadline === "Create" ? "User" : ""
        // );

        case "Review submits":
          // return this.props.navigateActivity();
          this.props.store.routerStore.navByLink("activity");
          break;
        // return 123;

        case "Edit site":
          this.props.store.routerStore.navByLink("admin/sites");
          break;

        case "Collaborations": //virker ikke
          // return this.props.navigateNew("4", "collaborations");
          break;
        default:
          break;
      }
    }
  };

  mapCreateNew() {
    var postList = this.choosenUserPost.map((data, i) => {
      let notificationAmount = 0; // parseInt(this.props.activityUpdate) > 0

      switch (data.text) {
        case "Review submits":
          notificationAmount = this.props.notificationAmounts.activity;
          break;
        default:
          break;
      }

      return (
        <div
          className={
            "create-new-page " +
            data.postName +
            (this.state.indexImageDrag === i ? " dragging" : "") +
            (this.state.indexImageDragOn === i ? " dragging-on" : "")
          }
          draggable={this.state.sortBtn ? "true" : "false"}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragStart={(e) => {
            this.setState({ indexImageDrag: i });
          }}
          onDrop={(e) => {
            let post = this.choosenUserPost[this.state.indexImageDrag];
            this.choosenUserPost.splice(this.state.indexImageDrag, 1);
            this.choosenUserPost.splice(i, 0, post);
            localStorage.setItem(
              "choosenUserPost",
              JSON.stringify(this.choosenUserPost)
            );
          }}
          onDragEnd={(e) => {
            this.setState({
              indexImageDrag: undefined,
              indexImageDragOn: undefined,
            });
          }}
          onDragEnter={(e) => {
            setTimeout(() => {
              this.setState({ indexImageDragOn: i });
            }, 0);
          }}
          onDragLeave={(e) => {
            this.setState({ indexImageDragOn: undefined });
          }}
          key={i}
          onClick={() => this.navigate(data)}
        >
          <ToolTip
            offset={{ x: 30, y: 30 }}
            title=""
            priority={"right,bottom"}
            delay={1000}
            toolTipElements={<div>Open {data.text}</div>}
            className="wrap-in-button"
          >
            <div className="home-icon">
              <FontAwesomeIcon icon={["fal", data.postIcon]} />
              {notificationAmount ? (
                <div className="popUp">{notificationAmount}</div>
              ) : (
                ""
              )}
            </div>
            <p> {data.text}</p>
          </ToolTip>
        </div>
      );
    });
    return postList;
  }
}
export default HomeDisplay;
