import styles from "./Orders.module.scss";
import { StatusListType } from "./types";
import { capitalize } from "src/assets/helperFunctions";

/**
 * List of order statuses
 * 
 * @author 					Pætur Mortensen
 */
export default function StatusList({ data }: StatusListType): JSX.Element {
	
	return (
		<div className={`${styles.box} ${styles.statusListWrap}`}>
			<h2>Status List</h2>
			<div className={`${styles.statusList}`}>
				<div className={`${styles.statusRow}`}>
					Order status:&nbsp;
					<span className={`status ${styles[data.orderStatus]}  ${styles.status}`}>
						{capitalize(data.orderStatus)}
					</span>
				</div>
				<div className={`${styles.statusRow}`}>
					Payment status:&nbsp;
					<span className={`status ${styles[data.paymentStatus]} ${styles.status}`}>
						{capitalize(data.paymentStatus)}
					</span>
				</div>
				<div className={`${styles.statusRow}`}>
					Shipping status:&nbsp;
					<span className={`status ${styles[data.shippingStatus]} ${styles.status}`} >
						{capitalize(data.shippingStatus)}
					</span>
				</div>
			</div>
		</div>
	);
}
