import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-light-svg-icons";
import { faPlayCircle } from "@fortawesome/pro-duotone-svg-icons";
import ReactPlayer from "react-player";
import VideoThumbnail from "react-video-thumbnail";
import languages from "../language.json";
import "./OneMedia.scss";
import VerifyMethods from "src/assets/VerifyMethods";
import { determineExtension, getFallbackImage } from "./utils";
import { useInitialImageSource, useMediaExtension } from "./hooks";

/**
 * OneMedia component: Provides a media viewer that supports both images and videos.
 * Allows for media uploading, drag-drop, and error handling for missing media.
 *
 * @param {Object} props - The properties passed to this component.
 * @returns {JSX.Element}
 */
export default function OneMedia(props) {
  // Destructuring for cleaner references
  const {
    style,
    media,
    media: { extension: ext, src },
    disableChange,
    size = "",
    className = "",
    setSiteRef,
    siteRef,
    openOverlay,
    whatMedia,
    onClick,
    draggable,
    onDragStart,
    onDragOver,
    onDragEnd,
    onDrop,
    imgFormat,
    videoThumbnail,
  } = props;

  // Localized language support
  const language = localStorage.getItem("language") || "english";
  const lang = languages[language].one_media;

  // Custom hooks for handling media extension and initial image source
  const foundExtension = useMediaExtension(media, ext, src);
  const [imgSrc, setImgSrc] = useInitialImageSource(
    media,
    src,
    determineExtension(
      media,
      src ? src.substring(src.lastIndexOf(".") + 1) : undefined,
      ext,
      foundExtension
    ),
    imgFormat
  );

  // Handler for image loading errors
  const handleImageError = () => {
    setImgSrc(getFallbackImage());
  };

  /**
   * UploadButton: Displays the button for uploading new media.
   *
   * @returns {JSX.Element}
   */
  function UploadButton() {
    return (
      <div
        className="add-media"
        title="Choose medium"
        onClick={() => {
          if (!disableChange) {
            setSiteRef?.(siteRef);
            openOverlay?.("mediaChoose", {
              filter: { mediaType: whatMedia },
              singleMedia: true,
            });
            onClick?.();
          }
        }}
      >
        <FontAwesomeIcon icon={faCamera} />
        <div className="text">{lang.upload_image}</div>
      </div>
    );
  }

  /**
   * UploadButton: Displays the button for uploading new media.
   *
   * @returns {JSX.Element}
   */
  function RenderImage() {
    if (
      determineExtension(
        media,
        src ? src.substring(src.lastIndexOf(".") + 1) : undefined,
        ext,
        foundExtension
      )
    ) {
      return (
        <img
          src={imgSrc}
          alt="media"
          draggable="false"
          onError={handleImageError}
        />
      );
    }

    return (
      <img
        className="no-image"
        src={getFallbackImage()}
        alt="none"
        draggable="false"
      />
    );
  }

  /**
   * RenderVideo: Renders a video player or a video thumbnail.
   *
   * @returns {JSX.Element}
   */
  function RenderVideo() {
    if (!videoThumbnail) {
      return (
        <ReactPlayer
          url={imgSrc}
          controls={true}
          height="100%"
          width="100%"
          style={{ pointerEvents: "auto" }}
        />
      );
    }

    return (
      <span className="video-thumbnail">
        <VideoThumbnail videoUrl={imgSrc} snapshotAtTime={10} />
        <FontAwesomeIcon icon={faPlayCircle} />
      </span>
    );
  }

  /**
   * RenderVideo: Renders a video player or a video thumbnail.
   *
   * @returns {JSX.Element}
   */
  function MissingImage() {
    return (
      <div className="missing-image">
        <img src={getFallbackImage()} alt="missing" />
      </div>
    );
  }

  return (
    <div
      style={style}
      className={`one-media ${
        disableChange ? "" : "clickable"
      } ${size} ${className}`}
    >
      <div className="upload-box">
        <div
          className="image-container"
          draggable={draggable}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          onDrop={onDrop}
        >
          {!disableChange && <UploadButton />}

          {/**
           * IIFE (Immediately Invoked Function Expression):
           * This pattern is used to create a self-executing function which can have its
           * own scope. It's a convenient way to encapsulate logic and return a JSX
           * element directly within the JSX structure.
           *
           * The logic checks:
           * 1. If there's a media present.
           * 2. Determines the media's extension.
           * 3. Depending on the media type (image/video), it renders the appropriate component.
           * 4. If no media is determined, it defaults to rendering a missing image placeholder.
           */}
          {(() => {
            if (media) {
              const determinedExt = determineExtension(
                media,
                src ? src.substring(src.lastIndexOf(".") + 1) : undefined,
                ext,
                foundExtension
              );

              if (determinedExt || src) {
                if (!VerifyMethods.extensionIsVideo(determinedExt)) {
                  return <RenderImage />;
                } else {
                  return <RenderVideo />;
                }
              }
            }
            return <MissingImage />;
          })()}
        </div>
      </div>
    </div>
  );
}
