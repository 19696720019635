import "../../EditProduct.scss";
import { useState, useEffect } from "react";
import { faPencil, faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Preloader from "src/assets/Preloader";
import { cloneDeep, isEqual } from "lodash";
import axios from "axios";
import env from "../../../../../../environment.json";
import Button from "src/Components/Buttons/Button";
import Select from "react-select";
import { IconProp } from "@fortawesome/fontawesome-svg-core";


/***************************************************************************************************
 * 		
 * 																				TYPES
 * 
 **************************************************************************************************/
// Array of properties
type Properties = Array<Property>;
// Single property
type Property = {
	// Property name
	label: string;
	// Property value
	value: string;
};
// Variant data
type Variant = {
	// from table: shopPhysical.id
	variantID:number,
	// Variant properties
	properties:Properties,
};
// Product data
type Product = {
	// Variants in product
	variants:Array<Variant>,
	synced:boolean,
};
// Properties from productConfig
type ConfigProp = {
	// Property slug
	slug:string,
	// Property readable name
	name:string,
};
// Single property option (to use with select)
type PropertyOption = {
	// Property name
	label:string,
	// Property slug
	value:string,
};

/**
 * Display property
 * 
 * Display a single property
 * 
 * @author 					Pætur Mortensen
 */
type DisplayProperty = {
	// Single property
	property:Property,
	// Properties from the config
	propertiesConfig:Array<ConfigProp>,
};
function DisplayProperty( { 
	property, 
	propertiesConfig 
} : DisplayProperty): JSX.Element {
	// Get the readable name for the property
	const name = propertiesConfig.find( item => item.slug === property.label)?.name ?? 'N/A';

	return (
		<tr>
			<td>{name}</td>
			<td>{property.value}</td>
		</tr>
	);
}

/**
 * Edit property
 * 
 * Open the edit field for a single property
 * 
 * @author 					Pætur Mortensen
 */
type EditProperty = {
	property:Property, 
	properties:Properties, 
	setProperties:(set:Properties) => void,
	propertiesConfig:Array<ConfigProp>,
};
function EditProperty({ 
	property, 
	properties, 
	setProperties,
	propertiesConfig,
} : EditProperty): JSX.Element {
	// Get the readable name for the property
	const name = propertiesConfig.find( item => item.slug === property.label)?.name ?? 'N/A';

	return (
		<tr>
			<td>{name}</td>
			<td className="property-edit-td">
				<input 
					type="text"
					className="value-input"
					value={property.value}
					onChange={ e => {
						const newProperty = {...property, value:e.target.value};
						const propertyIdx = properties.findIndex( item => item.label === property.label);
						const newProperties = cloneDeep(properties);
						newProperties[propertyIdx] = newProperty;
						setProperties(newProperties);
					}}
				/>
				<FontAwesomeIcon
						className="delete-property-btn"
						icon={faTrashAlt as IconProp}
						onClick={() => {
							const propertyIdx = properties.findIndex( item => item.label === property.label);
							const newProperties = cloneDeep(properties);
							newProperties.splice(propertyIdx, 1);
							setProperties(newProperties);
						}}
					/>
			</td>
		</tr>
	);
}



/**
 * Extract options for adding a property
 * 
 * Remove all already used properties and special properties and format for select
 * 
 * @author 					Pætur Mortensen 
 */
function extract_add_property_options(
	configProps:Array<ConfigProp>, 
	variantProps:Array<Property>
) : Array<PropertyOption>{

	// Filter already used properties and special properties out, then format for select options
	const properties = configProps.filter( configProp => {
		// Remove any properties that already exist for the variant
		if(variantProps.findIndex( variantProp => variantProp.label === configProp.slug) !== -1) {
			return false;
		}
		// Also remove all special properties
		if(configProp.slug.includes('_special')) return false;
		// Return true if filters don't apply
		return true;
	}).map( property => {
		// Format the property for a select option
		return {label:property.name, value:property.slug}
	});

	return properties;
}

/**
 * Variant properties
 * 
 * Display and edit variant properties
 * 
 * @author 					Pætur Mortensen 
 */
type VariantProperties = {
	variant: Variant,
	product:Product,
	setProduct:(set:Product) => void,
	productConfig:{
		variantProperties:Array<ConfigProp>
	},
};
export default function VariantProperties({
	variant,
	product,
	setProduct,
	productConfig
}: VariantProperties): JSX.Element {
	// Whether to edit properties
	const [editProperties, setEditProperties] = useState<boolean>(false);
	// Whether component is loading
	const [isLoading, setIsLoading] = useState<boolean>(false);
	// Properties state
	const [ properties, setProperties ] = useState<Properties>(cloneDeep(variant.properties));
	// Whether we are adding a property
	const [ addProperty, setAddProperty ] = useState<boolean>(false);
	// Type of property to add (if any)
	const [ addPropertyType, setAddPropertyType ] = useState<PropertyOption|null>(null);
	// Value of property to add if any
	const [ addPropertyValue, setAddPropertyValue ] = useState<string>('');
	// Set the possible options for the "add properties" select
	const [ addPropertyOptions, setAddPropertyOptions ] = useState<Properties>([]);
	
	useEffect(() => {
		update_add_property_options();
	}, [properties]);
	/**
	 * Update the options for add property
	 * 
	 * @author 					Pætur Mortensen
	 */
	function update_add_property_options(){
		setAddPropertyOptions(extract_add_property_options(
			productConfig.variantProperties, 
			properties));
	}

	/**
	 * Check whether properties have changed and save if they have
	 * 
	 * @author 					Pætur Mortensen
	 */
	function save_properties() : void {
		setIsLoading(true);

		// If there have been changes in the properties....
		if(!isEqual(variant.properties, properties)){
			// Make changes on server
			axios
				.post(
					env.protocol + env.env + "/api/secured/shop/EditVariantProperties", 
					{variant:{variantID:variant.variantID, properties:variant.properties}, properties})
				.then( response => {
					// Set the new product state
					const newProduct = cloneDeep(product);
					variant.properties = properties;
					const variantIdx = newProduct.variants.findIndex( 
						item => item.variantID === variant.variantID
					);
					newProduct.variants[variantIdx] = variant;
					setProduct(newProduct);
					setIsLoading(false);
					setEditProperties(false);
				})
				.catch( error => {
					console.error(error);
					setIsLoading(false);
					setEditProperties(false);
				});
		} else {
			// No changes, just close the edit fields
			setIsLoading(false);
			setEditProperties(false);
		}
	}

	/**
	 * Add a property
	 * 
	 * Check whether valid property can be added, and add it if it can
	 * 
	 * @author 					Pætur Mortensen
	 */
	function add_property(){
		const label = addPropertyType !== null ? addPropertyType.value : null;
		
		// If label and value are set, add the property for saving later
		if(label && addPropertyValue){
			const newProperties = cloneDeep(properties);
			newProperties.push({label, value:addPropertyValue});
			setProperties(newProperties);
		}

		setAddProperty(false);
		setAddPropertyType(null);
		setAddPropertyValue('');
	}

	/**
	 * Render the property edit buttons
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_edit_buttons() : JSX.Element {
		return (
			<div className="cta">
				<Preloader show={isLoading} />
				<Button
					onClick={() => { save_properties();}}
				>
					Save
				</Button>
				<Button
					type="secondary"
					onClick={() => { 
						setEditProperties(false);
						// Reset the properties
						setProperties(cloneDeep(variant.properties)); 
						setAddProperty(false);
					}}
				>
					Cancel
				</Button>
			</div>
		);
	}

	function render_edit_cp(){


		return (
			<>
				{editProperties ? (
						render_edit_buttons()
					) : (
						<FontAwesomeIcon
							className="edit-property-btn"
							icon={faPencil as IconProp}
							onClick={() => {
								setEditProperties(true);
							}}
						/>
				)}
		</>
		);
	}

	/**
	 * Render the "add property" controls (field or add button)
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_add_property_controls() : JSX.Element {
		// Set whether we can add a property
		const canAddProperty = addPropertyOptions.length > 0;

		/**
		 * Render the field to add a property to the variant
		 * 
		 * @author 					Pætur Mortensen 
		 */
		function render_add_property_field() : JSX.Element{
			
			return (
				<div className="add-property-field">
					<Select
						options={addPropertyOptions}
						className="add-property-select"
						value={addPropertyType}
						onChange={ e => {
							setAddPropertyType(e);
						}}
					></Select>
					<input 
						placeholder="Set property value"
						className="value-input"
						value={addPropertyValue}
						onChange={ e => setAddPropertyValue(e.target.value)}
					/>
					<Button 
						size="small" 
						onClick={ () => {add_property()}}
					> Add </Button>
					<Button 
						size="small" 
						type="secondary"
						onClick={() => {setAddProperty(false)}}
					> Cancel </Button>
				</div>
			);
		}

		return (
			<div className="add-property-row">
				{addProperty ? 
					render_add_property_field()
					:
					<Button 
						block
						type={canAddProperty ? 'primary' : 'secondary'}
						size="small"
						disabled={!canAddProperty}
						onClick={() => {setAddProperty(true)}}
					>
						Add Property
					</Button>
				}
			</div>
		);
	}

	return (
		<div className="variant-properties">
			<h3>Properties</h3>
			<table>
				<tbody>
					<tr>
						<th>Name</th>
						<th>Value</th>
					</tr>
					{editProperties ? 
						properties.map(property => 
							<EditProperty 
								key={property.label}
								property={property}
								properties={properties}
								setProperties={setProperties}
								propertiesConfig={productConfig.variantProperties}
							/>
						)
						:
						variant.properties.map(property => 
							<DisplayProperty 
								key={property.label} 
								property={property} 
								propertiesConfig={productConfig.variantProperties}
							/>
						)
					}
				</tbody>
			</table>
			{editProperties && render_add_property_controls() }
			<div className="property-edit-container">
				{!product.synced && render_edit_cp()}
			</div>
		</div>
	);
}
