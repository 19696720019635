import "./Shop.scss";
import {
	faArrowLeftLong,
	faMagnifyingGlass,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Button from "src/Components/Buttons/Button";
import AddProduct from "./AddProduct";

export default function ProductList({ 
	close, 
	setEditProduct, 
	setEditProductID, 
	defaultTyreImg,
	displayProducts,
	search,
	show_more,
	setSearch,
	reload_products,
}) {
	// Whether we are adding a product
	const [ addingProduct, setAddingProduct ] = useState(false);
	
	/**
	 * Product has been added, open the edit product view for this product
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function product_added(response){
		setEditProduct(response);
		setEditProductID(response.product.productID);
		reload_products();
	}


	/***************************************************************************************************
	 *
	 * 																				RENDER
	 *
	 **************************************************************************************************/

	/**
	 * Render a single product row
	 *
	 * @param 		{object} 	product 									Product to render
	 *
	 * @returns 	{jsx} 															Product row
	 *
	 * @author 					Pætur Mortensen
	 */
	function render_product_row(product) {
		
		return (
			<tr
				key={product.productID}
				onClick={() => {
					setEditProductID(product.productID);
				}}
			>
				<td>
					<div className="image">
						<img
							alt=""
							src={product.thumbnail}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = defaultTyreImg;
							}}
						/>
					</div>
				</td>
				<td>
					<div className="wrap-name">
						<div>{product.name}</div>
					</div>
				</td>
				<td>{product.productType ?? "No Type"}</td>
				<td>{product.variants.length}</td>
				<td>{product.available}</td>
				<td>{product.reg_date}</td>
			</tr>
		);
	}

	/**
	 * Render the header
	 * 
	 * @returns 	{jsx} 															Header
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_header() {
		
		/**
		 * Render the search bar
		 *
		 * @returns 	{jsx} 																		Search bar
		 *
		 * @author 					Pætur Mortensen
		 */
		function render_search() {
			return (
				<div className="search">
					<input
						type={"text"}
						value={search}
						onChange={(e) => {
							setSearch(e.target.value.toLowerCase());
						}}
						placeholder="Search products..."
					></input>
					<FontAwesomeIcon icon={faMagnifyingGlass} />
				</div>
			);
		}

		function render_add_product_btn() {
			return (
				<Button
					onClick={() => {setAddingProduct(true)}}
				>
					Add product
				</Button>
			);
		}

		return (
			<div className="top">
				<div>
					<div className="title">
						<div
							onClick={() => {
								close();
							}}
						>
							<FontAwesomeIcon icon={faArrowLeftLong} />
						</div>
						<h1>Products</h1>
					</div>
					{render_add_product_btn()}
				</div>
				<div>
					{render_search()}
				</div>
			</div>
		);
	}

	return (

		<div className="products list">
			{addingProduct && 
				<AddProduct 
					onClose={() => setAddingProduct(false)}
					onAdd={ response => {
						product_added(response);
						setAddingProduct(false);
					}} 
				/>
			}
			{render_header()}
			<div className="list">
				<table>
					<tbody>
						<tr>
							<th></th>
							<th>Name</th>
							<th>Type</th>
							<th>Variants</th>
							<th>Available</th>
							<th>Dato</th>
						</tr>
						{/* Product rows */}
						{displayProducts.map((product) => {
							return render_product_row(product);
						})}
					</tbody>
				</table>
				<div
					className="show-more-items"
					onClick={() => { show_more() }}
				>
					Show more
				</div>
			</div>
		</div>
	);
}
