import { useEffect, useState } from "react";
import VfiCheckbox from "../../VfiCheckbox";
import VfiInputText from "../../VfiInputText/VfiInputText";
import "./ModuleOptions.scss";
import Select from "react-select";
import moduleIcons from "../../images/module icons";
import ReactPlayer from "react-player";
import { cloneDeep } from "lodash";

const captionOptions = [
  { label: "None", value: "none" },
  // { label: "On hover", value: "on_hover" },
  {
    label: "Under image",
    value: "under_image",
  },
];

// Used in EditPage.js
export default function OneMediaMO(props) {
  const { values, change } = props;
  const [screen, setScreen] = useState("media");
  const [innerValues, setInnerValues] = useState(props.values);

  const setValues = (v) => {
    const newV = cloneDeep(v);
    change(newV);
    setInnerValues(newV);
  };

  useEffect(() => {
    setInnerValues(values);
  }, [values]);

  const setValue = (key, value) => {
    if (Array.isArray(key)) {
      let copyValues = cloneDeep(innerValues);
      for (let i = 0; i < key.length; i++) {
        copyValues[key[0]] = value[0];
      }
      props.change(copyValues);
      setInnerValues(copyValues);
    } else {
      let newValue = {
        ...innerValues,
        [key]: value,
        videoUpdate: new Date().getTime(),
      };
      props.change(newValue);
      setInnerValues(newValue);
    }
  };

  const getDomain = (url) => {
    let a = document.createElement("a");
    a.href = url;
    return a.hostname;
  };

  const handleUrlDomain = (e) => {
    let newDomain = undefined;
    if (e.getInternalPlayer) {
      if (e.getInternalPlayer().getVideoUrl) {
        newDomain = getDomain(e.getInternalPlayer().getVideoUrl());
      }
      if (e.getInternalPlayer().origin) {
        newDomain = getDomain(e.getInternalPlayer().origin);
      }
    }

    // set also playlist for youtube
    if (e.getInternalPlayer && e.getInternalPlayer().getVideoData) {
      setValue(
        ["domain", "videoUrlParams"],
        [
          newDomain,
          {
            ...innerValues.videoUrlParams,
            playlist: e.getInternalPlayer().getVideoData().video_id,
          },
        ]
      );
    } else {
      setValue("domain", newDomain);
    }
  };
  let content;
  switch (screen) {
    case "media":
      content = (
        <div className="media">
          <div className="media-sizes">
            <div
              className={
                "media-size" + (innerValues.size === "small" ? " active" : "")
              }
              onClick={() => {
                setValue("size", "small");
              }}
            >
              <div className="box box-350" />
              <p>350</p>
            </div>
            <div
              className={
                "media-size" + (innerValues.size === "medium" ? " active" : "")
              }
              onClick={() => {
                setValue("size", "medium");
              }}
            >
              <div className="box box-700" />
              <p>700</p>
            </div>
            <div
              className={
                "media-size" + (innerValues.size === "large" ? " active" : "")
              }
              onClick={() => {
                setValue("size", "large");
              }}
            >
              <div className="box box-1440" />
              <p>1440</p>
            </div>
            <div
              className={
                "media-size" + (innerValues.size === "full" ? " active" : "")
              }
              onClick={() => {
                setValue("size", "full");
              }}
            >
              <div className="box">
                <img alt="slider-display" src={moduleIcons.slider} />
              </div>
              <p>Edge</p>
            </div>
          </div>
          <div className="is-vimeo">
            <label>
              <VfiCheckbox
                className="vimeo-checkbox"
                checked={innerValues.isVideoUrl}
                onChange={(e) => {
                  setValue("isVideoUrl", e.target.checked);
                }}
              />
              External Media URL
            </label>
            <ReactPlayer
              // key={innerValues.videoUpdate}
              style={{ display: "none" }}
              url={innerValues.videoUrl}
              onReady={handleUrlDomain}
              config={
                innerValues.videoUrlParams
                  ? {
                      youtube: {
                        playerVars: {
                          autoplay: innerValues.videoUrlParams.autoplay ? 1 : 0,
                          controls: innerValues.videoUrlParams.controls ? 1 : 0,
                          start: innerValues.videoUrlParams.start,
                          end: innerValues.videoUrlParams.end,
                          loop: innerValues.videoUrlParams.loop ? 1 : 0,
                          playlist: innerValues.videoUrlParams.playlist,
                        },
                      },
                      vimeo: {
                        playerOptions: {
                          autoplay: innerValues.videoUrlParams.autoplay ? 1 : 0,
                          controls: innerValues.videoUrlParams.controls ? 1 : 0,
                          loop: innerValues.videoUrlParams.loop ? true : false,
                        },
                      },
                    }
                  : {}
              }
            />
            <div
              className={
                "vimeo-link" + (innerValues.isVideoUrl ? "" : " disabled")
              }
            >
              {/* <span>https://vimeo.com/</span> */}
              <VfiInputText
                placeholder="link"
                value={innerValues.videoUrl}
                onChange={(e) => {
                  setValue("videoUrl", e.target.value);
                }}
              />
            </div>
            <div className="video-settings">
              {innerValues.domain === "www.youtube.com" &&
                innerValues.isVideoUrl && [
                  <div className="sub-title">Youtube settings</div>,
                  <label>
                    <div className="option">
                      Auto play
                      <VfiCheckbox
                        className="checkbox"
                        checked={innerValues.videoUrlParams.autoplay}
                        onChange={(e) => {
                          setValue("videoUrlParams", {
                            ...innerValues.videoUrlParams,
                            autoplay: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </label>,
                  <label>
                    <div className="option">
                      Controls
                      <VfiCheckbox
                        className="checkbox"
                        checked={innerValues.videoUrlParams.controls}
                        onChange={(e) => {
                          setValue("videoUrlParams", {
                            ...innerValues.videoUrlParams,
                            controls: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </label>,
                  <label>
                    <div className="option">
                      Loop
                      <VfiCheckbox
                        className="checkbox"
                        checked={innerValues.videoUrlParams.loop}
                        onChange={(e) => {
                          setValue("videoUrlParams", {
                            ...innerValues.videoUrlParams,
                            loop: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </label>,
                ]}
              {innerValues.domain === "player.vimeo.com" &&
                innerValues.isVideoUrl && [
                  <div className="sub-title">Vimeo settings</div>,
                  <label>
                    <div className="option">
                      Auto play
                      <VfiCheckbox
                        className="checkbox"
                        checked={innerValues.videoUrlParams.autoplay}
                        onChange={(e) => {
                          setValue("videoUrlParams", {
                            ...innerValues.videoUrlParams,
                            autoplay: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </label>,
                  <label>
                    <div className="option">
                      Controls
                      <VfiCheckbox
                        className="checkbox"
                        checked={innerValues.videoUrlParams.controls}
                        onChange={(e) => {
                          setValue("videoUrlParams", {
                            ...innerValues.videoUrlParams,
                            controls: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </label>,
                  <label>
                    <div className="option">
                      Loop
                      <VfiCheckbox
                        className="checkbox"
                        checked={innerValues.videoUrlParams.loop}
                        onChange={(e) => {
                          setValue("videoUrlParams", {
                            ...innerValues.videoUrlParams,
                            loop: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </label>,
                ]}
              {getDomain(innerValues.videoUrl) === "soundcloud.com" &&
                innerValues.isVideoUrl && [
                  <div className="sub-title">SoundCloud settings</div>,
                  <label>
                    <div className="option">
                      Auto play
                      <VfiCheckbox
                        className="checkbox"
                        checked={innerValues.videoUrlParams.autoplay}
                        onChange={(e) => {
                          setValue("videoUrlParams", {
                            ...innerValues.videoUrlParams,
                            autoplay: e.target.checked,
                          });
                        }}
                      />
                    </div>
                  </label>,
                  // <label>
                  //   <div className='option'>
                  //     Controls
                  //     <VfiCheckbox
                  //       className='checkbox'
                  //       checked={innerValues.videoUrlParams.controls}
                  //       onChange={(e) => {
                  //         setValue('videoUrlParams', {
                  //           ...innerValues.videoUrlParams,
                  //           controls: e.target.checked
                  //         });
                  //       }}
                  //     />
                  //   </div>
                  // </label>,
                  // <label>
                  //   <div className='option'>
                  //     Loop
                  //     <VfiCheckbox
                  //       className='checkbox'
                  //       checked={innerValues.videoUrlParams.loop}
                  //       onChange={(e) => {
                  //         setValue('videoUrlParams', {
                  //           ...innerValues.videoUrlParams,
                  //           loop: e.target.checked
                  //         });
                  //       }}
                  //     />
                  //   </div>
                  // </label>
                ]}
            </div>
          </div>
          <div className="option">
            <div className="label">Caption</div>
            <div className="value">
              <Select
                options={captionOptions}
                value={captionOptions.find(
                  (e) => e.value === innerValues.caption
                )}
                onChange={(e) => {
                  setValue("caption", e.value);
                }}
              />
            </div>
          </div>
        </div>
      );
      break;
    default:
      content = "Should not be shown.";
  }

  return (
    <div className="one-media edit-module">
      <div className="top">
        <div
          className={"top-choice " + (screen === "media" ? "active " : "")}
          onClick={() => setScreen("media")}
        >
          MEDIA
        </div>
      </div>
      <div className="content">{content}</div>
    </div>
  );
}
