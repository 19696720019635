import { ChangeEventHandler, useState } from "react";
import Switch from "react-switch";
import styles from "./VfiSwitchButton.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface props {
  className?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: boolean) => void;
}
export function VfiSwitchButton(props: props) {
  const { className, defaultChecked, checked, disabled, onChange } = props;
  const [inDefaultChecked, setInDefaultChecked] = useState(
    defaultChecked ?? false
  );
  const switchOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setInDefaultChecked(checked);
    if (onChange) onChange(checked);
  };
  const isChecked = checked ?? inDefaultChecked;
  const isDisabled = disabled ?? false;
  return (
    // <Switch
    //   className={`${styles["vfi-switch-button"]} ` + (className ?? "")}
    //   checked={checked ?? inDefaultChecked}
    //   onChange={switchOnChange}
    //   disabled={disabled ?? false}
    // />
    <label
      className={
        `vfi-switch-button ${styles["vfi-switch-button"]}
        ${isChecked ? `${styles.checked} vfi-switch-button-checked` : ""}
        ${isDisabled ? `${styles.disabled} vfi-switch-button-disabled` : ""} ` +
        (className ?? "")
      }
    >
      <div className={styles["background"]}>
        <div className={styles["front-button"]}>
          <FontAwesomeIcon icon={faCheck as IconProp} />
        </div>
      </div>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={switchOnChange}
        disabled={isDisabled}
      />
    </label>
  );
}
