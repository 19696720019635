import axios from "axios";
import { cloneDeep } from "lodash";
import { useEffect, useRef, useState } from "react";
import "./WhatsonModule2.scss";
import env from "../../../environment.json";
import Select from "react-select";
import languages from "../language.json";
const language = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "english";

export default function WhatsonModule2(props) {
  const [regions, setRegions] = useState(undefined);
  const [whatson, setWhatson] = useState(undefined);
  const theLanguage = languages[language].whatson_module;
  useEffect(() => {
    axios
      .get(env.protocol + env.env + "/api/public/GetRegions.php")
      .then((response) => {
        let values = [];
        response.data.forEach((element) => {
          values.push({ label: element.region_name, value: element.region_id });
        });
        setRegions(values);
      })
      .catch((error) => console.error(error));

    axios
      .get(env.protocol + env.env + "/api/whatson/GetWhatsonNames")
      .then((response) => {
        let values = [];
        response.data.forEach((element) => {
          values.push({ label: element.name, value: element.id });
        });
        setWhatson(values);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const iframeFunc = (e) => {
      if (e.data.type) {
        const what_is_on = whatson.find(
          (e) => parseInt(e.value) === parseInt(props.data.whatson)
        );
        if (e.data.type.toLowerCase() === what_is_on.label.toLowerCase()) {
          setIframeHeight(e.data.height);
        }
      }
      // setIframeHeight(e.target.contentWindow.document.body.scrollHeight);//.document.body.scrollHeight);
    };
    if (iframeRef && iframeRef.current) {
      window.addEventListener("message", iframeFunc);

      return () => {
        window.removeEventListener("message", iframeFunc);
      };
    }
  });

  const [iframeHeight, setIframeHeight] = useState(200);
  const iframeRef = useRef(null);

  const what_is_on = whatson
    ? whatson.find((e) => e.value === parseInt(props.data.whatson))
    : undefined;

  return (
    <div style={props.style} className="whatson-module">
      {/* <Iframe
          className="the-iframe"
          src={
            what_is_on
              ? "http://whatson.fo.linux149.unoeuro-server.com/" +
                // props.data.what_is_on +
                (what_is_on ? what_is_on.label.toLowerCase() : "") +
                "?iframe"
              : ""
          }
          width="100%"
          height="100%"
          ref={iframeRef}
        /> */}
      <iframe
        ref={iframeRef}
        key={what_is_on ? what_is_on.label.toLowerCase() : ""}
        title="a"
        className="the-iframe"
        src={
          what_is_on
            ? "http://whatson.fo.linux149.unoeuro-server.com/" +
              // props.data.what_is_on +
              (what_is_on ? what_is_on.label.toLowerCase() : "") +
              "?iframe"
            : ""
        }
        // src={"https://example.com"}
        onLoad={() => {
          // setIframeHeight(iframeRef.current.contentWindow.document.body.scrollHeight + "px");
        }}
        width="100%"
        height={iframeHeight}
        scrolling={"no"}
        // style={{pointerEvents:"none"}}
      ></iframe>
      <div className="select-wrap row">
        <Select
          className="select what-whatson col-sm-6"
          options={whatson && whatson.map((e) => e)}
          value={
            whatson &&
            whatson.find(
              (e) => parseInt(props.data.whatson) === parseInt(e.value)
            )
          }
          onChange={(e) => {
            let copyData = cloneDeep(props.data);
            copyData.whatson = e.value;
            props.updateData(copyData);
          }}
          placeholder={theLanguage.whatson_placeholder}
        />
        <Select
          className="select what-region col-sm-6"
          options={regions && regions.map((e) => e)}
          value={
            regions &&
            regions.find(
              (e) => parseInt(props.data.region) === parseInt(e.value)
            )
          }
          onChange={(e) => {
            let copyData = cloneDeep(props.data);
            copyData.region = e.value;
            props.updateData(copyData);
          }}
          placeholder={theLanguage.region_placeholder}
        />
      </div>
    </div>
  );
}
