import BrandList from "./BrandList";
import styles from "./Brands.module.scss";
import BrandsHeader from "./BrandsHeader";
import { useState, useEffect } from "react";
import { Brand, ProductType, PriceRule } from "./types";
import axios from "axios";
import env from "../../../../environment.json";
import shopConfig from "../shopConfig";

/**
 * Brands component
 * 
 * Holds list of brands and options for editing these
 * 
 * @author 					Pætur Mortensen 
 */
export default function Brands(): JSX.Element {
	// List of brands as they are on the server (for checking for changes)
	const [serverBrands, setServerBrands] = useState<Brand[]>([]);
	// List of price rules
	const [priceRules, setPriceRules] = useState<PriceRule[]>([]);
	// List of product types
	const [productTypes, setProductTypes] = useState<ProductType[]>([]);
	// Whether component is loading
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// Load the brands and config information
	useEffect(() => {
		load_brands();
	}, []);

	/**
	 * Load the brands form server
	 * 
	 * @author 					Pætur Mortensen
	 */
	function load_brands() : void {
		setIsLoading(true);

		axios
			.get(
				env.protocol +
					env.env +
					"/api/secured/shop/AdminGetBrands?siteID=" +
					shopConfig.siteID
			)
			.then((response) => {
				setServerBrands(response.data.brands);
				setProductTypes(response.data.productTypes);
				setPriceRules(response.data.priceRules);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	}

	/**
	 * Reload brands from server
	 * 
	 * @author 					Pætur Mortensen
	 */
	function reload_brands() : void {
		load_brands();
	}

	return (
		<div className={styles.brands}>
			<BrandsHeader 
				reload_brands={reload_brands}
				productTypes={productTypes}
			/>
			<BrandList
				serverBrands={serverBrands}
				priceRules={priceRules}
				reload_brands={reload_brands}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
			/>
		</div>
	);
}
