import { faChevronDoubleRight } from "@fortawesome/pro-light-svg-icons";
import {
  faChevronDoubleLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import env from "../../../../environment.json";
import "./PLInfoscreens.scss";

function PLInfoscreens({ data, setData = () => void 0, editable = false }) {
  //   const [data, setData] = useState(cloneDeep(d));
  const [infoscreens, setInfoscreens] = useState([]);
  const [regions, setRegions] = useState([]);

  const [isToAdd, setIsToAdd] = useState([]);
  const [isToRemove, setIsToRemove] = useState([]);

  const [allIsToAdd, setAllIsToAdd] = useState([]);
  const [allIsToRemove, setAllIsToRemove] = useState([]);

  useEffect(() => {
    axios
      .get(env.protocol + env.env + "/api/public/infoscreen/GetInfoscreens.php")
      .then((response) => {
        setInfoscreens(response.data);
      })
      .catch((error) => console.error(error));
    axios
      .get(env.protocol + env.env + "/api/public/GetRegions.php")
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => console.error(error));
  }, []);

  const InfoscreensInRegions = useCallback(
    ({
      className,
      // list = [],
      addedAllRegionList = [],
      onSingleClick = () => void 0,
      onAllClick = () => void 0,
      selectedIds = [],
      added = false,
    }) => {
      const dataArticle = data.page.article;

      const list = infoscreens.filter((is) => {
        const allRegionPart =
          Boolean(
            dataArticle.article_filters.to_all_in_region?.find((tair) => {
              return (
                parseInt(tair.value) === is.region_id && !Boolean(tair.disabled)
              );
            })
          ) === added;

        const individualISPart =
          Boolean(
            dataArticle.infoscreens.find(
              (da) => da.infoscreen_id === is.id && !da.disabled
            )
          ) === added;

        if (added) {
          return allRegionPart || individualISPart;
        } else {
          return allRegionPart && individualISPart;
        }
      });

      const regionsLeft = regions.filter((r) => {
        return (
          dataArticle.article_filters.to_all_in_region
            .filter((tair) => !tair.disabled)
            .map((tair) => parseInt(tair.value))
            .includes(parseInt(r.region_id)) === added
        );
      });

      const addedAllRegionListId = addedAllRegionList.map((e) =>
        parseInt(e.value)
      );
      const regionList = added ? allIsToRemove : allIsToAdd;
      const allSelected =
        list.every((l) => selectedIds.includes(l.id)) &&
        regionsLeft.every((rl) => regionList.includes(parseInt(rl.region_id)));

      return (
        <div className={className}>
          {((added && regionsLeft.length === regions.length) ||
            (!added && (list.length > 0 || regionsLeft.length > 0))) && (
            <div key={"FO"} className="region">
              <div className="region-wrap">
                <div className="region-name">Faroe Islands</div>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
              <div className="list-in-region">
                <button
                  className={"all-is" + (allSelected ? " selected" : "")}
                  onClick={() =>
                    onAllClick(
                      list.map((is) => is.id),
                      undefined,
                      allSelected
                    )
                  }
                >
                  All infoscreens
                </button>
              </div>
            </div>
          )}
          {regions.map((region) => {
            const regionId = parseInt(region.region_id);
            const regionAddedInAll = addedAllRegionListId.includes(regionId);
            const listByRegion = list.filter(
              (l) => parseInt(l.region_id) === regionId
            );
            if (
              listByRegion.length === 0 &&
              ((!added &&
                dataArticle.article_filters.to_all_in_region?.find(
                  (tair) =>
                    parseInt(tair.value) === regionId && !Boolean(tair.disabled)
                )) ||
                added)
            )
              return "";
            const allSelected = (added ? allIsToRemove : allIsToAdd).includes(
              regionId
            );
            // listByRegion.every((l) => {
            //   return selectedIds.includes(l.id);
            // });
            const allRegionShown = added
              ? data.page.article.article_filters.to_all_in_region?.find(
                  (tair) => parseInt(tair.value) === regionId && !tair.disabled
                ) !== undefined
              : true;
            return (
              <div key={region.id} className="region">
                <div className="region-wrap">
                  <div className="region-name">{region.region_name}</div>
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
                <div className="list-in-region">
                  {allRegionShown && (
                    <button
                      className={"all-is" + (allSelected ? " selected" : "")}
                      onClick={() =>
                        onAllClick(
                          listByRegion.map((is) => is.id),
                          region.region_id,
                          // listByRegion.every((l) => selectedIds.includes(l.id))
                          allSelected
                        )
                      }
                    >
                      All infoscreens
                    </button>
                  )}
                  {listByRegion.map((is) => (
                    <button
                      className={selectedIds.includes(is.id) ? "selected" : ""}
                      key={is.id}
                      onClick={() => onSingleClick(is.id)}
                    >
                      {is.name}
                    </button>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    [
      regions,
      isToAdd,
      isToRemove,
      editable,
      allIsToAdd,
      allIsToRemove,
      data,
      infoscreens,
    ]
  );

  const onSingleClick = useCallback(
    (id, toAdd = true) => {
      const list = toAdd ? isToAdd : isToRemove;
      if (!list.includes(id)) {
        list.push(id);
      } else {
        list.splice(
          list.findIndex((l) => l === id),
          1
        );
      }

      const infoscreen = infoscreens.find((is) => is.id === id);

      if (toAdd) {
        setIsToAdd([...list]);
        if (infoscreen)
          setAllIsToAdd(
            [...allIsToAdd].filter((aitr) => aitr !== infoscreen.region_id)
          );
      } else {
        setIsToRemove([...list]);
        if (infoscreen)
          setAllIsToRemove(
            [...allIsToRemove].filter((aitr) => aitr !== infoscreen.region_id)
          );
      }
    },
    [isToAdd, isToRemove, infoscreens, allIsToAdd, allIsToRemove]
  );

  const onAllClick = useCallback(
    (listOfIds, regionId, toAdd = true, removeAll = false) => {
      const list = toAdd ? isToAdd : isToRemove;
      const regionList = toAdd ? allIsToAdd : allIsToRemove;
      regionId = parseInt(regionId);
      const regionIdExists = ![undefined, NaN].includes(regionId);
      if (!removeAll) {
        // Singles
        for (let i = 0; i < listOfIds.length; i++) {
          if (!list.includes(listOfIds[i])) list.push(listOfIds[i]);
        }

        //Regions
        if (regionIdExists) {
          if (!regionList.includes(regionId)) {
            regionList.push(regionId);
          }
        } else {
          // ADD ALL!!!
          const toAllInRegion =
            data.page.article.article_filters.to_all_in_region;
          const restOfRegions = regions
            .filter((r) => {
              const re = toAllInRegion.find((tair) => {
                return (
                  parseInt(tair.value) === parseInt(r.region_id) &&
                  !tair.disabled
                );
              });
              if (toAdd) {
                return re === undefined;
              } else {
                return re !== undefined;
              }
            })
            .map((r) => parseInt(r.region_id));

          regionList.push(...restOfRegions);
        }
      } else {
        // Singles
        for (let i = 0; i < listOfIds.length; i++) {
          const index = list.findIndex((l) => l === listOfIds[i]);
          if (index !== -1) {
            list.splice(index, 1);
          }
        }

        //Regions
        if (regionIdExists) {
          const index = regionList.findIndex((l) => l === regionId);
          if (index !== -1) {
            regionList.splice(index, 1);
          }
        } else {
          // REMOVE ALL!!!
          regionList.splice(0, regionList.length);
        }
      }

      if (toAdd) {
        setAllIsToAdd([...regionList]);
        setIsToAdd([...list]);
      } else {
        setAllIsToRemove([...regionList]);
        setIsToRemove([...list]);
      }
    },
    [isToAdd, isToRemove, allIsToAdd, allIsToRemove, data, regions]
  );

  const addIs = useCallback(() => {
    for (let i = 0; i < isToAdd.length; i++) {
      const infoscreen = data.page.article.infoscreens.find(
        (is) => is.infoscreen_id === isToAdd[i]
      );
      if (infoscreen) {
        infoscreen.disabled = 0;
      } else {
        const newInfoscreen = infoscreens.find((is) => is.id === isToAdd[i]);
        data.page.article.infoscreens.push({
          infoscreen_id: isToAdd[i],
          disabled: 0,
          region_id: newInfoscreen.region_id,
        });
      }
    }

    for (let i = 0; i < allIsToAdd.length; i++) {
      const theRegion = data.page.article.article_filters.to_all_in_region.find(
        (tair) => parseInt(tair.value) === allIsToAdd[i]
      );

      if (theRegion) {
        theRegion.disabled = 0;
      } else {
        data.page.article.article_filters.to_all_in_region.push({
          value: allIsToAdd[i],
        });
      }
    }
    setData({ ...data });
    setIsToAdd([]);
    setAllIsToAdd([]);
  }, [isToAdd, data]);

  const removeIs = useCallback(() => {
    for (let i = 0; i < isToRemove.length; i++) {
      const infoscreen = data.page.article.infoscreens.find((is) => {
        return is.infoscreen_id === isToRemove[i];
      });
      if (infoscreen) {
        infoscreen.disabled = 1;
        const theRegion =
          data.page.article.article_filters.to_all_in_region.find((tair) => {
            return parseInt(tair.value) === infoscreen.region_id;
          });

        if (theRegion) {
          theRegion.disabled = 1;
        } else {
          // data.page.article.article_filters.to_all_in_region.push({
          //   value: infoscreen.region_id,
          //   disabled: 1,
          // });
        }
      } else {
        const infoscreen = infoscreens.find((is) => is.id === isToRemove[i]);
        data.page.article.infoscreens.push({
          infoscreen_id: isToRemove[i],
          disabled: 1,
        });
        const theRegion =
          data.page.article.article_filters.to_all_in_region.find((tair) => {
            return parseInt(tair.value) === infoscreen.region_id;
          });
        if (theRegion) {
          theRegion.disabled = 1;
        } else {
          data.page.article.article_filters.to_all_in_region.push({
            value: infoscreen.region_id,
            disabled: 1,
          });
        }
      }
    }

    for (let i = 0; i < allIsToRemove.length; i++) {
      const theRegion = data.page.article.article_filters.to_all_in_region.find(
        (tair) => parseInt(tair.value) === allIsToRemove[i]
      );

      if (theRegion) {
        theRegion.disabled = 1;
      }
    }

    setData({ ...data });
    setIsToRemove([]);
    setAllIsToRemove([]);
  }, [isToRemove, data]);

  return (
    <div className={"pl-infoscreens" + (!editable ? " disabled" : "")}>
      <InfoscreensInRegions
        className="added-is"
        // list={infoscreens}
        //   .filter((is) => {
        //   const dataInfoscreens = data.page.article.infoscreens
        //     // .filter((is) => !is.disabled)
        //     .map((is) => is.infoscreen_id);
        //   return dataInfoscreens.includes(is.id);
        // })}
        addedAllRegionList={data.page.article.article_filters.to_all_in_region}
        onSingleClick={(id) => onSingleClick(id, false)}
        onAllClick={(ids, regionId, removeAll) => {
          onAllClick(ids, regionId, false, removeAll);
        }}
        selectedIds={isToRemove}
        added={true}
      />
      {editable && (
        <>
          <div className="add-funcs">
            <div className="add">
              <button
                disabled={isToAdd.length === 0 && allIsToAdd.length === 0}
                onClick={addIs}
              >
                <FontAwesomeIcon icon={faChevronDoubleLeft} />
                <span className="text">Add</span>
              </button>
            </div>
            <div className="remove">
              <button
                disabled={isToRemove.length === 0 && allIsToRemove.length === 0}
                onClick={removeIs}
              >
                <span className="text">Remove</span>
                <FontAwesomeIcon icon={faChevronDoubleRight} />
              </button>
            </div>
          </div>
          <div className="unadded-is">
            <InfoscreensInRegions
              className="added-is"
              list={infoscreens}
              //   .filter((is) => {
              //   return !data.page.article.infoscreens
              //     // .filter((is) => !is.disabled)
              //     .map((is) => is.infoscreen_id)
              //     .includes(is.id);
              // })}
              addedAllRegionList={
                data.page.article.article_filters.to_all_in_region
              }
              onSingleClick={(id) => onSingleClick(id, true)}
              onAllClick={(ids, regionId, removeAll) => {
                onAllClick(ids, regionId, true, removeAll);
              }}
              selectedIds={isToAdd}
              added={false}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default PLInfoscreens;
