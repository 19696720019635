import cloneDeep from "lodash.clonedeep";
import React, { Component } from "react";
import VfiCheckbox from "../../../../../assets/VfiCheckbox/VfiCheckbox2";
import env from "../../../../../environment.json";
import Select from "react-select";
import "./Panel.scss";
import {
  useSitesStore,
  withSitesStore,
} from "../../../../ZustandStores/sitesStore";
import { withAllOfStore } from "../../../../ZustandStores/allOfStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import OverlayElement from "../../../../../assets/OverlayElement";
import VfiCustomSelect from "../../../../../assets/VfiCustomSelect";
import globalObject from "../../../../../assets/globalVariables";
import { capitalize } from "src/assets/helperFunctions";

const styles = {
  multiValue: (base, state) => {
    return state.data.isFixed ? { ...base, backgroundColor: "gray" } : base;
  },
  multiValueLabel: (base, state) => {
    return state.data.isFixed
      ? { ...base, fontWeight: "bold", color: "white", paddingRight: 6 }
      : base;
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: "none" } : base;
  },
};

class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuperAdmin: false,
      // // ----------
      // newOverlayOpen: false,
      // newPlaceOverlaySelected: undefined,
      // deletePlaceId: undefined,
      // // ----------
      // newTourOperatorOverlayOpen: false,
      // newTourOperatorOverlaySelected: undefined,
      // deleteTourOperatorId: undefined,
      // // ----------
      newOverlayOpen: false,
      overlayWhatType: undefined,
      overlayWhatTypeLabel: undefined,
      overlayWhatStoreType: undefined,
      overlayColumnKeyName: undefined,
      newOverlaySelected: undefined,
      deleteId: undefined,
    };
    this.regions = [];

    this.axios = require("axios");
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
  }

  onCheckboxChange(i, k) {
    this.props.handleOptionChange(i, k);
    this.props.showUpdateBtn();
  }

  //Check if i am super admin
  isSuperAdmin = () => {
    this.axios
      .post(env.protocol + env.env + "/api/secured/checkSuperAdmin")
      .then((response) => {
        this.setState({ isSuperAdmin: response.data === "1" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    this.axios
      .get(env.protocol + env.env + "/api/public/GetRegions.php")
      .then((response) => {
        const regions = response.data;
        this.regions = [];
        regions.forEach((element) => {
          this.regions.push({
            label: element.region_name,
            id: element.region_id,
          });
        });
        this.setState({});
      })
      .catch((error) => {
        console.error(error);
      });

    // this._isMounted = true;
    this.isSuperAdmin();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.site !== this.props.site) {
      this.isSuperAdmin();
    } else return false;
  }

  refresh() {
    this.isSuperAdmin();
  }

  selectRegion = (id) => {
    // // old way
    // let regions = cloneDeep(this.props.data.regions);

    // if (!Array.isArray(regions)) {
    //   regions = [];
    // }

    // if (regions.find((e) => parseInt(e) === parseInt(id)) !== undefined) {
    //   regions = regions.filter((e) => parseInt(e) !== parseInt(id));
    // } else {
    //   regions.push(parseInt(id));
    // }
    // this.props.onChange("regions", regions);

    this.changeARoleInPermissions(
      "regions",
      "region_id",
      parseInt(id),
      this.roleAdminId
    );

    this.props.showUpdateBtn();
  };

  changeARoleInPermissions = (type, keyName, whatId, toRoleId) => {
    const permissions = cloneDeep(this.props.data.permissions);
    if (permissions[type] === undefined) {
      permissions[type] = [];
    }
    const toChange = permissions[type].find((s) => {
      return s[keyName] === whatId;
    });
    // If the current role is the same as the one you want to change to, set it to -1.
    if (toChange?.role_id === toRoleId) {
      toChange.role_id = -1;
      toChange.hidden = false;
    } else {
      if (toChange !== undefined) {
        // we found the correct element to change, so we change it.
        toChange.role_id = toRoleId;
        toChange.hidden = false;
      } else {
        // we did not find the element, so we create it, so we can give the role permission info.
        permissions[type].push({ [keyName]: whatId, role_id: toRoleId });
      }
    }
    this.props.onChange("permissions", permissions);
    this.props.showUpdateBtn();
  };

  mapSites() {
    // Get sites that user self has rights to

    // var sites = this.props.data.permission_info?.map((data, i) => {
    //   if (parseInt(data.siteId) !== 0) {
    //     //"All users" have siteid 0. Cant give permission to all sites
    //     return (
    //       <ul key={i} className="pageListing">
    //         <li className="listsite">{data.siteName}</li>
    //         <li className={"checkbox "}>
    //           {/* Publisher */}
    //           <VfiCheckbox
    //             checked={parseInt(data.permission) === 2}
    //             onChange={(e) => this.onCheckboxChange(i, 2)}
    //           />
    //         </li>
    //         <li className={"checkbox "}>
    //           {/* Admin */}
    //           <VfiCheckbox
    //             checked={parseInt(data.permission) === 1}
    //             onChange={(e) => this.onCheckboxChange(i, 1)}
    //           />
    //         </li>
    //       </ul>
    //     );
    //   } else {
    //     return "";
    //   }
    // });
    const store = this.props.store;
    const adminRole = this.roles.find((role) => role.permission === "Admin");
    const shownSites = store.sitesStore.allSitesNoRootPages
      .filter((site) =>
        globalObject.vfiUser.permissions.sites.find(
          (s) => s.site_id === site.siteId && s.role_id === adminRole.id
        )
      )
      .map((data) => {
        const siteIsForUser = this.props.data.permissions.sites?.find(
          (site) => site.site_id === data.siteId
        );
        return (
          <ul key={data.siteId} className="pageListing">
            <li className="listsite">{data.name}</li>
            {["Publisher", "Admin"].map((role, i) => {
              const roleId = this.roles.find((r) => r.permission === role).id;
              return (
                <li key={role} className={"checkbox "}>
                  {/* Publisher */}
                  <VfiCheckbox
                    // checked={parseInt(data.permission) === 2}
                    checked={siteIsForUser?.role_id === roleId ? true : false}
                    // onChange={(e) => this.onCheckboxChange(i, 2)}
                    onChange={() => {
                      this.changeARoleInPermissions(
                        "sites",
                        "site_id",
                        data.siteId,
                        roleId
                      );
                    }}
                  />
                </li>
              );
            })}
          </ul>
        );
      });
    return (
      <>
        {shownSites.length > 0 ? (
          <ul className="pageListing head">
            <li className="text site">SITE</li>
            <li className="text publisher">Publisher</li>
            <li className="text admin">Admin</li>
          </ul>
        ) : (
          ""
        )}
        {shownSites}
      </>
    );

    // return sites;
  }

  mapArticles() {
    const shownArticles = this.props.store.articlesStore.articleTypes
      .filter((e) => {
        return (
          globalObject.vfiUser.permissions.article_types.find(
            (articleType) =>
              articleType.article_type_id === e.id &&
              articleType.permission === "Admin"
          ) !== undefined
        );
      })
      .map((articleType) => (
        <ul key={articleType.id} className="pageListing">
          <li className="listsite">{articleType.name}</li>
          {["Publisher", "Admin"].map((role) => {
            const roleId = this.roles.find((r) => r.permission === role).id;
            const userArticleType =
              this.props.data.permissions.article_types?.find(
                (userArticleType) =>
                  userArticleType.article_type_id === articleType.id
              );
            return (
              <li key={role} className="checkbox">
                <VfiCheckbox
                  // checked={false}
                  checked={userArticleType?.role_id === roleId ? true : false}
                  onChange={() => {
                    this.changeARoleInPermissions(
                      "article_types",
                      "article_type_id",
                      articleType.id,
                      roleId
                    );
                  }}
                />
              </li>
            );
          })}
        </ul>
      ));
    return (
      <>
        {shownArticles.length > 0 ? (
          <ul className="pageListing head">
            <li className="text site">ARTICLES</li>
            <li className="text publisher">Publisher</li>
            <li className="text admin">Admin</li>
          </ul>
        ) : (
          ""
        )}
        {shownArticles}
      </>
    );
  }

  mapSpecifics() {
    const shownArticles = this.props.store.userStore.permissions.specifics
      .filter((e) => e.permission === "Admin")
      .map((specific) => (
        <ul key={specific.id} className="pageListing">
          <li className="listsite">{capitalize(specific.name)}</li>
          {["Publisher", "Admin"].map((role) => {
            const roleId = this.roles.find((r) => r.permission === role).id;
            const userSpecific = this.props.data.permissions.specifics?.find(
              (userSpecific) => userSpecific.name === specific.name
            );
            return (
              <li key={role} className="checkbox">
                <VfiCheckbox
                  // checked={false}
                  checked={userSpecific?.role_id === roleId ? true : false}
                  onChange={() => {
                    this.changeARoleInPermissions(
                      "specifics",
                      "name",
                      specific.name,
                      roleId
                    );
                  }}
                />
              </li>
            );
          })}
        </ul>
      ));
    return (
      <>
        {shownArticles.length > 0 ? (
          <ul className="pageListing head">
            <li className="text site">SPECIFIC ACCESS</li>
            <li className="text publisher">Publisher</li>
            <li className="text admin">Admin</li>
          </ul>
        ) : (
          ""
        )}
        {shownArticles}
      </>
    );
  }

  mapRegions() {
    const shownRegions = this.regions
      .filter((data) => {
        return (
          globalObject.vfiUser.permissions.regions.find(
            (r) =>
              r.region_id === parseInt(data.id) &&
              r.role_id === this.roleAdminId
          ) !== undefined
        );
      })
      .map((data, i) => {
        return (
          <div
            key={data.label}
            className={
              "regionListing" +
              // (this.props.data.regions?.find((e) => e === parseInt(data.id)) !==
              // undefined
              //   ? " checked"
              //   : "")
              (this.props.data.permissions.regions?.find((e) => {
                return (
                  parseInt(e.region_id) === parseInt(data.id) &&
                  e.role_id === this.roleAdminId
                );
              }) !== undefined
                ? " checked"
                : "")
            }
            onClick={() => this.selectRegion(data.id)}
          >
            {data.label}
          </div>
        );
      });
    // return region;

    return (
      !env.env.includes("vita.fo") && (
        <div>
          {shownRegions.length > 0 && <li className="text region">REGIONS</li>}
          <div className="regions">{shownRegions}</div>
        </div>
      )
    );
  }

  onSuperAdChange = (val) => {
    const permissions = cloneDeep(this.props.data.permissions);
    if (val === false) {
      Object.keys(permissions).forEach((permissionName) => {
        permissions[permissionName] = permissions[permissionName].map((e) => ({
          ...e,
          role_id: -1,
          hidden: true,
        }));
      });
    }

    this.props.onChange(
      ["superAdmin", "permissions"],
      [val ? "1" : "0", permissions]
    );
    this.props.showUpdateBtn();
  };

  professions = [{ label: "Tour Operator", value: "tour_operator" }];

  roles = this.props.store.userStore.roles;
  roleAdminId = this.roles.find((role) => role.permission === "Admin").id;
  render() {
    const store = this.props.store;
    var nextVal;
    var superAdmin = parseInt(this.props.data.superAdmin);
    superAdmin === 1 ? (nextVal = "0") : (nextVal = "1");
    const placesLength = globalObject.vfiUser.permissions.places?.filter(
      (p) => p.role_id === this.roleAdminId
    ).length;
    const tourOperatorsLength =
      globalObject.vfiUser.permissions.tour_operators?.filter(
        (p) => p.role_id === this.roleAdminId
      ).length;
    return (
      <div className="permission-content">
        {this.state.isSuperAdmin ? (
          <ul className="SuperAdminParent" key="super_admin">
            <li className="text superAdminLine" />
            <li className="text superAdmin">Super Admin</li>
            <li className={"checkbox "}>
              <VfiCheckbox
                checked={superAdmin === 1}
                onChange={(e) => {
                  this.onSuperAdChange(e.target.checked);
                }}
              />
            </li>
          </ul>
        ) : (
          <div></div>
        )}
        {!parseInt(this.props.data.superAdmin) ? (
          <>
            <ul>
              <div>{this.mapSites()}</div>
              <div>{this.mapArticles()}</div>
              <div>{this.mapSpecifics()}</div>
              <div>{this.mapRegions()}</div>

              {placesLength > 0 ? (
                <ul className="pageListing head">
                  <li className="text site">PLACES</li>
                  <li className="text collaborator">Collaborator</li>
                  <li className="text publisher">Publisher</li>
                  <li className="text admin">Admin</li>
                </ul>
              ) : (
                ""
              )}
              {this.props.data.permissions.places
                ?.filter(
                  (e) =>
                    globalObject.vfiUser.permissions.places?.find(
                      (f) =>
                        f.place_id === e.place_id &&
                        f.role_id === this.roleAdminId
                    ) && !e.hidden
                )
                .map((e) => {
                  const thePlace = store.whatsonStore.places.find(
                    (p) => p.id === e.place_id
                  );
                  const changeRole = (roleId) => {
                    this.changeARoleInPermissions(
                      "places",
                      "place_id",
                      thePlace.id,
                      roleId
                    );
                  };
                  return (
                    <ul key={e.place_id} className="pageListing">
                      <li className="listsite">{thePlace.headline}</li>
                      {["Collaborator", "Publisher", "Admin"].map((role) => {
                        return (
                          <li key={role} className="checkbox">
                            <VfiCheckbox
                              checked={
                                this.roles.find((r) => r.permission === role)
                                  .id === e.role_id
                              }
                              onChange={() =>
                                changeRole(
                                  this.roles.find((r) => r.permission === role)
                                    .id
                                )
                              }
                            />
                          </li>
                        );
                      })}
                      <div
                        className="remove-element"
                        onClick={() => {
                          this.setState({
                            deleteId: e.place_id,
                            overlayWhatType: "places",
                            overlayWhatTypeLabel: "place",
                            overlayWhatStoreType: "places",
                            overlayColumnKeyName: "place_id",
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </ul>
                  );
                }) ?? ""}
              {placesLength > 0 ? (
                <div
                  className="add-new-button"
                  onClick={() => {
                    this.setState({
                      newOverlayOpen: true,
                      overlayWhatType: "places",
                      overlayWhatTypeLabel: "place",
                      overlayWhatStoreType: "places",
                      overlayColumnKeyName: "place_id",
                    });
                  }}
                >
                  Add place <FontAwesomeIcon icon={faPlus} />
                </div>
              ) : (
                ""
              )}

              {tourOperatorsLength > 0 ? (
                <ul className="pageListing head">
                  <li className="text site">TOUR OPERATORS</li>
                  <li className="text collaborator">Collaborator</li>
                  <li className="text publisher">Publisher</li>
                  <li className="text admin">Admin</li>
                </ul>
              ) : (
                ""
              )}
              {this.props.data.permissions.tour_operators
                ?.filter(
                  (e) =>
                    globalObject.vfiUser.permissions.tour_operators?.find(
                      (f) =>
                        f.tour_operator_id === e.tour_operator_id &&
                        f.role_id === this.roleAdminId
                    ) && !e.hidden
                )
                .map((e) => {
                  const theTourOperator = store.whatsonStore.tourOperators.find(
                    (to) => to.id === e.tour_operator_id
                  );
                  const changeRole = (roleId) => {
                    this.changeARoleInPermissions(
                      "tour_operators",
                      "tour_operator_id",
                      theTourOperator.id,
                      roleId
                    );
                  };
                  return (
                    <ul key={e.tour_operator_id} className="pageListing">
                      <li className="listsite">{theTourOperator.headline}</li>
                      {["Collaborator", "Publisher", "Admin"].map((role) => {
                        return (
                          <li key={role} className="checkbox">
                            <VfiCheckbox
                              checked={
                                this.roles.find((r) => r.permission === role)
                                  .id === e.role_id
                              }
                              onChange={() =>
                                changeRole(
                                  this.roles.find((r) => r.permission === role)
                                    .id
                                )
                              }
                            />
                          </li>
                        );
                      })}
                      <div
                        className="remove-element"
                        onClick={() => {
                          this.setState({
                            deleteId: e.tour_operator_id,
                            overlayWhatType: "tour_operators",
                            overlayWhatTypeLabel: "tour operator",
                            overlayWhatStoreType: "tourOperators",
                            overlayColumnKeyName: "tour_operator_id",
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </ul>
                  );
                }) ?? ""}
              {tourOperatorsLength > 0 ? (
                <div
                  className="add-new-button"
                  onClick={() => {
                    this.setState({
                      newOverlayOpen: true,
                      overlayWhatType: "tour_operators",
                      overlayWhatTypeLabel: "tour operator",
                      overlayWhatStoreType: "tourOperators",
                      overlayColumnKeyName: "tour_operator_id",
                    });
                  }}
                >
                  Add tour operator <FontAwesomeIcon icon={faPlus} />
                </div>
              ) : (
                ""
              )}
            </ul>
          </>
        ) : (
          ""
        )}

        <OverlayElement
          onSave={() => {
            const selectedId = this.state.newOverlaySelected;

            if (selectedId !== undefined) {
              // const permissions = cloneDeep(this.props.data.permissions);
              // permissions.places.push({ place_id: selectedId });
              // this.props.onChange("permissions", permissions);

              this.changeARoleInPermissions(
                this.state.overlayWhatType,
                this.state.overlayColumnKeyName,
                selectedId,
                -1
              );
              this.setState({
                newOverlayOpen: false,
                overlaySelected: undefined,
              });
            }
          }}
          onCancel={() => {
            this.setState({
              newOverlayOpen: false,
              newOverlaySelected: undefined,
            });
          }}
          isOpen={this.state.newOverlayOpen}
          close={() => {
            this.setState({
              newOverlayOpen: false,
              newOverlaySelected: undefined,
            });
          }}
          boxWidth={475}
        >
          <div className="overlay-element">
            <p>Add {this.state.overlayWhatTypeLabel}</p>
            <p className="small">
              {this.state.overlayWhatTypeLabel?.toUpperCase()}
            </p>
            <VfiCustomSelect
              options={store.whatsonStore[this.state.overlayWhatStoreType]
                // Filter away those that you as user doesn't have permission to assign.
                ?.filter((item) => {
                  const user = globalObject.vfiUser;
                  const userItems =
                    user.permissions[this.state.overlayWhatType];
                  if (
                    user.superAdmin ||
                    userItems.find(
                      (userP) =>
                        item.id === userP[this.state.overlayColumnKeyName] &&
                        userP.role_id === this.roleAdminId
                    ) !== undefined ||
                    false // TODO: The user should see places that he/she has region admin access to.
                  )
                    return true;
                  return false;
                })
                // Filter away those that the user is already assigned to.
                .filter(
                  (item) =>
                    this.props.data.permissions[
                      this.state.overlayWhatType
                    ]?.find(
                      (f) =>
                        f[this.state.overlayColumnKeyName] === item.id &&
                        !f.hidden
                    ) === undefined
                )
                .map((p) => ({ label: p.headline, value: p.id }))}
              onChange={(e) => {
                this.setState({ newOverlaySelected: e.value });
              }}
            />
          </div>
        </OverlayElement>
        {this.state.deleteId !== undefined && (
          <OverlayElement
            isOpen={true}
            onSave={() => {
              const permissions = cloneDeep(this.props.data.permissions);

              const theItem = permissions[this.state.overlayWhatType].find(
                (item) =>
                  item[this.state.overlayColumnKeyName] === this.state.deleteId
              );
              if (theItem !== undefined) {
                theItem.role_id = -1;
                theItem.hidden = true;
              }
              this.setState({ deleteId: undefined });
              this.props.onChange("permissions", permissions);
              this.props.showUpdateBtn();
            }}
            onCancel={() => {
              this.setState({ deleteId: undefined });
            }}
            saveText={"YES, DELETE"}
            close={() => {
              this.setState({ deleteId: undefined });
            }}
          >
            <p>
              Are you sure, you want to delete{" "}
              <b>
                {this.props.data.firstname} {this.props.data.lastname}
              </b>
              's permission to{" "}
              {
                store.whatsonStore[this.state.overlayWhatStoreType].find(
                  (p) => p.id === this.state.deleteId
                ).headline
              }
              ?
            </p>
          </OverlayElement>
        )}
      </div>
    );
  }
}
export default withAllOfStore(Permission);
