import { faSortUp, faSortDown, faSort } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import styles from "./MediaCenter.module.scss";
import { MediaListTableType, MediaListColumns, MediaListItem, ShiftDirections } from "./types";
import { useState, useEffect } from "react";
import env from "../../environment.json";
import MediaItemCard from "./MediaItemCard";
import { handle_image_error } from "src/utils/mediaHelpers";

/**
 * Table of media items
 * 
 * @author 					Pætur Mortensen
 */
export default function MediaListTable({
	media,
	checkedItems,
	setCheckedItems,
	reload_media,
	insert_media,
}: MediaListTableType) : JSX.Element {
	// URL to media (uploads) directory
	const uploadsURL = env.protocol + env.env + '/uploads/';
	const numRowsPerPage = 25;

	// Sort by and direction
	const [ sortBy, setSortBy ] = useState<MediaListColumns>('date');
	// Sort direction, true: ASC, false: DESC
	const [ sortDirection, setSortDirection ] = useState<boolean>(true);
	// Media items for use in this component
	const [ mediaItems, setMediaItems ] = useState<Array<MediaListItem>>([]);
	// ID of media that is open if any
	const [ openMediaItem, setOpenMediaItem ] = useState<number|null>(null);
	// Number of rows to show
	const [ numRowsToShow, setNumRowsToShow ] = useState<number>(numRowsPerPage);
	// Whether all visible rows are checked
	const [ allChecked, setAllChecked ] = useState<boolean>(false);

	// Whenever media, sort type or sort direction change...
	useEffect(() => {
		// Apply the set sort order
		apply_sort_order();
	},[sortBy, sortDirection, media]);

	/**
	 * Apply the set sorting
	 * 
	 * @author 					Pætur Mortensen
	 */
	function apply_sort_order() : void {
		const newMediaItems = [...media];
		
		/**
		 * Compare numbers for sorting
		 * 
		 * @author 					Pætur Mortensen
		 */
		function compare_numbers(a:number, b:number) : number{
			return sortDirection ? b-a : a-b;
		}

		/**
		 * Compare strings for sorting
		 * 
		 * @author 					Pætur Mortensen
		 */
		function compare_strings(a:string, b:string) : number {
			return a.toLowerCase() > b.toLowerCase() ? 
				(sortDirection ? -1 : 1) : 
				(sortDirection ? 1 : -1);
		}

		// Map columns to mediaItems keys and sorting types
		const columnMap = {
			author:{key:'author', sortType:'string'},
			date:{key:'dateUNIX', sortType:'number'},
			location:{key:'locations', sortType:'string'},
			size:{key:'fileSizeInt', sortType:'number'},
			file:{key:'name', sortType:'string'}
		};
		
		// Sort the media items
		newMediaItems.sort( (a, b) => {
			// Extract key and sort type from the columnMap
			const { key, sortType } = columnMap[sortBy];

			// Set sortable types 
			const sortA = a[key as keyof typeof a];
			const sortB = b[key as keyof typeof b];

			// Depending on sorting type...
			switch(sortType){
				case 'string':
					// String sort
					return compare_strings(sortA as string, sortB as string);
				case 'number':
					// Numeric sort
					return compare_numbers(sortA as number, sortB as number);
				default: 
					console.error('Trying to sort by unsupported type');
					// Should not reach this
					return 0;
			}
		});

		setMediaItems(newMediaItems);
	}

	/**
	 * Handle check of a single item
	 *
	 * @author 					Pætur Mortensen
	 */
	function handle_item_check(checked: boolean, mediaID: number) : void {
		const newCheckedItems: number[] = [...checkedItems];
		// If we are checking the box...
		if (checked) {
			if (!newCheckedItems.includes(mediaID)) {
				newCheckedItems.push(mediaID);
			}
		} else {
			// We are unchecking the box
			if (newCheckedItems.includes(mediaID)) {
				const itemIndex = newCheckedItems.findIndex((item) => item === mediaID);
				newCheckedItems.splice(itemIndex, 1);
			}
		}

		setCheckedItems(newCheckedItems);
	}

	/**
	 * Toggle check all
	 *
	 * Toggle between checking and unchecking all items
	 *
	 * @author 					Pætur Mortensen
	 */
	function toggle_check_all(checked: boolean) : void {
		let newCheckedItems: number[] = [];
		if (checked) {
			newCheckedItems = mediaItems.slice(0, numRowsToShow).map((item) => item.mediaID);
		}

		setCheckedItems(newCheckedItems);
		setAllChecked(checked);
	}

	/**
	 * Shift open media item card
	 * 
	 * Go to previous or next media item card
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function shift_media_item_card(direction:ShiftDirections) : void {
		// Index of currently open media item
		const currentIdx = mediaItems.findIndex(item => item.mediaID === openMediaItem);

		// Init the new index as the current one (no change if not possible)
		let newIdx = currentIdx;
		if(direction === 'next'){
			// Increment the index and if this element exists, set this as new index
			const checkIdx = currentIdx +1;
			if(typeof mediaItems[checkIdx] !== 'undefined') newIdx = checkIdx;
		} else {
			const checkIdx = currentIdx - 1;
			if(typeof mediaItems[checkIdx] !== 'undefined') newIdx = checkIdx;
		}

		// Only update if there has been a change in index
		if(newIdx !== currentIdx){
			// We're using the media ID for setting the open card, so find this and set the value
			const destinationID = mediaItems[newIdx].mediaID;
			setOpenMediaItem(destinationID);
		}
	}

	/**
	 * Close any open media item card
	 * 
	 * @author 					Pætur Mortensen
	 */
	function close_media_item_card() : void {
		setOpenMediaItem(null);
	}

	/**
	 * Show more rows of media
	 * 
	 * @author 					Pætur Mortensen
	 */
	function show_more() : void {
		setNumRowsToShow(numRowsToShow + numRowsPerPage);
	}

/***************************************************************************************************
 * 
 * 																		RENDER
 * 
 **************************************************************************************************/
	
	
	/**
	 * Render the header row
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_header_row() : JSX.Element {

		/**
	 * Render a table header
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_th(name: string, type: MediaListColumns): JSX.Element {
		const thClassName = sortBy === type ? styles.sortActive : "";

		return (
			<th
				onClick={() => {
					setSortBy(type);
					setSortDirection(!sortDirection);
					// Uncheck all items
					toggle_check_all(false);
				}}
				className={thClassName}
			>
				<div className={styles.thContent}>
					{name}
					{sortBy !== type ? (
						<FontAwesomeIcon icon={faSort as IconProp} />
					) : sortDirection ? (
						<FontAwesomeIcon icon={faSortDown as IconProp} />
					) : (
						<FontAwesomeIcon icon={faSortUp as IconProp} />
					)}
				</div>
			</th>
		);
	}

		return (
			<tr>
				{render_th("File", "file")}
				{render_th("Author", "author")}
				{render_th("Location", "location")}
				{render_th("Size", "size")}
				{render_th("Date", "date")}
				<th>
					<input
						type="checkbox"
						checked={allChecked}
						onChange={(e) => {
							toggle_check_all(e.target.checked);
						}}
					/>
				</th>
			</tr>
		);
	}
	
	/**
	 * Render a single media row
	 * 
	 * @author 					Pætur Mortensen 
	 */
	function render_media_row(medium: MediaListItem): JSX.Element {
		/**
		 * Render the file table cell
		 *
		 * @author 					Pætur Mortensen
		 */
		function render_file_td(medium: MediaListItem): JSX.Element {
			// Thumbnail file name (file in media folder)
			const thumbnailFileName =
				medium.fileName + "_thumbnail." + medium.fileExtension;

			return (
				<div className={styles.fileContainer}>
					<div className={styles.imageContainer}>
						<img
							src={uploadsURL + thumbnailFileName}
							alt={medium.fileName}
							onError={handle_image_error}
						/>
					</div>
					<div>
						<b>{medium.name}</b>
						<br />
						{medium.fileName + "." + medium.fileExtension}
					</div>
				</div>
			);
		}

		return (
			<>
				<tr
					key={medium.mediaID}
					className={styles.mediaItemRow}
					onClick={() => {
						setOpenMediaItem(medium.mediaID);
					}}
				>
					<td>{render_file_td(medium)}</td>
					<td className={styles.authorCell}>{medium.author}</td>
					<td className={styles.locationsCell}>{medium.locations}</td>
					<td className={styles.sizeCell}>
						<b>{medium.fileSize}</b>
						<br />
						{medium.width > 0 && (
							<span className={styles.imageDimensions}>
								{medium.width} X {medium.height}
							</span>
						)}
					</td>
					<td className={styles.dateCell}>
						<b>{medium.dateRelative}</b>
						<br />
						<span className={styles.shortDate}>{medium.dateShort}</span>
					</td>
					<td
						className={styles.checkboxCell}
						onClick={(e) => e.stopPropagation()}
					>
						<input
							type="checkbox"
							checked={checkedItems.includes(medium.mediaID)}
							onChange={(e) => {
								handle_item_check(e.target.checked, medium.mediaID);
							}}
						/>
					</td>
				</tr>
				{openMediaItem === medium.mediaID && (
					<MediaItemCard
						medium={medium}
						close={close_media_item_card}
						shift={(direction) => {
							shift_media_item_card(direction);
						}}
						reload_media={reload_media}
						insert_media={insert_media}
					/>
				)}
			</>
		);
	}
	

	return (
		<table className={styles.mediaListTable}>
			<tbody>
				{render_header_row()}
				{mediaItems.slice(0, numRowsToShow).map( medium => render_media_row(medium))}
				{numRowsToShow < mediaItems.length &&
					<tr key="showMore">
						<td colSpan={6}>
							<div 
								className={styles.showMoreContainer}
								onClick={show_more}
							>
								Show more
							</div>
						</td>
					</tr>
				}	
			</tbody>
		</table>
	);
}
